<template>
    <div class="pa-2">
        <v-card-title class="subtitle">
            패널 고정 문구
        </v-card-title>
        <v-card-text>

            <!--
            <div class="d-flex">
                <v-switch
                        v-model="panel_comment.pc_status"
                        class="ma-0 mr-10"
                        label="PC"
                        color="teal"
                        inset
                ></v-switch>

                <v-switch
                        v-model="panel_comment.mo_status"
                        class="ma-0 "
                        label="Mobile"
                        color="teal"
                        inset
                ></v-switch>
            </div>
            -->

            <table class="mtable">
                <thead>
                <tr class="theader">
                    <th>변경 문구</th>
                    <th>최근 업데이트</th>
                    <th>관리</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!!!panel_comment.comment1">
                    <td v-if="panel_type == 'pbyc'">
                        당신을 위해<br>준비했어요
                    </td>
                    <td v-else-if="panel_type == 'best'">
                        고민할 필요 없어요<br>BEST N
                    </td>
                    <td v-else-if="panel_type == 'db'">
                        비용이 궁금하신가요?<br>지금 상담해보세요.
                    </td>
                    <td>-</td>
                    <td>
                        <v-btn icon color="teal" @click="update">
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
                <tr v-else>
                    <td v-if="panel_type == 'pbyc'">
                        {{panel_comment.comment1}}<br>{{panel_comment.comment2}}
                    </td>
                    <td v-else-if="panel_type == 'best'">
                        {{panel_comment.comment1}}<br>BEST N
                    </td>
                    <td v-else-if="panel_type == 'db'">
                        {{panel_comment.comment1}}<br>{{panel_comment.comment2}}
                    </td>
                    <td>{{panel_comment.comment_updated}}</td>
                    <td>
                        <v-btn icon color="teal" @click="update">
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
                </tbody>
            </table>
        </v-card-text>

        <v-dialog v-model="isshow" persistent max-width="600px" style="background-color: white">
            <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                    패널 고정 문구
                </v-card-title>
                <v-card-text>
                <div class="pl-4 pr-4">
                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-row class="pt-2">
                            <v-col class="py-0">
                                <v-text-field
                                        v-model="update_form.comment1"
                                        outlined
                                        dense
                                        :placeholder="p_comment1"
                                        counter="13"
                                        :rules="!!update_form.comment1?maxRules:[]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0">
                                <v-text-field
                                        v-model="update_form.comment2"
                                        outlined
                                        dense
                                        :placeholder="p_comment2"
                                        :disabled="panel_type=='best'?true:false"
                                        :hide-details="panel_type=='best'?true:false"
                                        counter="13"
                                        :rules="!!update_form.comment2?maxRules:[]"
                                        :class="panel_type=='best'?'pb-4':''"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
                </v-card-text>

                <div class="px-4 pb-4 d-flex">
                    <v-btn class="mr-1 flex-grow-1"
                           depressed
                           dark
                           color="grey"
                           @click="isshow=false"
                    >
                        취소
                    </v-btn>
                    <v-btn class="ml-1 flex-grow-1"
                           depressed
                           dark
                           color="#EC407A"
                           @click="updateComment"
                    >
                        저장
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            company_code:{
                type: String
            },
            panel_type:{
                type: String
            },
        },
        components: {

        },
        mounted() {
            if(this.panel_type == 'pbyc'){
                this.p_comment1 = '당신을 위해'
                this.p_comment2 = '준비했어요'
            }else if(this.panel_type == 'best'){
                this.p_comment1 = '고민할 필요 없어요'
                this.p_comment2 = 'BEST N'
            }else if(this.panel_type == 'db'){
                this.p_comment1 = '비용이 궁금하신가요?'
                this.p_comment2 = '지금 상담해보세요.'
            }
            this.load()
        },
        watch:{
            company_code:function () {
                this.load()
            },
            panel_type:function () {
                this.load()
            }
        },
        data: () => ({
            p_comment1:'',
            p_comment2:'',
            isshow:false,
            valid:false,
            panel_comment:{
                pc_status : 0,
                mo_status: 0,
            },
            update_form:{},
            required: [
                v => !!v || '필수 정보입니다'
            ],
            maxRules: [
                v => (v.length <= 13) || '13자 이하로 설정해주세요',
            ],
        }),
        methods: {
            load:function() {
                var that = this
                var url = "/api/more/get/panel_comment?panel_type=" + this.panel_type
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    that.panel_comment = res.data
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            update: function() {
                if(this.panel_type == 'pbyc'){
                    this.p_comment1 = '당신을 위해'
                    this.p_comment2 = '준비했어요'
                }else if(this.panel_type == 'best'){
                    this.p_comment1 = '고민할 필요 없어요'
                    this.p_comment2 = 'BEST N'
                }else if(this.panel_type == 'db'){
                    this.p_comment1 = '비용이 궁금하신가요?'
                    this.p_comment2 = '지금 상담해보세요.'
                }

                this.update_form = Object.assign({}, this.panel_comment)
                this.isshow = true
                this.$refs.form.resetValidation()
            },
            updateComment: function () {
                if (!this.$refs.form.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/update/panel_comment"
                this.update_form.panel_type = this.panel_type
                if(!!this.company_code){
                    this.update_form.company_code = this.company_code
                }
                this.$http.post(url, this.update_form).then(res=>{
                    if(res.status == '200'){
                        that.isshow = false
                        that.$event.$emit('alert','패널 고정 문구가 변경되었습니다')
                        that.load()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
        }
    }
</script>

<style scoped>

</style>