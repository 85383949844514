<template>
  <div>
    <div style="text-align: center;font-weight:600;color:#333333;font-size:14px;" class="mb-2">{{ tit }}</div>
    <Doughnut
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
    </div>
</template>


<script>
import { Doughnut } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 230
    },
    height: {
      type: Number,
      default: 230
    },
    cssClasses: {
      default: '',
      type: String
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    },
    tit: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true
        }
      },
      plugins: [{
        id: 'text',
        beforeDraw: function(chart, a, b) {
          var width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

          var percent = Math.round((chart.config.data.datasets[0].data[0] * 100) /
              (chart.config.data.datasets[0].data[0] +
                  chart.config.data.datasets[0].data[1]));
          var oldFill = ctx.fillStyle;
          var fontSize = ((height - chart.chartArea.top) / 100).toFixed(2);

          ctx.restore();
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";

          var text = percent + "%",
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = (height + chart.chartArea.top) / 2;

          ctx.fillStyle = chart.config.data.datasets[0].backgroundColor[0];
          ctx.fillText(text, textX, textY);
          ctx.fillStyle = oldFill;
          ctx.save();
        }
      }],
    }
  }
}
</script>

<style scoped>

</style>