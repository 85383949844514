<template>
    <div class="pa-2">
        <v-card-title class="subtitle">
            {{device}} 프로모션 패널 설정
        </v-card-title>
        <v-card-text>

            <table class="mtable">
                <thead>
                <tr class="theader">
                    <th>프로모션 이름</th>
                    <th>이미지</th>
                    <th>노출기간</th>
                    <th>노출상태</th>
                    <th>최근 업데이트</th>
                    <th>관리</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!!banner_list && banner_list.length == 0">
                    <td class="text-center" colspan="6" style="color: #888">등록된 프로모션이 없습니다</td>
                </tr>
                <tr v-else v-for="(item,i) in banner_list" :key="i">
                    <td>{{item.pro_name}}</td>
                    <td>
                        <v-btn style="margin-top:2px !important"
                               color="teal"
                               @click="showFile(item.pro_image_url)"
                               outlined
                        ><v-icon>mdi-image</v-icon>
                            이미지 보기
                        </v-btn>
                    </td>
                    <td>{{item.period}}</td>
                    <td>{{item.sta_text}}</td>
                    <td v-html="item.update_date"></td>
                    <td v-if="i == 0">
                        <v-btn icon color="teal" @click="update(item)">
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                    </td>
                    <td v-else-if="item.status == '0'">
                        <v-btn icon color="teal" @click="update(item)">
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="gray" @click="deleteBanner(item)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </td>
                    <td v-else-if="item.status == '1'">
                        <v-btn icon color="teal" @click="update(item)">
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="gray" @click="deleteBanner(item)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </td>
                    <td v-else-if="item.status == '2'">
                        <v-btn icon color="gray" @click="deleteBanner(item)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </td>
                    <td v-else></td>
                </tr>
                </tbody>
            </table>
        </v-card-text>

        <v-btn depressed block @click="show">
            프로모션 추가
        </v-btn>

        <v-dialog v-model="isshow" persistent max-width="600px" style="background-color: white">
            <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                    프로모션 정보
                </v-card-title>
                <v-card-text>
                </v-card-text>

                <div class="pl-4 pr-4">

                    <v-form
                            ref="form1"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-row class="ma-0 pa-0">
                            <div v-if="platform == 'pc'" class="text-right" style="width: 100%;font-size:12px;color:#f00;font-weight:600">
                                [이미지 사이즈] 가로: 420px, 세로: 850px
                            </div>
                            <div v-else class="text-right" style="width: 100%;font-size:12px;color:#f00;font-weight:600">
                                [이미지 사이즈] 가로: 640px, 세로: 590px
                            </div>
                        </v-row>
                        <v-row class="mt-0 pt-0">
                            <v-col cols="4" style="align-self: center;color: #666;font-size: 0.9em" v-if="!!filename">
                                <span style="text-overflow: ellipsis;overflow:hidden;">
                                    {{filename}}
                                </span>
                            </v-col>
                            <v-col :cols="!!filename?8:12">
                                <v-btn class="mt-3"
                                       color="#566270"
                                       block dark depressed
                                       @click="attachFile"
                                >
                                    프로모션 이미지 파일 선택
                                </v-btn>
                                <input ref="file" id="file" type="file" accept="image/*" hidden
                                       @change="fileHandle">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pb-0">
                                <v-text-field class="img_view"
                                        label="프로모션 이름"
                                        v-model="banner_form.pro_name"
                                        outlined
                                        required
                                        dense
                                        :rules="required"
                                >
                                    <template v-if="!!banner_form.pro_image_url" v-slot:append-outer>
                                        <v-btn style="margin-top:2px !important"
                                                color="teal"
                                                @click="showFile(banner_form.pro_image_url)"
                                               text
                                        >이미지 보기</v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0">
                                <v-text-field
                                        label="프로모션 랜딩 URL"
                                        v-model="banner_form.pro_page_url"
                                        outlined
                                        required
                                        dense
                                        :rules="required"
                                        hint="http:// 또는 https:// 를 포함해주세요"
                                        persistent-hint
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="(banner_form.mode == 'insert' && (!!banner_list && banner_list.length > 0)) || (banner_form.mode == 'update' && !!banner_form.start_ymd)" class="px-3 pt-3" style="text-align: center">
                            <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="center-input"
                                                  label="노출 시작일"
                                                  v-model="banner_form.start_ymd"
                                                  readonly
                                                  v-on="on"
                                                  style="width:140px;"
                                                  :rules="required"
                                                  outlined
                                                  dense
                                                  hint="오늘 이후"
                                                  persistent-hint
                                                  :disabled="banner_form.mode=='update'?true:false"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="banner_form.start_ymd" no-title scrollable color="teal" @input="menu=false"></v-date-picker>
                            </v-menu>
                            <span class="pt-3" style="width:40px;text-align: center;"> ~ </span>
                            <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="center-input"
                                                  label="노출 종료일"
                                                  v-model="banner_form.end_ymd"
                                                  readonly
                                                  v-on="on"
                                                  style="width:140px;"
                                                  :rules="required"
                                                  outlined
                                                  dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="banner_form.end_ymd" no-title scrollable color="teal" @input="menu2=false"></v-date-picker>
                            </v-menu>
                            <!--<my-range title="노출기간 선택" v-model="banner_form.date_range"/>-->
                        </v-row>
                    </v-form>
                </div>


                <div class="pa-4 d-flex">
                    <v-btn class="mr-1 flex-grow-1"
                           depressed
                           dark
                           color="grey"
                           @click="isshow=false"
                    >
                        취소
                    </v-btn>
                    <v-btn class="ml-1 flex-grow-1"
                           depressed
                           dark
                           color="#EC407A"
                           @click="addBanner"
                    >
                        저장
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

    export default {
        props:{
            company_code:{
                type: String
            },
            platform:{
                type: String
            },
            device:{
                type: String
            },
        },
        components: {
        },
        mounted() {
            this.load()
        },
        watch:{
            company_code:function () {
                this.load()
            },
        },
        data: () => ({
            menu:false,
            menu2:false,
            valid: false,
            valid1: false,
            filename:null,
            file:null,
            fileurl : '',
            isshow:false,
            banner_list:null,
            banner_form:{
                pro_name: '',
                pro_image_url: '',
                start_ymd: '',
                end_ymd: '',
                pro_page_url: '',
                mode: ''
            },
            required: [
                v => !!v || '필수 정보입니다'
            ],
        }),
        methods: {
            load:function() {
                var that = this
                var url = "/api/more/get/panel_promotion?platform=" + this.platform
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    that.banner_list = res.data
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            show:function() {
                this.banner_form.pro_name = ''
                this.banner_form.pro_image_url = ''
                this.banner_form.start_ymd = ''
                this.banner_form.end_ymd = ''
                this.banner_form.pro_page_url = ''

                this.banner_form.mode = 'insert'

                this.isshow=true
                this.$refs.form1.resetValidation()
            },
            update:function(item) {
                this.banner_form = Object.assign({}, item)

                this.banner_form.mode = 'update'

                this.isshow=true
            },
            attachFile:function () {

                // upload
                document.getElementById("file").click()

            },
            fileHandle() {
                var f2 = this.$refs.file.files[0];
                if (!!!f2)
                    return;

                this.file = f2
                var v = this

                // 입력된 파일로 교체
                var reader = new FileReader();
                reader.addEventListener("load", function () {
                    //v.imgsrc = reader.result
                }, false);

                if (this.file) {
                    reader.readAsDataURL(this.file);
                }

                this.filename = document.getElementById("file").value
                var n = this.filename.lastIndexOf('/')
                if (n < 0) {
                    n = this.filename.lastIndexOf('\\')
                }
                if (n < 0)
                    return

                this.filename = this.filename.substring(n + 1, this.filename.length)
            },
            addBanner: function() {
                if (!this.$refs.form1.validate()) {
                    return
                }

                if(!!this.banner_form.start_ymd){
                    if(this.banner_form.mode == 'insert') {
                        var now = this.$moment(new Date()).format('YYYY-MM-DD')
                        if (this.banner_form.start_ymd < now) {
                            this.$event.$emit('alert', "노출 시작일을 확인해주세요")
                            return
                        }
                    }

                    if(this.banner_form.start_ymd > this.banner_form.end_ymd){
                        this.$event.$emit('alert',"노출 기간을 확인해주세요")
                        return
                    }
                }

                if(!!this.file) {
                    this.submitFile()
                } else {
                    this.addBanner2()
                }

            },
            addBanner2: function() {
                if(this.banner_form.mode == 'insert') {
                    if (this.banner_form.b_type == 'I' && !!!this.fileurl) {
                        this.$event.$emit('alert', "이미지를 첨부해주세요")
                        return
                    }
                }else{
                    if (this.banner_form.b_type == 'I'){
                        if(!!!this.banner_form.pro_image_url && !!!this.fileurl) {
                            this.$event.$emit('alert', "이미지를 첨부해주세요")
                            return
                        }
                    }
                }

                var that = this
                var url = ''
                if(this.banner_form.mode == 'insert'){
                    url = "/api/more/create/panel_promotion"
                }else if(this.banner_form.mode == 'update'){
                    url = "/api/more/update/panel_promotion"
                }
                this.banner_form.platform = this.platform
                if(!!this.company_code){
                    this.banner_form.company_code = this.company_code
                }
                if(!!this.fileurl){
                    this.banner_form.pro_image_url = this.fileurl
                }

                var msg = ''
                if(this.banner_form.mode == 'insert'){
                    msg = '프로모션이 등록되었습니다'
                }else if(this.banner_form.mode == 'update'){
                    msg = '프로모션이 변경되었습니다'
                }
                this.$http.post(url, this.banner_form).then(res=>{
                    if(res.status == '200'){
                        that.file = null
                        that.filename = null
                        that.fileurl = ''
                        that.isshow = false
                        that.$event.$emit('alert',msg)
                        that.load()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    var errmsg = err.response.data.msg
                    if(errmsg == 'DUPLICATED'){
                        msg = '노출기간에 중복된 프로모션이 있습니다'
                    }
                    that.$event.$emit('alert',msg)
                })
            },
            submitFile(){
                let formData = new FormData()
                formData.append('file', this.file)

                var v = this
                this.isLoading = true
                this.$http.post(
                    'https://img0-upload.adnext.co/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/vid',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(data){
                    var url = data.data
                    // 업로드한 파일 이미지
                    v.fileurl = url
                    v.addBanner2()
                })
                .catch(function(err){
                    v.$event.$emit('alert', '파일 업로드에 실패하였습니다.')
                    v.isLoading = false
                });
            },
            showFile:function (url) {
                window.open(
                    url,
                    '_blank'
                );
            },
            deleteBanner: function(item) {
                var del = Object.assign({}, item)
                var that = this
                var url = "/api/more/delete/panel_promotion"
                del.platform = this.platform
                if(!!this.company_code){
                    del.company_code = this.company_code
                }

                this.$event.$emit('confirm', '프로모션 삭제', '선택하신 프로모션을 삭제하시겠습니까?', function () {
                    that.$http.post(url, del).then(res=>{
                        if(res.status == '200'){
                            that.$event.$emit('alert','프로모션이 삭제되었습니다')
                            that.load()
                        }
                    }).catch((err)=>{
                        var msg = '오류가 발생했습니다'
                        that.$event.$emit('alert',msg)
                    })
                })

            },
        }
    }
</script>

<style scoped>
    >>> .v-input__append-outer {margin-top:2px !important;}
</style>