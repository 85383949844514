<template>

    <div class="d-flex mt-4">

        <v-card
                v-if="!!mydata"
                class="pa-4 pb-6 mr-3"
                style="overflow: hidden;width:400px;position:relative;"
                outlined
        >
            <div style="position: absolute;top:12px;right:12px;">
                <v-btn class="mr-2"
                       depressed
                       dark
                       color="grey"
                       @click="load"
                >
                    취소
                </v-btn>
                <v-btn
                        depressed
                        dark
                        color="#EC407A"
                        @click="saveSettings"
                >
                    저장
                </v-btn>
            </div>

            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
            >
                <div class="pb-4 mt-4">
                    노출 타이밍 설정
                </div>

                <v-subheader class="pl-1" style="font-weight: 600;color:#333">1. 타이밍 설정</v-subheader>

                <div style="font-size: 0.8em" class="pl-3">
                    <table style="width: 100%">
                        <tr>
                            <td class="text-left">
                                - 메시지 노출시간 (Show)
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.show_time"
                                        style="width: 80px"
                                        suffix="초"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                - 다음 메시지까지 미노출 시간 (Hidden)
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.hide_time"
                                        style="width: 80px"
                                        suffix="초"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                - 닫기 클릭 후 미노출 시간
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.noshow"
                                        style="width: 80px"
                                        suffix="시간"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </div>

                <v-subheader class="pl-1" style="font-weight: 600;color:#333">2. 행동 기준 설정</v-subheader>
                <div style="font-size: 0.8em" class="pl-3">
                    <table style="width: 100%">
                        <tr>
                            <td class="text-left">
                                - 스크롤 탐색 조건<br>(설정 이상 스크롤을 내리면 노출됩니다.)
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.scroll_deep"
                                        style="width: 80px"
                                        suffix="%"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </div>

                <v-divider class="mt-3"></v-divider>

                <div class="pb-4 pt-4">
                    디자인 설정
                </div>

                <div>

                    <v-subheader class="pl-1" style="font-weight: 600;color:#333">
                        1. 기본 티커 설정
                        <v-btn style="margin-left:20px !important"
                               color="teal"
                               @click="preview()"
                               outlined
                        >
                            미리보기
                        </v-btn>
                    </v-subheader>

                    <div style="font-size: 0.8em" class="pl-3">

                        <!--
                        <v-select
                                label="레이아웃"
                                :items="layout"
                                item-text="name"
                                item-value="id"
                                dense
                                outlined
                                hide-details
                        ></v-select>
                        -->

                        <div class="pb-2 pt-4">
                            - 타이틀 텍스트 컬러
                        </div>
                        <my-color v-model="mydata.title_text_color"/>

                        <div class="pb-2 pt-4">
                            - 상세 설명 컬러
                        </div>
                        <my-color v-model="mydata.desc_text_color"/>

                        <div class="pb-2 pt-4">
                            - 강조 텍스트 컬러
                        </div>
                        <my-color v-model="mydata.point_text_color"/>

                        <div class="pb-2 pt-4">
                            - 노출위치
                        </div>

                        <div class="ticker_container">
                          <div :class="mydata.position==1?'ticker_item_selected':'ticker_item'" @click="changePosition(1)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==2?'ticker_item_selected':'ticker_item'" @click="changePosition(2)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==3?'ticker_item_selected':'ticker_item'" @click="changePosition(3)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==4?'ticker_item_selected':'ticker_item'" @click="changePosition(4)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==6?'ticker_item_selected':'ticker_item'" @click="changePosition(6)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==7?'ticker_item_selected':'ticker_item'" @click="changePosition(7)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==8?'ticker_item_selected':'ticker_item'" @click="changePosition(8)"></div>
                          <div style="height:10px;"></div>
                          <div :class="mydata.position==9?'ticker_item_selected':'ticker_item'" @click="changePosition(9)"></div>
                        </div>

                        <div class="pb-2 pt-4">
                          - 노출위치 미세조정(선택한 구간에서 위아래 px 조절)
                        </div>

                        <div class="d-flex">
                          <v-text-field
                              v-model="mydata.move"
                              class="ml-4"
                              suffix="px"
                              outlined
                              dense
                              :rules="required2"
                              hint="선택한 구간으로부터 -nn 기재 시 상단으로 이동, nn기재 시 하단으로 이동됩니다."
                              persistent-hint
                          ></v-text-field>
                        </div>

                        <div class="pb-2 pt-4">
                          - 애니메이션 효과
                        </div>
                        <div class="d-flex">
                            <v-select
                                    :items="animation"
                                    v-model="mydata.animation"
                                    item-text="value"
                                    item-value="code"
                                    return-code
                                    dense
                                    outlined
                                    hide-details
                            ></v-select>
                        </div>
                    </div>
                </div>


                <div class="mt-6">

                    <v-subheader class="pl-1" style="font-weight: 600;color:#333">
                        2. 상품 연계 티커 설정
                        <v-btn style="margin-left:20px !important"
                               color="teal"
                               @click="previewProd()"
                               outlined
                        >
                            미리보기
                        </v-btn>
                    </v-subheader>

                    <div style="font-size: 0.8em" class="pl-3">
                        <!--
                        <v-select
                                label="레이아웃"
                                :items="layout"
                                item-text="name"
                                item-value="id"
                                dense
                                outlined
                                hide-details
                        ></v-select>
                        -->
                        <div class="pb-2 pt-4">
                            - 텍스트 컬러
                        </div>
                        <my-color v-model="mydata.prod_text_color" />

                        <div class="pb-2 pt-4">
                            - 배경 컬러
                        </div>
                        <my-color v-model="mydata.prod_bg_color" />

                        <div class="pb-2 pt-4">
                            - 배경 투명도 (투명 <v-icon x-small color="black">mdi-arrow-left-right</v-icon> 불투명)
                        </div>
                        <v-slider
                                v-model="mydata.prod_opacity"
                                color="teal"
                                min="1"
                                max="100"
                                thumb-label
                                hide-details
                        ></v-slider>

                        <div class="pb-2 pt-4">
                            - 노출위치
                        </div>
                        <div class="d-flex">
                            <v-select style="width:110px"
                                    :items="positions"
                                    v-model="mydata.position_prod"
                                    item-text="value"
                                    item-value="code"
                                    return-code
                                    dense
                                    outlined
                                    hide-details
                            ></v-select>
                            <v-text-field
                                    v-model="mydata.vertical_prod"
                                    class="ml-4"
                                    suffix="px"
                                    outlined
                                    dense
                                    hide-details
                                    :rules="required"
                            ></v-text-field>

                        </div>

                    </div>
                </div>
            </v-form>
        </v-card>

        <v-card v-if="!!comment_vcount"
                class="pa-4 pb-6 flex-grow-1 ml-3"
                style="overflow: hidden;"
                outlined
        >
            <div class="pb-4 mt-4">
                메시지 설정
            </div>

            <v-subheader>구매 알림 메시지</v-subheader>
            <v-row class="mx-1">
                <v-col cols="9" class="my-0 py-0">
                    <v-text-field
                            v-model="comment_latest.comment"
                            outlined
                            dense
                            counter="14"
                            :rules="!!comment_latest.comment?maxRules:[]"
                            placeholder="누가 이 상품을 샀어요!"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="my-0 py-0">
                    <v-btn class="pl-2 pr-2"
                           style="min-width: 45px !important;"
                           text
                           dark
                           color="grey"
                           @click="cancelMessage('latest')"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            class="pl-2 pr-2"
                            style="min-width: 45px !important;"
                            text
                            dark
                            color="#EC407A"
                            @click="saveMessage('latest')"
                    >
                        저장
                    </v-btn>
                </v-col>
            </v-row>

            <v-subheader>인기 상품 알림 메시지</v-subheader>
            <v-row class="mx-1">
                <v-col cols="9" class="my-0 py-0">
                    <v-text-field
                            v-model="comment_best.comment"
                            outlined
                            dense
                            counter="14"
                            :rules="!!comment_best.comment?maxRules:[]"
                            placeholder="지금 가장 인기있는 상품!"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="my-0 py-0">
                    <v-btn class="pl-2 pr-2"
                           style="min-width: 45px !important;"
                           text
                           dark
                           color="grey"
                           @click="cancelMessage('best')"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            class="pl-2 pr-2"
                            style="min-width: 45px !important;"
                            text
                            dark
                            color="#EC407A"
                            @click="saveMessage('best')"
                    >
                        저장
                    </v-btn>
                </v-col>
            </v-row>


            <v-subheader>개인화 추천 메시지</v-subheader>
            <v-row class="mx-1">
                <v-col cols="9" class="my-0 py-0">
                    <v-text-field
                            v-model="comment_pbyc.comment"
                            outlined
                            dense
                            counter="14"
                            :rules="!!comment_pbyc.comment?maxRules:[]"
                            placeholder="당신을 위해 준비했어요"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="my-0 py-0">
                    <v-btn class="pl-2 pr-2"
                           style="min-width: 45px !important;"
                           text
                           dark
                           color="grey"
                           @click="cancelMessage('pbyc')"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            class="pl-2 pr-2"
                            style="min-width: 45px !important;"
                            text
                            dark
                            color="#EC407A"
                            @click="saveMessage('pbyc')"
                    >
                        저장
                    </v-btn>
                </v-col>
            </v-row>


            <v-form
                    ref="form1"
                    v-model="valid1"
                    lazy-validation
            >
                <v-subheader>구매수 알림 메시지</v-subheader>
                <v-row class="mx-1">
                    <v-col cols="9" class="my-0 py-0">
                        <v-text-field
                                v-model="comment_bcount.comment"
                                prefix="최근/오늘"
                                outlined
                                dense
                                :rules="alertRules"
                                placeholder="{N}명이 이 상품을 구매했어요!"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                        <v-btn class="pl-2 pr-2"
                               style="min-width: 45px !important;"
                               text
                               dark
                               color="grey"
                               @click="cancelMessage('bcount')"
                        >
                            취소
                        </v-btn>
                        <v-btn
                                class="pl-2 pr-2"
                                style="min-width: 45px !important;"
                                text
                                dark
                                color="#EC407A"
                                @click="saveMessage('bcount')"
                        >
                            저장
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>


            <v-form
                    ref="form2"
                    v-model="valid2"
                    lazy-validation
            >
                <v-subheader class="mt-0">조회수 알림 메시지</v-subheader>
                <v-row class="mx-1">
                    <v-col cols="9" class="my-0 py-0">
                        <v-text-field
                                v-model="comment_vcount.comment"
                                prefix="최근/오늘"
                                outlined
                                dense
                                :rules="alertRules"
                                placeholder="{N}명이 이 상품을 봤어요!"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                        <v-btn class="pl-2 pr-2"
                               style="min-width: 45px !important;"
                               text
                               dark
                               color="grey"
                               @click="cancelMessage('vcount')"
                        >
                            취소
                        </v-btn>
                        <v-btn
                                class="pl-2 pr-2"
                                style="min-width: 45px !important;"
                                text
                                dark
                                color="#EC407A"
                                @click="saveMessage('vcount')"
                        >
                            저장
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <div style="font-size: 0.8em;color:#999" class="mt-4">
                [TIP]<br>
                구매수 알림과 조회수 알림 메시지는 최소 숫자를 {10} 과 같이 설정하여 입력하셔야 합니다.<br>
                구매수 알림과 조회수 알림 메시지의 {N} 값은, 노출을 위한 최소 기준 값 입니다.<br>
                입력하신 N 값 이상부터 해당 메시지가 노출 됩니다.
            </div>

        </v-card>

        <v-dialog v-model="isshow" max-width="400px" style="background-color: white">
            <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                    티커 미리보기
                </v-card-title>
                <v-card-text>
                </v-card-text>


                <div class="pl-4" align="center" style="height:100px">
                  <Transition
                      :name="tanimation"
                      :enter-active-class="tanimation_enter"
                      leave-active-class="animate__animated animate__fadeOut"
                  >
                    <iframe v-if="isshow3" :src="preview_url" frameborder="0"
                            id="ifr_ticker"
                            v-on:load="iframe_loaded"
                            style="width:320px;height:74px"></iframe>
                  </transition>
                </div>

                <div class="pa-4 d-flex">
                    <v-btn class="mr-1 flex-grow-1"
                           depressed
                           dark
                           color="grey"
                           @click="closePreview"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isshow_prod" max-width="400px" style="background-color: white">
          <v-card class="pa-4">
            <v-card-title class="pa-3" style="color: #666">
              티커 미리보기
            </v-card-title>
            <v-card-text>
            </v-card-text>


            <div class="pl-4" align="center">
              <iframe v-if="isshow4" :src="preview_url_prod" frameborder="0"
                      id="ifr_ticker_prod"
                      v-on:load="iframe_loaded2"
                      style="width:320px;height:74px"></iframe>
            </div>

            <div class="pa-4 d-flex">
              <v-btn class="mr-1 flex-grow-1"
                     depressed
                     dark
                     color="grey"
                     @click="closePreviewProd"
              >
                닫기
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

    </div>

</template>
<script>
    import MyColor from "./MyColor";
    export default {
        props:{
            company_code:{
                type: String
            },
        },
        components: {
            MyColor
        },
        watch:{
            company_code: function () {
                this.load()
                this.load_message()
            }
        },
        mounted() {
            this.load()
            this.load_message()
        },
        data: () => ({
            isshow:false,
            isshow_prod:false,
            isshow3:false,
            isshow4:false,
            tanimation: '',
            tanimation_enter: '',
            preview_url:'',
            preview_url_prod:'',
            valid:false,
            valid1:false,
            valid2:false,
            positions: [
                { value: '상단', code: 1 },
                { value: '하단', code: 9 },
            ],
            animation: [
              { value: '밝기변화', code: 'fade' },
              { value: '날아오기', code: 'bounce' },
              { value: '올라오기', code: 'fu' },
              { value: '확대/축소', code: 'zoom' },
              { value: '펄스', code: 'hb' },
              { value: '흔들기', code: 'swing' },
              { value: '뒤집기', code: 'flip' },
            ],
            mydata:null,
            comments:null,
            comment_latest: null,
            comment_best: null,
            comment_pbyc: null,
            comment_bcount: null,
            comment_vcount: null,
            required: [
                v => !!v || '필수 입력값입니다',
            ],
            required2: [
              v => !!v || v == '0' || '필수 입력값입니다',
            ],
            maxRules: [
                v => (v.length <= 14) || '14자 이하로 설정해주세요',
            ],
            alertRules: [
                v => !!v || '필수 입력값입니다',
                v => /{[0-9]+}/.test(v) || '문구에 반드시 최소 숫자를 설정해 주세요',
            ],
        }),
        methods:{
            load: function () {
                var that = this
                var url = "/api/more/get/ticker/settings?platform=mobile_web"
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        res.data.vertical = res.data.vertical.replace('px','')
                        res.data.move = res.data.move
                        res.data.vertical_prod = res.data.vertical_prod.replace('px','')
                        that.mydata = res.data
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_message: function () {
                var that = this
                var url = "/api/more/get/ticker/messages?platform=mobile_web"
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.comments = res.data
                        that.comments.forEach(function (e, i) {
                            if(i == 0){
                                that.comment_latest = that.clone(e)
                            }else if(i == 1){
                                that.comment_best = that.clone(e)
                            }else if(i == 2){
                                that.comment_pbyc = that.clone(e)
                            }else if(i == 3){
                                that.comment_bcount = that.clone(e)
                            }else if(i == 4){
                                that.comment_vcount = that.clone(e)
                            }
                        })

                        if(!!this.$refs.form1){
                            this.$refs.form1.resetValidation()
                        }
                        if(!!this.$refs.form2){
                            this.$refs.form2.resetValidation()
                        }
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            changePosition: function(pos){
              this.mydata.position = pos
            },
            clone: function(obj){
                var output = {}
                for(var i in obj){
                    output[i] = obj[i]
                }
                return output
            },
            saveSettings: function () {
                if(!this.$refs.form.validate()){
                    return
                }

                var that = this
                var url = "/api/more/update/ticker/settings"
                this.mydata.platform = 'mobile_web'
                if(!!this.company_code){
                    this.mydata.company_code = this.company_code
                }
                this.$http.post(url, this.mydata).then(res=>{
                    if(res.status == '200'){
                        that.$event.$emit('alert','티커 설정이 변경되었습니다')
                        that.load()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
            cancelMessage: function(type) {
                if(type == 'latest'){
                    this.comment_latest = this.clone(this.comments[0])
                }else if(type == 'best'){
                    this.comment_best = this.clone(this.comments[1])
                }else if(type == 'pbyc'){
                    this.comment_pbyc = this.clone(this.comments[2])
                }else if(type == 'bcount'){
                    this.comment_bcount = this.clone(this.comments[3])
                    this.$refs.form1.resetValidation()
                }else if(type == 'vcount'){
                    this.comment_vcount = this.clone(this.comments[4])
                    this.$refs.form2.resetValidation()
                }
            },
            saveMessage: function (type) {
                var params = {}
                if(type == 'latest'){
                    params = this.comment_latest
                }else if(type == 'best'){
                    params = this.comment_best
                }else if(type == 'pbyc'){
                    params = this.comment_pbyc
                }else if(type == 'bcount'){
                    if(!this.$refs.form1.validate()){
                        return
                    }
                    params = this.comment_bcount
                }else if(type == 'vcount'){
                    if(!this.$refs.form2.validate()){
                        return
                    }
                    params = this.comment_vcount
                }

                var that = this
                var url = "/api/more/update/ticker/message"
                this.$http.post(url, params).then(res=>{
                    if(res.status == '200'){
                        that.$event.$emit('alert','티커 메시지가 변경되었습니다')
                        that.load_message()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
            preview: function () {
                var src = "/html/ticker_example_m.html"

                this.preview_url = src
                this.tanimation = this.mydata.animation
                if(this.tanimation == 'fade') {
                  this.tanimation_enter = 'animate__animated animate__fadeIn'
                }else if(this.tanimation == 'bounce') {
                  this.tanimation_enter = 'animate__animated animate__bounceInUp'
                }else if(this.tanimation == 'zoom') {
                  this.tanimation_enter = 'animate__animated animate__zoomIn'
                }else if(this.tanimation == 'hb') {
                  this.tanimation_enter = 'animate__animated animate__heartBeat'
                }else if(this.tanimation == 'swing') {
                  this.tanimation_enter = 'animate__animated animate__swing'
                }else if(this.tanimation == 'flip') {
                  this.tanimation_enter = 'animate__animated animate__flipInX'
                }else {
                  this.tanimation_enter = 'animate__animated animate__fadeInUp'
                }
                this.isshow = true
                var that = this
                setTimeout(function() {
                  that.isshow3 = true
                }, 500)
            },
            previewProd: function () {
              var src = "/html/ticker_example_m_prod.html"

              this.preview_url_prod = src
              this.isshow_prod = true
              this.isshow4 = true
            },
            closePreview: function() {
              this.isshow3 = false
              var that = this
              setTimeout(function () {
                that.isshow = false
              }, 300)
            },
            closePreviewProd: function() {
              this.isshow4 = false
              this.isshow_prod = false
            },
            iframe_loaded: function() {
              this.sendMessage()
            },
            sendMessage: function() {
              try {
                var iframeId = "ifr_ticker"
                var frameContentWindow = document.getElementById(iframeId).contentWindow
                if (frameContentWindow.postMessage) {
                  var data = {
                    'command': 'change_color',
                    'point_text_color': this.mydata.point_text_color,
                    'title_text_color': this.mydata.title_text_color,
                    'desc_text_color': this.mydata.desc_text_color,
                  }
                  frameContentWindow.postMessage(JSON.stringify(data), "*")
                }
              }catch (e) {
                alert(e)
              }
            },
            iframe_loaded2: function() {
              this.sendMessage2()
            },
            sendMessage2: function() {
              try {
                var iframeId = "ifr_ticker_prod"
                var frameContentWindow = document.getElementById(iframeId).contentWindow
                if (frameContentWindow.postMessage) {
                  var data = {
                    'command': 'change_color',
                    'prod_text_color': this.mydata.prod_text_color,
                    'prod_bg_color': this.mydata.prod_bg_color,
                    'prod_opacity': this.mydata.prod_opacity/100,
                  }
                  frameContentWindow.postMessage(JSON.stringify(data), "*")
                }
              }catch (e) {
                alert(e)
              }
            }
        }
    }
</script>
<style scoped>
.ticker_container {
  width: 260px;
  height: 533px;
  display: flex;
  flex-direction: column;
  background: url('../../assets/images/device.png') no-repeat center;
  background-size: contain;
  padding: 75px 26px;
  justify-content: space-between;
}
.ticker_item {
  background-color: #e3e3e3;
  width: 100%;
  height: 39px;
  cursor: pointer;
}
.ticker_item_selected {
  background-color: #29D1CF;
  width: 100%;
  height: 39px;
  cursor: pointer;
}
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
</style>