<template>
    <div>
        <div class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="패널 관리"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="panel"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    v-if="admin == 0 || !!form.company_code"
            >
                <v-tab key="opt1"
                >
                    Step1. 지면 관리
                </v-tab>
                <v-tab key="opt2"
                >
                    Step2. 패널소재 관리
                </v-tab>
                <v-tab key="opt3"
                >
                    Step3. 노출 관리
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless
                          v-if="admin == 0 || !!form.company_code"
            >
                <v-tab-item
                        class="pa-5"
                        key="opt1">

                    <div :key="flag">
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div v-if="!!min_ymd">
                                <manage-panel mode="PC" class="mb-6" :company_code="form.company_code" :min_ymd="min_ymd"/>

                                <manage-panel mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="opt2">
                    <div>
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <my-tab v-model="tab" :list="tab_list"/>

                            <div v-if="tab == 0">
                              <panel-pbyc :company_code="form.company_code" />
                            </div>

                            <div v-else-if="tab == 1">
                              <panel-best :company_code="form.company_code" />
                            </div>

                            <div v-else-if="tab == 2">
                              <panel-promotion :company_code="form.company_code" />
                            </div>

                            <div v-else-if="tab == 3">
                              <panel-db :company_code="form.company_code" />
                            </div>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item
                        class="pa-5"
                        key="opt3">

                    <div :key="flag">
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div v-if="!!min_ymd">
                                <manage-panel-status mode="PC" class="mb-6" :company_code="form.company_code" :min_ymd="min_ymd"/>

                                <manage-panel-status mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <!--
        <v-btn
                fab
                dark
                color="#EC407A"
                style="position: fixed;z-index: 10;bottom:0;right:0;margin-right: 30px;margin-bottom: 30px"
                v-if="(admin == 0 || !!form.company_code) && mytab == 0"
                @click="savePageStatus"
        >
            <v-icon>mdi-content-save</v-icon>
        </v-btn>
        -->
    </div>
</template>

<script>
    import MyTab from "../../common/MyTab";
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import { mapMutations, mapGetters } from 'vuex'
    import ManagePanel from "../../common/ManagePanel";
    import MyColor from "../../common/MyColor";
    import PromotionBanner from "../../common/PromotionBanner";
    import PanelComment from "../../common/PanelComment";
    import PromotionImage from "../../common/PromotionImage";
    import BestUpload from "../../common/BestUpload";
    import ManagePanelStatus from "../../common/ManagePanelStatus";
    import PanelPbyc from "@/components/page/manage/PanelPbyc";
    import PanelPromotion from "@/components/page/manage/PanelPromotion";
    import PanelDb from "@/components/page/manage/PanelDb";
    import PanelBest from "@/components/page/manage/PanelBest";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        watch:{
            admin: function () {
                this.loadAll()
            },
            "form.company_code": function () {
                this.loadAll()
            },
            mytab2: function () {
                //this.sendMessage()
            },
            mytab:function () {
                this.flag++
            },
        },
        components: {
            MainTitle,
            MySelect,
            ManagePanel,
            MyTab,
            MyColor,
            PromotionBanner,
            PanelComment,
            PromotionImage,
            BestUpload,
            ManagePanelStatus,
            PanelPbyc,
            PanelPromotion,
            PanelDb,
            PanelBest,
        },
        created() {
        },
        mounted() {
            this.loadAll()
        },
        beforeDestroy() {
        },
        methods:{
            flag:0,
            loadAll: function() {
                this.tab = 0
                this.load_current()
                this.load_common()
            },
            load_current: function() {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                var that = this
                var url = "/api/more/get/current_period?type=panel"
                if(!!this.form.company_code){
                    url += "&company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.term_start = res.data.term_start
                        that.term_end = res.data.term_end
                        var today = this.$moment(new Date()).format('YYYYMMDD')
                        that.min_ymd = this.$moment(that.term_start,'YYYYMMDD').format('YYYY-MM-DD')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_common: function(){
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                this.common = null
                var that = this
                var url = "/api/more/get/panel_common"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.common = res.data
                        //var frameContentWindow = document.getElementById('pc_panel').contentWindow
                        //console.log(frameContentWindow)

                    }
                }).catch((err)=>{
                    console.log(err)
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
        },
        data: () => ({
            min_ymd: '',
            form: {
                company_code: ''
            },
            common: null,

            mytab:null,
            mytab2:null,
            show_pc:false,
            params:{},

            tab:0,
            tab_list: [
                '<span class="mr-1" style="font-size: 1.3em"></span>\n' +
                '개인화 추천',
                '<span class="mr-1" style="font-size: 1.3em"></span>\n' +
                '베스트 추천',
                '<span class="mr-1" style="font-size: 1.3em"></span>\n' +
                '프로모션',
                '<span class="mr-1" style="font-size: 1.3em"></span>\n' +
                'DB 수집',
            ],
            term_start: '',
            term_end: '',
            show1:false,
            show2:false,
        })
    }
</script>

<style scoped>
.mtable > tbody > tr > td {
    padding: 20px;
}
.tab-item-wrapper {
    /* vuetify sets the v-tabs__container height to 48px */
    height: 100%;
}
</style>
