<template>
    <v-container fluid>
        <div style="padding-top:50px;text-align: center;width:100%;">
            <span class="text-gradient">404</span>
            <br>

            <div class="pa-2 pt-6" style="margin:auto">
                페이지를 찾을 수 없습니다.
            </div>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>
<style scoped>
    .text-gradient {
        font-size: 80px;
        font-weight: 500;
        font-family: "Roboto", "Helvetica Neue", "Helvetica";
        background: rgb(255,122,78);
        background: linear-gradient(0deg, rgba(255,122,78,1) 0%, rgba(255,78,145,1) 100%);
        color: #886ab5;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
    }
</style>