<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title :title="page_title"/>
                </v-card-title>
            </div>
            <div class="pa-4">
                <div>
                    <v-alert
                            text
                            dense
                            color="teal"
                            icon="mdi-text-subject"
                            border="left"
                    >
                        사이트 정보
                    </v-alert>
                    <v-form
                            ref="form2"
                            v-model="valid2"
                            lazy-validation
                            class="ma-3"
                    >
                        <v-row>
                            <v-col>
                                <v-text-field
                                        label="사이트명"
                                        v-model="siteInfo.company_name"
                                        disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-btn class="mt-3"
                                       color="#566270"
                                       block dark depressed
                                       @click="showPaymentInfo"
                                >
                                    정산 정보
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-text-field
                                label="사이트 URL (PC)"
                                v-model="siteInfo.site_url"
                                disabled
                        ></v-text-field>
                        <v-text-field
                                label="사이트 URL (Mobile)"
                                v-model="siteInfo.site_m_url"
                                disabled
                        ></v-text-field>
                        <v-select
                                v-model="siteInfo.service_ver"
                                :items="service_items"
                                item-text="value"
                                item-value="code"
                                label="커머스/논커머스"
                                return-code
                                disabled
                        ></v-select>
                        <v-select
                                v-model="siteInfo.category"
                                :items="cate_items"
                                item-text="value"
                                item-value="code"
                                label="업종"
                                return-code
                                disabled
                        ></v-select>
                        <v-select
                                v-model="siteInfo.hosting"
                                :items="host_items"
                                item-text="value"
                                item-value="code"
                                label="호스팅사"
                                return-code
                                disabled
                        ></v-select>
                        <v-select
                                v-model="siteInfo.sales_ch"
                                :items="sales_items"
                                item-text="value"
                                item-value="code"
                                label="영업채널"
                                :rules="selectRules"
                                return-code
                        ></v-select>
                        <v-select
                                v-model="siteInfo.comment2"
                                :items="agency_items"
                                item-text="value"
                                item-value="code"
                                label="대행사"
                                return-code
                        ></v-select>
                        <v-textarea class="mt-3"
                                v-model="siteInfo.comment"
                                rows="5"
                                label="비고"
                                counter="5000"
                                outlined
                        ></v-textarea>
                        <v-btn class="mt-3"
                               color="#29d1cf"
                               @click="updateInfo"
                               block dark depressed
                        >
                            수정하기
                        </v-btn>
                    </v-form>
                </div>
                <div>
                    <v-alert
                            text
                            dense
                            color="teal"
                            icon="mdi-account"
                            border="left"
                            class="mt-11"
                    >
                        계정 정보
                    </v-alert>
                    <v-form
                            ref="form1"
                            v-model="valid1"
                            lazy-validation
                            class="ma-3"
                    >
                        <v-text-field
                                label="아이디"
                                v-model="myInfo.id"
                                disabled
                        ></v-text-field>
                        <v-text-field
                                label="법인명(회사명)"
                                v-model="myInfo.name"
                                disabled
                        ></v-text-field>
                        <v-text-field
                                label="담당자"
                                v-model="myInfo.manager"
                                disabled
                        ></v-text-field>
                        <v-text-field
                                label="이메일"
                                v-model="myInfo.email"
                                disabled
                        ></v-text-field>
                        <v-text-field
                                label="연락처"
                                v-model="myInfo.phone"
                                disabled
                        ></v-text-field>
                    </v-form>
                </div>
            </div>
        </v-card>

        <v-dialog v-model="payment_dlg" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">정산 정보</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form2"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-text-field
                                label="법인(상호)명"
                                v-model="payment.corp_name"
                                readonly
                        ></v-text-field>
                        <v-text-field
                                label="사업자 등록번호"
                                v-model="payment.license_num"
                                readonly
                        >
                            <template v-slot:append-outer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on"
                                                color="teal"
                                                @click="showFile"
                                        >mdi-file-document-outline</v-icon>
                                    </template>
                                    사업자등록증 보기
                                </v-tooltip>
                            </template>
                        </v-text-field>
                        <v-text-field
                                label="세금계산서 담당자"
                                v-model="payment.tax_manager"
                                readonly
                        ></v-text-field>
                        <v-text-field
                                label="세금계산서 수신 이메일"
                                v-model="payment.tax_email"
                                readonly
                        ></v-text-field>
                    </v-form>
                    <v-spacer></v-spacer>
                    <div >
                        회원가입 및 약관동의 시점 : {{payment.agree_date}}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="payment_dlg = false">닫기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    export default {
        computed: {
            page_title:function() {
                return this.siteInfo.company_name + ' <span style="font-size:0.7em;">(' + this.siteInfo.company_code + ')</span>'
            }
        },
        mounted() {
            this.company_code = this.$route.query.company_code
            this.selectload()
            this.load()
        },
        components: {
            MainTitle,
        },
        data: () => ({
            company_code: '',
            myInfo: {},
            siteInfo: {},
            service_items: [
                { value: '커머스', code: 'service01' },
                { value: '논커머스', code: 'service02' },
            ],
            cate_items: [],
            agency_items: [],
            host_items: [],
            sales_items: [
                { value: '직접영업(IB)', code: 'sales03' },
                { value: '직접영업(AE)', code: 'sales01' },
                { value: '직접영업(OB)', code: 'sales04' },
                { value: '대행사', code: 'sales02' },
                { value: '제휴사(godo)', code: 'sales05' },
                { value: '제휴사(KCP)', code: 'sales06' },
            ],
            nameRules: [
                v => !!v || '필수 정보입니다'
            ],
            selectRules: [
                v => !!v || '필수 정보입니다'
            ],
            valid1: false,
            valid2: false,
            payment_dlg: false,
            payment: {
                corp_name: '',
                license_num: '',
                licens_file_url: '',
                tax_manager: '',
                tax_email: '',
            }
        }),
        methods: {
            selectload : function() {
                var that = this
                var url = "/api/more/get/codes?type=category"
                this.$http.get(url).then(res=>{
                    res.data.forEach(function(e, i){
                        that.cate_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })

                var url2 = "/api/more/get/codes?type=agency"
                this.$http.get(url2).then(res=>{
                    res.data.forEach(function(e, i){
                        that.agency_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })

                var url3 = "/api/more/get/codes?type=hosting"
                this.$http.get(url3).then(res=>{
                    res.data.forEach(function(e, i){
                        that.host_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })
            },
            load : function(){
                var that = this
                var url = "/api/more/account/info?company_code=" + this.company_code
                this.$http.get(url).then(res=>{
                    that.myInfo = res.data
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })

                var url2 = "/api/more/site/info?company_code=" + this.company_code
                this.$http.get(url2).then(res=>{
                    if(res.data.length > 0) {
                        that.siteInfo = res.data[0]
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            updateInfo:function () {
                if (!this.$refs.form2.validate()) {
                    return
                }

                var that = this
                this.$event.$emit('confirm', '사이트 정보 변경', '정보를 수정하시겠습니까?', function () {
                    var url = "/api/more/update/siteinfo"
                    this.$http.post(url, that.siteInfo).then(res=>{
                        if(res.data.msg == "OK"){
                            that.$event.$emit('alert',"저장되었습니다")
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert',"오류가 발생했습니다")
                    })
                });
            },
            showFile: function() {
                window.open(
                    this.payment.license_file_url,
                    '_blank'
                );
            },
            showPaymentInfo: function () {
                var that = this
                var url = "/api/more/corp/info?company_code=" + this.company_code
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        var data = res.data
                        that.payment.corp_name = data.corp_name
                        that.payment.license_num = data.license_num
                        that.payment.tax_manager = data.tax_manager
                        that.payment.tax_email = data.tax_email
                        that.payment.license_file_url = data.license_file_url
                        that.payment.agree_date = that.$moment(data.agree_date).format('YYYY.MM.DD HH:mm:ss')
                        that.payment_dlg = true
                    }else{
                        that.$event.$emit('alert',"정산 정보가 없습니다")
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
        }
    };
</script>