<template>
  <v-app>
    <router-view style="height:100%"/>
    <my-snackbar/>
    <my-confirm></my-confirm>
  </v-app>
</template>

<script>
import MySnackbar from "./components/common/MySnackbar";
import MyConfirm from "./components/common/MyConfirm";
import debounce from 'lodash/debounce';
import {mapGetters} from "vuex";
export default {
  name: 'App',

  components: {
    MySnackbar,
    MyConfirm
  },
  computed: mapGetters({
    admin: 'admin',
  }),
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  data: () => ({
    //
  }),
  methods: {
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if(this.admin == 1) {
        var sid = this.$cookie.get('sid');
        var skey = this.$cookie.get('skey');
        this.$cookie.set('sid', sid, { expires: '1h', domain: 'mocoplex.com' });
        this.$cookie.set('skey', skey, { expires: '1h', domain: 'mocoplex.com' });
      }
    }
  },
};
</script>
<style lang="scss">
tbody {
  tr:hover {
    background: transparent !important;
  }
}
</style>
