<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div v-if="option == 'daily'" class="flex-grow-1" style="margin:auto;">기간 비교</div>
                <div v-else class="flex-grow-1" style="margin:auto;">리포트 요약</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadSummaryCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>

        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div v-if="option == 'daily'" class="flex-grow-1">
                      <v-simple-table
                          style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                      >
                        <template v-slot:default>
                          <thead style="background-color: #eee"
                          >
                          <tr>
                            <th class="text-center bb bt br">기간</th>
                            <th class="text-center bb bt br">노출수</th>
                            <th class="text-center bb bt br">클릭수</th>
                            <th v-if="admin == 1" class="text-center bb bt br">전환수</th>
                            <th v-if="admin == 1" class="text-center bb bt br">구매수량</th>
                            <th v-if="admin == 1" class="text-center bb bt br">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in report_list" :key="i">
                            <td v-if="i == 0" class="text-center bb">
                              <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                              <br/>
                              <span style="font-size: 12px">(조회기간)</span>
                            </td>
                            <td v-else class="text-center bb">
                              <span v-html="$_ymd_s(reportParam.search_start_date)"/> ~ <span v-html="$_ymd_s(reportParam.search_end_date)"/>
                              <br/>
                              <span style="font-size: 12px">(비교기간)</span>
                            </td>
                            <td class="text-center bb">{{ item.impression.price() }}</td>
                            <td class="text-center bb">{{ item.click.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.conversion.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.conversion_total.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.sales.price() }}</td>
                          </tr>
                          </tbody>
                          <tfoot>
                          <tr v-if="!!report_list && report_list.length > 0">
                            <td class="text-center sum bbt bbb">증감</td>
                            <td class="text-center sum bbt bbb">
                              <span v-if="report_list[1].impression == 0">-</span>
                              <span v-else v-html="plusminus(report_list[0].impression, report_list[1].impression)"></span>
                            </td>
                            <td class="text-center sum bbt bbb">
                              <span v-if="report_list[1].click == 0">-</span>
                              <span v-else v-html="plusminus(report_list[0].click, report_list[1].click)"></span>
                            </td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">
                              <span v-if="report_list[1].conversion == 0">-</span>
                              <span v-else v-html="plusminus(report_list[0].conversion, report_list[1].conversion)"></span>
                            </td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">
                              <span v-if="report_list[1].conversion_total == 0">-</span>
                              <span v-else v-html="plusminus(report_list[0].conversion_total, report_list[1].conversion_total)"></span>
                            </td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">
                              <span v-if="report_list[1].sales == 0">-</span>
                              <span v-else v-html="plusminus(report_list[0].sales, report_list[1].sales)"></span>
                            </td>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                    <div v-else-if="option == 'keyword'" class="flex-grow-1">
                      <v-simple-table
                          style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                      >
                        <template v-slot:default>
                          <thead style="background-color: #eee"
                          >
                          <tr>
                            <th class="text-center bb bt br">기간</th>
                            <th class="text-center bb bt br">디바이스</th>
                            <th class="text-center bb bt br">키워드</th>
                            <th class="text-center bb bt br">노출수</th>
                            <th class="text-center bb bt br">클릭수</th>
                            <th v-if="admin == 1" class="text-center bb bt br">전환수</th>
                            <th v-if="admin == 1" class="text-center bb bt br">구매수량</th>
                            <th v-if="admin == 1" class="text-center bb bt">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in report_list" :key="i">
                            <td class="text-center bb">
                              <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                            </td>
                            <td v-if="reportParam.platform == 'mobile_ios'" class="text-center bb">mobile_ios</td>
                            <td v-else-if="reportParam.platform == 'mobile_aos'" class="text-center bb">mobile_aos</td>
                            <td v-else class="text-center bb">{{ item.platform }}</td>
                            <td class="text-center bb">{{ item.keyword }}</td>
                            <td class="text-center bb">{{ item.impression.price() }}</td>
                            <td class="text-center bb">{{ item.click.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.conversion.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.conversion_total.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.sales.price() }}</td>
                          </tr>
                          </tbody>
                          <tfoot>
                          <tr v-for="(item,i) in report_total" :key="i">
                            <td colspan="3" class="text-center sum bbt bbb">합계</td>
                            <td class="text-center sum bbt bbb">{{ item.impression.price() }}</td>
                            <td class="text-center sum bbt bbb">{{ item.click.price() }}</td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.conversion.price() }}</td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.conversion_total.price() }}</td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.sales.price() }}</td>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
            companyName : 'companyName',
            admin: 'admin',
        }),
        props:{
            option:{
                type:String,
                default:''
            }
        },
        watch: {
            reportParam:function() {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {

                if(!!!this.reportParam.start_date || !!!this.reportParam.end_date)
                    return

                var that = this;
                if (this.option == 'daily') {
                    var url = "/api/more/report/review/daily_summary"
                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data

                      var url2 = "/api/more/report/review/daily_summary_total_for_compare"
                      that.$http.post(url2, this.reportParam).then(res => {
                        that.report_total = res.data
                      }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                      })
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })

                } else if(this.option == 'keyword'){
                  var url = "/api/more/report/review/keyword_summary"

                  this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/review/keyword_total"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                }
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            plusminus:function(a, b){
                var pm = ((a/b-1)*100).toFixed(2)
                pm = pm.replace('.00','')
                return pm + '%'
            },
            plusminusAll:function(a, b, c, d){
              var pm = (((a/b) - (c/d))*100).toFixed(2)
              pm = pm.replace('.00','')
              return pm + '%'
            },
            showDialog:function(cost){
                this.dialog = true
                this.expected.total = cost
                this.expected.supply = Math.round(cost/1.1);
                this.expected.tax = cost - this.expected.supply;
            },
            downloadSummaryCSV: function () {
                var url = "/api/more/report/review/summary_csv?"
                if(!!this.companyName){
                    this.reportParam.company_name = this.companyName
                }
                var params = new URLSearchParams(this.reportParam).toString();
                if (this.option == 'daily') {
                    params += '&type=1'
                    location.href = url + params
                }else if (this.option == 'keyword') {
                    params += '&type=2'
                    location.href = url + params
                }
            }
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list: [],
            report_total: {},
            dialog: false,
            expected: {},
            data1: {},
            data2: {},
            data3: {},
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>