<template>
<div>
    <v-form
            ref="form"
            v-model="valid"
            lazy-validation
    >
        <v-alert
                text
                dense
                color="teal"
                icon="mdi-alert-circle"
                border="left"
        >
            회원 탈퇴시 유의사항
        </v-alert>

        <div class="mx-5" style="font-size: 0.9em;line-height:1.8;color: #666">
            <!--<div>* 탈퇴처리가 완료되는 즉시 해당 아이디의 회원정보는 삭제됩니다.</div>-->
            <div>* 회원 탈퇴 이후 해당 ID는 로그인이 불가능하며, 재가입이 제한될 수 있습니다.</div>
            <div>* 회원 탈퇴 이후 회원 정보 및 이용 데이터는 복구되지 않습니다.</div>
            <div>* 회원 탈퇴 이후 유료 서비스에 대한 환불 및 복구처리가 불가능합니다.</div>

            <v-checkbox
                    class="mt-8"
                    label="안내사항을 모두 확인하였으며 이에 동의합니다."
                    color="teal"
                    :rules="checkboxRules">
            </v-checkbox>
        </div>

        <v-alert
                class="mt-8"
                text
                dense
                color="teal"
                icon="mdi-alert-circle"
                border="left"
        >
            탈퇴를 위한 회원정보 인증
        </v-alert>

        <div class="mx-5">
            <v-row>
                <v-col cols="2" style="align-self: center">
                    <div>
                        아이디
                    </div>
                </v-col>
                <v-col>
                    <v-text-field outlined dense hide-details
                                  readonly
                                  v-model="formData.id"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="2" style="align-self: center">
                    <div>
                        비밀번호
                    </div>
                </v-col>
                <v-col>
                    <v-text-field outlined dense
                                  v-model="formData.password"
                                  autocomplete="off"
                                  type="password"
                                  hide-details
                                  :rules="required"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="2" style="align-self: center">
                    <div>
                        이메일
                    </div>
                </v-col>
                <v-col>
                    <div>
                        <v-row class="mt-0 mb-0">
                            <v-col class="pt-0 pb-0">
                                <v-text-field outlined dense
                                              hide-details
                                              v-model="formData.email"
                                              :rules="emailRules"
                                              @keyup="enableBtn"
                                              :readonly="auth"
                                              placeholder="담당자의 이메일주소를 입력해주세요."
                                />
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0">
                                <v-btn depressed color="#566270" block
                                       @click="sendEmail"
                                       :dark="!disEmailBtn"
                                       :disabled="disEmailBtn"
                                       dense
                                >인증메일 발송</v-btn>
                            </v-col>
                        </v-row>

                    </div>
                    <div class="mt-3">
                        <v-row class="mt-0 mb-0">
                            <v-col class="pt-0 pb-0">
                                <v-text-field outlined dense
                                              v-model="formData.code"
                                              hide-details
                                              :disabled="need"
                                              placeholder="인증번호를 입력해주세요."
                                />
                            </v-col>
                            <v-col cols="3" class="pt-0 pb-0">
                                <v-btn v-if="auth == false" depressed color="#29d1cf" block
                                       @click="onAuth"
                                       :disabled="need"
                                       outlined
                                       dense
                                >인증번호 확인</v-btn>
                                <v-btn v-else depressed dark
                                       color="#29d1cf" block
                                       dense
                                ><v-icon dark v-if="auth == true">mdi-check-circle-outline</v-icon></v-btn>
                            </v-col>
                        </v-row>

                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn class="mt-3"
                           color="#29d1cf"
                           block dark depressed
                           @click="cancelMembership"
                    >
                        탈퇴하기
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-form>
</div>
</template>
<script>

    export default {
        props: {
        },
        mounted() {
            this.load()
        },
        watch:{
        },
        components: {

        },
        data: () => ({
            valid: false,
            disEmailBtn:true,
            auth: false,
            need: true,
            required: [
                v => !!v || '필수 정보입니다'
            ],
            checkboxRules: [
                v => !!v || '탈퇴시 유의사항에 동의해주세요',
                //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],
            emailRules: [
                v => !!v || '필수 정보입니다',
                v => /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v) ||
                    '이메일 형식이 올바르지 않습니다'
            ],
            formData:{
                id:'',
                password:'',
                email:'',
                code:'',
            },
        }),
        methods: {
            load : function(){
                var that = this
                var url = "/api/user/info"
                this.$http.get(url).then(res=>{
                    that.formData.id = res.data.id
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    this.$event.$emit('alert',msg)
                })
            },
            sendEmail:function(){
                if(this.auth){
                    this.$event.$emit('alert','이미 인증 되었습니다')
                    return
                }

                var url = "/api/user/send/code"
                //console.log(this.formData)
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.need = false
                        this.$event.$emit('alert','이메일이 발송되었습니다')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            enableBtn:function(){
                var v = this.formData.email
                if(!!!v) {
                    this.disEmailBtn = true
                }else if(!/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v)){
                    this.disEmailBtn = true
                }else
                    this.disEmailBtn = false
            },
            onAuth:function(){
                var url = "/api/user/auth"
                //console.log(this.formData)
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.auth = true
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    if(msg == 'NOTVALID'){
                        msg = '인증번호를 다시 확인해주세요'
                    }else{
                        msg = '오류가 발생했습니다'
                    }
                    this.$event.$emit('alert',msg)
                })
            },
            cancelMembership: function () {
                if (!this.$refs.form.validate()) {
                    window.scrollTo(0, 0)
                    return
                }

                if(!this.auth){
                    this.$event.$emit('alert', '이메일 인증을 완료해주세요')
                    return
                }

                var that = this
                this.$event.$emit('confirm', '회원 탈퇴', '정말로 탈퇴하시겠습니까?', function () {
                    var url = "/api/user/delete"
                    that.$http.post(url, that.formData).then(res=>{
                        if(res.data.msg == "OK"){
                            that.logout()
                        }
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        if(msg == 'NOTVALID'){
                            that.auth = false
                            msg = '회원 정보가 일치하지 않습니다'
                        }else if(msg == 'NODATA'){
                            msg = '모든 항목을 입력해주세요'
                        }else if(msg == 'NOPAID'){
                            msg = '미납내역이 있어 탈퇴하실 수 없습니다.<br>결제 완료 후 진행해주세요'
                        }else{
                            msg = '오류가 발생했습니다'
                        }
                        this.$event.$emit('alert',msg)
                    })
                });
            },
            logout: function () {
                var url = "/api/user/logout_all"
                this.$http.post(url).then(res=>{
                    if(res.status == '200'){
                        document.location.href = '/'
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    this.$event.$emit('alert',msg)
                })
            }
        }
    }
</script>
<style scoped>
</style>