<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="리뷰 리포트"/>
                </v-card-title>
                <v-card-text class="stitle">
                    ! 기간별/키워드별 리포트는 한번의 노출당 최대 한번의 클릭만 집계하며, 리뷰별 리포트는 중복 클릭을 체크합니다.
                </v-card-text>
            </div>

            <div class="pt-4 pl-6">
                <v-row v-if="admin == 1">
                    <v-col cols="4">
                        <my-select
                                type="review"
                                option_default="전체"
                                :value="form2.company_code"
                                @input="(newCC) => {form2.company_code = newCC}"
                        ></my-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <my-date-range @mychange="calendarChanged" :start="computedStartDate" :end="computedEndDate"></my-date-range>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" v-if="mytab == 0">
                        <my-date-range @mychange="calendarChanged2" title="비교기간" :start="computedStartDate2" :end="computedEndDate2"></my-date-range>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col style="max-width:250px;">
                        <v-select
                                style="max-width:250px;"
                                :items="dList"
                                v-model="form2.platform"
                                label="디바이스"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                        ></v-select>
                    </v-col>
                </v-row>
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    class="pl-6"
            >
                <v-tab key="daily"
                >
                    기간별
                </v-tab>
                <v-tab key="keyword"
                >
                    키워드별
                </v-tab>
                <v-tab key="review"
                >
                    리뷰별
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless>
                <v-tab-item
                        class="pa-5"
                        key="daily">
                    <review-summary option="daily" ref="daily"/>
                    <div style="height:30px"/>
                    <review-detail option="daily" ref="daily"/>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="keyword">
                    <review-summary v-if="admin == 0 || !!form2.company_code" option="keyword" ref="keyword"/>
                    <div v-if="admin == 0 || !!form2.company_code" style="height:30px"/>
                    <review-detail option="keyword" ref="keyword"/>
                </v-tab-item>
                <v-tab-item
                    class="pa-5"
                    key="review">
                  <review-detail v-if="admin == 0 || !!form2.company_code" option="review" ref="review"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import ReviewSummary from "@/components/page/report/ReviewSummary";
    import ReviewDetail from "@/components/page/report/ReviewDetail";
    import MyDateRange from "../../common/MyDateRange";

    import {mapGetters} from "vuex";
    export default {
        computed: {
            computedStartDate() {
              var v = this.$moment(new Date()).format('YYYYMM01')
              return this.$moment(v, 'YYYYMMDD').toDate()
            },computedEndDate() {
              return new Date()
            },computedStartDate2() {
              var v = this.$moment(new Date()).add(-1, 'months')
              return v.startOf('month').toDate()
            },computedEndDate2() {
              var v = this.$moment(new Date()).add(-1, 'months')
              return v.endOf('month').toDate()
            },...mapGetters({
              companyCode: 'companyCode',
              admin: 'admin',
            })
        },
        watch: {
            admin:function () {
                this.dataload()
            },
            "form2.company_code":function () {
                this.dataload()
            },
            "form2.platform":function () {
                this.dataload()
            },
            mytab:function () {
                this.form2.page = 1
                this.dataload()
            }
        },
        mounted() {
            this.dataload()
        },
        components: {
            MainTitle,
            MySelect,
            MyDateRange,
            ReviewSummary,
            ReviewDetail
        },
        data: () => ({
            mytab: '',
            form2: {
                company_code : '',
                admin : -1,
                start_date:'',
                end_date:'',
                search_start_date:'',
                search_end_date:'',
                platform: '',
            },
            dList:[{id:"", name:"전체"},{id:"pc", name:"pc"},{id:"mobile_web", name:"mobile 전체"},{id:"mobile_aos", name:"mobile aos"},{id:"mobile_ios", name:"mobile ios"}],
        }),
        methods: {
            calendarChanged:function (v) {
                this.form2.start_date = v.start
                this.form2.end_date = v.end
                this.dataload()
            },
            calendarChanged2:function (v) {
                this.form2.search_start_date = v.start
                this.form2.search_end_date = v.end
                this.dataload()
            },
            dataload: function() {
                if(this.admin == -1)
                    return

                this.form2.admin = this.admin
                var v = Object.assign({}, this.form2)
                this.$store.commit('reportParam', {value:v})
            }
        }
    };
</script>