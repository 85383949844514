import { render, staticRenderFns } from "./TotalCurrent.vue?vue&type=template&id=2c92b998&scoped=true"
import script from "./TotalCurrent.vue?vue&type=script&lang=js"
export * from "./TotalCurrent.vue?vue&type=script&lang=js"
import style0 from "./TotalCurrent.vue?vue&type=style&index=0&id=2c92b998&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c92b998",
  null
  
)

export default component.exports