<template>
    <div>
        <div class="pa-2">
            <v-card-title class="subtitle">
                베스트 상품 목록 <span class="ml-3"><a href="/file/more_%EB%B2%A0%EC%8A%A4%ED%8A%B8%EC%83%81%ED%92%88_%ED%85%9C%ED%94%8C%EB%A6%BF.xlsx" download>[템플릿 다운로드]</a></span>
            </v-card-title>
            <v-card-text>

                <div style="width:calc(100vw - 340px)">
                    <div class="d-flex" style="overflow: auto">
                            <v-simple-table class="mtable" style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0">
                                <thead>
                                <tr class="theader">
                                    <th>순위</th>
                                    <th>상품코드</th>
                                    <th>상품명</th>
                                    <th>PC 페이지 URL</th>
                                    <th>Mobile 페이지 URL</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!!best_list && best_list.length == 0">
                                    <td class="text-center" colspan="5" style="color: #888">등록된 베스트 상품이 없습니다</td>
                                </tr>
                                <tr v-else v-for="(item) in best_list" :key="item.item">
                                    <td>{{item.rank}}</td>
                                    <td>{{item.recommend_product}}</td>
                                    <td>{{item.p_name}}</td>
                                    <td><a :href="item.p_url" target="_blank">{{item.p_url}}</a></td>
                                    <td><a :href="item.p_url_m" target="_blank">{{item.p_url_m}}</a></td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                    </div>
                </div>
            </v-card-text>
        </div>

        <div class="pa-2">
            <v-card-title class="subtitle">
                베스트 상품 업로드
            </v-card-title>

            <v-card
                    outlined
                    class="px-3 pt-3"
            >
                <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                >
                    <v-row>
                        <v-col cols="4" style="align-self: center;color: #666;font-size: 0.9em">
                            <v-text-field
                                    label="작업명"
                                    v-model="job_form.job_name"
                                    outlined
                                    dense
                                    counter="20"
                                    :rules="maxRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" style="text-align: right;padding-top:20px;color: #666;font-size: 0.9em" v-if="!!filename">
                                    {{filename}}
                        </v-col>
                        <v-col :cols="!!filename?3:6">
                            <v-btn
                                   color="#566270"
                                   block dark depressed
                                   @click="attachFile"
                            >
                                파일찾기
                            </v-btn>
                            <input ref="file" id="excelfile" type="file" accept=".xlsx, .xls" hidden
                                   @change="fileHandle">
                        </v-col>
                        <v-col :cols="2">
                            <v-btn
                                    color="#EC407A"
                                    block dark depressed
                                    @click="upload_excel"
                            >
                                업로드
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </div>

        <div class="pa-2">
            <v-card-title class="subtitle">
                업로드 이력 (최근 5건)
            </v-card-title>
            <v-card-text>

                <table class="mtable">
                    <thead>
                    <tr class="theader">
                        <th>작업명</th>
                        <th>등록일시</th>
                        <th>상태</th>
                        <th>등록파일</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!!history_list && history_list.length == 0">
                        <td class="text-center" colspan="4" style="color: #888">업로드 이력이 없습니다</td>
                    </tr>
                    <tr v-else v-for="(item,i) in history_list" :key="i">
                        <td>{{item.job_name}}</td>
                        <td>{{item.register_date}}</td>
                        <td>{{item.status}}</td>
                        <td><a :href="item.file_url" download>[다운로드]</a></td>
                    </tr>
                    </tbody>
                </table>
            </v-card-text>
        </div>
    </div>
</template>

<script>

    export default {
        props:{
            company_code:{
                type: String
            },
        },
        components: {
        },
        mounted() {
            this.load()
            this.load_history()
        },
        watch:{
            company_code:function () {
                this.load()
                this.load_history()
            },
        },
        data: () => ({
            valid:false,
            filename:null,
            file:null,
            fileurl : '',
            best_list:null,
            history_list:null,
            maxRules: [
                v => !!v || '필수 정보입니다',
                v => (v.length <= 20) || '20자 이하로 설정해주세요',
            ],
            job_form: {
                job_name: ''
            }
        }),
        methods: {
            load:function() {
                var that = this
                var url = "/api/more/get/best_products"
                if(!!this.company_code){
                    url += "?company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    this.best_list = res.data
                    res.data.forEach(function(e, i){
                        var url2 = "/api/more/manage/get_catalog?item=" + e.recommend_product
                        if(!!that.company_code){
                            url2 += "&company_code=" + that.company_code
                        }
                        that.$http.get(url2).then(res2=>{
                            if(!!!res2.data.p_name){
                                e.p_name = '추천DB에 상품이 수집되지 않았습니다'
                            }else{
                                e.p_name = res2.data.p_name
                            }
                            that.$set(that.best_list, i, e)

                        }).catch((err2)=>{

                        })
                    })
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_history:function(){
                var that = this
                var url = "/api/more/get/best_history"
                if(!!this.company_code){
                    url += "?company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    that.history_list = res.data
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            attachFile:function () {

                // upload
                document.getElementById("excelfile").click()

            },
            fileHandle() {
                var f2 = this.$refs.file.files[0];
                if (!!!f2)
                    return;

                this.file = f2
                var v = this

                // 입력된 파일로 교체
                var reader = new FileReader();
                reader.addEventListener("load", function () {
                    //v.imgsrc = reader.result
                }, false);

                if (this.file) {
                    reader.readAsDataURL(this.file);
                }

                this.filename = document.getElementById("excelfile").value
                var n = this.filename.lastIndexOf('/')
                if (n < 0) {
                    n = this.filename.lastIndexOf('\\')
                }
                if (n < 0)
                    return

                this.filename = this.filename.substring(n + 1, this.filename.length)
            },
            upload_excel: function() {
                if (!this.$refs.form.validate()) {
                    return
                }

                if(!!this.file) {
                    this.submitFile()
                } else {
                    this.upload_excel2()
                }

            },
            upload_excel2: function() {
                if (!!!this.fileurl) {
                    this.$event.$emit('alert', "엑셀 파일을 첨부해주세요")
                    return
                }

                var that = this
                var url = "/api/more/upload/excel/panel_best"
                this.job_form.file_url = this.fileurl
                if(!!this.company_code){
                    this.job_form.company_code = this.company_code
                }

                this.$http.post(url, this.job_form).then(res=>{
                    if(res.status == '200'){
                        that.job_form.job_name = ''
                        that.file = null
                        that.filename = null
                        that.fileurl = ''
                        that.$refs.form.resetValidation()
                        that.$event.$emit('alert','완료되었습니다')
                        that.load()
                        that.load_history()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    var errmsg = err.response.data.msg
                    if(errmsg == 'DUPLICATED'){
                        msg = '중복된 작업명 입니다'
                    }else if(errmsg == 'FILE_ERROR'){
                        msg = '사용할 수 없는 파일형식입니다'
                    }else if(errmsg == 'NOTCOMPLETE'){
                        msg = '상품목록 갱신에 실패했습니다'
                    }
                    that.$event.$emit('alert',msg)
                })
            },
            submitFile(){
                let formData = new FormData()
                formData.append('file', this.file)

                var v = this
                this.isLoading = true
                this.$http.post(
                    'https://img0-upload.adnext.co/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/vid',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(data){
                    var url = data.data
                    // 업로드한 파일 이미지
                    v.fileurl = url
                    v.upload_excel2()
                })
                .catch(function(err){
                    v.$event.$emit('alert', '파일 업로드에 실패하였습니다.')
                    v.isLoading = false
                });
            },
            showFile:function (url) {
                window.open(
                    url,
                    '_blank'
                );
            },
        }
    }
</script>

<style scoped>
    >>> .v-input__append-outer {margin-top:2px !important;}

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>