<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="공지사항"/>
                </v-card-title>
                <v-card-text class="stitle">
                    전체 회원에게 공유가 필요한 소식을 안내합니다.
                </v-card-text>
            </div>
            <div class="ml-4 mr-4 mt-4 mb-4 pb-8">
                <v-row class="mt-3">
                    <v-spacer/>
                    <v-col cols="2">
                        <v-btn block
                               dark
                               color="#29d1cf"
                               style="font-weight: 600;"
                               depressed
                               @click="createNotice"
                        >
                            공지 작성
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-layout column style="width:calc(100vw - 300px)">
                        <div class="d-flex" style="overflow: auto">

                            <div class="flex-grow-1">
                                <v-simple-table
                                        style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                                >
                                    <template v-slot:default>
                                        <thead style="background-color: #eee"
                                        >
                                        <tr>
                                            <th class="text-center bb bt br">번호</th>
                                            <th class="text-center bb bt br" style="width:600px;">제목</th>
                                            <th class="text-center bb bt br">작성일</th>
                                            <th class="text-center bb bt br">노출수</th>
                                            <th class="text-center bb bt br">노출설정</th>
                                            <th class="text-center bb bt">기능</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="item in all_list" :key="item.nid">
                                            <td class="text-center bb">{{ item.num }}</td>
                                            <td class="text-left bb">{{ item.title }}</td>
                                            <td class="text-center bb">{{ item.reg_date}}</td>
                                            <td class="text-center bb">{{ item.impression}}</td>
                                            <td class="text-center bb">
                                                <v-layout column align-center wrap>
                                                    <v-switch v-model="item.imp_yn"
                                                              @change="changeIstatus(item)">
                                                    </v-switch>
                                                </v-layout>
                                            </td>
                                            <td class="text-center bb">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn fab depressed dark x-small v-bind="attrs" v-on="on"
                                                               color="blue-grey lighten-1"
                                                               @click="goToDetail(item)">
                                                            <v-icon>mdi-file-document-edit-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>편집</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn fab depressed dark x-small v-bind="attrs" v-on="on"
                                                               color="blue-grey lighten-1"
                                                               @click="preview(item)"
                                                               class="ml-2">
                                                            <v-icon>mdi-eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>미리보기</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn fab depressed dark x-small v-bind="attrs" v-on="on"
                                                               color="red"
                                                               @click="deleteNotice(item)"
                                                               class="ml-2"
                                                        >
                                                            <v-icon>mdi-trash-can-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>삭제</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </v-layout>
                </v-row>

                <div class="text-center pa-2">
                    <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import { mapMutations, mapGetters } from 'vuex'

    export default {
        computed: mapGetters({
        }),
        watch:{
            page:function () {
                this.dataload()
            },
        },
        components: {
            MainTitle,
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {
                var url = "/api/more/get/notice?"
                url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                this.$http.get(url).then(res=>{
                    this.all_list = res.data.content

                    var paging = res.data.pageable
                    this.page = paging["pageNumber"] + 1
                    this.pageCount = res.data.totalPages
                }).catch((err)=> {
                    this.$event.$emit('alert', '오류가 발생했습니다')
                })
            },
            createNotice:function() {
                this.$router.push({path:"/create/notice"})
            },
            changeIstatus: function (item) {
                var that = this
                this.$event.$emit('confirm', '노출여부 변경', '노출 상태를 수정하시겠습니까?', function () {
                    var url = "/api/more/update/exposure"
                    var data =  Object.assign({}, item)
                    data.imp_yn = item.imp_yn?"1":"0"

                    this.$http.post(url, data).then(res => {
                        if (res.status == '200') {
                            that.dataload()
                            that.$event.$emit('alert', '노출 상태가 변경되었습니다')
                        }
                    }).catch((err) => {
                        item.imp_yn = !item.imp_yn
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                }, function() {
                    item.imp_yn = !item.imp_yn
                })
            },
            goToDetail:function(item) {
                this.$router.push({path:'/modify/notice',query:{id: item.nid}})
            },
            preview:function(item) {
                var plist = []
                var param = {}
                param.nid = item.nid
                param.title = item.title
                param.mode = 'preview'
                plist.push(param)
                this.$event.$emit('notice', plist)
            },
            deleteNotice:function(item) {
                var that = this
                this.$event.$emit('confirm', '공지 삭제', '선택하신 공지를 삭제하시겠습니까?', function () {
                    var url = "/api/more/delete/notice"
                    this.$http.post(url, item).then(res => {
                        if (res.status == '200') {
                            that.$event.$emit('alert', "공지가 삭제되었습니다")
                            that.dataload()
                        }
                    }).catch((err) => {
                        var msg = '오류가 발생했습니다'
                        that.$event.$emit('alert', msg)
                    })
                })
            },
        },
        data: () => ({
            show1: false,
            show2: false,
            show3: false,
            page: 1,
            pageCount: 1,
            itemsPerPage: 10,
            all_list: null,
        })
    }
</script>
<style scoped>
    .bold {font-weight: 600;}

    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 11px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>