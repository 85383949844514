<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">리포트 상세</div>
                <div v-if="reportParam.admin == 1 && !!reportParam.company_code && option == 'daily'" class="mr-2">
                    <v-btn block
                           depressed
                           @click="downloadConvCSV"
                    >
                        전환데이터 다운로드</v-btn>
                </div>
                <div v-if="option !== 'review'" style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>

        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div v-if="option == 'daily'" class="flex-grow-1">
                      <v-simple-table
                          style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                      >
                        <template v-slot:default>
                          <thead style="background-color: #eee"
                          >
                          <tr>
                            <th v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb bt br">기간</th>
                            <th v-else class="text-center bb bt br">날짜</th>
                            <th v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb bt br">사이트</th>
                            <th class="text-center bb bt br">노출수</th>
                            <th class="text-center bb bt br">클릭수</th>
                            <th class="text-center bb bt br">클릭율</th>
                            <th v-if="admin == 1" class="text-center bb bt br">전환수</th>
                            <th v-if="admin == 1" class="text-center bb bt br">구매상품수</th>
                            <th v-if="admin == 1" class="text-center bb bt br">전환율</th>
                            <th v-if="admin == 1" class="text-center bb bt br">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                            <th v-if="admin == 1" class="text-center bb bt">객단가</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="item in report_list" :key="item.company_code">
                            <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb">
                              <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                            </td>
                            <td v-else class="text-center bb"><span v-html="$_ymd_s(item.ymd)"/></td>
                            <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb">{{ item.company_name }}</td>
                            <td class="text-center bb">{{ item.impression.price() }}</td>
                            <td class="text-center bb">{{ item.click.price() }}</td>
                            <td class="text-center bb" v-html="percentage(item.click,item.impression)"></td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.conversion.price() }}</td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.conversion_total.price() }}</td>
                            <td class="text-center bb" v-if="admin == 1 && item.click == 0">0%</td>
                            <td class="text-center bb" v-else-if="admin == 1" v-html="percentage(item.conversion,item.click)"></td>
                            <td v-if="admin == 1" class="text-center bb">{{ item.sales.price() }}</td>
                            <td class="text-center bb" v-if="admin == 1 && item.conversion == 0">0</td>
                            <td class="text-center bb" v-else-if="admin == 1" v-html="perPrice(item.sales,item.conversion)"></td>
                          </tr>
                          </tbody>
                          <tfoot>
                          <tr v-for="(item,i) in report_total" :key="i">
                            <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" colspan="2" class="text-center sum bbt bbb">합계</td>
                            <td v-else class="text-center sum bbt bbb">합계</td>
                            <td class="text-center sum bbt bbb">{{ item.impression.price() }}</td>
                            <td class="text-center sum bbt bbb">{{ item.click.price() }}</td>
                            <td class="text-center sum bbt bbb" v-html="percentage(item.click,item.impression)"></td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.conversion.price() }}</td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.conversion_total.price() }}</td>
                            <td class="text-center sum bbt bbb" v-if="admin == 1 && item.click == 0">0%</td>
                            <td class="text-center sum bbt bbb" v-else-if="admin == 1" v-html="percentage(item.conversion,item.click)"></td>
                            <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.sales.price() }}</td>
                            <td class="text-center sum bbt bbb" v-if="admin == 1 && item.conversion == 0">0</td>
                            <td class="text-center sum bbt bbb" v-else-if="admin == 1" v-html="perPrice(item.sales,item.conversion)"></td>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                    <div v-else-if="option == 'keyword'" class="flex-grow-1">
                        <v-simple-table
                            style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                          <template v-slot:default>
                            <thead style="background-color: #eee"
                            >
                            <tr>
                              <th v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb bt br">기간</th>
                              <th v-else class="text-center bb bt br">날짜</th>
                              <th v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb bt br">사이트</th>
                              <th class="text-center bb bt br">디바이스</th>
                              <th class="text-center bb bt br">키워드</th>
                              <th class="text-center bb bt br">노출수</th>
                              <th class="text-center bb bt br">클릭수</th>
                              <th class="text-center bb bt br">클릭율</th>
                              <th v-if="admin == 1" class="text-center bb bt br">전환수</th>
                              <th v-if="admin == 1" class="text-center bb bt br">구매상품수</th>
                              <th v-if="admin == 1" class="text-center bb bt br">전환율</th>
                              <th v-if="admin == 1" class="text-center bb bt">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in report_list" :key="item.company_code">
                              <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb">
                                <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                              </td>
                              <td v-else class="text-center bb"><span v-html="$_ymd_s(item.ymd)"/></td>
                              <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" class="text-center bb">{{ item.company_name }}</td>
                              <td class="text-center bb">{{ item.platform }}</td>
                              <td class="text-center bb">{{ item.keyword }}</td>
                              <td class="text-center bb">{{ item.impression.price() }}</td>
                              <td class="text-center bb">{{ item.click.price() }}</td>
                              <td class="text-center bb" v-html="percentage(item.click,item.impression)"></td>
                              <td v-if="admin == 1" class="text-center bb">{{ item.conversion.price() }}</td>
                              <td v-if="admin == 1" class="text-center bb">{{ item.conversion_total.price() }}</td>
                              <td class="text-center bb" v-if="admin == 1 &&item.click == 0">0%</td>
                              <td class="text-center bb" v-else-if="admin == 1" v-html="percentage(item.conversion,item.click)"></td>
                              <td v-if="admin == 1" class="text-center bb">{{ item.sales.price() }}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr v-for="(item,i) in report_total" :key="i">
                              <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" colspan="4" class="text-center sum bbt bbb">합계</td>
                              <td v-else colspan="3" class="text-center sum bbt bbb">합계</td>
                              <td class="text-center sum bbt bbb">{{ item.impression.price() }}</td>
                              <td class="text-center sum bbt bbb">{{ item.click.price() }}</td>
                              <td class="text-center sum bbt bbb" v-html="percentage(item.click,item.impression)"></td>
                              <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.conversion.price() }}</td>
                              <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.conversion_total.price() }}</td>
                              <td class="text-center sum bbt bbb" v-if="admin == 1 && item.click == 0">0%</td>
                              <td class="text-center sum bbt bbb" v-else-if="admin == 1" v-html="percentage(item.conversion,item.click)"></td>
                              <td v-if="admin == 1" class="text-center sum bbt bbb">{{ item.sales.price() }}</td>
                            </tr>
                            </tfoot>
                          </template>
                        </v-simple-table>
                    </div>
                    <div v-else-if="option == 'review'" class="flex-grow-1">
                      <v-simple-table
                          style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                      >
                        <template v-slot:default>
                          <thead style="background-color: #eee"
                          >
                          <tr>
                            <th class="text-center bb bt br">순위</th>
                            <th class="text-center bb bt br">리뷰</th>
                            <th class="text-center bb bt br">클릭수</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in report_list" :key="i">
                            <td class="text-center bb">{{ item.rank }}</td>
                            <td class="text-left bb">
                              <v-row class="py-3">
                                <v-avatar
                                    size="100"
                                    tile
                                >
                                  <v-img v-if="item.image_url != null" :src="item.image_url"></v-img>
                                </v-avatar>
                                <v-col class="align-self-center">
                                  <div>
                                    <div style="display: flex;margin-bottom: 10px">
                                      <img :src="require('@/assets/images/' + item.sns_type + '.png')" style="width:22px">
                                      <span style="padding-left: 8px">{{ item.nickname }}</span>
                                    </div>
                                    <div style="margin-bottom: 15px"><span><strong>{{ item.content_summary }}</strong></span></div>
                                  </div>
                                </v-col>
                              </v-row>
                            </td>
                            <td class="text-center bb">{{ item.click.price() }}</td>
                          </tr>
                          </tbody>
                          <tfoot>
                          <tr v-for="(item,i) in report_total" :key="i">
                            <td colspan="2" class="text-center sum bbt bbb">합계</td>
                            <td class="text-center sum bbt bbb">{{ item.click.price() }}</td>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

        <div class="text-center pa-2">
            <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
        </div>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
            companyName : 'companyName',
            admin: 'admin',
        }),
        props:{
            option:{
                type:String,
                default:''
            }
        },
        components: {

        },
        watch: {
            reportParam:function() {
                this.dataload(true)
            },
            page:function() {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function(pageReset) {

                if(!!!this.reportParam.start_date || !!!this.reportParam.end_date)
                    return

                if(!!pageReset){
                    this.page = 1
                }

                var that = this;
                if (this.option == 'daily') {
                    var url = "/api/more/report/review/daily"

                    this.reportParam.page = this.page-1
                    this.reportParam.size = this.itemsPerPage

                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data.content
                        if(res.data.totalPages >= 1){
                            this.pageCount = res.data.totalPages
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })

                    var url2 = "/api/more/report/review/daily_total"
                    this.$http.post(url2, this.reportParam).then(res => {
                        that.report_total = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'keyword'){
                    var url = "/api/more/report/review/keyword"
                    this.reportParam.page = this.page-1
                    this.reportParam.size = this.itemsPerPage

                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data.content
                        if(res.data.totalPages >= 1){
                            this.pageCount = res.data.totalPages
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })

                    var url2 = "/api/more/report/review/keyword_total"
                    this.$http.post(url2, this.reportParam).then(res => {
                        that.report_total = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'review'){
                  var url = "/api/more/report/review/review"
                  this.reportParam.page = this.page-1
                  this.reportParam.size = this.itemsPerPage

                  this.$http.post(url, this.reportParam).then(res => {
                    //console.log(res.data.content)
                    that.report_list = res.data.content
                    if(res.data.totalPages >= 1){
                      this.pageCount = res.data.totalPages
                    }
                    res.data.content.forEach(function(e, i){
                      //e.regdate = moment(e.regdate).format('YYYY-MM-DD HH:mm:ss')
                      var url2 = "/api/more/review/get_catalog?item=" + e.post_id
                      if(!!that.reportParam.company_code){
                        url2 += "&company_code=" + that.reportParam.company_code
                      }
                      that.$http.get(url2).then(res2=>{
                        console.log(res2)
                        e.nickname = res2.data.nickname
                        e.sns_type = res2.data.sns_type
                        e.image_url = res2.data.image_url
                        e.content_summary = res2.data.content_summary
                        that.$set(that.report_list, i, e)
                      }).catch((err2)=>{

                      })
                    })
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/review/review_total"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                }
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            downloadCSV: function () {
                var url = "/api/more/report/recom/csv?"
                if (!!this.companyName) {
                    this.reportParam.company_name = this.companyName
                }
                var params = new URLSearchParams(this.reportParam).toString();
                if(this.option == 'daily') {
                    params += '&type=1'
                    location.href = url + params
                }else if(this.option == 'keyword') {
                    params += '&type=2'
                    location.href = url + params
                }
            },
            downloadConvCSV: function () {
                var url = "/api/more/report/recom/conv_csv?"
                if (!!this.companyName) {
                    this.reportParam.company_name = this.companyName
                }
                var params = new URLSearchParams(this.reportParam).toString();
                location.href = url + params
            }
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list: [],
            report_total: {},
            page: 1,
            pageCount: 1,
            itemsPerPage: 50,
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>