<template>

    <div class="limiter login-bg">


        <div class="container-login100">

            <div class="wrap-login100 p-l-50 p-r-50 p-t-77 p-b-30">

                <div class="login100-form">
                    <div class="login100-form-title p-b-55">
                        <v-img class="d-inline-flex"
                               :src="logo"
                               max-width="86"
                               height="21"
                               @click="goHome"
                               style="cursor:pointer;"
                        ></v-img>
                        <span class="m-l-10">로그인</span>
                    </div>


                    <v-form
                            style="width: 100%"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >



                        <v-text-field
                                prepend-inner-icon="mdi-account-outline"
                                v-model="formData.id"
                                :rules="idRules"
                                placeholder="아이디"
                                outlined
                                required
                        ></v-text-field>

                        <v-text-field
                                prepend-inner-icon="mdi-lock-outline"
                                v-model="formData.password"
                                :rules="passRules"
                                placeholder="비밀번호"
                                type="password"
                                @keydown.enter="onLogin"
                                outlined
                                required
                        ></v-text-field>

                      <v-row v-if="need_auth" no-gutters class="pb-4">
                        <v-col class="col-7 pt-0">
                          <v-text-field
                              v-model="formData.code"
                              label="인증번호"
                              outlined
                              hide-details
                              dense
                          ></v-text-field>
                        </v-col>
                        <v-col class="pt-0 pl-3">
                          <v-btn v-if="auth == false" depressed color="#29d1cf" block
                                 @click="onAuth"
                                 outlined
                                 dense
                          >인증번호 확인</v-btn>
                          <v-btn v-else depressed dark
                                 color="#29d1cf" block
                                 dense
                          ><v-icon dark v-if="auth == true">mdi-check-circle-outline</v-icon></v-btn>
                        </v-col>
                      </v-row>

                        <v-checkbox
                                class="pa-0 ma-0"
                                v-model="formData.remember"
                                label="로그인 상태 유지"
                                hide-details
                        ></v-checkbox>
                    </v-form>


                    <v-btn depressed x-large dark color="#29d1cf" block
                           class="mt-8"
                           @click="onLogin"
                    >로그인</v-btn>

                    <div class="w-full">
                        <div class="my-1">
                            <v-btn class="pa-2" text small style="color:rgba(0, 0, 0, 0.6)" @click="findId">아이디 찾기</v-btn>
                            <v-btn class="pa-2" text small style="color:rgba(0, 0, 0, 0.6)" @click="findPw">비밀번호 찾기</v-btn>
                        </div>
                    </div>

                    <div class="text-center w-full p-t-20">
                        <span class="txt1 p-l-3" style="font-size:12px">
                        계정이 없으신가요?
                        </span>
                        <v-chip
                                class="ma-2"
                                color="primary"
                                text-color="white"
                                style="cursor:pointer"
                                @click="join"
                        >
                            <v-avatar left>
                                <v-icon size="x-large">mdi-account-circle</v-icon>
                            </v-avatar>
                            회원가입
                        </v-chip>
                    </div>

                    <!--
                    <div class="text-center w-full p-t-20">
                        <v-icon style="font-size:medium">mdi-bullhorn</v-icon>
                        <span class="txt1 p-l-3" style="font-size:12px">
                        본 사이트는 크롬 브라우저에 최적화 되어있습니다.
                        </span>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    require('@/assets/css/main.css')
    require('@/assets/css/util.css')

    export default {
        created() {
        },
        mounted() {
            //console.log('aa')
            //console.log(this.$event)
        },
        data: () => ({
            logo: require('@/assets/logo.png'),
            checkbox:false,

            idRules: [
                v => !!v || '아이디를 입력해주세요',
                //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],

            passRules: [
                v => !!v || '비밀번호를 입력해주세요',
            ],

            valid:false,

            //
            formData:{
                id:'',
                password:'',
                remember:false
            },
            need_auth: false,
            auth: false,
        }),
        methods:{
            goHome:function(){
                this.$router.push({path:'/'})
            },
            onLogin:function () {
                if (!this.$refs.form.validate()) {
                    return;
                }
                if(this.need_auth == true && this.auth == false) {
                  this.$event.$emit('alert','인증을 완료해주세요')
                  return;
                }

                this.formData.auth = this.auth
                var url = "/api/user/login"
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.$store.commit('login', {value:true})
                        this.$router.push({path:'/'})
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    if(msg == 'NOTVALID'){
                        msg = '아이디/비밀번호가 일치하지 않습니다<br>5회 이상 잘못 입력 시 잠금 처리됩니다'
                    }else if(msg == 'ACCOUNT_LOCK'){
                        this.$router.push({path:'/lock'})
                        return
                    }else if(msg == 'NOAUTH'){
                        msg = '접근 권한이 없습니다'
                    }else if(msg == 'NEED_SECOND_AUTH'){
                      this.need_auth = true
                      this.formData.email = err.response.data.data
                      msg = '이메일로 발송된 인증코드를 입력해주세요'
                    }
                    this.$event.$emit('alert',msg)
                })
            },
            findId: function() {
                this.$router.push({path:'/findid'})
            },
            findPw: function() {
                this.$router.push({path:'/findpw'})
            },
            join: function() {
                this.$router.push({path:'/agree'})
            },
            onAuth:function(){
              var url = "/api/user/second/auth"
              //console.log(this.formData)
              this.$http.post(url, this.formData).then(res=>{
                if(res.data.msg == "OK"){
                  this.auth = true
                  this.$event.$emit('alert','인증 되었습니다')
                }
              }).catch((err)=>{
                var msg = err.response.data.msg
                if(msg == 'NOTVALID'){
                  msg = '인증번호를 다시 확인해주세요'
                }else{
                  msg = '오류가 발생했습니다'
                }
                this.$event.$emit('alert',msg)
              })
            },
        }
    }
</script>
