<template>
    <div>
        <div v-if="!!!term_start"></div>

        <div v-else>
          <div class="text-right pb-4 pr-4" style="font-size: 1em">
            계약 기간 : <span v-html="$_ymd2(term_start)"></span>
            <span> ~ </span>
            <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
            <span v-else>미정일</span>
          </div>

          <v-row class="pt-3 pb-6">
            <v-col cols="12" sm="5">
              <v-text-field
                  v-model="search_item"
                  placeholder="키워드를 입력하세요"
                  hide-details
                  dense
                  clearable
                  @keydown.enter="search"
                  append-icon="mdi-magnify"
                  @click:append="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 pb-3">
              <v-layout justify-end>
                <v-flex shrink>
                  <v-btn block depressed color="teal lighten-2" dark @click="add">
                    <v-icon left class="mr-3">
                      mdi-plus
                    </v-icon>
                    블럭 생성
                  </v-btn>
                </v-flex>
              </v-layout>
          </v-row>

          <v-card flat class="pb-3">
              <v-card-text>
                  <v-row class="mt-2 px-4 pb-3">

                      <table class="mtable">
                          <thead>
                          <tr class="theader">
                              <th width="115" class="text-center" style="border-bottom:1px solid #666">
                                <v-checkbox v-model="chk_all" @change="allCheck" class="d-inline-flex">
                                  <template v-slot:label>
                                    <span id="checkboxLabel">노출 여부</span>
                                  </template>
                                </v-checkbox>
                              </th>
                              <th class="text-left" style="border-bottom:1px solid #666">키워드</th>
                              <th class="text-left" style="border-bottom:1px solid #666">타이틀</th>
                              <th class="text-center" style="border-bottom:1px solid #666">리뷰 수</th>
                              <th width="250" class="text-center" style="border-bottom:1px solid #666">기능</th>
                          </tr>

                          </thead>
                          <tbody>
                              <tr v-if="itemList.length == 0">
                                  <td class="text-center" colspan="5" style="color: #888">등록된 내역이 없습니다.</td>
                              </tr>
                              <tr v-else v-for="obj in itemList" :key="obj.item">
                                  <td class="text-center">
                                    <v-checkbox v-model="obj.status" class="d-inline-flex" @change="toggleShow(obj)"></v-checkbox>
                                  </td>
                                  <td class="text-left">{{ obj.keyword }}</td>
                                  <td class="text-left">{{ obj.title }}</td>
                                  <td class="text-center" style="width: 140px">{{ obj.review_cnt }}</td>
                                  <td class="text-center">
                                      <v-btn class="ma-2"
                                             color="teal" small
                                             outlined
                                             @click="preview(obj)"
                                      >
                                        미리보기
                                      </v-btn>
                                      <v-btn class="mr-2" depressed color="blue-grey darken-3" dark small
                                             @click="edit(obj)"
                                      >
                                        수정
                                      </v-btn>
                                      <v-btn class="mr-2" depressed color="blue-grey darken-3" dark small
                                             @click="del(obj)"
                                      >
                                          삭제
                                      </v-btn>
                                  </td>
                              </tr>
                          </tbody>
                      </table>

                  </v-row>

                  <v-row class="px-4 pb-3">
                      <div class="text-center pa-2" style="width:100%">
                          <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                      </div>
                  </v-row>

              </v-card-text>
          </v-card>

          <v-row class="pb-3">
              <v-dialog v-model="dialog" scrollable max-width="700px" persistent>
                  <v-card>
                      <v-card-title style="color: #666">
                          <v-icon class="mr-3">mdi-check</v-icon>
                          {{ dialogm1.title }}
                      </v-card-title>
                      <v-card-text style="max-height: 500px; font-color: #666" class="pa-7">
                          <v-form
                                  ref="form"
                                  v-model="valid"
                                  lazy-validation
                          >
                              <v-row>
                                  <v-col>
                                      <v-text-field
                                          v-model="formData.sns_url"
                                          label="SNS URL"
                                          :rules="[rules.required]"
                                          :placeholder="dialogm1.message"
                                          hide-details
                                          :readonly="crawled"
                                      ></v-text-field>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-btn class="mt-3"
                                           color="#566270"
                                           block dark depressed
                                           :disabled="crawled"
                                           @click="crawl_review"
                                    >
                                      가져오기
                                    </v-btn>
                                  </v-col>
                              </v-row>
                              <v-row v-if="crawled">
                                <v-col>
                                  <v-text-field
                                      v-model="formData.sns_type"
                                      label="SNS 타입"
                                      hide-details
                                      disabled
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row v-if="crawled">
                                <v-col>
                                  <v-text-field
                                      v-model="formData.nickname"
                                      label="작성자"
                                      hide-details
                                      disabled
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row v-if="crawled" class="pb-3">
                                <v-col cols="4">
                                  <v-avatar
                                      size="170"
                                      tile
                                  >
                                    <v-img :src="formData.image_url"></v-img>
                                  </v-avatar>
                                </v-col>
                                <v-col>
                                  <div>
                                    <v-textarea
                                        outlined
                                        v-model="formData.content"
                                        label="본문내용"
                                        hide-details
                                        disabled
                                    ></v-textarea>
                                    <div style="margin-bottom: 15px"></div>
                                    <v-text-field
                                        v-model="formData.content_summary"
                                        label="제목"
                                        counter="80"
                                        :rules="[rules.content_summary]"
                                        hint="실제 리뷰영역에 노출되는 내용이므로 강조하고 싶은 부분을 넣어주세요"
                                        persistent-hint
                                        autofocus
                                    ></v-text-field>
                                    <div style="margin-bottom: 15px"></div>
                                    <v-textarea
                                        outlined
                                        v-model="formData.hashtag"
                                        rows="2"
                                        label="검색 태그"
                                        hint="등록한 리뷰를 쉽게 검색할 수 있게 키워드를 입력해주세요. 쉼표를 넣어 여러개 입력도 가능합니다. (ex. 스커트,민트컬러)"
                                        persistent-hint
                                    ></v-textarea>
                                  </div>
                                </v-col>
                              </v-row>
                          </v-form>
                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="teal darken-1" text @click="closeDialog">닫기</v-btn>
                          <v-btn v-if="crawled" color="teal darken-1" text @click="modify">수정하기</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-row>

          <v-dialog v-model="isshow" max-width="900px" style="background-color: white">
            <v-card class="pa-2">

              <div class="pl-4" align="center">
                <iframe :src="preview_url" frameborder="0" style="width:800px;height:500px"></iframe>
              </div>

              <div class="pa-2 d-flex">
                <v-btn class="mr-1 flex-grow-1"
                       depressed
                       dark
                       color="grey"
                       @click="isshow=false"
                >
                  닫기
                </v-btn>
              </div>
            </v-card>
          </v-dialog>

          <review-upload-dlg :key="flag" :company_code="company_code" :review="selected_review" :isshow="isshow3" @closeDlg="closeUploadDialog"/>
        </div>
    </div>
</template>
<script>
    import ReviewUploadDlg from "@/components/common/ReviewUploadDlg";
    export default {
        watch:{
            "page":function () {
                this.loadAll()
            }
        },
        props:{
            company_code:{
                type:String,
                default:''
            }
        },
        components: {
          ReviewUploadDlg
        },
        mounted() {
            // formData.myshp, formData.type 에 값 셋팅
            if(!!this.company_code){
                this.formData.company_code = this.company_code
            }
            this.page = 1
            this.pageCount = 1
            this.itemsPerPage = 50
            this.loadAll()
        },
        methods:{
            search: function() {
                this.page = 1;
                this.loadAll();
            },
            loadAll: function () {
              this.load_current()
              this.load()
            },
            load : function(){
                var that = this
                var sitem = this.search_item==null?'':this.search_item
                var url = "/api/more/review_keyword_list?search_item=" + sitem
                url += "&page="+(this.page-1)
                url += "&size=" + this.itemsPerPage
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }

                this.$http.get(url).then(res=>{
                    this.chk_all = false
                    this.itemList = res.data.content
                    var check_cnt = 0;
                    if(res.data.content.length == 0) {
                      this.pageCount = 1
                      this.totalCount = 0
                    }
                    res.data.content.forEach(function(e, i){
                      e.review_cnt = e.review.split(',').length
                      that.$set(that.itemList, i, e)
                      if(e.status) check_cnt++
                    })
                    if(res.data.totalPages >= 1){
                        if(check_cnt == that.itemList.length) that.chk_all = true
                        this.pageCount = res.data.totalPages
                        this.totalCount = res.data.totalElements
                    }
                }).catch((err)=>{
                    this.chk_all = false
                    var msg = err.response.data.msg
                    this.$event.$emit('my-toast',msg)
                })
            },
            load_current: function() {
              if(this.admin == -1)
                return

              if(this.admin == 1 && !!!this.company_code)
                return

              var that = this
              var url = "/api/more/get/current_period?type=review"
              if(!!this.company_code){
                url += "&company_code=" + this.company_code
              }
              this.$http.get(url).then(res=>{
                if(!!res.data) {
                  that.term_start = res.data.term_start
                  that.term_end = res.data.term_end
                }
              }).catch((err)=>{
                this.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })
            },
            crawl_review: function () {
                if (!this.$refs.form.validate()) {
                  return;
                }

                var that = this
                var url = "/api/more/add/review_post"
                if (!!this.company_code) {
                  url += "?company_code=" + this.company_code
                }
                this.$http.post(url, this.formData).then(res => {
                  if (res.status == '200') {
                    that.crawled = true
                    that.$event.$emit('alert', "등록되었습니다")

                    that.formData.post_id = res.data.post_id
                    that.formData.sns_type = res.data.sns_type
                    that.formData.nickname = res.data.nickname
                    that.formData.content = res.data.content
                    that.formData.image_url = res.data.image_url
                    if(res.data.content_summary){
                      that.formData.content_summary = res.data.content_summary
                    }

                    that.loadAll()
                  }
                }).catch((err) => {
                  var msg = '오류가 발생했습니다'
                  if(err.response.data.msg == 'NOT_SUPPORT'){
                    msg = '지원하지 않는 URL 입니다'
                  } else if(err.response.data.msg == 'EXISTED'){
                    msg = '이미 등록된 리뷰 입니다'
                  }
                  this.$event.$emit('alert',msg)
                })
            },
            add : function(){
              this.selected_review = null
              this.isshow3 = true
              this.flag++
            },
            edit : function (obj){
              this.selected_review = obj
              this.isshow3 = true
              this.flag++
            },
            del : function(obj){
                var param = {};
                param.keyword = obj.keyword

                var that = this
                this.$event.$emit('confirm', '삭제', '선택한 블럭을 삭제하시겠습니까?', function () {
                    var url = "/api/more/delete/review_keyword"
                    if(!!that.company_code){
                      url += '?company_code=' + that.company_code
                    }
                    this.$http.post(url,param).then(res=>{
                        if(res.status == '200'){
                            that.$event.$emit('my-toast','삭제되었습니다.')
                            that.remove(obj.keyword)
                        }
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        that.$event.$emit('my-toast',msg)
                    })
                })
            },
            remove:function(item) {

                for (var i = 0; i < this.itemList.length; i++) {
                    if (this.itemList[i].keyword === item) {
                        this.itemList.splice(i, 1);
                        this.totalCount--
                        break
                    }
                }
            },
            closeDialog: function () {
              if(this.crawled && !this.formData.content_summary){
                this.$event.$emit('alert', '먼저 리뷰 영역에 노출할 제목을 저장해주세요.')
                return
              }
              this.dialog = false
              this.crawled = false
              this.$refs.form.reset()
            },
            closeUploadDialog: function (){
              this.isshow3 = false
              this.selected_review = null
              this.loadAll()
            },
            modify : function(){
                if (!this.$refs.form.validate()) {
                    return;
                }

                var that = this
                var url = "/api/more/update/review_post"
                if(!!this.company_code){
                  url += '?company_code=' + this.company_code
                }
                this.$http.post(url, this.formData).then(res => {
                    if (res.status == '200') {
                        that.$event.$emit('alert', "저장되었습니다")
                        that.closeDialog()
                        that.loadAll()
                    }
                }).catch((err) => {
                    that.$event.$emit('alert', '오류가 발생했습니다')
                })
            },
            allCheck : function (e) {
              /*for(var i=0;i<this.itemList.length;i++) {
                var item = this.itemList[i]
                item.status = e
              }*/
              var that = this
              this.$event.$emit('confirm', '리뷰 블럭 노출', '모든 리뷰 블럭 노출 상태를 변경하시겠습니까?', function () {
                var url = "/api/more/update/review_keyword_status_all"
                if(!!that.company_code){
                  url += "?company_code=" + that.company_code
                }
                var item = {}
                item.status = (e == true)?1:0
                that.$http.post(url, item).then(res=>{
                  if(res.status == '200'){
                    that.loadAll()
                    that.$event.$emit('alert','노출 상태가 변경되었습니다')
                  }
                }).catch((err)=>{
                  that.loadAll()
                  that.$event.$emit('alert','오류가 발생했습니다')
                })
              }, function() {
                that.chk_all = !e
              })
            },
            toggleShow : function (obj){
              var that = this
              obj.status = (obj.status == true)?1:0
              this.$event.$emit('confirm', '리뷰 블럭 노출', '리뷰 블럭 노출 상태를 변경하시겠습니까?', function () {
                var url = "/api/more/update/review_keyword_status"
                if(!!that.company_code){
                  url += "?company_code=" + that.company_code
                }
                that.$http.post(url, obj).then(res=>{
                  if(res.status == '200'){
                    that.loadAll()
                    that.$event.$emit('alert','노출 상태가 변경되었습니다')
                  }
                }).catch((err)=>{
                  that.loadAll()
                  that.$event.$emit('alert','오류가 발생했습니다')
                })
              }, function() {
                obj.status = !obj.status
              })
            },
            preview : function (obj) {
              this.ver++
              var src = "https://pi.myshp.us/more?type=review&iw=1&ih=1&myid=_qftkt2bdc&style=p_notosans"
                  + "&ratid=" + this.company_code
                  + "&key=" + encodeURI(obj.keyword)
                  + "&title=" + encodeURI(obj.title)
                  + "&ver=" + this.ver
                  + "&temp=1"

              this.preview_url = src
              this.isshow = true
            }
        },
        data: () => ({
            term_start: '',
            term_end: '',
            chk_all:false,
            valid:false,
            search_item: '',
            formData:{
                company_code:'',
                sns_url:'',
            },
            page: 1,
            pageCount: 1,
            totalCount: 0,
            itemList: [],
            dialog: false,
            dialogm1:{
                title:'',
                message:''
            },
            rules: {
                required: value => !!value || '필수값입니다',
                content_summary: value => !!value || '실제 리뷰영역에 노출되는 내용이므로 강조하고 싶은 부분을 넣어주세요'
            },
            crawled: false,
            isshow: false,
            preview_url: null,
            flag: 0,
            isshow3: false,
            selected_review: null,
            ver: 0,
        }),
    }
</script>
<style scoped>
    .bbb {
        border-bottom: 1px solid #666;
    }
    .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg{
      background-color: #e3e3e3 !important;
      color: #8c8c8c !important;
    }
    #checkboxLabel {
      font-size: 0.8em;
    }
</style>
