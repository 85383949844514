<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="세일즈 현황"/>
                </v-card-title>
            </div>

            <div class="pt-4 pl-6">
                <v-row>
                    <v-col cols="4">
                        <v-select
                            style="max-width:250px;"
                            :items="yyyymm_list"
                            v-model="form2.yyyymm"
                            label="기간 선택"
                            outlined
                            dense
                            hide-details
                            @change="dateChanged"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" v-if="mytab == 1">
                      <v-select
                          style="max-width:250px;"
                          :items="last_yyyymm_list"
                          v-model="form2.search_yyyymm"
                          label="비교 기간"
                          outlined
                          dense
                          hide-details
                          @change="dataload"
                      ></v-select>
                    </v-col>
                </v-row>

                <v-row  v-if="mytab == 1">
                    <v-col style="max-width:250px;">
                        <v-select
                                style="max-width:250px;"
                                :items="cList"
                                v-model="form2.charge"
                                label="과금 유무"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                                hide-details
                                @change="dataload"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>
                  <v-col style="max-width:250px;">
                    <v-select
                        style="max-width:250px;"
                        :items="pList"
                        v-model="form2.prod"
                        label="상품 선택"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        hide-details
                        @change="dataload"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="padding-top: 0">
                    <v-radio-group
                        v-model="form2.tax_type"
                        row
                        @change="dataload"
                    >
                      <v-radio
                          label="부가세 포함"
                          value="Y"
                      ></v-radio>
                      <v-radio
                          label="부가세 별도"
                          value="N"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    class="pl-6"
            >
                <v-tab key="summary"
                >
                    요약
                </v-tab>
                <v-tab key="detail"
                >
                    상세
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless>
                <v-tab-item
                        class="pa-5"
                        key="summary">
                    <div style="display:flex;flex-direction: row;justify-content: space-between;">
                      <total-expected style="width:48%"/>
                      <total-current style="width:48%" />
                    </div>
                    <div style="height:30px"/>
                    <div style="display:flex;flex-direction: row;justify-content: space-between;">
                      <total-charge style="width:48%"/>
                      <total-sales style="width:48%"/>
                    </div>
                    <div style="height:30px"/>
                    <total-category />
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="detail">
                    <total-period />
                    <div style="height:30px"/>
                    <total-prod />
                    <div style="height:30px"/>
                    <total-summary-detail :key="flag"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import RecommendSummary from "./RecommendSummary";
    import MyDateRange from "../../common/MyDateRange";
    import TotalExpected from "@/components/page/report/TotalExpected";
    import TotalCurrent from "@/components/page/report/TotalCurrent";
    import TotalCharge from "@/components/page/report/TotalCharge";
    import TotalSales from "@/components/page/report/TotalSales";
    import TotalCategory from "@/components/page/report/TotalCategory";
    import TotalPeriod from "@/components/page/report/TotalPeriod";
    import TotalProd from "@/components/page/report/TotalProd";
    import TotalSummaryDetail from "@/components/page/report/TotalSummaryDetail";

    import {mapGetters} from "vuex";
    export default {
        computed: {
            computedStartDate() {
                var v = this.$moment(new Date()).format('YYYYMM01')
                return this.$moment(v, 'YYYYMMDD').toDate()
            },computedEndDate() {
                var v = this.$moment(new Date()).add(-1, 'days')
                return this.$moment(v, 'YYYYMMDD').toDate()
            },...mapGetters({
                companyCode: 'companyCode',
                admin: 'admin',
            })
        },
        watch: {
            admin:function () {
                this.dataload()
            },
            mytab:function () {
                this.flag++
                if(this.mytab == 0) {
                  var idx = this.yyyymm_list.indexOf(this.form2.yyyymm)
                  this.form2.search_yyyymm = this.last_yyyymm_list[idx]
                }
                this.dataload()
            }
        },
        mounted() {
          var that = this
          var url = "/api/more/get/yyyymm"
          this.$http.get(url).then(res => {
            this.yyyymm_list = res.data
            this.form2.yyyymm = res.data[0]

            var url2 = "/api/more/get/last_yyyymm"
            that.$http.get(url2).then(res2 => {
              that.last_yyyymm_list = res2.data
              that.form2.search_yyyymm = res2.data[0]
              that.dataload()
            })
          });
        },
        components: {
          TotalCurrent,
          MainTitle,
          MySelect,
          MyDateRange,
          RecommendSummary,
          TotalExpected,
          TotalCharge,
          TotalSales,
          TotalCategory,
          TotalPeriod,
          TotalProd,
          TotalSummaryDetail
        },
        data: () => ({
            flag: 0,
            mytab: '',
            form2: {
                admin : -1,
                yyyymm:'',
                search_yyyymm:'',
                charge: '',
                prod: '',
                tax_type: 'Y'
            },
            yyyymm_list:[],
            last_yyyymm_list:[],
            cList:[{id:"", name:"전체"},{id:"N", name:"무료"},{id:"Y", name:"유료"}],
            pList:[{id:"", name:"전체"},{id:"panel", name:"패널"},{id:"ticker", name:"티커"},{id:"recom", name:"추천"},{id:"sch", name:"서치"}],
        }),
        methods: {
            dateChanged:function (v) {
                var idx = this.yyyymm_list.indexOf(v)
                this.form2.search_yyyymm = this.last_yyyymm_list[idx]
                //this.form2.start_date = v.start
                //this.form2.end_date = v.end
                this.dataload()
            },
            dataload: function() {
              if(this.admin == -1)
                return

              this.form2.admin = this.admin
              var v = Object.assign({}, this.form2)
              this.$store.commit('reportParam2', {value:v})
            }
        }
    };
</script>