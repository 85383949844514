<template>

    <ul class="nav nav-tabs nav-fill ma-1 mt-3 mb-3">
        <li
                v-for="(item,idx) in list"
                class="nav-item">
            <a :class="val==idx?'nav-link active':'nav-link '" @click="clk_item(idx)">
                <span :class="val==idx?'sborder':''"  v-html="item">
                </span>
            </a>
        </li>
    </ul>

</template>
<script>

    export default {
        props: {
            list:{
                type:Array,
                default:[]
            },
            value:{
                type:Number,
                default:0
            },
        },
        mounted() {
        },
        updated() {
        },
        created () {
        },
        destroyed () {
        },
        activated() {
        },
        watch:{
            value:function () {
                this.val = this.value
            }
        },
        components: {

        },
        data: () => ({
            val:0,
        }),
        methods: {
            clk_item: function (idx) {
                this.val = idx
                this.$emit('input', this.val)
            }
        }
    }
</script>
<style scoped>
    .nav-tabs {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .nav {
        font-weight: 500;
        font-size: 1.1rem;
    }
    .nav-tabs .nav-item .nav-link:not(:hover) {
        color: inherit;
    }
    .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .nav-fill .nav-item {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: center;
    }

    .nav-tabs .nav-item {
        margin-bottom: -1px;
    }

    .nav-tabs .nav-item .nav-link:not(:hover) {
        color: inherit;
    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12) #fff;
    }
    .nav-link.active {
        color: #495057 !important;
    }
    .nav-link {
        color: #777 !important;
    }
    .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .nav-link {
        display: block;
        padding: 0.7rem 1.125rem;
    }
    .sborder {
        padding-bottom: 7px;
        padding-left: 5px;
        padding-right: 5px;
        border-bottom: rgb(60, 145, 230) 2px solid !important;
    }
</style>