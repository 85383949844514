<template>

    <div>
        <div class="limiter login-bg">

            <div class="container-login100">

                <div class="wrap-join100 p-l-50 p-r-50 p-t-77 p-b-30">

                    <div class="login100-form">
                        <div class="login100-form-title p-b-55">
                            <v-img class="d-inline-flex"
                                   :src="logo"
                                   max-width="86"
                                   height="21"
                                   style="cursor:pointer;"
                            ></v-img>
                            <span class="m-l-10">회원가입</span>
                        </div>
                        <v-subheader class="ml-0 pl-0 mb-3" style="font-weight: 600">회원 정보</v-subheader>
                        <div style="width:100%">
                            <v-form
                                    style="width: 100%"
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                                v-model="formData.id"
                                                :rules="[idRules]"
                                                :error="idError"
                                                :error-messages="idErrorMsg"
                                                label="아이디"
                                                @keyup="idValidate"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.password"
                                                :rules="passwordRules"
                                                type="password"
                                                label="비밀번호"
                                                class="input-group--focused"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.password2"
                                                :rules="[passRules2]"
                                                type="password"
                                                label="비밀번호 확인"
                                                class="input-group--focused"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.name"
                                                :rules="nameRules"
                                                label="법인명(회사명)"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.manager"
                                                :rules="nameRules"
                                                label="담당자"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.phone"
                                                :rules="phoneRules"
                                                label="담당자 휴대폰 번호"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="col-8 pt-0">
                                        <v-text-field
                                                v-model="formData.email"
                                                :rules="emailRules"
                                                label="담당자 이메일"
                                                @keyup="enableBtn"
                                                outlined
                                                :readonly="auth"
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pl-0 pl-3">
                                        <v-btn depressed color="#566270" block
                                               @click="sendEmail"
                                               :dark="!disEmailBtn"
                                               :disabled="disEmailBtn"
                                               dense
                                        >인증메일 발송</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="col-8 pt-0">
                                        <v-text-field
                                                v-model="formData.code"
                                                label="인증번호"
                                                :disabled="need"
                                                outlined
                                                hide-details
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pl-3">
                                        <v-btn v-if="auth == false" depressed color="#29d1cf" block
                                               @click="onAuth"
                                               :disabled="need"
                                               outlined
                                               dense
                                        >인증번호 확인</v-btn>
                                        <v-btn v-else depressed dark
                                               color="#29d1cf" block
                                               dense
                                        ><v-icon dark v-if="auth == true">mdi-check-circle-outline</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                                <div class="w-full">
                                    <div class="my-2">
                                        <v-alert
                                                dense
                                                type="warning"
                                                color="grey"
                                                outlined
                                                style="font-size:0.8em"
                                        >
                                            <strong style="color:#666;font-size:14px">인증메일이 오지 않나요?</strong><br>
                                            스팸 메일로 분류된 것은 아닌지 확인해 주세요.<br>
                                            특히 Gmail 을 사용하신다면  스팸함을 확인 바랍니다.<br>
                                            스팸 메일함에도 인증메일이 없다면, 다시 한 번 ‘인증 메일 받기'를 눌러주세요
                                        </v-alert>
                                        <!--
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <span style="color:rgba(0, 0, 0, 0.6);font-size:0.75rem;font-weight:500;letter-spacing:0.0892857143em;padding:5px" v-on="on">인증메일이 오지 않나요?</span>
                                            </template>
                                            <span>스팸메일로 분류된 것은 아닌지 확인해주세요.<br>스팸 메일함에도 메일이 없다면,<br>다시 한 번 ‘인증메일 발송'을 눌러주세요</span>
                                        </v-tooltip>-->
                                    </div>
                                </div>
                                <v-divider class="m-t-30 m-b-15"></v-divider>
                                <v-subheader class="ml-0 pl-0 mb-3" style="font-weight: 600">사이트 정보</v-subheader>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.site_name"
                                                :rules="nameRules"
                                                label="사이트명"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.site_url"
                                                hint="http:// 또는 https://를 포함하여 입력해주세요"
                                                :rules="urlRules"
                                                label="사이트 PC URL"
                                                persistent-hint
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.site_m_url"
                                                hint="http:// 또는 https://를 포함하여 입력해주세요"
                                                :rules="urlRules"
                                                label="사이트 Mobile URL"
                                                persistent-hint
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-select
                                                v-model="formData.service_ver"
                                                hint="사이트에서 상품/서비스의 결제까지 진행 시 커머스를 선택해주세요"
                                                :items="service_items"
                                                :rules="selectRules"
                                                item-text="value"
                                                item-value="code"
                                                label="커머스/논커머스"
                                                persistent-hint
                                                return-code
                                                outlined
                                                dense
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-select
                                                v-model="formData.category"
                                                :items="cate_items"
                                                :rules="selectRules"
                                                item-text="value"
                                                item-value="code"
                                                label="업종"
                                                return-code
                                                outlined
                                                dense
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0 pb-0">
                                      <v-checkbox
                                          v-model="formData.checkbox1"
                                          label="서비스 이용 약관에 동의합니다."
                                          color="teal"
                                          :rules="checkboxRules1">
                                      </v-checkbox>
                                    </v-col>
                                    <v-col class="col-2 pt-0" style="margin-top: 18px">
                                      <div style="text-align: right">
                                        <a id="terms" @click="terms" style="color:#00000099;text-decoration:none;font-weight:600;">[보기]</a>
                                      </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                  <v-col class="pt-0">
                                    <v-checkbox
                                        v-model="formData.checkbox2"
                                        label="개인 정보 수집 및 이용에 동의합니다."
                                        color="teal"
                                        :rules="checkboxRules2">
                                    </v-checkbox>
                                  </v-col>
                                  <v-col class="pt-0 col-2" style="margin-top: 18px">
                                    <div style="text-align: right">
                                      <a id="privacy" @click="privacy" style="color:#00000099;text-decoration:none;font-weight:600;">[보기]</a>
                                    </div>
                                  </v-col>
                                </v-row>
                            </v-form>


                            <v-btn depressed x-large dark color="#29d1cf" block
                                   class="mt-8"
                                   @click="onJoin"
                            >가입하기</v-btn>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <my-confirm></my-confirm>
        </div>
        <v-dialog
            v-model="dialog"
            width="700"
        >
          <v-card color="#fff">

            <v-card-text>
              <iframe :src="url" frameborder="0" style="width:100%;height:450px"></iframe>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="teal"
                  text
                  @click="dialog = false"
              >
                확인
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import MyConfirm from "../common/MyConfirm";
    require('@/assets/css/main.css')
    require('@/assets/css/util.css')
    //var ObjectID = require("bson-objectid");

    export default {
        components: {MyConfirm},
        created() {
          //this.nw_company_code = ObjectID().toString()
        },
        mounted() {
            //console.log('aa')
            //console.log(this.$event)
            if(!!!this.$store.getters.mallId || this.$store.getters.shopNo == -1) {
                this.$router.push({path:'/wrongaccess'})
                return
            }
            this.selectload()
            this.mall_id = this.$store.getters.mallId
            this.shop_no = this.$store.getters.shopNo
        },
        comments: {
            MyConfirm
        },
        data: () => ({
            dialog: false,
            mall_id: null,
            shop_no: 0,
            logo: require('@/assets/logo.png'),
            need: true,
            auth: false,
            idError:false,
            idErrorMsg:'',
            disEmailBtn:true,
            formData:{
                id:'',
                password:'',
                password2:'',
                name:'',
                manager:'',
                email:'',
                phone:'',
                site_name:'',
                site_url:'',
                site_m_url:'',
                service_ver:'',
                category:'',
                hosting:'host01',
                code:'',
            },
            service_items: [
                { value: '커머스', code: 'service01' },
                { value: '논커머스', code: 'service02' },
            ],
            cate_items: [],
            /*
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match'),
            },
            idRules: [
                v => !!v || '필수 정보입니다',
                v => (v.length >= 5 && v.length <= 20) || '5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다',
                v => /^[a-z0-9\-_]+$/.test(v) ||
                    '5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다',
            ],
            */
            passwordRules: [
                v => !!v || '필수 정보입니다',
                v => (v.length >= 8 && v.length <= 16) || '8~16자 영문 대/소문자, 숫자, 특수문자를 포함해주세요',
                v => /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(v) ||
                    '8~16자 영문 대/소문자, 숫자, 특수문자를 포함해주세요'
            ],
            nameRules: [
                v => !!v || '필수 정보입니다'
            ],
            urlRules: [
                v => !!v || '필수 정보입니다',
                v => /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(v) || 'http:// 또는 https:// 를 포함하여 url을 입력해주세요',
                v => v.indexOf('naver.com') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('band.us') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('tistory.com') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('instagram.com') < 0 || '서비스 이용이 불가능한 url 입니다'
            ],
            phoneRules: [
                v => !!v || '필수 정보입니다',
                v => (v.replace(/-/gi,"").length >= 10 && v.replace(/-/gi,"").length <= 11) || '휴대폰 번호 형식이 올바르지 않습니다',
                v => /^[0-9\-]+$/.test(v) ||
                    '휴대폰 번호 형식이 올바르지 않습니다'
            ],
            emailRules: [
                v => !!v || '필수 정보입니다',
                v => /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v) ||
                    '이메일 형식이 올바르지 않습니다'
            ],
            selectRules: [
                v => !!v || '필수 정보입니다'
            ],
            checkboxRules1: [
              v => !!v || '서비스 이용약관에 동의해주세요',
              //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],

            checkboxRules2: [
              v => !!v || '개인 정보 수집 및 이용에 동의해주세요',
              //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],

            valid:false,
        }),
        methods:{
            selectload : function() {
                var that = this
                var url = "/api/more/get/codes?type=category"
                this.$http.get(url).then(res=>{
                    res.data.forEach(function(e, i){
                        that.cate_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })
            },
            idRules:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(v.length < 5 || v.length > 20)
                    return "5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다"
                if(!/^[a-z0-9\-_]+$/.test(v))
                    return "5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다"
                return true
            },
            idValidate:function() {
                this.idError = false
                this.idErrorMsg = ""

                var key = this.formData.id
                if(!!!key)
                    return
                if(key.length < 5 || key.length > 20)
                    return
                if(!/^[a-z0-9\-_]+$/.test(key))
                    return

                var url = "/api/user/validate?id=" + key
                this.$http.get(url).then(res=>{
                    if(res.data.msg == "OK"){
                        this.idError = false
                        this.idErrorMsg = ""
                        //console.log(this.$refs.form))
                        //console.log(this.$refs.form.getAttribute("id"))
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    if(msg == 'EXISTED'){
                        msg = '사용할 수 없는 아이디 입니다'
                        this.idError = true
                        this.idErrorMsg = msg
                        //this.$refs.form.validate()
                    }
                })

            },
            passRules2:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.formData.password != v)
                    return "비밀번호가 일치하지 않습니다"
            },
            enableBtn:function(){
                var v = this.formData.email
                if(!!!v) {
                    this.disEmailBtn = true
                }else if(!/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v)){
                    this.disEmailBtn = true
                }else
                    this.disEmailBtn = false
            },
            sendEmail:function(){
                if(this.auth){
                    this.$event.$emit('alert','이미 인증 되었습니다')
                    return
                }

                var url = "/api/user/send/code"
                //console.log(this.formData)
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.need = false
                        this.$event.$emit('alert','이메일이 발송되었습니다')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            privacy: function(){
              this.url = "https://more.mocoplex.com/common/privacy"
              this.title = "more 개인정보 처리방침"
              this.dialog = true
            },
            terms: function(){
              this.url = "https://more.mocoplex.com/common/agreement"
              this.title = "more 서비스 이용 약관"
              this.dialog = true
            },
            onAuth:function(){
                var url = "/api/user/auth"
                //console.log(this.formData)
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.auth = true
                        this.$event.$emit('alert','인증 되었습니다')
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    if(msg == 'NOTVALID'){
                        msg = '인증번호를 다시 확인해주세요'
                    }else{
                        msg = '오류가 발생했습니다'
                    }
                    this.$event.$emit('alert',msg)
                })
            },
            onJoin:function () {
                if (!this.$refs.form.validate()) {
                    window.scrollTo(0, 0)
                    return
                }

                if(this.idError) {
                    window.scrollTo(0, 0)
                    return
                }

                if(!this.auth){
                    this.$event.$emit('alert', '이메일 인증을 완료해주세요')
                    return
                }
                //console.log(this.formData)
                //console.log(btoa());

                var that = this
                var url = "/api/cafe24/create?mall_id=" + this.mall_id + "&shop_no=" + this.shop_no
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.result == 0){
                        //this.$store.commit('login', {value:true})
                        this.$router.push({path:'/'})
                        //that.authorize(res.data.msg)
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    if(msg == 'EXISTED') {
                        // 이미 등록된 아이디일 경우
                        that.idError = true
                        that.idErrorMsg = '사용할 수 없는 아이디 입니다'
                        window.scrollTo(0, 0)
                    }else{
                        this.$event.$emit('alert',"오류가 발생했습니다")
                    }
                })
            },
            authorize: function(cc) {
                var state = this.mall_id + '_' + this.shop_no + '_' + cc
                var url = 'https://' + this.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=1sBkLANvFV2mQuA08WaZED&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/auth2&scope=mall.read_application,mall.write_application,mall.read_product'
                location.href = url
            },
        }
    }
</script>
