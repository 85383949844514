<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <div style="position: relative;">
                    <v-card-title class="pb-1">
                        <main-title title="스크립트 관리"/>
                    </v-card-title>
                    <v-card-text class="stitle">
                        MORE 솔루션을 이용하기 위해선 필수적으로 스크립트를 설치하셔야 합니다.
                    </v-card-text>
                </div>
                <div v-if="(admin == 0 || !!form2.company_code) && !!script_yn" style="position:absolute;top:30px;right:30px;">
                    <v-chip v-if="script_yn == 'N'"
                            class="ma-3"
                            outlined
                            style="font-size:0.8em;"
                    >
                        <v-icon left color="grey">mdi-lightbulb</v-icon>
                        스크립트 미설치
                    </v-chip>
                    <v-chip v-if="script_yn == 'Y'"
                            class="ma-3"
                            outlined
                            style="font-size:0.8em;"
                    >
                        <v-icon left color="orange">mdi-lightbulb-on</v-icon>
                        스크립트 설치 완료
                    </v-chip>
                    <v-chip v-if="script_yn == 'I'"
                            class="ma-3"
                            outlined
                            style="font-size:0.8em;"
                    >
                        <v-icon left>mdi-map-marker-question</v-icon>
                        스크립트 설치 확인중
                    </v-chip>
                </div>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="cafe24"
                            option_default="선택"
                            :value="form2.company_code"
                            @input="(newCC) => {form2.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin== 1 && !!!form2.company_code" style="height: 500px">
            </div>

            <v-tabs v-if="admin == 0 || !!form2.company_code" fixed-tabs color="teal" style="width:100%"
                    v-model="e2"
            >
                <v-tab key="step1"
                >
                    스크립트 설치
                </v-tab>
                <v-tab key="step2"
                >
                    담당자 설치 확인
                </v-tab>
                <v-tab key="step3"
                >
                    설치 완료
                </v-tab>
            </v-tabs>

            <v-stepper v-if="admin == 0 || !!form2.company_code" v-model="e1" vertical class="ma-4">
                <v-stepper-step :complete="e1 > 1" step="1" color="teal lighten-1">
                    스크립트 설치
                </v-stepper-step>

                <v-stepper-content step="1">
                    <v-card outlined class="mb-12">
                        <v-card-title style="font-size:1.1em">
                            카페24 스크립트 설치
                        </v-card-title>
                        <v-card-subtitle>
                            <br>
                            설치하기 버튼을 클릭하시면, 스크립트 설치가 진행됩니다.
                            <br>
                            스크립트 설치가 완료될 때 까지 기다려주세요.
                            <br>
                            설치 진행 후 아래 4가지 페이지 중 미완성된 페이지가 있으면 설치하기를 다시 클릭해주세요.
                            <br>
                            <span class="mr-3"><v-icon color="teal" class="mr-1">mdi-check-bold</v-icon>설치 완료</span>
                            <span><v-icon color="grey lighten-1" class="mr-1">mdi-close-thick</v-icon>설치 미완료</span>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-row align="center" >
                                <v-col>
                                    <p class="mb-0"><br>
                                        <span style="font-weight: 600;font-size:14px">
                                            <v-icon v-if="script.main == 1" color="teal" class="mr-2">mdi-check-bold</v-icon>
                                            <v-icon v-else color="grey lighten-1" class="mr-2">mdi-close-thick</v-icon>
                                            메인 페이지
                                        </span><br>
                                        <span style="font-weight: 600;font-size:14px">
                                            <v-icon v-if="script.category == 1" color="teal" class="mr-2">mdi-check-bold</v-icon>
                                            <v-icon v-else color="grey lighten-1" class="mr-2">mdi-close-thick</v-icon>
                                            카테고리 페이지
                                        </span><br>
                                        <span style="font-weight: 600;font-size:14px">
                                            <v-icon v-if="script.detail == 1" color="teal" class="mr-2">mdi-check-bold</v-icon>
                                            <v-icon v-else color="grey lighten-1" class="mr-2">mdi-close-thick</v-icon>
                                            상세 페이지
                                        </span><br>
                                        <span style="font-weight: 600;font-size:14px">
                                            <v-icon v-if="script.order_complete == 1" color="teal" class="mr-2">mdi-check-bold</v-icon>
                                            <v-icon v-else color="grey lighten-1" class="mr-2">mdi-close-thick</v-icon>
                                            주문 완료 페이지
                                        </span>
                                    </p>
                                </v-col>
                                <v-col md="3" align="center">
                                    <v-btn v-if="script.main + script.category + script.detail + script.order_complete < 4" depressed
                                           color="#29d1cf" dark
                                           @click="createScript"
                                    >
                                        설치하기
                                    </v-btn>
                                    <v-btn v-else depressed
                                           color="#29d1cf"
                                           disabled
                                    >
                                        설치하기
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <div v-if="script.main + script.category + script.detail + script.order_complete > 0">
                          <v-divider />
                          <v-card-title style="font-size:1.1em">
                            카페24 스크립트 삭제
                          </v-card-title>
                          <v-card-text>
                            <v-row align="center" >
                              <v-col>
                                <p class="mb-0">삭제하기 버튼을 클릭하시면, 스크립트 삭제가 진행됩니다.
                                  <br>
                                  스크립트 삭제가 완료될 때 까지 기다려주세요.</p>
                              </v-col>
                              <v-col md="3" align="center">
                                <v-btn depressed
                                       color="#566270" dark
                                       @click="deleteScript"
                                >
                                  삭제하기
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </div>
                    </v-card>
                    <v-btn
                           color="teal lighten-1" dark
                           @click="nextStep(1)"
                    >
                        다음
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="e1 > 2" step="2" color="teal lighten-1">
                    담당자 설치 확인
                    <small class="pt-1">영업일 기준 7일 이내</small>
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card outlined class="mb-12">
                        <v-card-title style="font-size:1.1em">
                            최종 스크립트 설치 확인 요청
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center" >
                                <v-col>
                                    <p class="mb-0">스크립트 설치가 끝나셨다면, 담당자가 최종 확인하여 스크립트 설치 상태를 변경해 드립니다.<br>
                                        우측 버튼을 통해 확인 요청을 진행해주세요.</p>
                                </v-col>
                                <v-col md="3" align="center">
                                    <v-btn v-if="script_yn == 'N'" depressed
                                           color="#29d1cf" dark
                                           @click="request"
                                    >
                                        설치 확인 요청
                                    </v-btn>
                                    <v-btn v-else-if="script_yn == 'I'" depressed
                                           color="#29d1cf"
                                           disabled
                                    >
                                        설치 확인 중
                                    </v-btn>
                                    <v-btn v-else depressed
                                           color="#29d1cf"
                                           disabled
                                    >
                                        설치 완료
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn text @click="prevStep(2)">이전</v-btn>
                    <v-btn depressed class="ml-3"
                           color="teal lighten-1" dark
                           @click="nextStep(2)"
                    >
                        다음
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step step="3" color="teal lighten-1">
                    설치 완료
                    <small class="pt-1">무료체험 신청</small>
                </v-stepper-step>
                <v-stepper-content step="3">
                    <v-card outlined class="mb-12">
                        <v-card-title style="font-size:1.1em">
                            무료체험 신청
                        </v-card-title>
                        <v-card-subtitle>
                            담당자가 스크립트 설치 확인까지 완료하면 아래 무료체험 신청 버튼이 활성화 됩니다.
                        </v-card-subtitle>
                    </v-card>
                    <v-btn text @click="prevStep(3)">이전</v-btn>
                    <v-btn class="ml-3"
                           color="#29d1cf"
                           @click="showApply"
                           style="font-weight: 600;"
                           :dark="script_yn=='Y'?true:false"
                           :disabled="script_yn=='Y'?false:true"
                    >
                        무료체험 시작하기
                    </v-btn>
                </v-stepper-content>
            </v-stepper>
        </v-card>

        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">무료체험 신청하기</span>
                </v-card-title>
                <v-card-subtitle v-if="!!script_ymd" class="mt-1">
                    <span>스크립트 설치 확인일 : </span><span>{{script_ymd}}</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="form.service_type"
                                        :items="services"
                                        label="이용 상품"
                                        multiple
                                        chips
                                        :rules="required2"
                                    ></v-combobox>
                                    <!--
                                    <v-select
                                            :items="services"
                                            v-model="form.service_type"
                                            label="이용 상품"
                                            item-text="name"
                                            item-value="id"
                                            :rules="required"
                                    ></v-select>
                                    -->
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="form.f_start_ymd"
                                                    label="무료체험 시작 희망일자"
                                                    :rules="[ymdRules]"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="form.f_start_ymd" no-title scrollable color="teal" @input="$refs.menu.save(form.f_start_ymd)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <small>* 스크립트 설치 완료 이후 최소 5일간의 데이터 수집 기간이 필요합니다.<br>참고하시어 무료체험 시작 일자를 선택해주세요</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="dialog = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="apply">신청하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay
            :z-index="zIndex"
            :value="overlay"
        >
          <v-layout justify-center align-center>
            <v-progress-circular
                indeterminate
                color="teal lighten-3"
                size="50"
                width="8"
            >
            </v-progress-circular>
          </v-layout>
        </v-overlay>
<!--
        <v-dialog v-model="loading" fullscreen full-width>
          <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
            <v-layout justify-center align-center>
              <v-progress-circular
                  indeterminate
                  color="primary">
              </v-progress-circular>
            </v-layout>
          </v-container>
        </v-dialog>
-->
    </div>
</template>

<script>
    import MainTitle from "../common/MainTitle";
    import MySelect from "../common/MySelect";

    import {mapGetters} from "vuex";
    export default {
        computed: {computedDateFormatted () {
                return this.formatDate(this.form.f_start_ymd)
            },...mapGetters({
                companyCode: 'companyCode',
                admin: 'admin',
            })
        },

        watch: {
            e1: function() {
                this.e2 = this.e1 - 1
            },
            e2: function() {
                this.e1 = this.e2 + 1
            },
            admin:function () {
                this.load_scriptstatus()
                this.load()
                if(this.admin == 1) {
                    var cc = location.hash.replace('#','')
                    this.form2.company_code = cc
                }
            },
            "form2.company_code": function () {
                if(!!!this.form2.company_code){
                    this.script_yn = ''
                }
                if(this.admin == 1) {
                  if (!!!this.form2.company_code)
                    location.hash = ''
                  else
                    location.hash = this.form2.company_code
                }
                this.load_scriptstatus()
                this.load()
            },
        },
        mounted() {
            this.load_scriptstatus()
            this.load()
        },
        components: {
            MainTitle,
            MySelect,
        },
        data: () => ({
            e1: 1,
            e2: 0,
            steps: 3,
            script_yn: '',
            script_ymd: '',
            min_ymd: '',
            dialog: false,
            menu: false,
            valid: false,
            overlay: false,
            zIndex: 10,
            required: [
                v => !!v || '필수 정보입니다'
            ],
            required2: [
              v => v.length>0 || '필수 정보입니다'
            ],
            form2: {
                company_code : '',
            },
            form: {
                service_type: '',
                f_start_ymd: '',
                f_end_ymd: '',
            },
            services: ["패널","티커","추천","서치","리뷰"],
            form3: {},
            min_ymd2: '',
            dialog2: false,
            menu2: false,
            script: {
                mall_id: '',
                shop_no: 0,
                main: 0,
                category: 0,
                detail: 0,
                order_complete: 0
            },
        }),
        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${year}-${month}-${day}`
            },
            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            ymdRules:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.$moment(new Date()).format('YYYY-MM-DD') > v)
                    return "과거일자는 선택할 수 없습니다"
                if(this.min_ymd > v)
                    return "체험 시작일은 스크립트 완료 후 최소 5일 후부터 가능합니다"
                return true
            },
            ymdRules2:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.min_ymd2 > v)
                    return "설치 대행 신청은 최소 1일 후부터 가능합니다"
                var weekDayName = this.$moment(v,'YYYY-MM-DD').day()
                if(weekDayName == 0 || weekDayName == 6)
                    return "주말은 신청이 불가합니다"
                return true
            },
            load_scriptstatus: function() {
                if(this.admin == -1)
                  return

                if(this.admin == 1 && !!!this.form2.company_code)
                  return

              var that = this
              var url = "/api/cafe24/get/script_status"
              if(!!this.form2.company_code){
                url += "?company_code=" + this.form2.company_code
              }
              this.$http.get(url).then(res=>{
                  that.script = res.data
              }).catch((err)=>{
              })
            },
            load: function(){
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form2.company_code)
                    return

                var that = this
                var url = "/api/more/info"
                if(!!this.form2.company_code){
                    url += "?company_code=" + this.form2.company_code
                }
                this.$http.get(url).then(res=>{
                    // 스크립트 설치 여부 가져오기
                    // 스크립트 설치 여부가 'Y'이면 setp 4로..
                    that.script_yn = res.data.script_yn
                    that.script_ymd = !!res.data.script_ymd?that.$moment(res.data.script_ymd, 'YYYYMMDD').format('YYYY-MM-DD'):""
                    if(that.script_yn == 'Y' && !!res.data.script_ymd){
                        that.min_ymd = this.$moment(res.data.script_ymd, 'YYYYMMDD').add(5, 'days').format('YYYY-MM-DD')
                        var td = that.form.f_start_ymd = this.$moment(new Date()).format('YYYY-MM-DD')
                        var min = this.$moment(res.data.script_ymd, 'YYYYMMDD').add(5, 'days').format('YYYY-MM-DD')
                        if(td > min){
                            that.form.f_start_ymd = td
                        }else{
                            that.form.f_start_ymd = min
                        }
                    } else if(that.script_yn == 'Y') {
                        that.min_ymd = this.$moment(new Date()).format('YYYY-MM-DD')
                        that.form.f_start_ymd = this.$moment(new Date()).format('YYYY-MM-DD')
                    }

                    if(that.script_yn == 'Y'){
                        that.e2 = 2
                    }else if(that.script_yn == 'I'){
                        that.e2 = 1
                    }else{
                        that.e2 = 0
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            nextStep: function(n) {
                if (n !== this.steps) {
                    this.e1 = n + 1
                }
            },
            prevStep: function(n) {
                if (n !== 1) {
                    this.e1 = n - 1
                }
            },
            copy: function(objId){
                var srcObj = document.getElementById(objId);
                var range = document.createRange();
                range.selectNode(srcObj);
                window.getSelection().addRange(range);

                try {
                    document.execCommand('copy');
                    this.$event.$emit('alert',"스크립트가 복사 되었습니다")
                } catch(err) {
                }
                window.getSelection().removeAllRanges();

            },
            request : function(){
                var that = this
                this.$event.$emit('confirm', '설치 확인', '설치 확인을 요청하시겠습니까?<br />영업일 기준 7일 이내에 담당자가 확인 해드릴 예정입니다.', function () {
                    var url = "/api/more/apply/script"
                    if(!!that.form2.company_code){
                        url += "?company_code=" + that.form2.company_code
                    }
                    this.$http.get(url).then(res=>{
                        if(res.status == '200'){
                            that.script_yn = 'I'
                        }
                    }).catch((err)=>{
                        var msg = '오류가 발생했습니다'
                        if(err.response.data.msg == 'DUPLICATED') {
                            msg = '이미 설치 확인 요청중 입니다'
                            that.script_yn = 'I'
                        } else if(err.response.data.msg == 'ALREADY_COMPLETE'){
                            msg = '이미 설치 확인이 완료되었습니다'
                            that.load()
                        }
                        that.$event.$emit('alert',msg)
                    })
                })
            },
            showApply : function(){
                // 스크립트 미설치이면 신청 불가.
                this.dialog = true
            },
            apply : function(){
                if (!this.$refs.form.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/apply/service"
                if(!!this.form2.company_code){
                    this.form.company_code = this.form2.company_code
                }

                this.form.f_end_ymd = this.$moment(this.form.f_start_ymd, 'YYYY-MM-DD').add(13, 'days').format('YYYY-MM-DD')
                this.$http.post(url, this.form).then(res=>{
                    if(res.status == '200'){
                        that.dialog = false
                        that.$event.$emit('confirm', '무료 체험 신청완료', '무료체험 신청이 완료되었습니다.<br />소재 관리 페이지로 이동하시겠습니까?', function () {
                            var type = that.form.service_type
                            if(type.includes("패널")){
                              that.$router.push({path:'/manage/design/panel'})
                            }else if(type.includes("티커")){
                              that.$router.push({path:'/manage/design/ticker'})
                            }else if(type.includes("추천")){
                              that.$router.push({path:'/manage/design/recommend'})
                            }else if(type.includes("서치")){
                              that.$router.push({path:'/manage/design/search'})
                            }else {
                              that.$router.push({path:'/manage/review_items'})
                            }
                        })
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    if(err.response.data.msg == 'EXISTED') {
                        msg = '이미 무료체험을 이용한 상품이 있습니다'
                    }
                    that.$event.$emit('alert',msg)
                })
            },
            showDialog2 : function(){
                this.form3.ymd = this.$moment(new Date()).add(1,'days').format('YYYY-MM-DD')
                this.min_ymd2 = this.$moment(new Date()).add(1,'days').format('YYYY-MM-DD')
                this.dialog2 = true
            },
            createScript: function() {
              this.overlay = true
              var cc = this.companyCode
              if(!!!cc) {
                cc = this.form2.company_code
              }

              var state = this.script.mall_id + '_' + this.script.shop_no + '_' + cc + '_' + this.admin
              var url = '';
              if(this.script.app_type == 'panel') {
                url = 'https://' + this.script.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=jSPMChQ5GhKtwIEClbLqOC&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/authaddscript&scope=mall.read_application,mall.write_application,mall.read_product'
              } else if(this.script.app_type == 'ticker') {
                url = 'https://' + this.script.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=1sBkLANvFV2mQuA08WaZED&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/authaddscript2&scope=mall.read_application,mall.write_application,mall.read_product'
              }
              location.href = url
            },
            deleteScript: function() {
              this.overlay = true
              var cc = this.companyCode
              if(!!!cc) {
                  cc = this.form2.company_code
              }

              var state = this.script.mall_id + '_' + this.script.shop_no + '_' + cc + '_' + this.admin
              var url = ''
              if(this.script.app_type == 'panel') {
                url = 'https://' + this.script.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=jSPMChQ5GhKtwIEClbLqOC&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/authdelscript&scope=mall.read_application,mall.write_application,mall.read_product'
              }else if(this.script.app_type == 'ticker') {
                url = 'https://' + this.script.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=1sBkLANvFV2mQuA08WaZED&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/authdelscript2&scope=mall.read_application,mall.write_application,mall.read_product'
              }
              location.href = url
            }
        }
    };
</script>
<style scoped>
    .round-top { border-top-left-radius: 4px !important; border-top-right-radius: 4px !important; border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important;  }
</style>
