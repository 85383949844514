<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">리포트 상세</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>
        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">날짜</th>
                                    <th class="text-center bb bt br">노출수</th>
                                    <th class="text-center bb bt br">클릭수</th>
                                    <th class="text-center bb bt br">클릭율</th>
                                    <th class="text-center bb bt br">전환수</th>
                                    <th class="text-center bb bt br">구매상품수</th>
                                    <th class="text-center bb bt br">전환율</th>
                                    <th class="text-center bb bt br">매출액</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in report_list" :key="item.ymd">
                                    <td class="text-center bb"><span v-html="$_ymd_s(item.ymd)"/></td>
                                    <td v-if="option == 'recom' ||  option == 'sch'" class="text-center bb">-</td>
                                    <td v-else class="text-center bb">{{ item.impression.price() }}</td>
                                    <td class="text-center bb">{{ item.click.price() }}</td>
                                    <td v-if="option == 'recom' ||  option == 'sch'" class="text-center bb">-</td>
                                    <td v-else-if="item.impression == 0" class="text-center bb">-</td>
                                    <td v-else class="text-center bb" v-html="percentage(item.click,item.impression)"></td>
                                    <td class="text-center bb">{{ item.conversion.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center bb" v-if="item.click == 0">0%</td>
                                    <td class="text-center bb" v-else v-html="percentage(item.conversion,item.click)"></td>
                                    <td class="text-center bb">{{ item.sales.price() }}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-for="(item,i) in report_total" :key="i">
                                    <td class="text-center sum bbt bbb">합계</td>
                                    <td v-if="option == 'recom' || option == 'sch'" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb">{{ item.impression.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.click.price() }}</td>
                                    <td v-if="option == 'recom' || option == 'sch'" class="text-center sum bbt bbb">-</td>
                                    <td v-else-if="item.impression == 0" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb" v-html="percentage(item.click,item.impression)"></td>
                                    <td class="text-center sum bbt bbb">{{ item.conversion.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.click == 0">0%</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="percentage(item.conversion,item.click)"></td>
                                    <td class="text-center sum bbt bbb">{{ item.sales.price() }}</td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
        }),
        props:{
            option:{
                type:String,
                default:''
            }
        },
        components: {

        },
        watch: {
            reportParam:function() {
                this.dataload()
            },
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {

                if(!!!this.reportParam.yyyymm)
                    return

                var that = this;
                if (this.option == 'panel') {
                    var url = "/api/more/report/panel/ymd_total"

                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })

                    var url2 = "/api/more/report/panel/ymd_total_sum"
                    this.$http.post(url2, this.reportParam).then(res => {
                        that.report_total = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'ticker'){
                  var url = "/api/more/report/ticker/ymd_total"

                  this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/ticker/ymd_total_sum"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                } else if(this.option == 'recom'){
                  var url = "/api/more/report/recom/ymd_total"

                  this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/recom/ymd_total_sum"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                } else if(this.option == 'sch') {
                  var url = "/api/more/report/sch/ymd_total"

                  this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/sch/ymd_total_sum"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                } else if(this.option == 'all') {
                  var url = "/api/more/report/all/ymd_total"

                  this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/all/ymd_total_sum"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                }
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            downloadCSV: function () {
                var url = "/api/more/report/total_daily_prod/csv?"
                var params = new URLSearchParams(this.reportParam).toString();
                params += '&type=' + this.option
                location.href = url + params
            },
        },
        data: () => ({
            report_list: [],
            report_total: {},
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>