<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div style="margin:auto;">리포트 상세</div>
                <v-autocomplete class="ml-5"
                    :items="company_list"
                    label="사이트명"
                    item-text="name"
                    item-value="id"
                    v-model="selected_company"
                    outlined
                    dense
                    hide-details
                >
                </v-autocomplete>
                <div class="flex-grow-1">
                  <div style="width:110px;float: right">
                      <v-btn block
                             depressed
                             @click="downloadCSV"
                      >
                          다운로드</v-btn>
                  </div>
                </div>
            </div>
        </div>

        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                  <th class="text-center bb bt br" rowspan="2">기간</th>
                                  <th class="text-center bb bt br" rowspan="2">고객사</th>
                                  <th class="text-center bb bt br" rowspan="2">상품</th>
                                  <th class="text-center bb bt br" rowspan="2">사이트PV</th>
                                  <th class="text-center bb bt br" colspan="7">모어 성과</th>
                                  <th class="text-center bb bt" colspan="5">세일즈 정보</th>
                                </tr>
                                <tr>
                                    <th class="text-center bb bt br">노출수</th>
                                    <th class="text-center bb bt br">클릭수</th>
                                    <th class="text-center bb bt br">클릭율</th>
                                    <th class="text-center bb bt br">전환수</th>
                                    <th class="text-center bb bt br">구매상품수</th>
                                    <th class="text-center bb bt br">전환율</th>
                                    <th class="text-center bb bt br">매출액</th>
                                    <th class="text-center bb bt br">솔루션 비용</th>
                                    <th class="text-center bb bt br">월 예상 비용</th>
                                    <th class="text-center bb bt br">업종</th>
                                    <th class="text-center bb bt br">현 계약상태</th>
                                    <th class="text-center bb bt">영업 채널</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in report_list">
                                    <td class="text-center bb">{{ item.ymd }}</td>
                                    <td class="text-center bb">
                                      <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                            @click="showDetail(item)"
                                      >{{ item.company_name }}</span>
                                    </td>
                                    <td class="text-center bb">{{ item.prod }}</td>
                                    <td class="text-center bb">{{ item.pv.price() }}</td>
                                    <td class="text-center bb" v-if="item.impression == 0">-</td>
                                    <td class="text-center bb" v-else>{{item.impression.price()}}</td>
                                    <td class="text-center bb">{{ item.click.price() }}</td>
                                    <td class="text-center bb" v-if="item.impression == 0">-</td>
                                    <td class="text-center bb" v-else v-html="percentage(item.click,item.impression)" />
                                    <td class="text-center bb">{{ item.conversion.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center bb" v-if="item.click == 0">0%</td>
                                    <td class="text-center bb" v-else v-html="percentage(item.conversion,item.click)" />
                                    <td class="text-center bb">{{ item.sales.price() }}</td>
                                    <td class="text-center bb">{{ item.solution_cost.price() }}</td>
                                    <td class="text-center bb">{{ item.expected_cost.price() }}</td>
                                    <td class="text-center bb">{{ item.category }}</td>
                                    <td v-if="item.c_status == '무료'" class="text-center bb">
                                      무료<br>
                                      <span v-if="item.remain == 0">(유료전환일)</span>
                                      <span v-else-if="item.remain > 0">({{item.remain}}일 남음)</span>
                                      <span v-if="item.remain == 0">({{item.remain*-1}}일 지남)</span>
                                    </td>
                                    <td v-else class="text-center bb">{{item.c_status}}</td>
                                    <td class="text-center bb">{{ item.sales_ch }}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-for="(item, i) in report_summary">
                                  <td v-if="!reportParam2.charge" class="text-center sum bbt bbb" colspan="4">
                                    <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                          @click="showDetail2(item)"
                                    >{{item.prod}} 합계</span>
                                  </td>
                                  <td v-else class="text-center sum bbt bbb" colspan="4">{{item.prod}} 합계</td>
                                  <td class="text-center sum bbt bbb" v-if="item.impression == 0">-</td>
                                  <td class="text-center sum bbt bbb" v-else>{{item.impression.price()}}</td>
                                  <td class="text-center sum bbt bbb">{{item.click.price()}}</td>
                                  <td class="text-center sum bbt bbb" v-if="item.impression == 0">-</td>
                                  <td class="text-center sum bbt bbb" v-else v-html="percentage(item.click,item.impression)"></td>
                                  <td class="text-center sum bbt bbb">{{item.conversion.price()}}</td>
                                  <td class="text-center sum bbt bbb">{{item.conversion_total.price()}}</td>
                                  <td class="text-center sum bbt bbb" v-if="item.click == 0">0%</td>
                                  <td class="text-center sum bbt bbb" v-else v-html="percentage(item.conversion,item.click)"></td>
                                  <td class="text-center sum bbt bbb">{{item.sales.price()}}</td>
                                  <td class="text-center sum bbt bbb">{{item.solution_cost.price()}}</td>
                                  <td class="text-center sum bbt bbb">{{item.expected_cost.price()}}</td>
                                  <td class="text-center sum bbt bbb"></td>
                                  <td class="text-center sum bbt bbb"></td>
                                  <td class="text-center sum bbt bbb"></td>
                                </tr>
                                <tr v-for="(item, i) in report_total">
                                    <td v-if="!reportParam2.charge" class="text-center sum bbt bbb" colspan="4">
                                      <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                            @click="showDetail2(item)"
                                      >총 합계</span>
                                    </td>
                                    <td v-else class="text-center sum bbt bbb" colspan="4">총 합계</td>
                                    <td class="text-center sum bbt bbb" v-if="item.impression == 0">-</td>
                                    <td class="text-center sum bbt bbb" v-else>{{item.impression.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.click.price()}}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.impression == 0">-</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="percentage(item.click,item.impression)"></td>
                                    <td class="text-center sum bbt bbb">{{item.conversion.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.conversion_total.price()}}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.click == 0">0%</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="percentage(item.conversion,item.click)"></td>
                                    <td class="text-center sum bbt bbb">{{item.sales.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.solution_cost.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.expected_cost.price()}}</td>
                                    <td class="text-center sum bbt bbb"></td>
                                    <td class="text-center sum bbt bbb"></td>
                                    <td class="text-center sum bbt bbb"></td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

      <v-dialog v-model="dialog" persistent max-width="90%">
        <v-card>
          <v-toolbar
              flat
              dark
              color="#29d1cf"
          >
            <v-toolbar-title>{{dialog_company}} {{dialog_type}} 리포트 일별 상세</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div v-if="dialog_type == '패널'">
              <panel-detail option="daily" ref="daily" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type == '티커'">
              <ticker-detail option="daily" ref="daily" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type == '추천'">
              <recommend-detail option="daily" ref="daily" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type == '서치'">
              <search-detail option="daily" ref="daily" style="height: 80%;"/>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog2" max-width="90%">
        <v-card>
          <v-toolbar
              flat
              dark
              color="#29d1cf"
          >
            <v-toolbar-title>{{dialog_type2}} 리포트 일별 상세</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="dialog2 = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div v-if="dialog_type2 == '패널'">
              <total-daily-prod option="panel" ref="panel" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type2 == '티커'">
              <total-daily-prod option="ticker" ref="ticker" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type2 == '추천'">
              <total-daily-prod option="recom" ref="recom" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type2 == '서치'">
              <total-daily-prod option="sch" ref="sch" style="height: 80%;"/>
            </div>
            <div v-else-if="dialog_type2 == '전체'">
              <total-daily-prod option="all" ref="all" style="height: 80%;"/>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import PanelDetail from "@/components/page/report/PanelDetail";
    import TickerDetail from "@/components/page/report/TickerDetail";
    import RecommendDetail from "@/components/page/report/RecommendDetail";
    import SearchDetail from "@/components/page/report/SearchDetail";
    import TotalDailyProd from "@/components/page/report/TotalDailyProd";
    export default {
        computed: mapGetters({
            reportParam2: 'getReportParam2',
            companyName : 'companyName',
        }),
        components: {
          PanelDetail,
          TickerDetail,
          RecommendDetail,
          SearchDetail,
          TotalDailyProd,
        },
        watch: {
            reportParam2:function() {
                this.dataload()
            },
            page:function() {
                this.dataload()
            },
            selected_company:function () {
              this.reportParam2.company_code = this.selected_company
              this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {
              if(!!!this.reportParam2.yyyymm )
                return

              var that = this;
              var url = "/api/more/report/total/detail"
              this.$http.post(url, this.reportParam2).then(res => {
                that.report_list = res.data
              }).catch((err)=>{
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })

              var url2 = "/api/more/report/total/prod"
              this.$http.post(url2, this.reportParam2).then(res => {
                that.report_summary = res.data
              }).catch((err)=>{
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })

              var url3 = "/api/more/report/total/sales_summary_total"
              this.$http.post(url3, this.reportParam2).then(res => {
                that.report_total = res.data
                //console.log(res.data)
              }).catch((err)=>{
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })

              this.company_lode()
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            plusminus:function(a, b){
              var pm = ((a/b-1)*100).toFixed(2)
              pm = pm.replace('.00','')
              return pm + '%'
            },
            downloadCSV: function () {
                var url = "/api/more/report/sales_detail/csv?"
                var params = new URLSearchParams(this.reportParam2).toString();
                params += '&type=3'
                location.href = url + params
            },
            company_lode: function() {
              var that = this
              var url = "/api/more/get/sales/company_list"
              this.$http.post(url, this.reportParam2).then(res => {
                this.company_list = []
                this.company_list.push({ id: "", name: "전체"})
                res.data.forEach(function(e){
                  that.company_list.push({id: e.id, name: e.name})
                })
              })
              .catch((err)=>{

              })
            },
            showDetail: function(item) {
              this.dialog_company = item.company_name
              this.dialog_type = item.prod
              var ymd = item.ymd.replace(/(\s*)/g, "").split('~')
              var v = {}
              v.start_date = ymd[0]
              v.end_date = ymd[1]
              v.company_code = item.company_code
              v.company_name = item.company_name
              v.platform = ''
              this.$store.commit('reportParam', {value:v})
              this.dialog = true
            },
            showDetail2: function(item) {
              this.dialog_type2 = (item.prod == null)?'전체':item.prod
              var v = {}
              v.yyyymm =  this.reportParam2.yyyymm
              if(!!this.reportParam2.company_code) {
                v.company_code =  this.reportParam2.company_code
              }
              this.$store.commit('reportParam', {value:v})
              this.dialog2 = true
            }
        },
        data: () => ({
            company_list: [
              {
                id: "",
                name: "전체"
              },
            ],
            report_list: [],
            report_summary: [],
            report_total: {},
            page: 1,
            pageCount: 1,
            itemsPerPage: 50,
            selected_company: '',
            reportParam: {},
            dialog: false,
            dialog2: false,
            dialog_company: null,
            dialog_type: null,
            dialog_type2: null
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>