import { render, staticRenderFns } from "./ReviewList.vue?vue&type=template&id=b0d5c580&scoped=true"
import script from "./ReviewList.vue?vue&type=script&lang=js"
export * from "./ReviewList.vue?vue&type=script&lang=js"
import style0 from "./ReviewList.vue?vue&type=style&index=0&id=b0d5c580&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0d5c580",
  null
  
)

export default component.exports