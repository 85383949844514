<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div v-if="option == 'daily'" class="flex-grow-1" style="margin:auto;">기간 비교</div>
                <div v-else class="flex-grow-1" style="margin:auto;">리포트 요약</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadSummaryCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>

        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div v-if="option == 'daily'" class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">기간</th>
                                    <th class="text-center bb bt br">노출수</th>
                                    <th class="text-center bb bt br">클릭수</th>
                                    <th class="text-center bb bt br">전환수</th>
                                    <th class="text-center bb bt br">구매수량</th>
                                    <th class="text-center bb bt br">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                                    <th class="text-center bb bt" colspan="2">실시간 요금</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,i) in report_list" :key="i">
                                    <td v-if="i == 0" class="text-center bb">
                                        <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                                        <br/>
                                        <span style="font-size: 12px">(조회기간)</span>
                                    </td>
                                    <td v-else class="text-center bb">
                                        <span v-html="$_ymd_s(reportParam.search_start_date)"/> ~ <span v-html="$_ymd_s(reportParam.search_end_date)"/>
                                        <br/>
                                        <span style="font-size: 12px">(비교기간)</span>
                                    </td>
                                    <td class="text-center bb">{{ item.impression.price() }}</td>
                                    <td class="text-center bb">{{ item.click.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center bb">{{ item.sales.price() }}</td>
                                    <td v-if="reportParam.admin == 1 && !!!reportParam.company_code" colspan="2" class="text-center bb">-</td>
                                    <td v-if="reportParam.admin == 0 || !!reportParam.company_code" class="text-right bb">
                                        {{ item.expected_cost.price() }}
                                    </td>
                                    <td v-if="reportParam.admin == 0 || !!reportParam.company_code" class="text-left bb">
                                        <v-btn x-small
                                               fab
                                               depressed
                                               :disabled="item.expected_cost==0?true:false"
                                               @click="showDialog(item.expected_cost)"
                                        >
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-if="!!report_list && report_list.length > 0">
                                    <td class="text-center sum bbt bbb">증감</td>
                                    <td class="text-center sum bbt bbb">
                                        <span v-if="report_list[1].impression == 0">-</span>
                                        <span v-else v-html="plusminus(report_list[0].impression, report_list[1].impression)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb">
                                        <span v-if="report_list[1].click == 0">-</span>
                                        <span v-else v-html="plusminus(report_list[0].click, report_list[1].click)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb">
                                        <span v-if="report_list[1].conversion == 0">-</span>
                                        <span v-else v-html="plusminus(report_list[0].conversion, report_list[1].conversion)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb">
                                        <span v-if="report_list[1].conversion_total == 0">-</span>
                                        <span v-else v-html="plusminus(report_list[0].conversion_total, report_list[1].conversion_total)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb">
                                        <span v-if="report_list[1].sales == 0">-</span>
                                        <span v-else v-html="plusminus(report_list[0].sales, report_list[1].sales)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb" colspan="2">
                                        <span v-if="report_list[1].expected_cost == 0">-</span>
                                        <span v-else v-html="plusminus(report_list[0].expected_cost, report_list[1].expected_cost)"></span>
                                    </td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                    <div v-else-if="option == 'message'" class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">기간</th>
                                    <th class="text-center bb bt br">디바이스</th>
                                    <th class="text-center bb bt br">메시지타입</th>
                                    <th class="text-center bb bt br">노출수</th>
                                    <th class="text-center bb bt br">클릭수</th>
                                    <th class="text-center bb bt br">전환수</th>
                                    <th class="text-center bb bt br">구매수량</th>
                                    <th class="text-center bb bt">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,i) in report_list" :key="i">
                                    <td class="text-center bb">
                                        <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                                    </td>
                                    <td v-if="reportParam.platform == 'mobile_ios'" class="text-center bb">mobile_ios</td>
                                    <td v-else-if="reportParam.platform == 'mobile_aos'" class="text-center bb">mobile_aos</td>
                                    <td v-else class="text-center bb">{{ item.platform }}</td>
                                    <td class="text-center bb">{{ item.panel_name }}</td>
                                    <td class="text-center bb">{{ item.impression.price() }}</td>
                                    <td class="text-center bb">{{ item.click.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center bb">{{ item.sales.price() }}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-for="(item,i) in report_total" :key="i">
                                    <td colspan="3" class="text-center sum bbt bbb">합계</td>
                                    <td class="text-center sum bbt bbb">{{ item.impression.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.click.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.conversion.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.sales.price() }}</td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">실시간 요금 상세</span>
                </v-card-title>
                <v-card-text>
                    <v-container class="my-3">
                        <table class="mtable">
                            <thead>
                            <tr class="theader">
                                <th colspan="4" style="border-top: #666 0.5px solid;">솔루션 비용</th>
                            </tr>
                            <tr class="theader">
                                <th >실시간 요금</th>
                                <th>공급가액</th>
                                <th>세액</th>
                                <th>합계</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="!!expected.total">
                                <td>{{ expected.total.price() }}</td>
                                <td>{{ expected.supply.price() }}</td>
                                <td>{{ expected.tax.price() }}</td>
                                <td>{{ expected.total.price() }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </v-container>
                    <small>* 리포트 조회기간 성과 기준으로 산출된 금액으로, 실제 청구 비용과 다를 수 있습니다.</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="dialog = false">닫기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
            companyName : 'companyName',
        }),
        props:{
            option:{
                type:String,
                default:''
            }
        },
        components: {

        },
        watch: {
            reportParam:function() {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {

                if(!!!this.reportParam.start_date || !!!this.reportParam.end_date)
                    return

                var that = this;
                if (this.option == 'daily') {
                    var url = "/api/more/report/ticker/daily_summary"

                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'message'){
                    var url = "/api/more/report/ticker/message_summary"

                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })

                    var url2 = "/api/more/report/ticker/message_total"
                    this.$http.post(url2, this.reportParam).then(res => {
                        that.report_total = res.data
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                }
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            plusminus:function(a, b){
                var pm = ((a/b-1)*100).toFixed(2)
                pm = pm.replace('.00','')
                return pm + '%'
            },
            showDialog:function(cost){
                this.dialog = true
                this.expected.total = cost
                this.expected.supply = Math.round(cost/1.1);
                this.expected.tax = cost - this.expected.supply;
            },
            downloadSummaryCSV: function () {
                var url = "/api/more/report/ticker/summary_csv?"
                if(!!this.companyName){
                    this.reportParam.company_name = this.companyName
                }
                var params = new URLSearchParams(this.reportParam).toString();
                if (this.option == 'daily') {
                    params += '&type=1'
                    location.href = url + params
                }else if (this.option == 'message') {
                    params += '&type=2'
                    location.href = url + params
                }
            }
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list: [],
            report_total: {},
            dialog: false,
            expected: {},
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>