<template>
    <div>
        <v-card class="mcard">

            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="계약 해지 신청"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="all"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <div class="pa-4" v-if="admin == 0 || !!form.company_code">

                <v-card outlined class="pa-2">
                    <v-card-title class="subtitle">
                        <v-icon style="font-size: 1.1em" class="mr-2">mdi-table</v-icon> 해지 신청
                    </v-card-title>
                    <v-card-text>

                        <table class="mtable">
                            <thead>
                            <tr class="theader">
                                <th>상품</th>
                                <th>계약기간</th>
                                <th>해지신청</th>
                                <th>해지예정일</th>
                                <th>잔여일수</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="current.length == 0">
                                <td class="text-center" colspan="5" style="color: #888">유료 계약 내역이 없습니다</td>
                            </tr>
                            <tr v-else v-for="item in current">
                                <td v-html="getProdName(item.product_type)"></td>
                                <td>
                                    <span v-html="$_ymd(item.start_ymd)"/>
                                    ~
                                    <span v-if="!!item.last_ymd" v-html="$_ymd(item.last_ymd)"/>
                                    <span v-else style="font-size:12px;">미정일</span>
                                </td>
                                <td>
                                    <v-btn v-if="!!item.last_ymd" depressed @click="rollback(item)">
                                        해지예약중
                                    </v-btn>
                                    <v-btn v-else depressed @click="showCancel(item)">
                                        계약해지
                                    </v-btn>
                                </td>
                                <td>
                                    <span v-if="!!item.end_ymd" v-html="$_ymd(item.end_ymd)"/>
                                    <span v-else style="font-size:12px;">-</span>
                                </td>
                                <td v-if="!!item.end_ymd" v-html="calc_diff(item)"></td>
                                <td v-else>-</td>
                            </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>

            </div>

            <div class="pa-4" v-if="admin == 0 || !!form.company_code">

                <v-card outlined class="pa-2">
                    <v-card-title class="subtitle">
                        <v-icon style="font-size: 1.1em" class="mr-2">mdi-file-document-edit-outline</v-icon> 환불 정책
                    </v-card-title>
                    <v-card-text>
                        <p style="font-size:12px">
                            회원과 회사는 상호 합의에 의하여 이용계약을 해지할 수 있습니다. 단, 이용계약 해지 시 결제 방법에 따라 환불 방식이 상이합니다.<br><br>
                            ① 후불 결제 회원<br>
                            - 후불 결제 회원이 계약을 중도 해지하는 경우, 회사는 회원에게 계약 시작일부터 해지 시점까지의 서비스 이용분에 대한 요금을 산출하여 청구합니다.<br>
                            - 해지 시점의 서비스 이용 분이 최소 비용 구간(기본 요금)에 해당할 경우, 최소 비용으로 청구됩니다.<br><br>
                            ② 선불 결제 회원<br>
                            - N개월 분의 금액을 선 결제한 회원이 계약을 중도 해지하는 경우, 이미 경과된 계약기간에 대한 이용요금은 환불되지 않으며, 남은 계약기간의 이용요금에 대하여 환불합니다.<br>
                            - 회사는 남은 계약 기간에 해당하는 이용요금을 일 단위로 계산하며, 정산하는데 있어 위약금(미사용 잔여 요금의 20%)을 공제한 금액을 회원에서 환불합니다.<br><br>
                            a. 카드 결제<br>
                            카드 결제 건에 대한 환불을 신청하면, 해당 카드 결제 건이 부분 환불 처리됩니다.<br><br>
                            b. 가상 계좌<br>
                            가상 계좌 환불을 신청하면 해당 가상 계좌 결제 건이 부분 환불 처리됩니다.<br><br>
                            c. 환불 절차 및 소요 시간<br>
                            현금 환불의 경우 환불 신청 접수 완료 후 영업일 기준으로 2~3일 정도 소요되나, 환불 금액 계산등 환불 과정 진행에 따른 추가 기간이 소요되거나 부득이한 사정으로 더 긴 기간이 소요될 수 있습니다.<br>
                            카드 환불의 경우 환불 신청 접수 완료 후 영업일 기준으로 2~3일 정도 소요됩니다. 카드사의 사정에 따라 3일 이상경과될 수 있습니다. 정확한 카드 환불 일정은 해당 카드사로 문의해주세요.<br>
                        </p>
                    </v-card-text>
                </v-card>

            </div>

        </v-card>


        <v-dialog v-model="cancel_dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">계약 해지 신청</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form2"
                            v-model="valid"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>상품</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getProdName(form2.product_type)"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약상태</span>
                                </v-col>
                                <v-col>
                                    <span class="bold">유료 / </span><span class="bold" v-html="getChargeName(form2.charge_type)"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(form2.start_ymd)"></span> ~ <span>미정일</span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3" style="vertical-align: middle;">
                                    <span>해지 예정일</span>
                                </v-col>
                                <v-col cols="5" >
                                    <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="form2.end_ymd"
                                                    :rules="[ymdRules]"
                                                    readonly
                                                    v-on="on"
                                                    hint="선택 일의 00시부터 계약이 종료됩니다"
                                                    persistent-hint
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="form2.end_ymd" no-title scrollable color="teal" @input="$refs.menu.save(form2.end_ymd)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>결제일</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" style="color:red;">해지 예정일부터 결제 가능합니다.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>결제금액</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" style="color:red;">해지 예정일부터 확인 가능합니다.</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <small style="color:black;font-size:0.9em;">해지 후, 재이용을 원하시면 more 고객센터로 문의 바랍니다.<br>MAIL : help-more@mocoplex.com </small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="cancel_dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="cancelContract">해지신청</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import {mapGetters} from "vuex";
    export default {
        computed:
            mapGetters({
                companyName: 'companyName',
                admin: 'admin',
            })
        ,
        components: {
            MainTitle,
            MySelect,
        },
        watch:{
            "form.company_code":function () {
                this.load()
            },
            admin:function () {
                this.load()
            }
        },
        data: () => ({
            current:[],
            menu: false,
            valid: false,
            form: {
                company_code : '',
            },
            form2: {},
            cancel_dlg: false,
            min_ymd: ''
        }),
        mounted() {
            this.min_ymd = this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
            this.load()
        },
        methods:{
            calc_diff:function(item) {
                var start = this.$moment(new Date()).format("YYYYMMDD")
                var diff = this.$moment(item.end_ymd, 'YYYYMMDD').diff(start,'days')
                return diff + '일'
            },
            ymdRules:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.min_ymd > v)
                    return "해지 예정일은 익일부터 가능합니다"
                return true
            },
            showCancel: function(item) {
                this.form2 = Object.assign({}, item)
                this.form2.end_ymd = this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                this.cancel_dlg = true
            },
            rollback:function(item) {
                var that = this

                this.$event.$emit('confirm', '계약해지 취소', '계약 해지 신청을 취소하시겠습니까?', function () {
                    var url = "/api/more/cancel/cancel_contract"
                    var data = Object.assign({}, item)
                    if (!!that.form.company_code) {
                        data.company_code = that.form.company_code
                    }

                    this.$http.post(url, data).then(res => {
                        if (res.status == '200') {
                            that.load()
                            that.$event.$emit('alert', '계약해지 신청이 취소되었습니다')
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                })
            },
            cancelContract:function() {
                if (!this.$refs.form2.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/apply/cancel_contract"
                if(!!this.form.company_code){
                    this.form2.company_code = this.form.company_code
                }
                var last = this.$moment(this.form2.end_ymd, 'YYYYMMDD').add(-1, 'days').format('YYYYMMDD')
                this.form2.last_ymd = last

                this.$http.post(url, this.form2).then(res=>{
                    if(res.status == '200'){
                        that.load()
                        that.cancel_dlg = false
                        that.$event.$emit('alert','계약해지 신청이 완료되었습니다')
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            load:function () {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                // 현재 계약상태
                var url = "/api/more/current_paid"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    //that.myInfo = res.data
                    this.current = res.data
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })

            },
            getChargeName: function(type) {
                if(type == 'C') {
                    return 'CPS'
                }else if(type == 'I') {
                    return '노출형'
                }else if(type == 'P') {
                  return 'PV당 단가'
                }
            },
            getProdName: function(type) {
                if(type == 'P') {
                    return '패널'
                }else if(type == 'T') {
                    return '티커'
                }else if(type == 'R') {
                  return '추천'
                }else if(type == 'S') {
                  return '서치'
                }
            }
        }
    };
</script>
<style scoped>
    .bold {font-weight: 600;}
    .v-text-field input {
        font-size: 14px;
    }
</style>
