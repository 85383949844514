<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">리포트 계정 현황</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>
        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">상품</th>
                                    <th class="text-center bb bt br">계약 상태</th>
                                    <th class="text-center bb bt br">당월 리포트 계정 수</th>
                                    <th class="text-center bb bt br">전월 리포트 계정 수</th>
                                    <th class="text-center bb bt">전월 대비</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="!!report_list_panel && report_list_panel.length > 0">
                                  <template v-for="(item,i) in report_list_panel">
                                    <tr>
                                      <td v-if="i==0" rowspan="2">패널</td>
                                      <td>{{item.type}}</td>
                                      <td>{{item.cur.price()}}</td>
                                      <td>{{item.prev.price()}}</td>
                                      <td v-if="item.prev == 0">-</td>
                                      <td v-else v-html="plusminus2(item.cur,item.prev)"  />
                                    </tr>
                                  </template>
                                  <tr>
                                    <td class="text-center sum bbt bbb" colspan="2">합계/증감</td>
                                    <td class="text-center sum bbt bbb">{{report_list_panel[0].cur + report_list_panel[1].cur}}</td>
                                    <td class="text-center sum bbt bbb">{{report_list_panel[0].prev + report_list_panel[1].prev}}</td>
                                    <td v-if="report_list_panel[0].prev + report_list_panel[1].prev == 0" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb" v-html="plusminus(report_list_panel[0].cur + report_list_panel[1].cur, report_list_panel[0].prev + report_list_panel[1].prev)" />
                                  </tr>
                                </template>
                                <template v-if="!!report_list_ticker && report_list_ticker.length > 0">
                                  <template v-for="(item,i) in report_list_ticker">
                                    <tr>
                                      <td v-if="i==0" rowspan="2">티커</td>
                                      <td>{{item.type}}</td>
                                      <td>{{item.cur.price()}}</td>
                                      <td>{{item.prev.price()}}</td>
                                      <td v-if="item.prev == 0">-</td>
                                      <td v-else v-html="plusminus2(item.cur,item.prev)"  />
                                    </tr>
                                  </template>
                                  <tr>
                                    <td class="text-center sum bbt bbb" colspan="2">합계/증감</td>
                                    <td class="text-center sum bbt bbb">{{report_list_ticker[0].cur + report_list_ticker[1].cur}}</td>
                                    <td class="text-center sum bbt bbb">{{report_list_ticker[0].prev + report_list_ticker[1].prev}}</td>
                                    <td v-if="report_list_ticker[0].prev + report_list_ticker[1].prev == 0" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb" v-html="plusminus(report_list_ticker[0].cur + report_list_ticker[1].cur, report_list_ticker[0].prev + report_list_ticker[1].prev)" />
                                  </tr>
                                </template>
                                <template v-if="!!report_list_recom && report_list_recom.length > 0">
                                  <template v-for="(item,i) in report_list_recom">
                                    <tr>
                                      <td v-if="i==0" rowspan="2">추천</td>
                                      <td>{{item.type}}</td>
                                      <td>{{item.cur.price()}}</td>
                                      <td>{{item.prev.price()}}</td>
                                      <td v-if="item.prev == 0">-</td>
                                      <td v-else v-html="plusminus2(item.cur,item.prev)"  />
                                    </tr>
                                  </template>
                                  <tr>
                                    <td class="text-center sum bbt bbb" colspan="2">합계/증감</td>
                                    <td class="text-center sum bbt bbb">{{report_list_recom[0].cur + report_list_recom[1].cur}}</td>
                                    <td class="text-center sum bbt bbb">{{report_list_recom[0].prev + report_list_recom[1].prev}}</td>
                                    <td v-if="report_list_recom[0].prev + report_list_recom[1].prev == 0" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb" v-html="plusminus(report_list_recom[0].cur + report_list_recom[1].cur, report_list_recom[0].prev + report_list_recom[1].prev)" />
                                  </tr>
                                </template>
                                <template v-if="!!report_list_sch && report_list_sch.length > 0">
                                  <template v-for="(item,i) in report_list_sch">
                                    <tr>
                                      <td v-if="i==0" rowspan="2">서치</td>
                                      <td>{{item.type}}</td>
                                      <td>{{item.cur.price()}}</td>
                                      <td>{{item.prev.price()}}</td>
                                      <td v-if="item.prev == 0">-</td>
                                      <td v-else v-html="plusminus2(item.cur,item.prev)"  />
                                    </tr>
                                  </template>
                                  <tr>
                                    <td class="text-center sum bbt bbb" colspan="2">합계/증감</td>
                                    <td class="text-center sum bbt bbb">{{report_list_sch[0].cur + report_list_sch[1].cur}}</td>
                                    <td class="text-center sum bbt bbb">{{report_list_sch[0].prev + report_list_sch[1].prev}}</td>
                                    <td v-if="report_list_sch[0].prev + report_list_sch[1].prev == 0" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb" v-html="plusminus(report_list_sch[0].cur + report_list_sch[1].cur, report_list_sch[0].prev + report_list_sch[1].prev)" />
                                  </tr>
                                </template>
                                <template v-if="!!report_list_rcsch && report_list_rcsch.length > 0 && report_list_rcsch[0].cur > 0">
                                  <template v-for="(item,i) in report_list_rcsch">
                                    <tr>
                                      <td v-if="i==0" rowspan="2">추천/서치</td>
                                      <td>{{item.type}}</td>
                                      <td>{{item.cur.price()}}</td>
                                      <td>{{item.prev.price()}}</td>
                                      <td v-if="item.prev == 0">-</td>
                                      <td v-else v-html="plusminus2(item.cur,item.prev)"  />
                                    </tr>
                                  </template>
                                  <tr>
                                    <td class="text-center sum bbt bbb" colspan="2">합계/증감</td>
                                    <td class="text-center sum bbt bbb">{{report_list_rcsch[0].cur + report_list_rcsch[1].cur}}</td>
                                    <td class="text-center sum bbt bbb">{{report_list_rcsch[0].prev + report_list_rcsch[1].prev}}</td>
                                    <td v-if="report_list_rcsch[0].prev + report_list_rcsch[1].prev == 0" class="text-center sum bbt bbb">-</td>
                                    <td v-else class="text-center sum bbt bbb" v-html="plusminus(report_list_rcsch[0].cur + report_list_rcsch[1].cur, report_list_rcsch[0].prev + report_list_rcsch[1].prev)" />
                                  </tr>
                                </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam2: 'getReportParam2',
            companyName : 'companyName',
        }),
        components: {

        },
        watch: {
            reportParam2:function() {
                this.dataload()
            },
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {
                if(!!!this.reportParam2.yyyymm )
                    return

                var that = this;
                if(this.reportParam2.prod == '' || this.reportParam2.prod == 'panel') {
                  var url = "/api/more/report/total/current?prod=panel"
                  this.$http.post(url, this.reportParam2).then(res => {
                    that.report_list_panel = res.data
                  }).catch((err) => {
                    that.$event.$emit('alert', "데이터 조회에 실패했습니다")
                  })
                  if(!this.reportParam2.prod == ''){
                    this.report_list_ticker = []
                    this.report_list_recom = []
                    this.report_list_sch = []
                    this.report_list_rcsch = []
                  }
                }
                if(this.reportParam2.prod == '' || this.reportParam2.prod == 'ticker') {
                  var url2 = "/api/more/report/total/current?prod=ticker"
                  this.$http.post(url2, this.reportParam2).then(res => {
                    that.report_list_ticker = res.data
                  }).catch((err) => {
                    that.$event.$emit('alert', "데이터 조회에 실패했습니다")
                  })
                  if(!this.reportParam2.prod == ''){
                    this.report_list_panel = []
                    this.report_list_recom = []
                    this.report_list_sch = []
                    this.report_list_rcsch = []
                  }
                }
                if(this.reportParam2.prod == '' || this.reportParam2.prod == 'recom') {
                  var url3 = "/api/more/report/total/current?prod=recom"
                  this.$http.post(url3, this.reportParam2).then(res => {
                    that.report_list_recom = res.data
                  }).catch((err) => {
                    that.$event.$emit('alert', "데이터 조회에 실패했습니다")
                  })
                  if(!this.reportParam2.prod == ''){
                    this.report_list_ticker = []
                    this.report_list_panel = []
                    this.report_list_sch = []
                    this.report_list_rcsch = []
                  }
                }
                if(this.reportParam2.prod == '' || this.reportParam2.prod == 'sch') {
                  var url4 = "/api/more/report/total/current?prod=sch"
                  this.$http.post(url4, this.reportParam2).then(res => {
                    that.report_list_sch = res.data
                  }).catch((err) => {
                    that.$event.$emit('alert', "데이터 조회에 실패했습니다")
                  })
                  if(!this.reportParam2.prod == ''){
                    this.report_list_ticker = []
                    this.report_list_recom = []
                    this.report_list_panel = []
                    this.report_list_rcsch = []
                  }
                }
                if(this.reportParam2.prod == '') {
                  var url = "/api/more/report/total/current?prod=rcsch"
                  this.$http.post(url, this.reportParam2).then(res => {
                    that.report_list_rcsch = res.data
                  }).catch((err) => {
                    that.$event.$emit('alert', "데이터 조회에 실패했습니다")
                  })
                  if(!this.reportParam2.prod == ''){
                    this.report_list_panel = []
                    this.report_list_ticker = []
                    this.report_list_recom = []
                    this.report_list_sch = []
                  }
                }
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            plusminus:function(a, b){
              var pm = ((a/b-1)*100).toFixed(2)
              pm = pm.replace('.00','')
              return pm + '%'
            },
            plusminus2:function(a, b){
              var pm = ((a/b-1)*100).toFixed(2)
              pm = pm.replace('.00','')
              pm += '%'
              pm += '('
              if(a-b >0){pm+='+'}
              pm+= (a-b).toString()
              pm += ')'
              return pm
            },
            downloadCSV: function () {
              var url = "/api/more/report/sales_summary/csv?"
              var params = new URLSearchParams(this.reportParam2).toString();
              params += '&type=2'
              location.href = url + params
            },
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list_panel: [],
            report_list_ticker: [],
            report_list_recom: [],
            report_list_sch: [],
            report_list_rcsch: [],
            report_total: {},
            page: 1,
            pageCount: 1,
            itemsPerPage: 50,
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>