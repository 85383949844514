<template>

    <div>
        <v-card>
            <v-toolbar flat dense>
                <v-toolbar-title>
                    {{mode}}
                </v-toolbar-title>
                <!--
                <v-spacer/>
                <div>
                    <v-switch
                            color="white"
                            hide-details
                            inset
                    ></v-switch>
                </div>
                -->
            </v-toolbar>
            <v-divider></v-divider>
            <div class="pa-4" style="position:relative;">
                <table class="mtable">
                    <thead>
                    <tr class="theader">
                        <th style="width: 150px">지면</th>
                        <th style="width: 100px">타입</th>
                        <th>노출기간</th>
                        <th>지면 타이틀</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data,i) in mydata" :key="i">
                        <td v-if="data.page_type == 'search'" style="background-color: #eee;">
                            검색 페이지
                        </td>
                        <td v-else-if="data.page_type == 'category'" style="background-color: #eee;">
                          카테고리 페이지
                        </td>
                        <td class="px-5">
                            {{data.rs_type}}
                        </td>
                        <td class="px-5">
                            <v-row align="center" class="py-3">
                            <v-menu
                                    ref="menu"
                                    v-model="data.menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="center-input"
                                            v-model="data.start_ymd"
                                            readonly
                                            v-on="on"
                                            style="width:140px;"
                                            :disabled="data.status==0?false:true"
                                            hint="계약시작일 이후"
                                            persistent-hint
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="data.start_ymd" no-title scrollable color="teal" @input="data.menu=false"></v-date-picker>
                            </v-menu>
                            <span> ~ </span>
                            <v-menu
                                    ref="menu2"
                                    v-model="data.menu2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="center-input"
                                            v-model="data.end_ymd"
                                            readonly
                                            v-on="on"
                                            style="width:140px;"
                                            hint="미지정 가능"
                                            persistent-hint
                                            placeholder="미설정"
                                            clearable
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="data.end_ymd" no-title scrollable color="teal" @input="data.menu2=false"></v-date-picker>
                            </v-menu>
                            </v-row>
                        </td>
                        <td>
                          <v-text-field
                              v-model="data.title"
                              outlined
                              dense
                              counter="30"
                              :rules="!!data.title?maxRules:[]"
                          ></v-text-field>
                        </td>
                        <td v-if="i == 0">
                          <v-layout column align-center>
                            <v-btn
                                depressed
                                outlined
                                color="teal"
                                @click="copyRow"
                            >
                              모든 페이지에 설정 복사
                            </v-btn>
                          </v-layout>
                        </td>
                        <td v-else></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div style="position: absolute;bottom:30px;right:15px;">
                <v-btn class="mr-2"
                        depressed
                        dark
                        color="grey"
                        @click="load"
                >
                    취소
                </v-btn>
                <v-btn
                        depressed
                        dark
                        color="#EC407A"
                        @click="savePageStatus"
                >
                    저장
                </v-btn>
            </div>
        </v-card>

    </div>
</template>
<script>

    import {mapGetters} from "vuex";
    export default {
        props:{
            mode:{
                type: String
            },
            company_code:{
                type: String
            },
            min_ymd:{
                type: String
            },
        },
        computed: mapGetters({
            companyCode: 'companyCode',
        }),
        created() {
            //this.$event.$on('save_page', this.savePageStatus)
        },
        beforeDestroy() {
            //this.$event.$off('save_page', this.savePageStatus)
        },
        mounted() {
            this.load()
        },
        data: () => ({
            sync: '',
            mytab: '',
            mydata:[],
            maxRules: [
              v => (v.length <= 30) || '30자 이하로 설정해주세요',
            ],
        }),
        watch:{
            company_code: function () {
                this.load()
            }
        },
        methods:{
            ymdRules:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                return true
            },
            load: function () {
                var that = this
                var url = "/api/more/get/sch/page_info?platform=" + this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile'){
                    url += '_web'
                }
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.mydata = res.data
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            savePageStatus: function () {
                if(!this.chkValidation())
                    return

                var that = this
                this.$event.$emit('confirm', '노출 지면 설정', '노출 지면 설정을 변경하시겠습니까?', function () {
                    var url = "/api/more/update/sch/page_info"
                    that.$http.post(url, that.mydata).then(res=>{
                        if(res.status == '200'){
                            that.load()
                            that.$event.$emit('alert','노출 지면이 설정되었습니다')
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert','오류가 발생했습니다')
                    })
                })
            },
            chkValidation: function() {
                var that = this
                var no_input = true
                var err_message = ''
                var now = this.$moment(new Date()).format('YYYY-MM-DD')
                this.mydata.some(function(e, i){
                    if(!!e['start_ymd'] || !!e['end_ymd']){
                        no_input = false
                    }

                    if(!!e['start_ymd'] && (e['start_ymd'] < that.min_ymd)){
                        if(i == 0) {
                            err_message = 'pbyk 타입의 노출 시작일을 확인해주세요'
                            return false
                        }else if(i == 1) {
                            err_message = 'kbyk 타입의 노출 시작일을 확인해주세요'
                            return false
                        }
                    }

                    if(!!e['end_ymd'] && !!!e['start_ymd']){
                        if(i == 0) {
                            err_message = 'pbyk 타입의 노출 시작일을 확인해주세요'
                            return false
                        }else if(i == 1) {
                            err_message = 'kbyk 타입의 노출 시작일을 확인해주세요'
                            return false
                        }
                    }

                    if(!!e['end_ymd'] && (e['start_ymd'] > e['end_ymd'])){
                        if(i == 0) {
                            err_message = 'pbyk 타입의 노출 기간을 확인해주세요'
                            return false
                        }else if(i == 1) {
                            err_message = 'kbyk 타입의 노출 기간을 확인해주세요'
                            return false
                        }
                    }

                    if(!!e['end_ymd'] && (now > e['end_ymd'])){
                        if(i == 0) {
                            err_message = 'pbyk 타입의 노출 기간을 확인해주세요'
                            return false
                        }else if(i == 1) {
                            err_message = 'kbyk 타입의 노출 기간을 확인해주세요'
                            return false
                        }
                    }

                    if(e['title'].length > 30) {
                      err_message = '타이틀 글자수를 확인해주세요'
                      return false
                    }
                })

                if(no_input) {
                    that.$event.$emit('alert', '저장할 항목이 없습니다')
                    return false
                } else if(err_message.length > 0) {
                    that.$event.$emit('alert', err_message)
                    return false
                } else {
                    return true
                }

            },
            copyRow: function () {
              var array = this.mydata.slice(0,1)
              var r2 = Object.assign({}, this.mydata[1])
              r2.page_type = 'category';
              r2.rs_type = 'pbyc';
              r2.start_ymd = this.mydata[0].start_ymd;
              r2.end_ymd = this.mydata[0].end_ymd;
              var r3 = Object.assign({}, this.mydata[2])
              r3.page_type = 'detail'
              r3.rs_type = 'pbyp';
              r3.start_ymd = this.mydata[0].start_ymd;
              r3.end_ymd = this.mydata[0].end_ymd;
              array.push(r2)
              array.push(r3)
              this.mydata = array
            }
        }
    }
</script>
<style scoped>
    .main-title {
        margin: 8px 8px;
        font-weight: 500;
        font-size:1em;
        color:#666;
    }

    .center-input >>> input {
        text-align: center;
    }
</style>
