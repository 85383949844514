<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="330">

            <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                        기간을 선택해주세요
                </v-card-title>
                <v-card-text>

                    <v-row class="pa-5">
                        <v-layout column align-center>

                            <vc-date-picker
                                    v-model="range"
                                    mode="range"
                                    is-inline
                            />
                        </v-layout>
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">취소</v-btn>
                    <v-btn color="green darken-1" text @click="select()">선택</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-text-field
                style="max-width:353px;"
                v-model="mydate"
                @click="dialog=true"
                value=""
                :label="title"
                placeholder="기간을 선택해주세요."
                outlined
                readonly
                hide-details
                dense
        ></v-text-field>
    </div>
</template>

<script>
    export default {
        props:{
            title: {
                default:'기간',
                type:String
            },
            start: {
                type:Date
            },
            end: {
                type:Date
            }
        },
        mounted() {
            if(!!!this.start || !!!this.end){
                var d1 = new Date()
                var dayOfMonth = d1.getDate()
                d1.setDate(dayOfMonth - 1)
                this.range.end = d1
                this.trange.end = this.yyyymmdd(d1)
                var d2 = new Date()
                var monthOfYear = d2.getMonth()
                d2.setMonth(monthOfYear - 1)
                this.range.start = d2
                this.trange.start = this.yyyymmdd(d2)
            } else {
                this.range.start = this.start
                this.trange.start = this.yyyymmdd(this.start)
                this.range.end = this.end
                this.trange.end = this.yyyymmdd(this.end)
            }

            this.select()
        },
        data: () => ({
            dialog: false,
            range: {},
            date: null,
            mydate:'',
            trange: {}
        }),
        methods: {
            yyyymmdd: function(date) {
                var yyyy = date.getFullYear().toString();
                var mm = (date.getMonth() + 1).toString();
                var dd = date.getDate().toString();
                return  yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]);
            },
            select: function(){
                this.dialog = false;
                this.trange.start = this.getFormatDate(this.range.start)
                this.trange.end = this.getFormatDate(this.range.end)
                this.mydate = this.trange.start + ' ~ ' + this.trange.end
                this.$emit('mychange', this.trange)
            },
            getFormatDate: function(strDate){
                var date = new Date(strDate);
                var year = date.getFullYear();              //yyyy
                var month = (1 + date.getMonth());          //M
                month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
                var day = date.getDate();                   //d
                day = day >= 10 ? day : '0' + day;          //day 두자리로 저장
                return  year + '-' + month + '-' + day;
            }
        }
    }
</script>
<style>
    .vc-border {
        border: 0 !important;
    }
</style>