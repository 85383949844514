<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="상품소개"/>
                </v-card-title>
            </div>
            <v-tabs color="teal"
                    v-model="mytab"
            >
                <v-tab key="panel"
                >
                    패널
                </v-tab>
                <v-tab key="ticker"
                >
                    티커
                </v-tab>
                <v-tab key="recom"
                >
                  추천
                </v-tab>
                <v-tab key="sch"
                >
                  서치
                </v-tab>
                <v-tab key="review"
                >
                  리뷰
                </v-tab>

                <v-tab-item
                        class="pa-0"
                        align="center"
                        key="panel"
                        style="background-color: #ebebeb;"
                >
                    <v-img
                            :src="panel"
                            min-width="900"
                            max-width="1100"
                    ></v-img>
                </v-tab-item>
                <v-tab-item
                        class="pa-0"
                        align="center"
                        key="ticker"
                        style="background-color: #ebebeb;">
                    <v-img
                            :src="ticker"
                            min-width="900"
                            max-width="1100"
                    ></v-img>
                </v-tab-item>
                <v-tab-item
                    class="pa-0"
                    align="center"
                    key="recom"
                    style="background-color: #ebebeb;">
                    <v-img
                        :src="recom"
                        min-width="900"
                        max-width="1100"
                    ></v-img>
                </v-tab-item>
                <v-tab-item
                    class="pa-0"
                    align="center"
                    key="sch"
                    style="background-color: #ebebeb;">
                  <v-img
                      :src="search"
                      min-width="900"
                      max-width="1100"
                  ></v-img>
                </v-tab-item>
                <v-tab-item
                    class="pa-0"
                    align="center"
                    key="review"
                    style="background-color: #ebebeb;">
                  <v-img
                      :src="review"
                      min-width="900"
                      max-width="1100"
                  ></v-img>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../common/MainTitle";
    import {mapGetters} from "vuex";
    export default {
        computed: mapGetters({
            admin: 'admin',
        }),
        mounted() {
            this.getNotice()
        },
        components: {
            MainTitle,
        },
        watch: {
            admin:function () {
                this.getNotice()
            },
        },
        data: () => ({
            mytab: '',
            panel: require('@/assets/images/1100_panel_0720.jpg'),
            ticker: require('@/assets/images/1100_ticker_0720.jpg'),
            recom: require('@/assets/images/1100_recom.jpg'),
            search: require('@/assets/images/1100_search.jpg'),
            review: require('@/assets/images/review.jpg'),
        }),
        methods: {
            getNotice: function() {
                if(this.admin !== 1) return

                var url = "/api/more/get/ex_notice"
                var that = this
                this.$http.get(url).then(res=>{
                    var plist = []
                    res.data.forEach(function(e, i){
                        var v = that.$cookie.get('nid_' + e.nid)
                        if(!!!v) {
                            var param = {}
                            param.nid = e.nid
                            param.title = e.title
                            param.mode = 'live'
                            plist.push(param)
                        }
                    })
                    that.$event.$emit('notice', plist)
                }).catch((err)=> {
                })
            }
        }
    };
</script>