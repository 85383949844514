<template>
    <v-navigation-drawer
            app
            permanent
            class="d-flex"
            style="width:235px;border-right:0.5px solid rgba(51,51,51,0.3); background-color: white;margin-top:64px;max-height: calc(100% - 64px);"
    >

        <v-list style="border-radius:0;">
            <v-list-item class="px-5">
                <v-list-item-content>
                    <v-list-item-subtitle style="font-size:0.8em;">
                        내 사이트
                    </v-list-item-subtitle>
                    <v-list-item-title v-if="!!companyName" class="title">
                        {{ companyName }}
                    </v-list-item-title>
                    <v-list-item-title v-else class="title">
                        사이트 없음
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list shaped dense style="width: 100%;border-radius:0">
            <v-list-item-group v-model="item" mandatory color="teal">
                <div v-for="(item, i) in items" :key="i">

                    <v-subheader v-if="!!item.subtitle">{{item.subtitle}}</v-subheader>

                    <v-list-item v-else
                            :key="i"
                            link
                            @click="href(item.link)"
                    >

                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list-item-group>
        </v-list>

        <div style="height:76px"></div>
        <v-bottom-navigation
                absolute
        >
            <v-chip
                    class="mr-2"
                    style="margin-top:12px"
                    color="transparent"
                    @click="download"
            >
                어드민 가이드 다운로드
                <v-icon right color="grey darken-2">mdi-download</v-icon>
            </v-chip>
        </v-bottom-navigation>

    </v-navigation-drawer>

</template>

<script>
    import Avatar from 'vue-avatar'
    import {mapGetters} from "vuex";
    export default {
        computed: mapGetters({
            companyName: 'companyName',
        }),
        watch : {
            "$route.path" : function() {
                var current = this.$route.path
                var subCnt = 0
                for(var i in this.items){
                    if(!!!this.items[i].link) subCnt++
                    if(this.items[i].link == current){
                        this.item = parseInt(i) - subCnt
                        break
                    }
                }
            },
            item : function() {
                var current = this.$route.path
                var subCnt = 0
                for(var i in this.items){
                    if(!!!this.items[i].link) subCnt++
                    if(this.items[i].link == current){
                        this.item = parseInt(i) - subCnt
                        break
                    }
                }
            }
        },
        data () {
            return {
                drawer: true,
                item: -1,
                items: [],
                mini: true,
                accountname:''
            }
        },
        created() {

        },
        mounted() {
            this.load()
        },
        components: {
            Avatar
        },
        methods:{
            load : function(){
                var that = this
                var url = "/api/user/account_info"
                this.$http.get(url).then(res=>{
                    that.accountname = res.data.name
                    that.setMenu(res.data.admin, res.data.agency, res.data.mall_id)
                    that.$store.commit('agency',{value:res.data.agency})
                    that.$store.commit('mallId',{value:res.data.mall_id})
                    that.$store.commit('admin',{value:res.data.admin})
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    this.$event.$emit('alert',msg)
                })
            },
            setMenu : function(isAdmin, agency, mallId) {
                if(isAdmin == 1) {
                    this.items = [
                        /*
                        {
                            title: '홈',
                            icon: 'mdi-home',
                            link:'/home'
                        },
                        */
                        {
                            title: '상품소개',
                            icon: 'mdi-map-outline',
                            link:'/intro'
                        },
                        {
                            title: '스크립트 관리',
                            icon: 'mdi-iframe-outline',
                            link:'/start'
                        },
                        {
                            title: '스크립트 관리(카페24)',
                            icon: 'mdi-iframe',
                            link:'/script'
                        },
                        {
                            title: '계약관리 및 결제',
                            icon: 'mdi-credit-card-outline',
                            link:'/payment/list'
                        },
                        {
                            title: '해지 신청',
                            icon: 'mdi-cancel',
                            link:'/cancel'
                        },
                        {
                            subtitle: '운영 관리',
                            link:''
                        },
                        {
                            title: '패널 관리',
                            icon: 'mdi-view-dashboard',
                            link:'/manage/design/panel'
                        },
                        {
                            title: '티커 관리',
                            icon: 'mdi-tooltip-text',
                            link:'/manage/design/ticker'
                        },
                        {
                          title: '추천 관리',
                          icon: 'mdi-page-layout-header-footer',
                          link:'/manage/design/recommend'
                        },
                        {
                          title: '서치 관리',
                          icon: 'mdi-layers-search',
                          link:'/manage/design/search'
                        },
                        {
                            title: '추천항목 관리',
                            icon: 'mdi-format-list-text',
                            link:'/manage/items'
                        },
                        {
                          title: '소셜 리뷰 등록',
                          icon: 'mdi-page-previous-outline',
                          link:'/manage/review_items'
                        },
                        {
                          title: '리뷰 관리',
                          icon: 'mdi-newspaper-variant-multiple',
                          link:'/manage/review'
                        },
                        {
                            subtitle: '성과 리포트',
                            link:''
                        },
                        {
                            title: '패널 리포트',
                            icon: 'mdi-finance',
                            link:'/report/panel'
                        },
                        {
                            title: '티커 리포트',
                            icon: 'mdi-finance',
                            link:'/report/ticker'
                        },
                        {
                            title: '추천 리포트',
                            icon: 'mdi-chart-pie',
                            link:'/report/recommend'
                        },
                        {
                            title: '서치 리포트',
                            icon: 'mdi-chart-pie',
                            link:'/report/search'
                        },
                        {
                          title: '리뷰 리포트',
                          icon: 'mdi-chart-line',
                          link:'/report/review'
                        },
                        {
                            subtitle: '관리자',
                            link:''
                        },
                        {
                            title: '계정 관리',
                            icon: 'mdi-account-edit',
                            link:'/manage/account'
                        },
                        {
                            title: '권한 관리',
                            icon: 'mdi-account-key',
                            link:'/manage/authority'
                        },
                        {
                            title: '공지 관리',
                            icon: 'mdi-bulletin-board',
                            link:'/manage/notice'
                        },
                        {
                          subtitle: '관리자 리포트',
                          link:''
                        },
                        {
                          title: '세일즈 리포트',
                          icon: 'mdi-chart-multiple',
                          link:'/report/sales'
                        },
                        {
                          title: '사이트 분석',
                          icon: 'mdi-google-analytics',
                          link:'/report/total'
                        },
                        {
                            subtitle: '대행 관리',
                            link:''
                        },
                        {
                            title: '대행 계정 현황',
                            icon: 'mdi-text-account',
                            link:'/manage/agency'
                        },
                    ]
                } else {
                    if(!!mallId){
                        this.items = [
                          /*
                          {
                              title: '홈',
                              icon: 'mdi-home',
                              link:'/home'
                          },
                          */
                          {
                            title: '상품소개',
                            icon: 'mdi-map-outline',
                            link:'/intro'
                          },
                          {
                            title: '스크립트 관리',
                            icon: 'mdi-iframe-outline',
                            link:'/script'
                          },
                          {
                            title: '계약관리 및 결제',
                            icon: 'mdi-credit-card-outline',
                            link:'/payment/list'
                          },
                          {
                            title: '해지 신청',
                            icon: 'mdi-cancel',
                            link:'/cancel'
                          },
                          {
                            subtitle: '운영 관리',
                            link:''
                          },
                          {
                            title: '패널 관리',
                            icon: 'mdi-view-dashboard',
                            link:'/manage/design/panel'
                          },
                          {
                            title: '티커 관리',
                            icon: 'mdi-tooltip-text',
                            link:'/manage/design/ticker'
                          },
                          {
                            title: '추천 관리',
                            icon: 'mdi-page-layout-header-footer',
                            link:'/manage/design/recommend'
                          },
                          {
                            title: '서치 관리',
                            icon: 'mdi-layers-search',
                            link:'/manage/design/search'
                          },
                          {
                            title: '추천항목 관리',
                            icon: 'mdi-format-list-text',
                            link:'/manage/items'
                          },
                          {
                            title: '소셜 리뷰 등록',
                            icon: 'mdi-page-previous-outline',
                            link:'/manage/review_items'
                          },
                          {
                            title: '리뷰 관리',
                            icon: 'mdi-newspaper-variant-multiple',
                            link:'/manage/review'
                          },
                          {
                            subtitle: '성과 리포트',
                            link:''
                          },
                          {
                            title: '패널 리포트',
                            icon: 'mdi-finance',
                            link:'/report/panel'
                          },
                          {
                            title: '티커 리포트',
                            icon: 'mdi-finance',
                            link:'/report/ticker'
                          },
                          {
                            title: '추천 리포트',
                            icon: 'mdi-chart-pie',
                            link:'/report/recommend'
                          },
                          {
                            title: '서치 리포트',
                            icon: 'mdi-chart-pie',
                            link:'/report/search'
                          },
                          {
                            title: '리뷰 리포트',
                            icon: 'mdi-chart-line',
                            link:'/report/review'
                          },
                        ]
                    }else{
                        this.items = [
                          /*
                          {
                              title: '홈',
                              icon: 'mdi-home',
                              link:'/home'
                          },
                          */
                          {
                            title: '상품소개',
                            icon: 'mdi-map-outline',
                            link:'/intro'
                          },
                          {
                            title: '스크립트 관리',
                            icon: 'mdi-iframe-outline',
                            link:'/start'
                          },
                          {
                            title: '계약관리 및 결제',
                            icon: 'mdi-credit-card-outline',
                            link:'/payment/list'
                          },
                          {
                            title: '해지 신청',
                            icon: 'mdi-cancel',
                            link:'/cancel'
                          },
                          {
                            subtitle: '운영 관리',
                            link:''
                          },
                          {
                            title: '패널 관리',
                            icon: 'mdi-view-dashboard',
                            link:'/manage/design/panel'
                          },
                          {
                            title: '티커 관리',
                            icon: 'mdi-tooltip-text',
                            link:'/manage/design/ticker'
                          },
                          {
                            title: '추천 관리',
                            icon: 'mdi-page-layout-header-footer',
                            link:'/manage/design/recommend'
                          },
                          {
                            title: '서치 관리',
                            icon: 'mdi-layers-search',
                            link:'/manage/design/search'
                          },
                          {
                            title: '추천항목 관리',
                            icon: 'mdi-format-list-text',
                            link:'/manage/items'
                          },
                          {
                            title: '소셜 리뷰 등록',
                            icon: 'mdi-page-previous-outline',
                            link:'/manage/review_items'
                          },
                          {
                            title: '리뷰 관리',
                            icon: 'mdi-newspaper-variant-multiple',
                            link:'/manage/review'
                          },
                          {
                            subtitle: '성과 리포트',
                            link:''
                          },
                          {
                            title: '패널 리포트',
                            icon: 'mdi-finance',
                            link:'/report/panel'
                          },
                          {
                            title: '티커 리포트',
                            icon: 'mdi-finance',
                            link:'/report/ticker'
                          },
                          {
                            title: '추천 리포트',
                            icon: 'mdi-chart-pie',
                            link:'/report/recommend'
                          },
                          {
                            title: '서치 리포트',
                            icon: 'mdi-chart-pie',
                            link:'/report/search'
                          },
                          {
                            title: '리뷰 리포트',
                            icon: 'mdi-chart-line',
                            link:'/report/review'
                          },
                        ]
                    }
                    if(!!agency){
                        this.items.push({subtitle: '대행 관리', link:''})
                        this.items.push({title: '대행 계정 현황',icon: 'mdi-note-text-outline',link:'/manage/agency'})
                    }
                }
            },
            href: function(link){
                if(link == 'logout'){
                    var that = this
                    this.$event.$emit('confirm', '로그아웃', '로그아웃하시겠습니까?', function () {
                        var url = "/api/user/logout"
                        this.$http.post(url).then(res=>{
                            if(res.status == '200'){
                                document.location.href = '/'
                            }
                        }).catch((err)=>{
                            var msg = err.response.data.msg
                            that.$event.$emit('alert',msg)
                        })
                    })
                }else{
                    this.$router.push({path:link})
                }
            },
            download: function () {
                var file = "/file/MORE_%EC%96%B4%EB%93%9C%EB%AF%BC_%EA%B0%80%EC%9D%B4%EB%93%9C%EB%AC%B8%EC%84%9C_%ED%86%B5%ED%95%A9_v2.0.pdf"
                window.open(file)
            }
        }
    }
</script>
<style scoped>
    >>> .v-item-group.v-bottom-navigation {
        border-top: 1px solid #eee;
        box-shadow: none;
    }
</style>
