<template>
    <div>
        <viewer v-if="show"
                :initialValue="viewerText"
                height="500px" style="background-color: #fff;" />
        <v-row v-if="!!file_url">
            <v-col>
                <v-chip
                        label
                        @click="downloadResource(file_url)"
                >
                    <v-icon left>mdi-paperclip</v-icon>
                    첨부파일 다운로드
                </v-chip>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import '@toast-ui/editor/dist/toastui-editor-viewer.css';

    import { Viewer } from '@toast-ui/vue-editor';

    export default {
        props:{
            mode:{
                type: String,
                default: ''
            },
            nid:{
                type: Number,
                default: 0
            },
        },
        watch:{
            nid: function () {
                this.dataload()
            },
        },
        components: {
            viewer: Viewer
        },
        mounted() {
            this.dataload()
        },
        data: () => ({
            show: false,
            viewerText: '',
            file_url: ''
        }),
        methods: {
            dataload: function () {
                var url = "/api/more/get/notice/view?nid=" + this.nid
                this.$http.get(url).then(res => {
                    this.viewerText = res.data.content
                    this.file_url = res.data.file_url
                    this.show = true
                    this.impression()
                }).catch((err) => {
                })
            },
            impression: function() {
                if(this.mode == 'preview')
                    return

                var url = "/api/more/increment/notice/view?nid=" + this.nid
                this.$http.get(url).then(res => {
                }).catch((err) => {
                })
            },
            forceDownload: function(blob, filename) {
                var a = document.createElement('a');
                a.download = filename
                a.href = blob
                document.body.appendChild(a)
                a.click()
                a.remove()
            },
            downloadResource: function(url, filename) {
                var that = this
                if (!filename) filename = url.split('\\').pop().split('/').pop();
                fetch(url, {
                    headers: new Headers({
                        'Origin': location.origin
                    }),
                    mode: 'cors'
                })
                    .then(response => response.blob())
                    .then(blob => {
                        let blobUrl = window.URL.createObjectURL(blob);
                        that.forceDownload(blobUrl, filename);
                    })
                    .catch(e => console.error(e));
            },
        },
    };
</script>