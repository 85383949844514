import Vue from "vue";
import Vuex from "vuex";

const VueCookie = require('vue-cookie');

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        isLogin: false,
        reportParam: {},
        reportParam2: {},
        reportSummary: {},
        companyCode: '',
        companyName: '',
        admin:-1,
        agency:null,
        agreeYn: null,
        mallId: '',
        shopNo: -1,
    },

    getters: {
        getReportParam (state) {
            return state.reportParam
        },
        getReportParam2 (state) {
            return state.reportParam2
        },
        getReportSummary (state) {
            return state.reportSummary
        },
        isLogin (state) {

            if(state.isLogin)
                return true

            var sid = VueCookie.get('sid')
            var skey = VueCookie.get('skey')

            // !! => '' && null && undefined 둘다걸러줌


            if(!!sid && !!skey)
                return true;
            return false
        },
        companyCode (state) {
            return state.companyCode
        },
        companyName(state) {
            return state.companyName
        },
        admin(state) {
            return state.admin
        },
        agency(state) {
            return state.agency
        },
        agreeYn(state) {
            return state.agreeYn
        },
        mallId(state) {
            return state.mallId
        },
        shopNo(state) {
            return state.shopNo
        },
    },

    mutations: {
        login (state, {value}) {
            state.isLogin = value
        },
        reportParam (state, {value}) {
            state.reportParam = value
        },
        reportParam2 (state, {value}) {
            state.reportParam2 = value
        },
        reportSummary (state, {value}) {
            state.reportSummary = value
        },
        companyCode (state, {value}) {
            state.companyCode = value
        },
        companyName (state, {value}) {
            state.companyName = value
        },
        admin (state, {value}) {
            state.admin = value
        },
        agency (state, {value}) {
            state.agency = value
        },
        agreeYn (state, {value}) {
            state.agreeYn = value
        },
        mallId (state, {value}) {
            state.mallId = value
        },
        shopNo (state, {value}) {
            state.shopNo = value
        },
    },

    /*
    actions: {
        increase: function({ commit }) {
            commit('increase')
        },

        decrease: function({ commit }) {
            commit('decrement');
        },
        asyncIncrement: function({ commit }, incrementalObject) {
            setTimeout(function(){
                commit('asyncIncrement', incrementalObject)
            }, 3000);
        }
    }
    */
});
