<template>

    <div class="main-title">
        <v-icon style="font-size: 1.1em" class="mr-2">mdi-pound</v-icon>
        <span v-html="title"></span>
    </div>

</template>
<script>
    export default {
        props:{
            title:''
        },
    }
</script>
<style scoped>
    .main-title {
        margin: 8px 8px;
        font-weight: 500;
        font-size:1em;
        color:#666;
    }
</style>