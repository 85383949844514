<template>
    <div>
        <v-card class="mcard">

            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="계약관리 및 결제"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="all"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <div class="px-4 pt-4" v-if="(admin == 0 || !!form.company_code) && !!need_corp_info">
                <v-alert type="error" class="mb-0" dense>
                    유료 계약 신청 및 결제를 위해서는 정산정보가 필요합니다. <span style="font-size:14px;float:right">(우측상단 계정명 클릭>회원 정보>정산 정보)</span>
                </v-alert>
            </div>

            <div class="pa-4" v-if="admin == 0 || !!form.company_code">

                <v-card outlined class="pa-2">
                    <v-card-title class="subtitle">
                        <v-icon style="font-size: 1.1em" class="mr-2">mdi-table</v-icon> 결제 대기 상품
                    </v-card-title>
                    <v-card-subtitle class="ml-3" style="font-size:12px">
                        * 매월 1일에 전월 이용 요금이 청구됩니다.<br>
                        * 해지신청한 계약은 해지 예정일에 미정산된 전월 이용 요금이 청구됩니다.<br>
                        * 월 중간에 계약이 시작 또는 해지되는 경우에도 서비스 노출일과 무관하게 매월 1일에 전월 이용 요금이 청구됩니다.<br>
                        &nbsp;&nbsp;&nbsp;(ex. 8월 4일 ~ 8월 27일 이용시, 서비스 요금 산정 방식에 따라 9월 초 비용 청구)
                    </v-card-subtitle>
                    <v-card-text>

                        <table class="mtable">
                            <thead>
                            <tr class="theader">
                                <th>상품</th>
                                <th>과금방식</th>
                                <th>이용기간</th>
                                <th>결제기한</th>
                                <th>이용요금</th>
                                <th>비용결제</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="ready.length == 0">
                                <td class="text-center" colspan="6" style="color: #888">결제 대기 내역이 없습니다</td>
                            </tr>
                            <tr v-else v-for="(item,i) in ready" :key="i">
                                <td v-html="getProdName(item.product_type)"></td>
                                <td>
                                    <span v-html="getChargeName(item.charge_type)"></span>
                                </td>
                                <td>
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-html="$_ymd(item.term_end)"/>
                                </td>
                                <td>
                                    <span style="color:#f00" v-html="ymdhms(item.exp_date)"/><br>
                                    <span v-if="!!item.exp_date" style="font-size:12px">미납 시 OFF 처리됩니다.</span>
                                </td>
                                <td>
                                    <span v-html="item.amount.price('원')"/>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot v-if="ready.length > 0">
                                <tr>
                                    <td colspan="3" style="border:0"></td>
                                    <td style="border:0;font-weight:600;">Total</td>
                                    <td style="border:0;font-weight:600;" v-html="totalAmount.price('원')"></td>
                                    <td style="border:0">
                                        <v-btn depressed @click="preparePayment()">
                                            결제하기
                                        </v-btn>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </v-card-text>

                </v-card>

            </div>


            <div class="pa-4" v-if="admin == 0">

                <v-card outlined class="pa-2">
                    <v-card-title class="subtitle">
                        <v-icon style="font-size: 1.1em" class="mr-2">mdi-table</v-icon>
                        계약 현황
                    </v-card-title>
                    <v-card-subtitle class="ml-3" style="font-size:12px">
                        * 유료 계약의 예상 요금은 현재 월의 집행일자에 따른 예상 요금입니다.<br>
                        * 무료 계약의 예상 요금은 체험분에 대한 30일치의 예상 요금입니다.<br>
                        * 모든 요금은 부가세(VAT)를 포함하고 있습니다.
                    </v-card-subtitle>
                    <v-card-text>
                        <table class="mtable">
                            <thead>
                            <tr class="theader">
                                <th>상품</th>
                                <th>과금방식</th>
                                <th>계약기간</th>
                                <th>예상요금</th>
                                <th>계약상태</th>
                                <th>계약변경</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="current.length == 0">
                                <td class="text-center" colspan="6" style="color: #888">계약 내역이 없습니다</td>
                            </tr>
                            <tr v-else v-for="item in current" :key="item.product_type">
                                <td v-html="getProdName(item.product_type)"></td>
                                <td>
                                    <span v-html="getChargeName(item.charge_type)"></span>
                                </td>
                                <td v-if="item.cstatus == 'F' && !!item.start_ymd">
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-html="$_ymd(item.term_end)"/>
                                    <br>
                                    <span style="font-size:12px;color:blue;">유료계약 시작일 : </span><span style="color:blue;" v-html="$_ymd(item.start_ymd)"/>
                                </td>
                                <td v-else>
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-if="!!item.term_end" v-html="$_ymd(item.term_end)"/>
                                    <span v-else style="font-size:12px;">미정일</span>
                                </td>
                                <td v-if="item.amount == 0">
                                    <span>-</span>
                                </td>
                                <td v-else-if="item.amount == 99999999">
                                  <span>별도 견적</span>
                                </td>
                                <td v-else>
                                    <span v-html="item.amount.price('원')"/>
                                </td>
                                <td v-html="getCstatus(item)"></td>
                                <td>
                                    <v-btn v-if="!!!item.start_ymd && (item.cstatus == 'F' || item.cstatus == 'E')"
                                           depressed dark color="pink" @click="showApply(item)"
                                    >
                                        유료신청
                                    </v-btn>
                                    <div v-else-if="item.cstatus == 'F' || item.cstatus == 'A'">
                                        <v-chip
                                                class="ma-2"
                                        >
                                            유료신청중
                                        </v-chip>
                                        <v-btn icon color="gray" @click="cancelApply(item)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </div>
                                    <v-chip
                                            v-else-if="item.cstatus == 'P' && !!!item.last_ymd"
                                            class="ma-2"
                                    >
                                        자동연장중
                                    </v-chip>
                                    <v-chip
                                            v-else-if="item.cstatus == 'P'"
                                            class="ma-2"
                                    >
                                        해지예약중
                                    </v-chip>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>
            </div>
            <div class="pa-4" v-else-if="admin == 1 && !!form.company_code">

                <v-card outlined class="pa-2">
                    <v-card-title class="subtitle" style="position:relative;">
                        <v-icon style="font-size: 1.1em" class="mr-2">mdi-table</v-icon>
                        계약 현황
                        <v-btn fab depressed dark small
                               color="pink"
                               style="position:absolute;right:10px;"
                               @click="refreshCurrent">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="ml-3" style="font-size:12px">
                        * 유료 계약의 예상 요금은 현재 월의 집행일자에 따른 예상 요금입니다.<br>
                        * 무료 계약의 예상 요금은 체험분에 대한 30일치의 예상 요금입니다.<br>
                        * 모든 요금은 부가세(VAT)를 포함하고 있습니다.
                    </v-card-subtitle>
                    <v-card-text>
                        <table class="mtable">
                            <thead>
                            <tr class="theader">
                                <th>상품</th>
                                <th>과금방식</th>
                                <th>계약기간</th>
                                <th>예상요금</th>
                                <th>계약상태</th>
                                <th>계약변경</th>
                                <th>결제활성화</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="current.length == 0">
                                <td class="text-center" colspan="7" style="color: #888">계약 내역이 없습니다</td>
                            </tr>
                            <tr v-else v-for="item in current" :key="item.product_type">
                                <td v-html="getProdName(item.product_type)"></td>
                                <td v-if="item.cstatus == 'P'">
                                    <v-layout class="d-inline-flex align-center">
                                        <v-select v-if="item.product_type == 'P' && item.service_ver == 'service02'"
                                                :items="p_s02_charges"
                                                v-model="item.charge_type"
                                                item-text="name"
                                                item-value="id"
                                                style="width: 110px"
                                        ></v-select>
                                        <v-select v-else-if="item.product_type == 'P'"
                                                  :items="p_charges"
                                                  v-model="item.charge_type"
                                                  item-text="name"
                                                  item-value="id"
                                                  style="width: 110px"
                                        ></v-select>
                                        <v-select v-else-if="item.product_type == 'T'"
                                                  :items="t_charges"
                                                  v-model="item.charge_type"
                                                  item-text="name"
                                                  item-value="id"
                                                  style="width: 110px"
                                        ></v-select>
                                        <v-select v-else
                                                  :items="rcsch_charges"
                                                  v-model="item.charge_type"
                                                  item-text="name"
                                                  item-value="id"
                                                  style="width: 110px"
                                        ></v-select>
                                        <v-text-field class="right-input"
                                                v-model="item.charge_cost"
                                                style="text-align:right;"
                                                :disabled="item.charge_type=='F'?false:true"
                                        ></v-text-field>
                                        <v-btn class="ml-2" icon
                                               color="primary"
                                               @click="updateChargeType(item)">
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                    </v-layout>
                                </td>
                                <td v-else-if="item.charge_type == 'F'" align="center">
                                    <span v-html="getChargeName(item.charge_type)"></span>
                                    <span class="ml-4">{{item.charge_cost.price()}}</span>
                                </td>
                                <td v-else>
                                    <span v-html="getChargeName(item.charge_type)"></span>
                                </td>
                                <td v-if="item.cstatus == 'F' && !!item.start_ymd">
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-html="$_ymd(item.term_end)"/>
                                    <v-btn icon
                                           color="pink"
                                           @click="showChange(item)"
                                    >
                                        <v-icon>mdi-calendar-check</v-icon>
                                    </v-btn>
                                    <br>
                                    <span style="font-size:12px;color:blue;">유료계약 시작일 : </span><span style="color:blue;" v-html="$_ymd(item.start_ymd)"/>
                                </td>
                                <td v-else-if="item.cstatus == 'E'">
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-if="!!item.term_end" v-html="$_ymd(item.term_end)"/>
                                    <span v-else style="font-size:12px;">미정일</span>
                                </td>
                                <td v-else>
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-if="!!item.term_end" v-html="$_ymd(item.term_end)"/>
                                    <span v-else style="font-size:12px;">미정일</span>
                                    <v-btn icon
                                           color="pink"
                                           @click="showChange(item)"
                                    >
                                        <v-icon>mdi-calendar-check</v-icon>
                                    </v-btn>
                                </td>
                                <td v-if="item.amount == 0">
                                    <span>-</span>
                                </td>
                                <td v-else-if="item.amount == 99999999">
                                  <span>별도 견적</span>
                                </td>
                                <td v-else-if="item.cstatus == 'F' || item.cstatus == 'P'">
                                    <span v-html="item.amount.price('원')"/>
                                </td>
                                <td v-else>
                                    <span>-</span>
                                </td>
                                <td v-html="getCstatus(item)"></td>
                                <td>
                                    <v-btn v-if="item.cstatus == 'F' && !!!item.start_ymd"
                                           depressed dark color="pink" @click="showApply(item)"
                                    >
                                        유료신청
                                    </v-btn>
                                    <div v-else-if="item.cstatus == 'F'">
                                        <v-chip
                                                class="ma-2"
                                        >
                                            유료신청중
                                        </v-chip>
                                        <v-btn icon color="gray" @click="cancelApply(item)">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                    </div>
                                    <v-chip
                                            v-else-if="item.cstatus == 'P' && !!!item.last_ymd"
                                            class="ma-2"
                                    >
                                        자동연장중
                                    </v-chip>
                                    <v-chip
                                            v-else-if="item.cstatus == 'P'"
                                            class="ma-2"
                                    >
                                        해지예약중
                                    </v-chip>
                                    <v-btn v-if="item.cstatus == 'E'"
                                           depressed dark color="pink" @click="showChangeToPayed(item)"
                                    >
                                        유료전환
                                    </v-btn>
                                </td>
                                <td v-if="item.cstatus == 'P'" class="text-center">
                                    <v-layout column align-center wrap>
                                        <v-switch v-model="item.pstatus"
                                            @change="changePstatus(item)">
                                        </v-switch>
                                    </v-layout>
                                </td>
                                <td v-else>-</td>
                            </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>

            </div>


            <div class="pa-4" v-if="admin == 0 || !!form.company_code">

                <v-card outlined class="pa-2">
                    <v-card-title class="subtitle">
                        <v-icon style="font-size: 1.1em" class="mr-2">mdi-table</v-icon>
                        결제 이력
                    </v-card-title>
                    <v-card-text>

                        <table class="mtable">
                            <thead>
                            <tr class="theader">
                                <th>상품</th>
                                <th>이용기간</th>
                                <th>결제요금</th>
                                <th>결제일시</th>
                                <th>결제수단</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="done.length == 0">
                                <td class="text-center" colspan="5" style="color: #888">결제 이력이 없습니다</td>
                            </tr>
                            <tr v-else v-for="item in done" :key="item.id">
                                <td v-html="getProdName(item.product_type)"></td>
                                <td>
                                    <span v-html="$_ymd(item.term_start)"/>
                                    ~
                                    <span v-html="$_ymd(item.term_end)"/>
                                </td>
                                <td>
                                    <span v-html="item.amount.price('원')"/>
                                </td>
                                <td>
                                    <span v-html="$ymdhms(item.payed_at)"/>
                                </td>
                                <td v-if="item.pay_method == 'card'">
                                    카드결제
                                </td>
                                <td v-else-if="item.pay_method == 'vbank'">
                                    가상계좌
                                </td>
                                <td v-else>{{item.pay_method}}</td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="text-center pa-2">
                            <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                        </div>


                    </v-card-text>
                </v-card>

            </div>

        </v-card>

        <v-dialog
                v-model="method_dlg"
                max-width="290"
        >
            <v-card>
                <v-card-title>결제시작</v-card-title>
                <v-card-text>

                    <v-select
                            v-model="payment_method"
                            class="mt-8"
                            item-text="name"
                            item-value="id"

                            :items="payment_items"
                            label="결제방법 선택"
                            outlined
                    ></v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="green darken-1"
                            text
                            @click="method_dlg = false"
                    >
                        취소
                    </v-btn>

                    <v-btn
                            color="green darken-1"
                            text
                            @click="startPayment()"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="apply_dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">유료 신청하기</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form2"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>상품</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getProdName(form2.product_type)"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>과금방식</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getChargeName(form2.charge_type)"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>이용요금</span>
                                </v-col>
                                <v-col>
                                    <span class="bold">계약 신청일부터 월 말까지 집행 성과에 따라 요금이 산출됩니다.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(form2.start_ymd)"></span> ~ <span style="color:dodgerblue;">미정일 (월 단위 자동 연장)</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>결제기한</span>
                                </v-col>
                                <v-col class="bold">
                                    <span>{{form2.pay_ymd}}</span> <span>00:00:00 까지</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <small style="color:red;text-align:center;">* 서비스의 계약기간은, 월 단위로 자동 연장 됩니다.<br>* 결제기한 내 미결제시, 서비스 이용이 종료됩니다.</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="apply_dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="apply">유료신청</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="change_to_payed_dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">유료 전환하기</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form3"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>상품</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getProdName(change_form.product_type)"></span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약 상태 변경</span>
                                </v-col>
                                <v-col class="bold">
                                    종료 -> 대기 -> 유료
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>기존 계약 기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(change_form.term_start)"></span> ~ <span v-html="$_ymd2(change_form.term_end)"></span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3">
                                    <span>변경 계약 기간</span>
                                </v-col>
                                <v-col>
                                    <v-row align="center">
                                        <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.start_ymd"
                                                        label="유료 시작일"
                                                        :rules="[ymdRules]"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.start_ymd" no-title scrollable color="teal" @input="$refs.menu.save(change_form.start_ymd)"></v-date-picker>
                                        </v-menu>
                                        <span> ~ </span>
                                        <v-menu
                                                ref="menu2"
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.last_ymd"
                                                        label="유료 종료일"
                                                        :rules="[ymdRules2]"
                                                        placeholder="미정일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        clearable
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.last_ymd" no-title scrollable color="teal" @input="$refs.menu2.save(change_form.last_ymd)"></v-date-picker>
                                        </v-menu>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="change_to_payed_dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="changePayed">전환하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="change_double_dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">계약 일자 변경</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form4"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>상품</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getProdName(change_form.product_type)"></span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약 상태</span>
                                </v-col>
                                <v-col class="bold">
                                    무료
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>기존 계약 기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(change_form.term_start_f)"></span> ~ <span v-html="$_ymd2(change_form.term_end_f)"></span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3">
                                    <span>변경 계약 기간</span>
                                </v-col>
                                <v-col>
                                    <v-row align="center">
                                        <v-menu
                                                ref="menu5"
                                                v-model="menu5"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.f_start_ymd"
                                                        label="시작일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        disabled
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.f_start_ymd" no-title scrollable color="teal" @input="$refs.menu5.save(change_form.f_start_ymd)"></v-date-picker>
                                        </v-menu>
                                        <span> ~ </span>
                                        <v-menu
                                                ref="menu6"
                                                v-model="menu6"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.f_end_ymd"
                                                        label="종료일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        :disabled="dis2"
                                                        :rules="[ymdRules4]"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.f_end_ymd" no-title scrollable color="teal" @input="$refs.menu6.save(change_form.f_end_ymd)"></v-date-picker>
                                        </v-menu>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약 상태</span>
                                </v-col>
                                <v-col class="bold">
                                    유료
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>기존 계약 기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(change_form.term_start_p)"></span>
                                     ~
                                    <span v-if="!!change_form.term_end_p" v-html="$_ymd2(change_form.term_end_p)"></span>
                                    <span v-else>미정일</span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3">
                                    <span>변경 계약 기간</span>
                                </v-col>
                                <v-col>
                                    <v-row align="center">
                                        <v-menu
                                                ref="menu3"
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.start_ymd"
                                                        label="시작일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        :disabled="dis3"
                                                        :rules="[ymdRules3]"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.start_ymd" no-title scrollable color="teal" @input="$refs.menu3.save(change_form.start_ymd)"></v-date-picker>
                                        </v-menu>
                                        <span> ~ </span>
                                        <v-menu
                                                ref="menu4"
                                                v-model="menu4"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.last_ymd"
                                                        label="종료일"
                                                        placeholder="미정일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        :rules="[ymdRules2]"
                                                        clearable
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.last_ymd" no-title scrollable color="teal" @input="$refs.menu4.save(change_form.last_ymd)"></v-date-picker>
                                        </v-menu>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="change_double_dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="changePeriodDouble">변경하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="change_single_dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">계약 일자 변경</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form5"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>상품</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getProdName(change_form.product_type)"></span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col cols="3">
                                    <span>계약 상태 변경</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-if="change_form.cstatus == 'F'">무료</span>
                                    <span v-else-if="change_form.cstatus == 'W'">대기</span>
                                    <span v-else-if="change_form.cstatus == 'A'">유료 대기</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>기존 계약 기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(change_form.term_start)"></span>
                                     ~
                                    <span v-if="!!change_form.term_end" v-html="$_ymd2(change_form.term_end)"></span>
                                    <span v-else>미정일</span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3">
                                    <span>변경 계약 기간</span>
                                </v-col>
                                <v-col>
                                    <v-row v-if="change_form.cstatus == 'F' || change_form.cstatus== 'W'" align="center">
                                        <v-menu
                                                ref="menu7"
                                                v-model="menu7"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.f_start_ymd"
                                                        label="무료 시작일"
                                                        :rules="[ymdRules]"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        :disabled="dis1"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.f_start_ymd" no-title scrollable color="teal" @input="$refs.menu7.save(change_form.f_start_ymd)"></v-date-picker>
                                        </v-menu>
                                        <span> ~ </span>
                                        <v-menu
                                                ref="menu8"
                                                v-model="menu8"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.f_end_ymd"
                                                        label="무료 종료일"
                                                        :rules="[ymdRules5]"
                                                        placeholder="미정일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.f_end_ymd" no-title scrollable color="teal" @input="$refs.menu8.save(change_form.f_end_ymd)"></v-date-picker>
                                        </v-menu>
                                    </v-row>
                                    <v-row v-else-if="change_form.cstatus == 'A'" align="center">
                                        <v-menu
                                                ref="menu7"
                                                v-model="menu7"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.start_ymd"
                                                        label="유료 시작일"
                                                        :rules="[ymdRules]"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.start_ymd" no-title scrollable color="teal" @input="$refs.menu7.save(change_form.start_ymd)"></v-date-picker>
                                        </v-menu>
                                        <span> ~ </span>
                                        <v-menu
                                                ref="menu8"
                                                v-model="menu8"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="change_form.last_ymd"
                                                        label="유료 종료일"
                                                        :rules="[ymdRules2]"
                                                        placeholder="미정일"
                                                        readonly
                                                        v-on="on"
                                                        class="mx-2"
                                                        clearable
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="change_form.last_ymd" no-title scrollable color="teal" @input="$refs.menu8.save(change_form.last_ymd)"></v-date-picker>
                                        </v-menu>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="change_single_dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="changePeriod">변경하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <PG2 ref="pg" @succ_order="success_order" @failed_order="failed_order"/>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import PG2 from "../../common/PG2";
    import MySelect from "../../common/MySelect";
    import {mapGetters} from "vuex";
    export default {
        computed: {
            totalAmount: function () {
                var sum = 0;
                this.ready.forEach(e => {
                    sum += e.amount;
                });
                return sum
            },...mapGetters({
                admin: 'admin',
                companyName: 'companyName',
            })
        },
        components: {
            MainTitle,
            PG2,
            MySelect,
        },
        watch:{
            page:function () {
                this.load_done()
            },
            admin:function () {
                this.refreshAll()
            },
            "form.company_code":function () {
                this.refreshAll()
            }
        },
        data: () => ({
            merchant_uid:null,
            form: {
                company_code : '',
            },
            form2: {},
            change_form: {},
            page: 1,
            pageCount: 1,
            itemsPerPage: 30,

            //
            payment_items:[
                {
                    "id": "card",
                    "name": "신용카드",
                },
                {
                    "id": "vbank",
                    "name": "가상계좌",
                }
                ],
            ready:[],
            current:[],
            done:[],
            isLoading:false,
            payment_method:'card',
            method_dlg:false,
            apply_dlg:false,
            change_to_payed_dlg: false,
            change_single_dlg: false,
            change_double_dlg: false,
            menu:false,
            menu2:false,
            menu3:false,
            menu4:false,
            menu5:false,
            menu6:false,
            menu7:false,
            menu8:false,
            dis1:false,
            dis2:false,
            dis3:false,
            item:null,
            p_charges: [
                {
                    id: "C",
                    name: "CPS"
                },
                {
                    id: "I",
                    name: "노출형"
                },
                {
                    id: "F",
                    name: "고정과금"
                }
            ],
            p_s02_charges: [
                {
                    id: "I",
                    name: "노출형"
                },
                {
                    id: "F",
                    name: "고정과금"
                }
            ],
            t_charges: [
                {
                    id: "I",
                    name: "노출형"
                },
                {
                    id: "F",
                    name: "고정과금"
                }
            ],
            rcsch_charges: [
              {
                id: "P",
                name: "PV당 단가"
              },
              {
                id: "F",
                name: "고정과금"
              }
            ],
            need_corp_info: null
        }),
        mounted() {
            this.now = this.$moment(new Date()).format('YYYY-MM-DD')
            this.refreshAll()
        },
        methods:{
            refreshAll:function() {
                this.load_corp()
                this.load_ready()
                this.load_current()
                this.load_done()
            },
            refreshCurrent:function() {
                this.load_current()
            },
            success_order:function() {

                // validate()
                this.isLoading = false

                // 성공시
                if(this.payment_method == 'vbank') {

                    this.$event.$emit('alert', '가상계좌 결제 요청에 성공했습니다. (입금정보는 이메일로 전송됩니다.)')

                } else {
                    this.$event.$emit('alert', '결제 요청에 성공했습니다.')
                    this.refreshAll()
                }
            },
            failed_order:function() {
                // 실패시
                this.isLoading = false

                this.$event.$emit('alert', '결제 진행중 실패했습니다.')
            },
            preparePayment:function() {
                if(this.admin == 1){
                    this.$event.$emit('alert',"관리자 계정에서는 결제진행을 할 수 없습니다")
                    return
                }

                var that = this
                var url = "/api/user/corp/info"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.need_corp_info = null

                        // 결제기한 지난 내역있는 경우 안내창
                        var showPopup = 0
                        for(var idx=0; idx<this.ready.length; idx++){
                          showPopup += this.ready[idx].show_popup
                        }

                        if(showPopup > 0) {
                          that.$event.$emit('noti', '서비스 안내', '이용하신 서비스 비용 결제 후 재이용을 희망하시면,<br>노출 관리로 이동하셔서 설정값을 변경하시기 바랍니다', function () {
                            that.method_dlg = true
                          })
                        }else{
                          that.method_dlg = true
                        }
                    }else{
                        that.need_corp_info = true
                        that.$event.$emit('confirm', '정산 정보 입력', '결제를 위해서는 정산 정보가 필요합니다.<br>정산 정보 입력 페이지로 이동하시겠습니까?', function () {
                            that.$router.push({path:'/account',query:{tab:1}})
                        })
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"오류가 발생했습니다")
                })
            },
            startPayment:function() {
                this.method_dlg = false

                // 주문시작
                this.isLoading = true
                /*

                        var obj = {
                            service_name:'shockping',
                            merchant_uid: this.$route.query.id,
                            amount: v.total,
                            name: v.prod_name,
                            buyer_name: v.name,
                            buyer_addr: v.addr1 + ' ' + v.addr2,
                            buyer_email: v.email,
                            buyer_tel: v.phone,
                            buyer_postcode: v.postcode,
                            //url2: document.location.href+'&pg=true',
                            url2: dom+'/user/order?id='+this.$route.query.id,
                            //http://my.shockping.com:3333/user/order?id=rfr30i4gdr
                            //m_redirect_url: postback_url
                        }

                 */

                var url = "/api/payment/make/payment"
                this.$http.get(url)
                    .then(data => {
                        var v = data.data

                        this.merchant_uid = v.order_sid

                        var url2 = document.location.href
                        url2 += (url2.indexOf('?')>0)?'&':'?'
                        url2 += 'pg=true&order_id='
                        url2 += v.order_sid

                        var obj = {
                            service_name:'more',
                            site_name:'모어',
                            merchant_uid: v.order_sid,
                            amount: v.amount,
                            name: v.prod_name,
                            buyer_name: v.user_name,
                            buyer_email: v.user_email,
                            buyer_addr:'',
                            buyer_tel:'',
                            buyer_postcode:'',
                            url2: url2,

                            // 모바일에서의 피지 이벤트
                            //m_redirect_url: document.location.href+'&pg=true',
                        }

                        //console.log(obj)
                        this.$refs.pg.start(this.payment_method, obj)

                    }).catch(err=>{
                        console.log(err)
                        this.isLoading = false
                        this.$event.$emit('alert', '결제중 문제가 발생했습니다.')
                })

            },
            load_corp:function() {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                var that = this
                var url = "/api/user/corp/info"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.need_corp_info = null
                    }else{
                        that.need_corp_info = true
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_ready:function () {

                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                // 결제해야하는거
                var url = "/api/payment/get/ready"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    //that.myInfo = res.data
                    this.ready = res.data
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })

            },
            load_current:function () {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                // 현재 계약상태
                var url = "/api/more/current"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    //console.log(res.data)
                    this.current = res.data
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })

            },
            load_done:function () {

                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                // 결제된거
                var url = "/api/payment/get/done?"
                url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                if(!!this.form.company_code){
                    url += "&company_code=" + this.form.company_code
                }

                this.$http.get(url).then(data=>{
                    //that.myInfo = res.data
                    this.done = data.data.content

                    var paging = data.data.pageable
                    this.page = paging["pageNumber"] + 1
                    this.pageCount = data.data.totalPages

                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            getProdName: function(type) {
                if(type == 'P') {
                    return '패널'
                }else if(type == 'T') {
                    return '티커'
                }else if(type == 'R') {
                  return '추천'
                }else if(type == 'S') {
                  return '서치'
                }
            },
            getChargeName: function(type) {
                if(type == 'C') {
                    return 'CPS'
                }else if(type == 'I') {
                    return '노출형'
                }else if(type == 'F') {
                    return '고정과금'
                }else if(type == 'P') {
                  return 'PV당 단가'
                }
            },
            getCstatus: function (item) {
                var cstatus = item.cstatus
                if(cstatus == 'N') {
                    return '신규'
                }else if(cstatus == 'F') {
                    return '무료'
                }else if(cstatus == 'P') {
                    return '유료'
                }else if(cstatus == 'W') {
                    return '대기'
                }else if(cstatus == 'E') {
                    return '종료'
                }else if(cstatus == 'A') {
                    return '유료 대기'
                }else {
                    return '-'
                }
            },
            showApply: function(item) {
                // 정산정보 여부
                var that = this
                var url = "/api/user/corp/info"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.need_corp_info = null
                        that.form2 = Object.assign({}, item)
                        var start = this.$moment(item.f_end_ymd, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD')
                        var start2 = this.$moment(new Date()).add(1, 'days').format('YYYYMMDD')
                        if(start2 > start) {
                          start = start2
                        }
                        var pay = this.$moment(start, 'YYYYMMDD').add(1, 'months').date('8')
                        that.form2.start_ymd = start
                        that.form2.pay_ymd = pay.format('YYYY.MM.DD')
                        that.apply_dlg = true
                    }else{
                        that.need_corp_info = true
                        that.$event.$emit('confirm', '정산 정보 입력', '유료 계약 신청을 위해서는 정산 정보가 필요합니다.<br>정산 정보 입력 페이지로 이동하시겠습니까?', function () {
                            that.$router.push({path:'/account',query:{tab:1}})
                        })
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"오류가 발생했습니다")
                })
            },
            cancelApply: function(item) {
                var that = this

                this.$event.$emit('confirm', '유료신청 취소', '유료 계약 신청을 취소하시겠습니까?', function () {
                    var url = "/api/more/cancel/paid_service"
                    var data = Object.assign({}, item)
                    if (!!that.form.company_code) {
                        data.company_code = that.form.company_code
                    }
                    var tod = this.$moment(new Date()).format('YYYYMMDD')
                    if(tod > item.f_end_ymd){
                      data.end_ymd = this.$moment(item.f_end_ymd, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD')
                    }

                    this.$http.post(url, data).then(res => {
                        if (res.status == '200') {
                            that.load_current()
                            that.$event.$emit('alert', '유료계약 신청이 취소되었습니다')
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                })
            },
            apply: function() {
                var that = this
                var url = "/api/more/apply/paid_service"
                if(!!this.form.company_code){
                    this.form2.company_code = this.form.company_code
                }
                this.$http.post(url, this.form2).then(res=>{
                    if(res.status == '200'){
                        that.load_current()
                        that.apply_dlg = false
                        that.$event.$emit('alert','유료계약 신청이 완료되었습니다')
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            updateChargeType: function (item) {
                if(item.charge_type == 'F'){
                    if(!!!item.charge_cost) {
                        alert("고정단가를 입력해주세요")
                        return
                    }

                    if(item.charge_cost == '0'){
                        alert("고정단가를 입력해주세요")
                        return
                    }
                }

                var that = this
                this.$event.$emit('confirm', '과금방식 변경', '과금방식을 수정하시겠습니까?', function () {
                    var url = "/api/more/update/contract"
                    var data = {}
                    data.product_type = item.product_type
                    data.charge_type = item.charge_type
                    data.charge_cost = item.charge_cost
                    if (!!that.form.company_code) {
                        data.company_code = that.form.company_code
                    }

                    this.$http.post(url, data).then(res => {
                        if (res.status == '200') {
                            that.load_current()
                            that.$event.$emit('alert', '과금방식이 변경되었습니다')
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                })
            },
            changePstatus: function (item) {
                var that = this
                this.$event.$emit('confirm', '결제 활성여부 변경', '결제 활성여부를 수정하시겠습니까?', function () {
                    var url = "/api/more/update/contract"
                    var data = {}
                    data.product_type = item.product_type
                    data.pstr = item.pstatus?"1":"0"
                    if (!!that.form.company_code) {
                        data.company_code = that.form.company_code
                    }

                    this.$http.post(url, data).then(res => {
                        if (res.status == '200') {
                            that.load_current()
                            that.$event.$emit('alert', '결제 활성여부가 변경되었습니다')
                        }
                    }).catch((err) => {
                        item.pstatus = !item.pstatus
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                }, function() {
                    item.pstatus = !item.pstatus
                })
            },
            showChangeToPayed: function(item) {
                this.change_form = Object.assign({}, item)
                var start = this.$moment(new Date()).add(1, 'days')
                this.change_form.start_ymd = start.format('YYYY-MM-DD')
                this.change_form.last_ymd = ""
                this.change_form.min_ymd = start.format('YYYY-MM-DD')
                this.change_to_payed_dlg = true
            },
            changePayed: function() {
                if (!this.$refs.form3.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/change/contract_payed"
                if(!!this.form.company_code){
                    this.change_form.company_code = this.form.company_code
                }
                if(!!this.change_form.last_ymd){
                    var end = this.$moment(this.change_form.last_ymd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    this.change_form.end_ymd = end
                }

                this.$http.post(url, this.change_form).then(res=>{
                    if(res.status == '200'){
                        that.load_current()
                        that.change_to_payed_dlg = false
                        that.$event.$emit('alert','유료 전환이 완료되었습니다')
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            showChange: function(item) {
                this.dis1 = false
                this.dis2 = false
                this.dis3 = false
                if(item.cstatus == 'F' && !!!item.start_ymd){
                    // single f_end_ymd 만 편집
                    this.change_form = Object.assign({}, item)
                    this.change_form.f_start_ymd = item.f_start_ymd.dateFormat()
                    this.change_form.f_end_ymd = item.f_end_ymd.dateFormat()
                    this.dis1 = true
                    this.change_single_dlg = true
                }else if(item.cstatus == 'F'){
                    // double  f_end_ymd, start_ymd, end_ymd 편집
                    this.change_form = Object.assign({}, item)
                    this.change_form.term_start_f = item.f_start_ymd
                    this.change_form.term_end_f = item.f_end_ymd
                    this.change_form.term_start_p = item.start_ymd
                    this.change_form.term_end_p = item.last_ymd
                    this.change_form.f_start_ymd = item.f_start_ymd.dateFormat()
                    this.change_form.f_end_ymd = item.f_end_ymd.dateFormat()
                    this.change_form.start_ymd = item.start_ymd.dateFormat()
                    if(!!item.last_ymd){
                        this.change_form.last_ymd = item.last_ymd.dateFormat()
                    }
                    this.dis2 = false
                    this.dis3 = false
                    this.change_double_dlg = true
                }else if(item.cstatus == 'W'){
                    // single  f_start_ymd, f_end_ymd 편집
                    this.change_form = Object.assign({}, item)
                    this.change_form.f_start_ymd = item.f_start_ymd.dateFormat()
                    this.change_form.f_end_ymd = item.f_end_ymd.dateFormat()
                    var start = this.$moment(new Date()).add(1, 'days')
                    if(!!item.script_ymd){
                        var src_ymd = this.$moment(item.script_ymd, 'YYYYMMDD').add(5, 'days').format('YYYYMMDD')
                        if(src_ymd > start.format('YYYYMMDD')){
                            this.change_form.min_ymd = this.$moment(item.script_ymd, 'YYYYMMDD').add(5, 'days').format('YYYY-MM-DD')
                        }else{
                            this.change_form.min_ymd = start.format('YYYY-MM-DD')
                        }
                    }else{
                        this.change_form.min_ymd = start.format('YYYY-MM-DD')
                    }
                    this.change_single_dlg = true
                } else if(item.cstatus == 'A') {
                    // single  start_ymd, last_yme 편집
                    this.change_form = Object.assign({}, item)
                    this.change_form.start_ymd = item.start_ymd.dateFormat()
                    if(!!item.last_ymd) {
                        this.change_form.last_ymd = item.last_ymd.dateFormat()
                    }
                    this.change_single_dlg = true
                }else if(item.cstatus == 'P') {
                    // double last_ymd만 편집
                    this.change_form = Object.assign({}, item)
                    this.change_form.term_start_f = item.f_start_ymd
                    this.change_form.term_end_f = item.f_end_ymd
                    this.change_form.term_start_p = item.start_ymd
                    this.change_form.term_end_p = item.last_ymd
                    this.change_form.f_start_ymd = item.f_start_ymd.dateFormat()
                    this.change_form.f_end_ymd = item.f_end_ymd.dateFormat()
                    this.change_form.start_ymd = item.start_ymd.dateFormat()
                    if(!!item.last_ymd){
                        this.change_form.last_ymd = item.last_ymd.dateFormat()
                    }
                    this.dis2 = true
                    this.dis3 = true
                    this.change_double_dlg = true
                }
            },
            changePeriodDouble: function() {
                if (!this.$refs.form4.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/change/contract_period"
                if(!!this.form.company_code){
                    this.change_form.company_code = this.form.company_code
                }
                if(!!this.change_form.last_ymd){
                    var end = this.$moment(this.change_form.last_ymd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                    this.change_form.end_ymd = end
                }

                this.$http.post(url, this.change_form).then(res=>{
                    if(res.status == '200'){
                        that.load_current()
                        that.change_double_dlg = false
                        that.$event.$emit('alert','계약일이 변경되었습니다')
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            changePeriod: function() {
                if (!this.$refs.form5.validate()) {
                    return
                }

                var that = this
                if(this.change_form.cstatus == 'F' || this.change_form.cstatus == 'W'){

                    var url = "/api/more/change/contract_free"
                    if(!!this.form.company_code){
                        this.change_form.company_code = this.form.company_code
                    }
                    this.$http.post(url, this.change_form).then(res=>{
                        if(res.status == '200'){
                            that.load_current()
                            that.change_single_dlg = false
                            that.$event.$emit('alert','계약일이 변경되었습니다')
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert','오류가 발생했습니다')
                    })

                } else if(this.change_form.cstatus == 'A'){

                    var url = "/api/more/change/contract_payed"
                    if(!!this.form.company_code){
                        this.change_form.company_code = this.form.company_code
                    }
                    if(!!this.change_form.last_ymd){
                        var end = this.$moment(this.change_form.last_ymd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
                        this.change_form.end_ymd = end
                    }
                    this.$http.post(url, this.change_form).then(res=>{
                        if(res.status == '200'){
                            that.load_current()
                            that.change_single_dlg = false
                            that.$event.$emit('alert','계약일이 변경되었습니다')
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert','오류가 발생했습니다')
                    })

                }
            },
            ymdhms:function (date) {
                if(!!!date){
                    return '<span>-</span>'
                }

                var strDate = this.$moment(date).format('YYYY.MM.DD HH:mm:ss')
                return '<span style="font-size: 13px">'+strDate+' 까지</span>'
            },
            ymdRules:function(v) {
                if (!!!v)
                    return "필수 정보입니다"
                if (!!this.change_form.min_ymd) {
                    if (this.change_form.min_ymd > v)
                        return "시작일은 " + this.change_form.min_ymd + "부터 가능합니다"
                }
                return true
            },
            ymdRules2:function(v) {
                if(!!v && this.change_form.start_ymd > v)
                    return "종료일이 시작일보다 빠를 수 없습니다"
                if(!!v && this.now > v)
                    return "종료일은 금일부터 가능합니다"
                return true
            },
            ymdRules3:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(!!v && this.change_form.f_end_ymd >= v)
                    return "무료 종료일 보다 크게 설정해주세요"
                return true
            },
            ymdRules4:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(!!v && this.change_form.f_start_ymd > v)
                    return "종료일이 시작일보다 빠를 수 없습니다"
                return true
            },
            ymdRules5:function(v) {
                if(!!v && this.change_form.f_start_ymd > v)
                    return "종료일이 시작일보다 빠를 수 없습니다"
                if(this.now > v)
                    return "종료일은 금일부터 가능합니다"
                return true
            },
        }
    };
</script>
<style scoped>
    .bold {font-weight: 600;}

    .right-input >>> input {
        text-align: right;
    }
</style>
