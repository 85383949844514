<template>
  <div>
    <v-dialog v-model="isshow" persistent max-width="800px" style="background-color: white">
      <v-card class="pa-4">
        <v-card-title class="pa-3" style="color: #666">
          리뷰 블럭 정보
        </v-card-title>
        <v-card-text>
          <div>
            <div style="display: flex;flex-direction: column;justify-content: space-between;">
              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-row class="pt-2">
                  <v-col class="py-0">
                    <v-text-field
                        label="키워드"
                        v-model="block.keyword"
                        outlined
                        required
                        dense
                        :rules="required"
                        hint="설정한 리뷰들이 노출되기 원하는 상품명을 정확하게 입력해주세요"
                        persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                        label="블럭에 노출될 타이틀"
                        v-model="block.title"
                        outlined
                        required
                        dense
                        :rules="required"
                        hint="블럭의 제목을 입력해주세요. 사이트에 블럭의 타이틀로 노출됩니다."
                        persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div class="best_container pt-7">
                <div class="best_container_row">
                  <div v-if="best_list.length==0" class="best_item">
                    <v-btn v-if="best_list.length==0" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[0].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(0)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=1" class="best_item">
                    <v-btn v-if="best_list.length==1" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[1].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(1)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=2" class="best_item">
                    <v-btn v-if="best_list.length==2" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[2].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(2)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=3" class="best_item">
                    <v-btn v-if="best_list.length==3" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[3].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(3)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=4" class="best_item">
                    <v-btn v-if="best_list.length==4" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[4].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(4)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="best_container_row mt-2">
                  <div v-if="best_list.length<=5" class="best_item">
                    <v-btn v-if="best_list.length==5" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[5].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(5)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=6" class="best_item">
                    <v-btn v-if="best_list.length==6" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[6].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(6)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=7" class="best_item">
                    <v-btn v-if="best_list.length==7" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[7].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(7)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=8" class="best_item">
                    <v-btn v-if="best_list.length==8" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[8].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(8)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="best_list.length<=9" class="best_item">
                    <v-btn v-if="best_list.length==9" class="mx-2"
                           fab dark x-small
                           color="#29D1CF"
                           @click="editable=true"
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="best_item"
                       :style="'background: url('+best_list[9].thumb+') no-repeat center center/contain;'">
                    <v-btn class="mx-2"
                           fab dark x-small
                           color="red"
                           @click="remove(9)"
                    >
                      <v-icon dark>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="px-4 pt-7 d-flex">
          <v-btn class="mr-1 flex-grow-1"
                 depressed
                 dark
                 color="grey"
                 @click="close"
          >
            취소
          </v-btn>
          <v-btn class="ml-1 flex-grow-1"
                 depressed
                 dark
                 color="#EC407A"
                 @click="save"
          >
            저장
          </v-btn>
        </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editable" persistent max-width="90%">
      <v-card>
        <v-toolbar
            flat
            dark
            color="#29d1cf"
        >
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="editable = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div>
            <review-list :company_code="company_code" option="popup" :key="flag2" @reviewSelected="selected" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ReviewList from "@/components/page/manage/ReviewList";

export default {
  name: "ReviewUploadDlg",
  computed: mapGetters({
    admin: 'admin',
    companyName: 'companyName',
  }),
  props:{
    company_code:{
      type: String
    },
    review:{
      type: Object
    },
    isshow:{
      type: Boolean
    }
  },
  data: () => ({
    valid: false,
    required: [
      v => !!v || '필수 정보입니다'
    ],
    best_list:[],
    block: {
      keyword: null,
      title: null,
    },
    editable: false,
    dialog: false,
    flag2: 0,
  }),
  components: {
    ReviewList
  },
  mounted() {
    this.load()
  },
  methods: {
    load:function() {
      var that = this
      if(this.review == null)
        return;

      this.block.keyword = this.review.keyword
      this.block.title = this.review.title

      var review_list = this.review.review.split(',')
      review_list.forEach(function(e, i){
        var url2 = "/api/more/review/get_catalog?item=" + e
        if(!!that.company_code){
          url2 += "&company_code=" + that.company_code
        }
        that.$http.get(url2).then(res2=>{
          var obj = {}
          obj.post_id = e
          obj.thumb = res2.data.image_url
          that.$set(that.best_list, i, obj)

        }).catch((err2)=>{

        })
      })
    },
    editCancel: function() {
      try {
        this.$refs.form.resetValidation()
      }catch (e){}
      this.block = {}
      this.editable = false
    },
    remove: function(idx) {
      this.best_list.splice(idx, 1)
    },
    close: function() {
      this.editCancel()
      this.isshow = false
      this.$emit("closeDlg");
    },
    save: function (){
      if (!this.$refs.form.validate()) {
        return;
      }

      if(this.best_list.length < 1) {
        this.$event.$emit('alert',"리뷰를 1개 이상 등록해주세요")
        return
      }

      var arr_rv = []
      for(var i=0;i<this.best_list.length;i++) {
        arr_rv.push(this.best_list[i].post_id)
      }
      this.block.review = arr_rv.toString()

      var url = "/api/more/add/review_keyword"
      if (!!this.company_code) {
        url += "?company_code=" + this.company_code
      }

      var that = this
      this.$event.$emit('confirm', '리뷰 블럭 저장', '리뷰 블럭을 저장하시겠습니까?\n이미 사용중인 키워드는 위 정보로 변경됩니다.', function () {
        that.$http.post(url, that.block).then(res => {
          if (res.status == '200') {
            that.close()
            that.$event.$emit('alert', '리뷰 블럭이 저장되었습니다')
          }
        }).catch((err) => {
          that.$event.$emit('alert', '오류가 발생했습니다')
        })
      })
    },
    selected : function (obj){
      this.editable = false
      var rv = {post_id: obj.post_id, thumb: obj.image_url}
      this.best_list.push(rv)
      this.flag2++
    }
  }
}
</script>

<style scoped>
.best_container {
  display: flex;
  flex-direction: column;
}
.best_container_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.best_item {
  background-color: #e3e3e3;
  width: 116px;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>