<template>
    <div>
        <div class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="서치 관리"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="sch"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    v-if="admin == 0 || !!form.company_code"
            >
                <v-tab key="opt1"
                >
                    Step1. 지면 관리
                </v-tab>
                <v-tab key="opt2"
                >
                    Step2. 서치소재 관리
                </v-tab>
                <v-tab key="opt3"
                >
                    Step3. 노출 관리
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless
                          v-if="admin == 0 || !!form.company_code"
            >
                <v-tab-item
                        class="pa-5"
                        key="opt1">

                    <div :key="flag">
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div v-if="!!min_ymd">
                                <manage-search mode="PC" class="mb-6" :company_code="form.company_code" :min_ymd="min_ymd"/>

                                <manage-search mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="opt2">
                    <div>
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="d-flex" >
                                <div class="flex-grow-1">

                                    <div class="pa-2">
                                        <v-card-title class="subtitle">
                                            공통 설정 (PC/Mobile)
                                        </v-card-title>

                                      <v-card
                                          outlined
                                          class="pa-3"
                                      >
                                        <div class="pa-2">

                                          <div class="pb-4 mt-4">
                                            <b>디자인 설정</b>
                                          </div>

                                          <div style="border: 1px solid #e3e3e3">
                                            <div class="pl-2 my-4">
                                              <span style="font-size: 14px;color: #009688;font-weight: 600;letter-spacing: 0.0892857143em;">[디자인 미리보기]</span>
                                            </div>
                                            <iframe :src="preview_url" frameborder="0" scrolling="no" style="width:100%;height:80px;overflow:hidden"></iframe>
                                            <iframe :src="preview_url2" frameborder="0" scrolling="no" style="width:100%;height:360px;overflow:hidden"></iframe>
                                          </div>

                                          <v-subheader class="pl-1" style="font-weight: 600;color:#333">1. 컬러 설정</v-subheader>

                                          <div style="font-size: 0.8em" class="pl-3">
                                            <div class="pb-2">
                                              - 키워드 배경 컬러
                                            </div>
                                            <my-color v-model="common.kbyk_background_color" />

                                            <div class="pb-2 pt-4">
                                              - 키워드 타이틀 컬러
                                            </div>
                                            <my-color v-model="common.kbyk_title_color" />

                                            <div class="pb-2 pt-4">
                                              - 키워드 컬러
                                            </div>
                                            <my-color v-model="common.kbyk_text_color" />

                                            <div class="pb-2 pt-4">
                                              - 키워드 기반 상품 타이틀 컬러
                                            </div>
                                            <my-color v-model="common.pbyk_title_color" />

                                            <div class="pb-2 pt-4">
                                              - 상품 가격 강조 컬러
                                            </div>
                                            <my-color v-model="common.pbyk_point_color" />

                                          </div>

                                          <v-subheader class="pl-1" style="font-weight: 600;color:#333">2. 폰트 설정 & 스타일</v-subheader>
                                          <div style="font-size: 0.8em" class="pl-3">
                                            <v-row>
                                              <v-col>
                                                <v-select
                                                    :items="fonts"
                                                    label="무료 폰트 선택"
                                                    v-model="selected_font"
                                                    item-text="value"
                                                    item-value="code"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    @change="fontChanged"
                                                ></v-select>
                                              </v-col>
                                              <v-col>
                                                <v-text-field
                                                    v-model="common.font"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :disabled="selected_font==''?false:true"
                                                    label="스타일"
                                                    :rules="required"
                                                />
                                              </v-col>
                                            </v-row>
                                          </div>
                                        </div>

                                        <v-row class="pa-4">
                                          <v-col>
                                            <v-btn
                                                block
                                                depressed
                                                dark
                                                color="grey"
                                                @click="load_common"
                                            >
                                              취소
                                            </v-btn>
                                          </v-col>
                                          <v-col>
                                            <v-btn
                                                block
                                                depressed
                                                dark
                                                color="#EC407A"
                                                @click="updateSchCommon"
                                            >
                                              저장
                                            </v-btn>
                                          </v-col>
                                        </v-row>
                                      </v-card>

                                      <v-dialog v-model="isshow" max-width="800px" style="background-color: white">
                                        <v-card class="pa-2">
                                          <div class="pl-4" align="center">
                                            <iframe :src="preview_url" frameborder="0" style="width:100%;height:130px"></iframe>
                                            <iframe :src="preview_url2" frameborder="0" style="width:100%;height:360px"></iframe>
                                          </div>

                                          <div class="px-4 d-flex">
                                            <v-btn class="mr-1 flex-grow-1"
                                                   depressed
                                                   dark
                                                   color="grey"
                                                   @click="isshow=false"
                                            >
                                              닫기
                                            </v-btn>
                                          </div>
                                        </v-card>
                                      </v-dialog>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item
                        class="pa-5"
                        key="opt3">

                    <div :key="flag">
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div v-if="!!min_ymd">
                                <manage-search-status mode="PC" class="mb-6" :company_code="form.company_code" :min_ymd="min_ymd"/>

                                <manage-search-status mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
    import MyTab from "../../common/MyTab";
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import { mapMutations, mapGetters } from 'vuex'
    import MyColor from "../../common/MyColor";
    import ManageSearchStatus from "@/components/common/ManageSearchStatus";
    import ManageSearch from "@/components/common/ManageSearch";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        watch:{
            admin: function () {
                this.loadAll()
            },
            "form.company_code": function () {
                this.loadAll()
            },
            mytab2: function () {
                //this.sendMessage()
            },
            mytab:function () {
                this.flag++
            },
        },
        components: {
          MainTitle,
          MySelect,
          ManageSearch,
          MyTab,
          MyColor,
          ManageSearchStatus
        },
        created() {
            this.$event.$on('color_changed',this.colorChanged)
        },
        mounted() {
            this.loadAll()
        },
        beforeDestroy() {
            this.$event.$off('color_changed',this.colorChanged)
        },
        methods:{
            flag:0,
            loadAll: function() {
                this.tab = 0
                this.load_current()
                this.load_fonts()
                this.load_common()
            },
            load_current: function() {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                var that = this
                var url = "/api/more/get/current_period?type=sch"
                if(!!this.form.company_code){
                    url += "&company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                   //console.log(res)
                    if(!!res.data) {
                        that.term_start = res.data.term_start
                        that.term_end = res.data.term_end
                        var today = this.$moment(new Date()).format('YYYYMMDD')
                        that.min_ymd = this.$moment(that.term_start,'YYYYMMDD').format('YYYY-MM-DD')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_fonts: function() {
              var that = this
              var url = "/api/more/get/fonts"
              this.$http.get(url).then(res=>{
                res.data.forEach(function(e, i){
                  that.fonts.push({code: e.code, value: e.code_name})
                })
              }).catch((err)=> {
              })
            },
            load_common: function(){
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                this.common = null
                var that = this
                var url = "/api/more/get/sch_common"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.common = res.data
                        that.selected_font = that.common.font
                        if (that.fonts.filter(e => e.code === that.common.font).length <= 0) {
                          that.selected_font = ''
                        }
                        that.preview()
                    }
                }).catch((err)=>{
                    console.log(err)
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            updateSchCommon: function(){
                 if(!!!this.common.font) {
                   this.$event.$emit('alert', '스타일을 입력해주세요')
                   return
                 }

                var url = "/api/more/update/sch_common"
                if (!!this.form.company_code) {
                    this.common.company_code = this.form.company_code
                }

                var that = this
                this.$event.$emit('confirm', '서치 공통 설정', '변경사항을 저장하시겠습니까?', function () {
                    that.$http.post(url, that.common).then(res => {
                        if (res.status == '200') {
                            that.load_common()
                            that.$event.$emit('alert', '서치 공통 설정이 변경되었습니다')
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                })
            },
            clone: function(obj){
              var output = {}
              for(var i in obj){
                output[i] = obj[i]
              }
              return output
            },
            preview: function () {
              var src = "https://pi.myshp.us/more?&key=&type=kbyk&url=%23&title=%EC%97%B0%EA%B4%80%EA%B2%80%EC%83%89%EC%96%B4&iw=1&ih=1&ratid=more_demo_id&channel=search&myid=_th3qgf77g"
              src += "&style=p_" + this.common.font.toLowerCase()
              src += '&kbgclr='+encodeURIComponent(this.common.kbyk_background_color);
              src += '&kclr='+encodeURIComponent(this.common.kbyk_text_color);
              src += '&ktitclr='+encodeURIComponent(this.common.kbyk_title_color)

              var src2 = "https://pi.myshp.us/more?&key=&type=pbyp&url=%23&iw=1&ih=1&ratid=more_demo_id&channel=detail&myid=_chj85tlri&title=%ED%95%A8%EA%BB%98%20%EB%A7%8E%EC%9D%B4%20%EB%B3%B8%20%EC%83%81%ED%92%88"
              src2 += "&style=p_" + this.common.font.toLowerCase()
              src2 += '&clr='+encodeURIComponent(this.common.pbyk_point_color);
              src2 += '&titclr='+encodeURIComponent(this.common.pbyk_title_color);

              this.preview_url = src
              this.preview_url2 = src2
            },
            colorChanged: function(){
              this.preview()
            },
            fontChanged: function() {
              this.common.font = this.selected_font
              this.preview()
            }
        },
        data: () => ({
            min_ymd: '',
            form: {
                company_code: ''
            },
            common: null,
            selected_font: '',
            mytab:null,
            mytab2:null,
            show_pc:false,
            params:{},

            tab:0,
            term_start: '',
            term_end: '',
            show1:false,
            show2:false,
            fonts: [],
            isshow:false,
            preview_url:'',
            preview_url2:'',
            required: [
              v => !!v || '필수 정보입니다'
            ],
        })
    }
</script>

<style scoped>
.mtable > tbody > tr > td {
    padding: 20px;
}
.tab-item-wrapper {
    /* vuetify sets the v-tabs__container height to 48px */
    height: 100%;
}
</style>
