<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title :title="companyName"/>
                </v-card-title>
                <v-card-text class="stitle">
                    MORE 아이디 : {{companyCode}}
                </v-card-text>
            </div>
            <div class="pa-4" style="height: 500px">
            </div>
        </v-card>

    </div>
</template>

<script>
    import MainTitle from "../common/MainTitle";
    import {mapGetters} from "vuex";
    export default {
        computed: mapGetters({
            companyCode: 'companyCode',
            companyName: 'companyName',
        }),
        components: {
            MainTitle,
        },
        data: () => ({
            //
        }),
    };
</script>
