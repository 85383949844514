<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="권한 관리"/>
                </v-card-title>
            </div>
            <div class="ml-4 mr-4 mt-4 mb-4 pb-8">
                <v-row class="mb-5">
                    <v-col cols="4">
                        <v-autocomplete
                                v-model="sel_id"
                                :items="id_list"
                                item-text="name"
                                item-value="id"
                                label="ID"
                                outlined
                                dense
                                hide-details
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-simple-table
                            style="display: block;border-radius: 0"
                    >
                        <template v-slot:default>
                            <thead style="background-color: #eee"
                            >
                            <tr>
                                <th class="text-center bb bt br" style="width:300px;">ID</th>
                                <th class="text-center bb bt br" style="width:400px;">계정명</th>
                                <th class="text-center bb bt br" style="width:120px;">권한</th>
                                <th class="text-center bb bt" style="width:300px;">대행사</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in all_list" :key="item.id">
                                <td class="text-center bb">{{ item.id }}</td>
                                <td class="text-center bb">{{ item.name }}</td>
                                <td class="text-center bb">
                                    <span v-if="item.auth == 'admin'">관리자</span>
                                    <span v-else-if="item.auth == 'agency'"
                                              style="text-decoration: underline;color: #00f;cursor:pointer;text-underline-position: under;"
                                              @click="showDialog(item)"
                                    >대행사</span>
                                    <span v-else
                                          style="text-decoration: underline;color: #00f;cursor:pointer;text-underline-position: under;"
                                          @click="showDialog(item)"
                                    >회원</span>
                                </td>
                                <td class="text-center bb">
                                    <span v-if="item.auth == 'agency'">{{item.agency}}</span>
                                    <span v-else>-</span>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>

                <!--
                <div class="text-center pa-2" style="width:700px">
                    <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                </div>
                -->
            </div>
        </v-card>

        <v-dialog v-model="dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">계정 권한 변경</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>ID</span>
                                </v-col>
                                <v-col>
                                    <span class="bold ml-2">{{form.id}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>계정명</span>
                                </v-col>
                                <v-col>
                                    <span class="bold ml-2">{{form.name}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>담당자</span>
                                </v-col>
                                <v-col>
                                    <span class="bold ml-2">{{form.manager}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>현재 권한</span>
                                </v-col>
                                <v-col>
                                    <div class="bold ml-2">
                                        <span v-if="form.auth == 'admin'">
                                            관리자
                                        </span>
                                        <span v-else-if="form.auth == 'agency'">
                                            대행사
                                        </span>
                                        <span v-else>
                                            회원
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3">
                                    <span>변경 권한</span>
                                </v-col>
                                <v-col>
                                    <v-select v-if="form.auth == 'user'"
                                            style="width:300px"
                                            v-model="sel_grant"
                                            :items="auth_items_user"
                                            :rules="selectRules"
                                            item-text="value"
                                            item-value="code"
                                            return-code
                                            outlined
                                            hide-details
                                            dense
                                    ></v-select>
                                    <v-select v-else-if="form.auth == 'agency'"
                                              style="width:300px"
                                              v-model="sel_grant"
                                              :items="auth_items_agency"
                                              :rules="selectRules"
                                              item-text="value"
                                              item-value="code"
                                              return-code
                                              outlined
                                              hide-details
                                              dense
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="sel_grant == 'agency'" align="center">
                                <v-col cols="3">
                                    <span>대행사</span>
                                </v-col>
                                <v-col>
                                    <v-select
                                              style="width:300px"
                                              v-model="form.agency"
                                              :items="agency_items"
                                              :rules="selectRules"
                                              item-text="value"
                                              item-value="code"
                                              return-code
                                              outlined
                                              hide-details
                                              dense
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="grant">변경하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import { mapMutations, mapGetters } from 'vuex'

    export default {
        computed: mapGetters({
        }),
        watch:{
            sel_id:function () {
                this.dataload()
            },
        },
        components: {
            MainTitle,
        },
        mounted() {
            // commit
            this.getCodes()
            this.dataload()
        },
        methods:{
            getCodes:function() {
                var that = this
                var url = "/api/more/get/codes?type=agency"
                this.$http.get(url).then(res=>{
                    res.data.forEach(function(e, i){
                        that.agency_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })
            },
            dataload:function() {
                var that = this
                var url = "/api/more/get/user_id_list"
                if(!!this.sel_id){
                    url += "?id=" + this.sel_id
                }
                this.$http.get(url).then(res=>{
                    if(this.id_list.length == 1){
                        res.data.forEach(function(e, i){
                            that.id_list.push({id: e.id, name: e.id})
                        })
                    }
                    this.all_list = res.data
                }).catch((err)=> {
                    this.$event.$emit('alert', '오류가 발생했습니다')
                })
            },
            showDialog: function (item) {
                if(!!this.$refs.form){
                    this.$refs.form.resetValidation()
                }
                this.form = Object.assign({}, item)
                this.form.agency = ''
                this.sel_grant = ''
                this.dlg = true
            },
            grant: function () {
                if (!this.$refs.form.validate()) {
                    return
                }

                this.form.grant = this.sel_grant
                if(this.sel_grant !== 'agency'){
                    this.form.agency = ''
                }

                var url = "/api/more/change/authority"
                this.$http.post(url, this.form).then(res=>{
                    if(res.data.msg == "OK"){
                        this.dlg = false
                        this.$event.$emit('alert','권한이 변경되었습니다')
                        this.dataload()
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert','오류가 발생했습니다')
                })
            },
        },
        data: () => ({
            dlg: false,
            form:{
                grant: null,
                agency: null,
            },
            sel_id: '',
            id_list: [
                {
                    id: "",
                    name: "전체"
                },
            ],
            all_list: null,
            sel_grant: '',
            auth_items_user: [
                { value: '선택', code: '' },
                { value: '대행사', code: 'agency' },
                { value: '관리자', code: 'admin' },
            ],
            auth_items_agency: [
                { value: '선택', code: '' },
                { value: '회원', code: 'user' },
            ],
            agency_items: [
                { value: '선택', code: '' },
            ],
            selectRules: [
                v => !!v || '필수 정보입니다',
            ],
        })
    }
</script>
<style scoped>
    .bold {font-weight: 600;}

    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 11px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>