<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="티커 리포트"/>
                </v-card-title>
                <v-card-text class="stitle">
                    ! 전환과 관련된 수치(전환수, 전환율, 구매상품수, 매출액, 객단가)에는 간편 결제를 통한 수치가 포함되어 있지 않습니다.
                </v-card-text>
            </div>

            <div class="pt-4 pl-6">
                <v-row v-if="admin == 1">
                    <v-col cols="4">
                        <my-select
                                type="ticker"
                                option_default="전체"
                                :value="form2.company_code"
                                @input="(newCC) => {form2.company_code = newCC}"
                        ></my-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <my-date-range @mychange="calendarChanged" :start="computedStartDate" :end="computedEndDate"></my-date-range>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" v-if="mytab == 0">
                        <my-date-range @mychange="calendarChanged2" title="비교기간" :start="computedStartDate2" :end="computedEndDate2"></my-date-range>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col style="max-width:250px;">
                        <v-select
                                style="max-width:250px;"
                                :items="dList"
                                v-model="form2.platform"
                                label="디바이스"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                        ></v-select>
                    </v-col>
                    <v-col style="max-width:250px;" v-if="admin == 1 && !!!form2.company_code">
                        <v-select
                                style="max-width:250px;"
                                :items="cList"
                                v-model="form2.cstatus"
                                label="계약상태"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                        ></v-select>
                    </v-col>
                </v-row>
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    class="pl-6"
            >
                <v-tab key="daily"
                >
                    기간별
                </v-tab>
                <v-tab key="message"
                >
                    메시지타입별
                </v-tab>
                <v-tab key="prod"
                >
                  상품별
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless>
                <v-tab-item
                        class="pa-5"
                        key="daily">
                    <ticker-summary option="daily" ref="daily"/>
                    <div style="height:30px"/>
                    <ticker-detail option="daily" ref="daily"/>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="message">
                    <ticker-summary v-if="admin == 0 || !!form2.company_code" option="message" ref="message"/>
                    <div v-if="admin == 0 || !!form2.company_code" style="height:30px"/>
                    <ticker-detail option="message" ref="message"/>
                </v-tab-item>
                <v-tab-item
                    class="pa-5"
                    key="prod">
                  <ticker-detail v-if="admin == 0 || !!form2.company_code" option="prod" ref="prod"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import TickerSummary from "./TickerSummary";
    import TickerDetail from "./TickerDetail";
    import MyDateRange from "../../common/MyDateRange";

    import {mapGetters} from "vuex";
    export default {
        computed: {
            computedStartDate() {
                var v = this.$moment(new Date()).format('YYYYMM01')
                return this.$moment(v, 'YYYYMMDD').toDate()
            },computedEndDate() {
                return new Date()
            },computedStartDate2() {
                var v = this.$moment(new Date()).add(-1, 'months')
                return v.startOf('month').toDate()
            },computedEndDate2() {
                var v = this.$moment(new Date()).add(-1, 'months')
                return v.endOf('month').toDate()
            },...mapGetters({
                companyCode: 'companyCode',
                admin: 'admin',
            })
        },
        watch: {
            admin:function () {
                this.dataload()
            },
            "form2.company_code":function () {
                this.dataload()
            },
            "form2.cstatus":function () {
                this.dataload()
            },
            "form2.platform":function () {
                this.dataload()
            },
            mytab:function () {
                this.form2.page = 1
                this.dataload()
            }
        },
        mounted() {
            this.dataload()
        },
        components: {
            MainTitle,
            MySelect,
            MyDateRange,
            TickerSummary,
            TickerDetail
        },
        data: () => ({
            mytab: '',
            form2: {
                company_code : '',
                admin : -1,
                start_date:'',
                end_date:'',
                search_start_date:'',
                search_end_date:'',
                platform: '',
                cstatus: '',
            },
            cList:[{id:"", name:"전체"},{id:"F", name:"무료"},{id:"P", name:"유료"},{id:"E", name:"종료"}],
            dList:[{id:"", name:"전체"},{id:"pc", name:"pc"},{id:"mobile_web", name:"mobile 전체"},{id:"mobile_aos", name:"mobile aos"},{id:"mobile_ios", name:"mobile ios"}],
        }),
        methods: {
            calendarChanged:function (v) {
                this.form2.start_date = v.start
                this.form2.end_date = v.end
                this.dataload()
            },
            calendarChanged2:function (v) {
                this.form2.search_start_date = v.start
                this.form2.search_end_date = v.end
                this.dataload()
            },
            dataload: function() {
                if(this.admin == -1)
                    return

                this.form2.admin = this.admin
                var v = Object.assign({}, this.form2)
                this.$store.commit('reportParam', {value:v})
            }
        }
    };
</script>