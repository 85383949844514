<template>
    <div>
        <div v-if="option=='daily'">
          <v-row class="my-6">
            <v-col>
              <doughnut-chart chart-id="d1" :chart-data="data1" tit="서치영역 클릭 비중"/>
            </v-col>
            <v-col>
              <doughnut-chart chart-id="d2" :chart-data="data2" tit="서치영역 구매 비중"/>
            </v-col>
            <v-col>
              <doughnut-chart chart-id="d3" :chart-data="data3" tit="서치영역 매출액 비중"/>
            </v-col>
          </v-row>
        </div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div v-if="option == 'daily'" class="flex-grow-1" style="margin:auto;">기간 비교</div>
                <div v-else class="flex-grow-1" style="margin:auto;">리포트 요약</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadSummaryCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>

        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div v-if="option == 'daily'" class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">기간</th>
                                    <th class="text-center bb bt br">기준</th>
                                    <th class="text-center bb bt br">클릭수</th>
                                    <th class="text-center bb bt br">전환수</th>
                                    <th class="text-center bb bt br">구매수량</th>
                                    <th class="text-center bb bt">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item,i) in report_list">
                                  <tr>
                                      <td v-if="i == 0" class="text-center bb" rowspan="2">
                                          <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                                          <br/>
                                          <span style="font-size: 12px">(조회기간)</span>
                                      </td>
                                      <td v-else class="text-center bb" rowspan="2">
                                          <span v-html="$_ymd_s(reportParam.search_start_date)"/> ~ <span v-html="$_ymd_s(reportParam.search_end_date)"/>
                                          <br/>
                                          <span style="font-size: 12px">(비교기간)</span>
                                      </td>
                                      <td class="text-center bb">서치영역</td>
                                      <td class="text-center bb">{{ item.click.price() }}</td>
                                      <td class="text-center bb">{{ item.conversion.price() }}</td>
                                      <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                                      <td class="text-center bb">{{ item.sales.price() }}</td>
                                  </tr>
                                  <tr style="background-color:#f9f9f9;" v-if="!!report_total && report_total.length > 0">
                                    <td class="text-center bb" style="color:#9a9a9a;">전체대비</td>
                                    <td v-if="report_total[i].click == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                    <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.click, report_total[i].click)"></td>
                                    <td v-if="report_total[i].conversion == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                    <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.conversion, report_total[i].conversion)"></td>
                                    <td v-if="report_total[i].conversion_total == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                    <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.conversion_total, report_total[i].conversion_total)"></td>
                                    <td v-if="report_total[i].sales == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                    <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.sales, report_total[i].sales)"></td>
                                  </tr>
                                </template>
                                </tbody>
                                <tfoot>
                                <template v-if="!!report_list && report_list.length > 0 && !!report_total && report_total.length > 0">
                                  <tr>
                                      <td class="text-center sum bbt bbb" rowspan="2">증감</td>
                                      <td class="text-center sum bbt bbb">서치영역</td>
                                      <td class="text-center sum bbt bbb">
                                          <span v-if="report_list[1].click == 0">-</span>
                                          <span v-else v-html="plusminus(report_list[0].click, report_list[1].click)"></span>
                                      </td>
                                      <td class="text-center sum bbt bbb">
                                          <span v-if="report_list[1].conversion == 0">-</span>
                                          <span v-else v-html="plusminus(report_list[0].conversion, report_list[1].conversion)"></span>
                                      </td>
                                      <td class="text-center sum bbt bbb">
                                          <span v-if="report_list[1].conversion_total == 0">-</span>
                                          <span v-else v-html="plusminus(report_list[0].conversion_total, report_list[1].conversion_total)"></span>
                                      </td>
                                      <td class="text-center sum bbt bbb">
                                          <span v-if="report_list[1].sales == 0">-</span>
                                          <span v-else v-html="plusminus(report_list[0].sales, report_list[1].sales)"></span>
                                      </td>
                                  </tr>
                                  <tr style="background-color:#f9f9f9;">
                                    <td class="text-center sum bbt bbb" style="color:#9a9a9a;">전체대비</td>
                                    <td class="text-center sum bbt bbb" style="color:#9a9a9a;">
                                      <span v-if="report_list[1].click == 0">-</span>
                                      <span v-else v-html="plusminusAll(report_list[0].click, report_total[0].click, report_list[1].click, report_total[1].click)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb" style="color:#9a9a9a;">
                                      <span v-if="report_list[1].conversion == 0">-</span>
                                      <span v-else v-html="plusminusAll(report_list[0].conversion, report_total[0].conversion, report_list[1].conversion, report_total[1].conversion)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb" style="color:#9a9a9a;">
                                      <span v-if="report_list[1].conversion_total == 0">-</span>
                                      <span v-else v-html="plusminusAll(report_list[0].conversion_total, report_total[0].conversion_total, report_list[1].conversion_total, report_total[1].conversion_total)"></span>
                                    </td>
                                    <td class="text-center sum bbt bbb" style="color:#9a9a9a;">
                                      <span v-if="report_list[1].sales == 0">-</span>
                                      <span v-else v-html="plusminusAll(report_list[0].sales, report_total[0].sales, report_list[1].sales, report_total[1].sales)"></span>
                                    </td>
                                  </tr>
                                </template>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                    <div v-else-if="option == 'page'" class="flex-grow-1">
                      <v-simple-table
                          style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                      >
                        <template v-slot:default>
                          <thead style="background-color: #eee"
                          >
                          <tr>
                            <th class="text-center bb bt br">기간</th>
                            <th class="text-center bb bt br">디바이스</th>
                            <th class="text-center bb bt br">지면이름</th>
                            <th class="text-center bb bt br">기준</th>
                            <th class="text-center bb bt br">클릭수</th>
                            <th class="text-center bb bt br">전환수</th>
                            <th class="text-center bb bt br">구매수량</th>
                            <th class="text-center bb bt">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                            <th class="text-center bb bt br">검색수</th>
                          </tr>
                          </thead>
                          <tbody>
                          <template v-for="(item, i) in report_list">
                            <tr>
                              <td v-if="i==0" class="text-center bb" :rowspan="report_list.length*2">
                                <span v-html="$_ymd_s(reportParam.start_date)"/> ~ <span v-html="$_ymd_s(reportParam.end_date)"/>
                              </td>
                              <td class="text-center bb" rowspan="2">{{ item.platform }}</td>
                              <td class="text-center bb" rowspan="2">{{ item.page_name }}</td>
                              <td class="text-center bb">추천영역</td>
                              <td class="text-center bb">{{ item.click.price() }}</td>
                              <td class="text-center bb">{{ item.conversion.price() }}</td>
                              <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                              <td class="text-center bb">{{ item.sales.price() }}</td>
                              <td class="text-center bb">{{ item.search.price() }}</td>
                            </tr>
                            <tr style="background-color:#f9f9f9;">
                              <td class="text-center bb" style="color:#9a9a9a;">전체대비</td>
                              <template v-if="item.platform=='pc'">
                                <td v-if="report_total_pc.click == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.click, report_total_pc.click)"></td>
                                <td v-if="report_total_pc.conversion == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.conversion, report_total_pc.conversion)"></td>
                                <td v-if="report_total_pc.conversion_total == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.conversion_total, report_total_pc.conversion_total)"></td>
                                <td v-if="report_total_pc.sales == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.sales, report_total_pc.sales)"></td>
                                <td v-if="report_total_pc.search == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.search, report_total_pc.search)"></td>
                              </template>
                              <template v-else-if="item.platform=='mobile_web'">
                                <td v-if="report_total_mo.click == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.click, report_total_mo.click)"></td>
                                <td v-if="report_total_mo.conversion == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.conversion, report_total_mo.conversion)"></td>
                                <td v-if="report_total_mo.conversion_total == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.conversion_total, report_total_mo.conversion_total)"></td>
                                <td v-if="report_total_mo.sales == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.sales, report_total_mo.sales)"></td>
                                <td v-if="report_total_mo.search == 0" class="text-center bb" style="color:#9a9a9a;">-</td>
                                <td v-else class="text-center bb" style="color:#9a9a9a;" v-html="percentage(item.search, report_total_mo.search)"></td>
                              </template>
                            </tr>
                          </template>
                          </tbody>
                          <tfoot>
                          <tr>
                            <td colspan="3" rowspan="2" class="text-center sum bbt bbb">합계</td>
                            <td class="text-center sum bbt bbb">추천영역</td>
                            <td class="text-center sum bbt bbb">{{ report_total[0].click.price() }}</td>
                            <td class="text-center sum bbt bbb">{{ report_total[0].conversion.price() }}</td>
                            <td class="text-center sum bbt bbb">{{ report_total[0].conversion_total.price() }}</td>
                            <td class="text-center sum bbt bbb">{{ report_total[0].sales.price() }}</td>
                            <td class="text-center sum bbt bbb">{{ report_total[0].search.price() }}</td>
                          </tr>
                          <tr style="background-color:#f9f9f9;">
                            <td class="text-center sum bbt bbb" style="color:#9a9a9a;">전체대비</td>
                            <td v-if="report_total_summary.click == 0" class="text-center sum bbt bbb" style="color:#9a9a9a;">0%</td>
                            <td v-else class="text-center sum bbt bbb" style="color:#9a9a9a;" v-html="percentage(report_total[0].click, report_total_summary.click)"></td>
                            <td v-if="report_total_summary.conversion == 0" class="text-center sum bbt bbb" style="color:#9a9a9a;">0%</td>
                            <td v-else class="text-center sum bbt bbb" style="color:#9a9a9a;" v-html="percentage(report_total[0].conversion, report_total_summary.conversion)"></td>
                            <td v-if="report_total_summary.conversion_total == 0" class="text-center sum bbt bbb" style="color:#9a9a9a;">0%</td>
                            <td v-else class="text-center sum bbt bbb" style="color:#9a9a9a;" v-html="percentage(report_total[0].conversion_total, report_total_summary.conversion_total)"></td>
                            <td v-if="report_total_summary.sales == 0" class="text-center sum bbt bbb" style="color:#9a9a9a;">0%</td>
                            <td v-else class="text-center sum bbt bbb" style="color:#9a9a9a;" v-html="percentage(report_total[0].sales, report_total_summary.sales)"></td>
                            <td v-if="report_total_summary.search == 0" class="text-center sum bbt bbb" style="color:#9a9a9a;">0%</td>
                            <td v-else class="text-center sum bbt bbb" style="color:#9a9a9a;" v-html="percentage(report_total[0].search, report_total_summary.search)"></td>
                          </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import DoughnutChart from "@/components/common/DoughnutChart";
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
            companyName : 'companyName',
        }),
        props:{
            option:{
                type:String,
                default:''
            }
        },
        components: {
          DoughnutChart
        },
        watch: {
            reportParam:function() {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {

                if(!!!this.reportParam.start_date || !!!this.reportParam.end_date)
                    return

                var that = this;
                if (this.option == 'daily') {
                    var url = "/api/more/report/sch/daily_summary"
                    this.$http.post(url, this.reportParam).then(res => {
                        that.report_list = res.data

                        var url2 = "/api/more/report/sch/daily_summary_total_for_compare"
                        that.$http.post(url2, this.reportParam).then(res => {
                          that.report_total = res.data

                          that.data1 = {
                            labels: ['추천', '일반'],
                            datasets: [
                              {
                                backgroundColor: ["rgba(48, 193, 192, 1)", "rgba(170, 222, 241, 1)"],
                                data: [that.report_list[0].click, that.report_total[0].click - that.report_list[0].click]
                              }
                            ]
                          }

                          that.data2 = {
                            labels: ['추천', '일반'],
                            datasets: [
                              {
                                backgroundColor: ["rgba(48, 193, 192, 1)", "rgba(170, 222, 241, 1)"],
                                data: [that.report_list[0].conversion, that.report_total[0].conversion - that.report_list[0].conversion]
                              }
                            ]
                          }

                          that.data3 = {
                            labels: ['추천', '일반'],
                            datasets: [
                              {
                                backgroundColor: ["rgba(48, 193, 192, 1)", "rgba(170, 222, 241, 1)"],
                                data: [that.report_list[0].sales, that.report_total[0].sales - that.report_list[0].sales]
                              }
                            ]
                          }
                        }).catch((err)=>{
                          that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                        })
                    }).catch((err)=>{
                        that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'page'){
                  var url = "/api/more/report/sch/page_summary"

                  this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url2 = "/api/more/report/sch/page_total_summary"
                  this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total_summary = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url3 = "/api/more/report/sch/page_total_platform?platform=pc"
                  this.$http.post(url3, this.reportParam).then(res => {
                    //console.log(res.data)
                    that.report_total_pc = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url4 = "/api/more/report/sch/page_total_platform?platform=mobile_web"
                  this.$http.post(url4, this.reportParam).then(res => {
                    //console.log(res.data)
                    that.report_total_mo = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })

                  var url5 = "/api/more/report/sch/page_total"
                  this.$http.post(url5, this.reportParam).then(res => {
                    that.report_total = res.data
                  }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                }
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            plusminus:function(a, b){
                var pm = ((a/b-1)*100).toFixed(2)
                pm = pm.replace('.00','')
                return pm + '%'
            },
            plusminusAll:function(a, b, c, d){
              var pm = (((a/b) - (c/d))*100).toFixed(2)
              pm = pm.replace('.00','')
              return pm + '%'
            },
            showDialog:function(cost){
                this.dialog = true
                this.expected.total = cost
                this.expected.supply = Math.round(cost/1.1);
                this.expected.tax = cost - this.expected.supply;
            },
            downloadSummaryCSV: function () {
                var url = "/api/more/report/sch/summary_csv?"
                if(!!this.companyName){
                    this.reportParam.company_name = this.companyName
                }
                var params = new URLSearchParams(this.reportParam).toString();
                if (this.option == 'daily') {
                    params += '&type=1'
                    location.href = url + params
                }else if (this.option == 'page') {
                    params += '&type=2'
                    location.href = url + params
                }
            }
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list: [],
            report_total: {},
            report_total_summary: {},
            report_total_pc: {},
            report_total_mo: {},
            dialog: false,
            expected: {},
            data1: {},
            data2: {},
            data3: {},
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>