import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const VueCookie = require('vue-cookie');

import Login from "../components/fullscreen/Login";
import Agree from "../components/fullscreen/Agree";
import Join from "../components/fullscreen/Join";
import FindId from "../components/fullscreen/FindId";
import FindPw from "../components/fullscreen/FindPw";
import Lock from "../components/fullscreen/Lock";
import Dashboard from "../components/layout/Dashboard";
import Home from "../components/page/Home";
import MyAccount from "../components/page/Account";
import Intro from "../components/page/Intro";
import GetStart from "../components/page/GetStart";
import GetStartCafe24 from "../components/page/GetStartCafe24";
import Cancel from "../components/page/payment/Cancel";
import Cafe24Join from "../components/fullscreen/Cafe24Join";
import Cafe24JoinT from "../components/fullscreen/Cafe24JoinT";

import PaymentList from "../components/page/payment/List"
import ManageNotice from "../components/page/manage/Notice"
import Editor from "../components/common/Editor";
import ManageAuthority from "../components/page/manage/Authority"
import ManageAccount from "../components/page/manage/Account"
import AccountDetail from "../components/page/manage/AccountDetail";

import DesignTickerNw from "@/components/page/manage/DesignTickerNw";
import DesignPanelNw from "@/components/page/manage/DesignPanelNw";
import DesignRecommendNw from "@/components/page/manage/DesignRecommendNw";
import DesignSearchNw from "@/components/page/manage/DesignSearchNw";

import ReportPanel from "../components/page/report/ReportPanel";
import ReportTicker from "../components/page/report/ReportTicker";
import ReportRecommend from "../components/page/report/ReportRecommend";
import ReportSearch from "../components/page/report/ReportSearch";
import ReportReview from "@/components/page/report/ReportReview";
import ReportTotal from "@/components/page/report/ReportTotal";
import ReportSales from "@/components/page/report/ReportSales";
import Agree2 from "../components/fullscreen/Agree2";
import Join2 from "../components/fullscreen/Join2";
import ManageItems from "../components/page/manage/ManageItems";
import Agency from "../components/page/manage/Agency";

import SnsReviewItems from "@/components/page/manage/SnsReviewItems";
import SnsReviewBlocks from "@/components/page/manage/SnsReviewBlocks";

import NotFound from "../components/page/NotFound";
import WrongAccess from "../components/page/WrongAccess";
import axios from 'axios';


const router = new VueRouter({

    mode: 'history',
    routes: [
        {
            path:'/wrongaccess', component:WrongAccess
        },
        {
            path:'/login', component:Login
        },
        {
            path:'/agree', component:Agree
        },
        {
            path:'/agree/terms', component:Agree2
        },
        {
            path:'/enter/user', component:Join2
        },
        {
            path:'/join', component:Join
        },
        {
            path:'/cafe24/join', component:Cafe24Join
        },
        {
            path:'/cafe24App/join', component:Cafe24JoinT
        },
        {
            path:'/findid', component:FindId
        },
        {
            path:'/findpw', component:FindPw
        },
        {
            path:'/lock', component:Lock
        },
        {
            path:'/', component:Dashboard,
            children: [
                {
                    path:'home', component:Home
                },
                {
                    path:'payment/list', component:PaymentList
                },
                {
                    path:'cancel', component:Cancel
                },
                {
                    path:'account', component:MyAccount
                },
                {
                    path:'intro', component:Intro
                },
                {
                    path:'start', component:GetStart
                },
                {
                    path:'script', component:GetStartCafe24
                },
                {
                    path:'manage/account', component:ManageAccount
                },
                {
                    path:'manage/account/detail', component:AccountDetail
                },
                {
                    path:'manage/authority', component:ManageAuthority
                },
                {
                    path:'manage/notice', component:ManageNotice
                },
                {
                    path:'create/notice', component:Editor
                },
                {
                    path:'modify/notice', component:Editor
                },
                {
                    path:'manage/design/ticker', component:DesignTickerNw
                },
                {
                    path:'manage/design/panel', component:DesignPanelNw
                },
                {
                    path:'manage/design/recommend', component:DesignRecommendNw
                },
                {
                    path:'manage/design/search', component:DesignSearchNw
                },
                {
                    path:'report/panel', component:ReportPanel
                },
                {
                    path:'report/ticker', component:ReportTicker
                },
                {
                    path:'report/recommend', component:ReportRecommend
                },
                {
                    path:'report/search', component:ReportSearch
                },
                {
                    path:'report/review', component:ReportReview
                },
                {
                    path:'report/total', component:ReportTotal
                },
                {
                    path:'report/sales', component:ReportSales
                },
                {
                    path:'manage/items', component:ManageItems
                },
                {
                    path:'manage/agency', component:Agency
                },
                {
                    path: '/manage/review_items', component:SnsReviewItems
                },
                {
                    path: '/manage/review', component:SnsReviewBlocks
                }
                /*
                {
                    path:'report', component:Report
                },
                {
                    path:'manage', component:Manage
                },
                {
                    path:'md', component:ManageMd
                }
                */
            ]
        },
        {
            path:'*', component:NotFound
        },
    ],
    scrollBehavior() {
        window.scrollTo(0,0);
    }
})

function checkNext(path, next) {
    if(path == '/') {
        next('/intro')
    } else if(path == '/home') {
        next('/intro')
    }else
        next()
}
router.beforeEach(async(to, from, next) => {

    var store = router.app.$store
    var toPage = to.path

    var login = store.getters.isLogin
    //console.log(login)

    var sid = VueCookie.get('sid')
    var skey = VueCookie.get('skey')

    if(!!!sid || !!!skey){
       login = false
    }

    if(toPage == '/cafe24'){
        if(!!!to.query.mall_id || !!!to.query.shop_no){
            next({path: '/wrongaccess'})
            return
        }

        store.commit('mallId', {value: to.query.mall_id})
        store.commit('shopNo', {value: to.query.shop_no})
        var curl = '/api/cafe24/get/join_yn?mall_id=' + to.query.mall_id + '&shop_no=' + to.query.shop_no

        try {
            var cdata = await axios.get(curl)
            if(!!!cdata.data){
                var state = to.query.mall_id + '_' + to.query.shop_no
                var url = 'https://' + to.query.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=jSPMChQ5GhKtwIEClbLqOC&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/auth&scope=mall.read_application,mall.write_application,mall.read_product'
                location.href = url
            }
            else if(!!cdata.data.company_code) {
                next('/')
            }else {
                next({path: '/cafe24/join'})
            }
            return
        }catch(e){
            console.log(e)
            var state = to.query.mall_id + '_' + to.query.shop_no
            var url = 'https://' + to.query.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=jSPMChQ5GhKtwIEClbLqOC&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/auth&scope=mall.read_application,mall.write_application,mall.read_product'
            location.href = url
        }

    } else if(toPage == '/cafe24App'){
        if(!!!to.query.mall_id || !!!to.query.shop_no){
            next({path: '/wrongaccess'})
            return
        }

        store.commit('mallId', {value: to.query.mall_id})
        store.commit('shopNo', {value: to.query.shop_no})
        var curl = '/api/cafe24/get/join_yn?mall_id=' + to.query.mall_id + '&shop_no=' + to.query.shop_no

        try {
            var cdata = await axios.get(curl)
            if(!!!cdata.data){
                var state = to.query.mall_id + '_' + to.query.shop_no
                var url = 'https://' + to.query.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=1sBkLANvFV2mQuA08WaZED&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/auth2&scope=mall.read_application,mall.write_application,mall.read_product'
                location.href = url
            }
            else if(!!cdata.data.company_code) {
                next('/')
            }else {
                next({path: '/cafe24/join'})
            }
            return
        }catch(e){
            console.log(e)
            var state = to.query.mall_id + '_' + to.query.shop_no
            var url = 'https://' + to.query.mall_id + '.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=1sBkLANvFV2mQuA08WaZED&state=' + state + '&redirect_uri=https://moreadmin.mocoplex.com/api/cafe24/auth2&scope=mall.read_application,mall.write_application,mall.read_product'
            location.href = url
        }

    }else if(login && toPage != '/agree/terms' && toPage != '/enter/user') {
        if(!store.getters.agreeYn)
        {
            // 비동의 상태
            //console.log('not valid')

            var url = '/api/user/get/agree_yn'

            try{
                var data = await axios.get(url)
                var user = data.data
                var agree = user.agree_yn

                if(agree == 'Y') {
                    // ok
                    store.commit('agreeYn', {value:agree})
                    checkNext(toPage, next)
                } else {
                    next({path: '/agree/terms'})
                }
            }catch(err){
                var url2 = "/api/user/logout"
                router.app.$http.post(url2).then(res=>{
                    if(res.status == '200'){
                        document.location.href = '/'
                    }
                })
            }

        } else {
            checkNext(toPage, next)
        }
    } else if(login){
        if(!store.getters.agreeYn)
        {
            // 비동의 상태
            //console.log('not valid')

            var url = '/api/user/get/agree_yn'

            try {
                var data = await axios.get(url)
                var user = data.data
                var agree = user.agree_yn

                if (agree == 'Y') {
                    // ok
                    store.commit('agreeYn', {value: agree})
                    next({path: '/'})
                } else {
                    next()
                }
            } catch(err){
                var url2 = "/api/user/logout"
                router.app.$http.post(url2).then(res=>{
                    if(res.status == '200'){
                        document.location.href = '/'
                    }
                })
            }
        } else {
            next({path: '/'})
        }
    } else {

        //console.log(toPage)
        var noauth = [
            '/login','/agree'
            ,'/join'
            ,'/findid'
            ,'/findpw'
            ,'/lock'
            ,'/agree/terms'
            ,'/notice/view'
            ,'/cafe24'
            ,'/cafe24/join'
            ,'/cafe24App'
            ,'/cafe24App/join'
            ,'/notfound'
            ,'/wrongaccess'
        ]

        var b = noauth.includes(toPage)
        if(b) {
            checkNext(toPage, next)
        }
        else {
            next('/login')
        }
    }

})

export default router
