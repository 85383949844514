<template>
    <div style="height:100%">
        <div
            class="d-flex"
            style="position:fixed;background-color:white;left:0;top:0;width:100%;z-index:10;border-bottom: 0.5px solid rgba(51,51,51,0.3);height:64px"
        >
            <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
            <v-img class="d-inline-flex"
                   :src="logo"
                   max-width="86"
                   height="21"
                   style="cursor:pointer;margin: auto;margin-left: 18px"
                   @click="home"
            ></v-img>

            <v-spacer></v-spacer>

            <!--
            <v-btn icon>
                <v-icon>mdi-heart</v-icon>
            </v-btn>

            <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            -->
            <div style="margin: auto">
                <v-menu
                        nudge-bottom="30"
                        left
                >
                    <template v-slot:activator="{ on }">
                        <v-chip icon v-on="on"
                                class="ma-4"
                                color="#29d1cf"
                                text-color="white"
                                style="font-weight:600;font-size:0.9em;"
                        >
                            <v-avatar left>
                                <v-icon size="x-large">mdi-account-circle</v-icon>
                            </v-avatar>
                            {{ accountname }}
                        </v-chip>
                    </template>

                    <v-list>
                        <v-list-item
                                dense
                                @click="href('account')"
                        >
                            <v-list-item-title>회원 정보</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                dense
                                @click="href('logout')"
                        >
                            <v-list-item-title>로그아웃</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

        </div>
        <div
            style="background-color:#eff0f1;padding-top: 64px;"
        >
            <div class="d-flex flex-grow-1">

                <left-menu
                />

                <div class="flex-grow-1 pa-2" style="margin-left:235px;min-width: 900px">
                    <router-view class="ma-0 pa-0"/>
                </div>

            </div>
        </div>
        <div style="background-color:white;height:80px;border-top: 0.5px solid rgba(51,51,51,0.3);width: 100%;position:relative;">
            <div style="width: 100%; margin-left:235px;" class="d-flex py-6 mfooter"
            >
                <div class="footer">
                    <div class="box-header clearfix">
                        <ul class="footer-menu">
                            <li><span>(주)모코플렉스</span></li>
                            <li><span>대표 : 박나라</span></li>
                            <li><span>개인정보보호 책임자 : 박나라</span></li>
                        </ul>
                    </div>
                    <div class="box-header clearfix">
                        <ul class="footer-menu">
                            <li><span>주소 : 서울 중구 을지로 50 17층 모코플렉스</span></li>
                        </ul>
                    </div>
                    <div class="box-header clearfix">
                        <ul class="footer-menu">
                            <li><span>사업자등록번호 : 117-81-76369</span></li>
                            <li><span>통신판매업신고번호 : 2021-서울중구-0726</span></li>
                        </ul>
                    </div>
                    <div class="box-header clearfix">
                        <ul class="footer-menu">
                            <li><span>ⓒ Mocoplex Inc. All rights reserved.</span></li>
                        </ul>
                    </div>
                    <div class="box-header clearfix" style="margin-top:12px">
                        <ul class="footer-menu">
                            <li><a id="privacy" @click="privacy">개인정보처리방침</a></li>
                            <li><a @click="terms">이용약관</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style="position: absolute;top:24px;right:24px;color:#999;font-size:0.8em;">
                ! 본 사이트는 크롬 브라우저에 최적화되어 있습니다.
            </div>
        </div>

        <v-dialog
                v-model="dialog"
                width="700"
        >
            <v-card color="#fff">

                <v-card-text>
                    <iframe :src="url" frameborder="0" style="width:100%;height:450px"></iframe>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="teal"
                            text
                            @click="dialog = false"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-for="item in notice_list" :key="item.nid"
                v-model="item.dialog"
                width="700"
                persistent
        >
            <v-card color="#fff">

                <v-card-title class="headline">{{item.title}}</v-card-title>
                <v-card-text>
                    <viewer :key="item.flag" :nid="item.nid" :mode="item.mode"></viewer>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-checkbox
                            v-if="item.mode != 'preview'"
                            class="mr-2"
                            v-model="item.checkbox"
                            dense
                            color="teal"
                            label="오늘 하루 다시 보지 않기"
                            @click="changeCheckbox(item)"
                    ></v-checkbox>
                    <v-btn
                            color="teal"
                            text
                            @click="closeNotice(item)"
                    >
                        닫기
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import LeftMenu from "../common/LeftMenu";
    import Viewer from "../common/Viewer";

    export default {
        created() {
            this.$event.$on('notice', this.notice)
        },
        destroyed() {
            this.$event.$off('notice', this.notice)
        },
        mounted() {
            this.load()
        },
        components: {
            LeftMenu,
            Viewer
        },
        data: () => ({
            logo: require('@/assets/logo.png'),
            accountname:'',
            dialog: false,
            url: '',
            title: '',
            notice_list: [],
            flag: 0,
        }),
        methods: {
            load : function(){
                var that = this
                var url = "/api/user/account_info"
                this.$http.get(url).then(res=>{
                    that.accountname = res.data.name
                }).catch((err)=> {
                    var target = err.response.data.target
                    var msg = err.response.data.msg

                    if (target == 'session' && msg == 'NOTVALID') {
                        this.$event.$emit('alert', '세션이 만료되어 로그아웃 합니다')
                        setTimeout(function(){
                            that.href('force_logout')
                        },2500)
                    } else {
                        this.$event.$emit('alert', msg)
                    }
                })

                var url2 = "/api/user/site/info"
                this.$http.get(url2).then(res=>{
                    if(res.data.length > 0) {
                        that.siteInfo = res.data[0]
                        //console.log(that.siteInfo.company_code)
                        that.$store.commit('companyCode',{value:that.siteInfo.company_code})
                        that.$store.commit('companyName',{value:that.siteInfo.company_name})
                    }
                }).catch((err)=>{
                })
            },
            home: function() {
                this.$router.push({path:"/home"})
            },
            href: function(link){
                if(link == 'logout'){
                    var that = this
                    this.$event.$emit('confirm', '로그아웃', '로그아웃하시겠습니까?', function () {
                        var url = "/api/user/logout"
                        this.$http.post(url).then(res=>{
                            if(res.status == '200'){
                                document.location.href = '/'
                            }
                        }).catch((err)=>{
                            var msg = err.response.data.msg
                            that.$event.$emit('alert',msg)
                        })
                    })
                }else if(link == 'force_logout'){
                    var url = "/api/user/logout"
                    this.$http.post(url).then(res=>{
                        if(res.status == '200'){
                            document.location.href = '/'
                        }
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        that.$event.$emit('alert',msg)
                    })
                }else{
                    this.$router.push({path:"/"+link})
                }
            },
            privacy: function(){
                this.url = "https://more.mocoplex.com/common/privacy"
                this.title = "more 개인정보 처리방침"
                this.dialog = true
            },
            terms: function(){
                this.url = "https://more.mocoplex.com/common/agreement"
                this.title = "more 서비스 이용 약관"
                this.dialog = true
            },
            notice: function (list) {
                var that = this
                setTimeout(function () {
                    that.notice_list = list
                    list.forEach(function(e, i){
                        e.flag = that.flag
                        that.flag++
                        e.index = i
                        e.checkbox = false
                        e.dialog = true
                    })
                },300)
            },
            changeCheckbox: function (item) {
                this.$cookie.set('nid_' + item.nid, 'show', 1);
                item.dialog = false
                this.$set(this.notice_list, item.index, item)
            },
            closeNotice: function(item){
                item.dialog = false
                this.$set(this.notice_list, item.index, item)
            }
        }
    };
</script>
<style scoped>
    #privacy {font-weight: 700}
</style>
