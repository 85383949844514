<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title :title="myInfo.name"/>
                </v-card-title>
            </div>
            <v-tabs color="teal"
                    v-model="mytab"
            >
                <v-tab key="opt1"
                >
                    회원 정보
                </v-tab>
                <v-tab v-show="admin == 0" key="opt2"
                >
                    정산 정보
                </v-tab>
                <v-tab key="opt3"
                >
                    회원 탈퇴
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="mytab"
                          touchless>
                <v-tab-item
                        class="pa-4"
                        key="opt1">

                    <div>
                        <v-alert
                                text
                                dense
                                color="teal"
                                icon="mdi-account"
                                border="left"
                        >
                            기본 정보
                        </v-alert>
                        <v-form
                                ref="form1"
                                v-model="valid1"
                                lazy-validation
                                class="ma-3"
                        >
                            <v-row>
                                <v-col class="col-8">
                                    <v-text-field
                                            label="아이디"
                                            v-model="myInfo.id"
                                            disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn class="mt-3"
                                           color="#566270"
                                           block dark depressed
                                           @click="showChangePwd"
                                    >
                                        비밀번호 변경
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-text-field
                                    label="담당자"
                                    v-model="myInfo.manager"
                                    :rules="nameRules"
                            ></v-text-field>
                            <v-text-field
                                    label="이메일"
                                    v-model="myInfo.email"
                                    :rules="emailRules"
                            ></v-text-field>
                            <v-text-field
                                    label="연락처"
                                    v-model="myInfo.phone"
                                    :rules="phoneRules"
                            ></v-text-field>
                            <v-btn class="mt-3"
                                   color="#29d1cf"
                                   @click="updateInfo"
                                   block dark depressed
                            >
                                수정하기
                            </v-btn>
                        </v-form>
                    </div>
                    <div v-if="Object.keys(siteInfo).length > 0">
                        <v-alert
                                text
                                dense
                                color="teal"
                                icon="mdi-text-subject"
                                border="left"
                                class="mt-11"
                        >
                            사이트 정보
                        </v-alert>
                        <v-form
                                ref="form2"
                                v-model="valid2"
                                lazy-validation
                                class="ma-3"
                        >
                            <v-text-field
                                    label="사이트명"
                                    v-model="siteInfo.company_name"
                                    :rules="nameRules"
                                    disabled
                            ></v-text-field>
                            <v-text-field
                                    label="사이트 URL (PC)"
                                    v-model="siteInfo.site_url"
                                    :rules="urlRules"
                                    disabled
                            ></v-text-field>
                            <v-text-field
                                    label="사이트 URL (Mobile)"
                                    v-model="siteInfo.site_m_url"
                                    :rules="urlRules"
                                    disabled
                            ></v-text-field>
                            <v-select
                                    v-model="siteInfo.service_ver"
                                    :items="service_items"
                                    item-text="value"
                                    item-value="code"
                                    label="커머스/논커머스"
                                    :rules="selectRules"
                                    return-code
                                    disabled
                            ></v-select>
                            <v-select
                                    v-model="siteInfo.category"
                                    :items="cate_items"
                                    item-text="value"
                                    item-value="code"
                                    label="업종"
                                    :rules="selectRules"
                                    return-code
                            ></v-select>
                            <v-select
                                    v-model="siteInfo.hosting"
                                    :items="host_items"
                                    item-text="value"
                                    item-value="code"
                                    label="호스팅사"
                                    :rules="selectRules"
                                    return-code
                            ></v-select>
                            <v-btn class="mt-3"
                                   color="#29d1cf"
                                   @click="updateSiteInfo"
                                   block dark depressed
                            >
                                수정하기
                            </v-btn>
                        </v-form>
                    </div>
                </v-tab-item>

                <v-tab-item v-show="admin == 0"
                        class="pa-4"
                        key="opt2">
                    <account-payment-setting/>
                </v-tab-item>

                <v-tab-item
                        class="pa-4"
                        key="opt3">
                    <cancel-membership/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">비밀번호 변경</span>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container>
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-text-field
                                    v-model="formData.password"
                                    :rules="nameRules"
                                    :error="pwdError"
                                    :error-messages="pwdErrorMsg"
                                    @keyup="resetError"
                                    type="password"
                                    label="기존 비밀번호"
                                    class="input-group--focused mb-2"
                                    block
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="formData.password1"
                                    :rules="passwordRules"
                                    type="password"
                                    label="새 비밀번호"
                                    class="input-group--focused mb-2"
                                    block
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-model="formData.password2"
                                    :rules="[passRules2]"
                                    type="password"
                                    label="새 비밀번호 확인"
                                    class="input-group--focused"
                                    @keydown.enter="changePwd"
                                    outlined
                                    block
                            ></v-text-field>
                        </v-form>
                    </v-container>
                    <!--<small>*indicates required field</small>-->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">닫기</v-btn>
                    <v-btn color="blue darken-1" text @click="changePwd">변경</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import AccountPaymentSetting from "../common/AccountPaymentSetting";
    import MainTitle from "../common/MainTitle";
    import {mapGetters} from "vuex";
    import CancelMembership from "../common/CancelMembership";
    export default {
        computed: mapGetters({
            admin: 'admin',
        }),
        mounted() {
            if(!!this.$route.query.tab){
                this.mytab = this.$route.query.tab
            }
            this.selectload()
            this.load()
        },
        components: {
            CancelMembership,
            MainTitle,
            AccountPaymentSetting
        },
        data: () => ({
            mytab:'',
            myInfo: {
                name:'',
                id:'',
                email:'',
                manager:'',
                phone:''
            },
            siteInfo: {},
            formData: {
                password:'',
                password1:'',
                password2:''
            },
            service_items: [
                { value: '커머스', code: 'service01' },
                { value: '논커머스', code: 'service02' },
            ],
            cate_items: [
                { value: '선택', code: '' },
            ],
            host_items: [],
            nameRules: [
                v => !!v || '필수 정보입니다'
            ],

            urlRules: [
                v => !!v || '필수 정보입니다',
                v => /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(v) || 'url 형식이 올바르지 않습니다',
                v => v.indexOf('naver.com') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('band.us') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('tistory.com') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('instagram.com') < 0 || '서비스 이용이 불가능한 url 입니다'
            ],

            phoneRules: [
                v => !!v || '필수 정보입니다',
                v => (v.replace(/-/gi,"").length >= 10 && v.replace(/-/gi,"").length <= 11) || '휴대폰 번호 형식이 올바르지 않습니다',
                v => /^[0-9\-]+$/.test(v) ||
                    '휴대폰 번호 형식이 올바르지 않습니다'
            ],

            emailRules: [
                v => !!v || '필수 정보입니다',
                v => /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v) ||
                    '이메일 형식이 올바르지 않습니다'
            ],

            selectRules: [
                v => !!v || '필수 정보입니다'
            ],

            passwordRules: [
                v => !!v || '필수 정보입니다',
                v => (v.length >= 8 && v.length <= 16) || '8~16자 영문 대/소문자, 숫자, 특수문자를 포함해주세요',
                v => /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(v) ||
                    '8~16자 영문 대/소문자, 숫자, 특수문자를 포함해주세요'
            ],
            valid1: false,
            valid2: false,
            valid: false,
            dialog: false,
            pwdError: false,
            pwdErrorMsg: '',
        }),
        methods: {
            selectload : function() {
                var that = this
                var url = "/api/more/get/codes?type=category"
                this.$http.get(url).then(res=>{
                    res.data.forEach(function(e, i){
                        that.cate_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })

                var url2 = "/api/more/get/codes?type=hosting"
                this.$http.get(url2).then(res=>{
                    res.data.forEach(function(e, i){
                        that.host_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })
            },
            load : function(){
                var that = this
                var url = "/api/user/info"
                this.$http.get(url).then(res=>{
                    that.myInfo = res.data
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })

                var url2 = "/api/user/site/info"
                this.$http.get(url2).then(res=>{
                    if(res.data.length > 0) {
                        that.siteInfo = res.data[0]
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            passRules2:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.formData.password1 != v)
                    return "비밀번호가 일치하지 않습니다"
            },
            updateInfo:function () {
                if (!this.$refs.form1.validate()) {
                    return
                }

                var that = this
                this.$event.$emit('confirm', '기본 정보 변경', '정보를 수정하시겠습니까?', function () {
                    var url = "/api/user/update/info"
                    this.$http.post(url, that.myInfo).then(res=>{
                        if(res.data.msg == "OK"){
                            that.$event.$emit('alert',"저장되었습니다")
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert',"오류가 발생했습니다")
                    })
                });
            },
            updateSiteInfo:function () {
                if (!this.$refs.form2.validate()) {
                    return
                }

                var that = this
                this.$event.$emit('confirm', '사이트 정보 변경', '정보를 수정하시겠습니까?', function () {
                    var url = "/api/user/update/siteinfo"
                    this.$http.post(url, that.siteInfo).then(res=>{
                        if(res.data.msg == "OK"){
                            that.$event.$emit('alert',"저장되었습니다")
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert',"오류가 발생했습니다")
                    })
                });
            },
            showChangePwd:function(){
                this.dialog = true
            },
            resetError: function() {
                this.pwdError = false
                this.pwdErrorMsg = ''
            },
            changePwd:function(){
                if (!this.$refs.form.validate()) {
                    return
                }

                if(this.pwdError) {
                    return
                }

                var that = this
                this.$event.$emit('confirm', '비밀번호 변경', '비밀번호를 변경하시겠습니까?', function () {
                    var url = "/api/user/change_pwd"
                    this.$http.post(url, that.formData).then(res=>{
                        if(res.data.msg == "OK"){
                            that.dialog = false
                            that.$refs.form.reset()
                            that.$event.$emit('alert',"비밀번호가 변경되었습니다")
                        }
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        if(msg == 'NOTEQUAL'){
                            msg = '비밀번호가 일치하지 않습니다. 다시 입력해주세요.'
                            that.pwdError = true
                            that.pwdErrorMsg = msg
                            //this.$refs.form.validate()
                        }else{
                            that.$event.$emit('alert',"오류가 발생했습니다")
                        }
                    })
                });
            },
            closeDialog:function() {
                this.dialog = false
                this.formData.password = ''
                this.formData.password1 = ''
                this.formData.password2 = ''
                this.$refs.form.resetValidation()
            }
        }
    };
</script>