<template>

    <div class="limiter">


        <div class="container-login100">

            <div class="wrap-join100 p-l-50 p-r-50 p-t-77 p-b-30">

                <div class="login100-form">
                    <div class="login100-form-title p-b-55">
                        <v-img class="d-inline-flex"
                               :src="logo"
                               max-width="86"
                               height="21"
                               @click="goHome"
                               style="cursor:pointer;"
                        ></v-img>
                        <span class="m-l-10">서비스 이용약관 및 개인 정보 동의</span>
                    </div>

                    <v-form
                            style="width: 100%"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <div class="p-b-30">
                            <v-checkbox
                                    class="p-b-5"
                                    v-model="formData.checkbox1"
                                    label="서비스 이용 약관에 동의합니다."
                                    color="teal"
                                    :rules="checkboxRules1"
                                    :hide-details="valid">
                            </v-checkbox>
                            <div class="col-agree">
                                <div class="agree-box">
                                    <iframe src="/html/service.html" frameborder="0" style="width:100%;height:100%"></iframe>
                                </div>
                            </div>
                        </div>

                        <div>
                            <v-checkbox
                                    class="p-b-5"
                                    v-model="formData.checkbox2"
                                    label="개인 정보 수집 및 이용에 동의합니다."
                                    color="teal"
                                    :rules="checkboxRules2"
                                    :hide-details="valid">
                            </v-checkbox>
                            <div class="col-agree">
                                <div class="agree-box">
                                    <iframe src="/html/privacy.html" frameborder="0" style="width:100%;height:100%"></iframe>
                                </div>
                            </div>
                        </div>
                    </v-form>

                    <v-row>
                        <v-col>
                            <v-btn depressed x-large dark color="#566270" block
                                   class="mt-8"
                                   @click="onLogout"
                            >로그아웃</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn depressed x-large dark color="#29d1cf" block
                                   class="mt-8"
                                   @click="onNext"
                            >다음</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    require('@/assets/css/main.css')
    require('@/assets/css/util.css')

    export default {
        created() {
        },
        mounted() {
            //console.log('aa')
            //console.log(this.$event)
        },
        data: () => ({
            logo: require('@/assets/logo.png'),
            checkboxRules1: [
                v => !!v || '서비스 이용약관에 동의해주세요',
                //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],

            checkboxRules2: [
                v => !!v || '개인 정보 수집 및 이용에 동의해주세요',
                //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],

            valid:false,

            //
            formData:{
                checkbox1:false,
                checkbox2:false,
            }
        }),
        methods:{
            goHome:function(){
                this.$router.push({path:'/'})
            },
            onLogout:function() {
                var that = this
                var url = "/api/user/logout"
                this.$http.post(url).then(res=>{
                    if(res.status == '200'){
                        document.location.href = '/'
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    that.$event.$emit('alert',msg)
                })
            },
            onNext:function () {
                if (!this.$refs.form.validate()) {
                    return;
                }

                this.$router.push({path:'/enter/user'})
            }
        }
    }
</script>
<style scoped>
    .col-agree{padding:15px 15px;height:275px;overflow:hidden;border:1px solid #dedede;background-color:#eee;border-radius:6px}
    .col-agree .agree-box{height:100%;overflow:hidden;}
</style>