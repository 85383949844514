<template>
    <v-autocomplete
            :items="company_list"
            label="사이트명"
            item-text="name"
            item-value="id"
            v-model="value"
            outlined
            dense
            hide-details
    >
        <template v-slot:selection="data">
            <span
                    v-bind="data.attrs"
                    :input-value="data.selected"
            >
                {{ data.item.name }}
            </span>
        </template>
        <template v-slot:item="data">
            <template>
                <v-list-item-content>
                    <v-list-item-title v-if="data.item.name.indexOf('유료') > 0" v-html="data.item.name" style="font-weight: 600;"></v-list-item-title>
                    <v-list-item-title v-else-if="data.item.name.indexOf('종료') > 0" v-html="data.item.name" style="color:#c0c0c0;"></v-list-item-title>
                    <v-list-item-title v-else v-html="data.item.name" ></v-list-item-title>
                </v-list-item-content>
            </template>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        data: () => ({
            company_list: [
                {
                    id: "",
                    name: "전체"
                },
            ],
        }),
        props: {
            value: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                default: 'all'
            },
            option_default: {
                type: String,
                default: '전체'
            },
        },
        watch: {
            type: function () {

            },
            value() {
                if(typeof this.value !== 'undefined')
                    this.$emit('input', this.value);
            }
        },
        mounted() {
            this.load()
        },
        methods : {
            load: function () {
                var that = this
                var url = "/api/more/get/company_list?type=" + this.type
                this.$http.get(url).then(res => {
                    this.company_list = []
                    this.company_list.push({ id: "", name: this.option_default})
                    res.data.forEach(function(e){
                        if(!!e.cstatus) {
                            var cs = e.cstatus
                            if(cs == "F") {
                                e.name += "-무료"
                            }else if(cs == 'P') {
                                e.name += "-유료"
                            }else if(cs == 'E') {
                                e.name += "-종료"
                            }else if(cs == 'W') {
                                e.name += "-대기"
                            }
                        }
                        that.company_list.push({id: e.id, name: e.name})
                    })
                })
                .catch((err)=>{

                })
            }
        }
    }
</script>

<style scoped>

</style>
