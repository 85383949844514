<template>

    <div>
        <v-card v-if="!!mydata">
            <v-toolbar flat dense>
                <v-toolbar-title>
                    {{mode}}
                </v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <div class="pa-4">
                <table class="mtable">
                    <thead>
                    <tr class="theader">
                        <th style="width: 200px">지면</th>
                        <th style="width: 200px">타입</th>
                        <th>노출기간</th>
                        <th>노출제어</th>
                        <th>상태</th>
                        <th>최종 변경일시</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data,i) in mydata" :key="i">
                        <td v-if="data.page_type == 'search'" style="background-color: #eee">
                            검색 페이지
                        </td>
                        <td v-else-if="data.page_type == 'category'" style="background-color: #eee">
                            카테고리 페이지
                        </td>
                        <td>{{data.rs_type}}</td>
                        <td v-if="!!data.start_ymd" class="px-5">
                            {{data.start_ymd}}
                            <span> ~ </span>
                            <span v-if="!!data.end_ymd">{{data.end_ymd}}</span>
                            <span v-else>미설정</span>
                        </td>
                        <td v-else></td>
                        <td v-if="!!data.start_ymd">
                            <v-layout column align-center wrap>
                                <v-switch
                                        v-model="data.status"
                                        color="teal"
                                        inset
                                        @change="changePstatus(data)"
                                >
                                </v-switch>
                            </v-layout>
                        </td>
                        <td v-else></td>
                        <td v-if="!!data.start_ymd">
                            <span v-html="getStautsText(data)"></span>
                        </td>
                        <td v-else></td>
                        <td>
                          <span style="font-size: 12px;" v-html="ymdhms(data.onoff_date)"></span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </v-card>

    </div>
</template>
<script>

    export default {
        props:{
            mode:{
                type: String
            },
            company_code:{
                type: String
            },
            min_ymd:{
                type: String
            },
        },
        components: {
        },
        mounted() {
            this.load()
        },
        data: () => ({
            mydata:null,
            useStatus: 0,
            onCount: 0,
        }),
        watch:{
            company_code: function () {
                this.load()
            }
        },
        methods:{
            load: function () {
                var that = this
                var url = "/api/more/get/sch/page_info?platform=" + this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile'){
                    url += '_web'
                }
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.onCount = 0
                        res.data.forEach(function(e, i){
                            that.onCount += e['status']
                            if(!!e['rs_type']){
                                that.useStatus++
                            }

                            e['current_status'] = e['status']
                        })
                        that.mydata = res.data
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            changePstatus: function(item){
                var that = this
                var platform = this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile') {
                    platform += '_web'
               }
                item.platform = platform
                if(!!this.company_code){
                    item.company_code = this.company_code
                }
                item.status = item.status?1:0
                this.$event.$emit('confirm', '서치 노출 상태', '서치 노출 상태를 변경하시겠습니까?', function () {
                    var url = "/api/more/update/sch/status"
                    that.$http.post(url, item).then(res=>{
                        if(res.status == '200'){
                            that.load()
                            that.$event.$emit('alert','노출 상태가 변경되었습니다')
                        }
                    }).catch((err)=>{
                        that.load()
                        var msg = '오류가 발생했습니다'
                        var errmsg = err.response.data.msg
                        if(errmsg == 'NOPAID'){
                            msg = '서치 상품의 미납내역이 있어 ON 하실 수 없습니다.<br>결제 완료 후 진행해주세요'
                        }else if(errmsg == 'STATUS_END'){
                            msg = '계약이 종료된 상품입니다'
                        }
                        that.$event.$emit('alert',msg)
                    })
                }, function() {
                    item.status = !item.status
                })
            },
            getStautsText: function (item) {
                if(item.current_status == 0){
                    return '미노출'
                }else{
                    var today = this.$moment(new Date()).format('YYYY-MM-DD')
                    if(!!item.start_ymd){
                        if(item.start_ymd > today){
                            return '노출예약'
                        } else if(!!!item.end_ymd){
                            return '노출중'
                        } else if(item.end_ymd >= today){
                            return '노출중'
                        }
                    }
                    return '미노출'
                }
            },
            ymdhms:function (date) {
              if(!!!date){
                return '<span>-</span>'
              }

              var strDate = this.$moment(date).format('YYYY.MM.DD HH:mm:ss')
              return '<span style="font-size: 13px">'+strDate+'</span>'
            },
        }
    }
</script>
<style scoped>
    .main-title {
        margin: 8px 8px;
        font-weight: 500;
        font-size:1em;
        color:#666;
    }

    .center-input >>> input {
        text-align: center;
    }
</style>