<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">리포트 요약 - 상품별 성과</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>

        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">기간</th>
                                    <th class="text-center bb bt br">상품</th>
                                    <th class="text-center bb bt br">계정 수</th>
                                    <th class="text-center bb bt br">노출수</th>
                                    <th class="text-center bb bt br">클릭수</th>
                                    <th class="text-center bb bt br">클릭율</th>
                                    <th class="text-center bb bt br">전환수</th>
                                    <th class="text-center bb bt br">구매상품수</th>
                                    <th class="text-center bb bt br">전환율</th>
                                    <th class="text-center bb bt br">매출액</th>
                                    <th class="text-center bb bt br">솔루션 비용</th>
                                    <th class="text-center bb bt br">월 예상 비용</th>
                                    <th class="text-center bb bt">매출 비중</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in report_list" :key="item.company_code" v-if="!!report_total && report_total.length > 0">
                                    <td class="text-center bb">{{ item.ymd }}</td>
                                    <td class="text-center bb">{{ item.prod }}</td>
                                    <td class="text-center bb">{{ item.count.price() }}</td>
                                    <td class="text-center bb" v-if="item.impression == 0">-</td>
                                    <td class="text-center bb" v-else>{{item.impression.price()}}</td>
                                    <td class="text-center bb">{{ item.click.price() }}</td>
                                    <td class="text-center bb" v-if="item.impression == 0">-</td>
                                    <td class="text-center bb" v-else v-html="percentage(item.click,item.impression)" />
                                    <td class="text-center bb">{{ item.conversion.price() }}</td>
                                    <td class="text-center bb">{{ item.conversion_total.price() }}</td>
                                    <td class="text-center bb" v-if="item.click == 0">0%</td>
                                    <td class="text-center bb" v-else v-html="percentage(item.conversion,item.click)" />
                                    <td class="text-center bb">{{ item.sales.price() }}</td>
                                    <td class="text-center bb">{{ item.solution_cost.price() }}</td>
                                    <td class="text-center bb">{{ item.expected_cost.price() }}</td>
                                    <td class="text-center bb" v-if="report_total[0].expected_cost == 0">-</td>
                                    <td class="text-center bb" v-else v-html="percentage(item.expected_cost, report_total[0].expected_cost)" />
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-for="(item, i) in report_total">
                                    <td class="text-center sum bbt bbb" colspan="2">합계</td>
                                    <td class="text-center sum bbt bbb">{{item.total_count}}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.impression == 0">-</td>
                                    <td class="text-center sum bbt bbb" v-else>{{item.impression.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.click.price()}}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.impression == 0">-</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="percentage(item.click,item.impression)"></td>
                                    <td class="text-center sum bbt bbb">{{item.conversion.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.conversion_total.price()}}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.click == 0">0%</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="percentage(item.conversion,item.click)"></td>
                                    <td class="text-center sum bbt bbb">{{item.sales.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.solution_cost.price()}}</td>
                                    <td class="text-center sum bbt bbb">{{item.expected_cost.price()}}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.expected_cost == 0">-</td>
                                    <td class="text-center sum bbt bbb" v-else>100%</td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam2: 'getReportParam2',
            companyName : 'companyName',
        }),
        components: {

        },
        watch: {
            reportParam2:function() {
                this.dataload(true)
            },
            page:function() {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function() {
              if(!!!this.reportParam2.yyyymm )
                return

              var that = this;
              var url = "/api/more/report/total/prod"
              this.$http.post(url, this.reportParam2).then(res => {
                that.report_list = res.data
              }).catch((err)=>{
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })

              var url = "/api/more/report/total/sales_summary_total"
              this.$http.post(url, this.reportParam2).then(res => {
                that.report_total = res.data
                //console.log(res.data)
              }).catch((err)=>{
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            plusminus:function(a, b){
              var pm = ((a/b-1)*100).toFixed(2)
              pm = pm.replace('.00','')
              return pm + '%'
            },
            downloadCSV: function () {
              var url = "/api/more/report/sales_detail/csv?"
              var params = new URLSearchParams(this.reportParam2).toString();
              params += '&type=2'
              location.href = url + params
            },
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list: [],
            report_total: {},
            page: 1,
            pageCount: 1,
            itemsPerPage: 50,
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>