import { render, staticRenderFns } from "./PromotionBanner.vue?vue&type=template&id=eab4d906&scoped=true"
import script from "./PromotionBanner.vue?vue&type=script&lang=js"
export * from "./PromotionBanner.vue?vue&type=script&lang=js"
import style0 from "./PromotionBanner.vue?vue&type=style&index=0&id=eab4d906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eab4d906",
  null
  
)

export default component.exports