<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="계정관리"/>
                </v-card-title>
            </div>
            <v-tabs color="teal"
                    v-model="mytab"
            >
                <v-tab key="all"
                >
                    전체
                </v-tab>
                <v-tab key="panel"
                >
                    패널
                </v-tab>
                <v-tab key="ticker"
                >
                    티커
                </v-tab>
                <v-tab key="recom"
                >
                    추천
                </v-tab>
                <v-tab key="sch"
                >
                    서치
                </v-tab>
                <v-tab key="payment"
                >
                    납부현황
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless>
                <v-tab-item
                            class="pa-5"
                            key="all">
                    <detail option="all" ref="all" class="pt-3" />
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="panel">
                    <detail option="panel" ref="panel" class="pt-3" />
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="ticker">
                    <detail option="ticker" ref="ticker" class="pt-3" />
                </v-tab-item>
                <v-tab-item
                    class="pa-5"
                    key="recom">
                  <detail option="recom" ref="recom" class="pt-3" />
                </v-tab-item>
                <v-tab-item
                    class="pa-5"
                    key="sch">
                  <detail option="sch" ref="sch" class="pt-3" />
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="payment">
                    <payment-management class="pt-3" />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import Detail from "./Detail";
    import { mapMutations, mapGetters } from 'vuex'
    import PaymentManagement from "./PaymentManagement";

    export default {
        computed: mapGetters({
        }),
        watch:{
            mytab:function () {
                this.dataload()
            }
        },
        components: {
            PaymentManagement,
            MainTitle,
            Detail,
        },
        mounted() {
            // commit
            this.dataload()
        },
        methods:{
            dataload:function() {
                //var v = Object.assign({}, this.form)
                //this.$store.commit('reportParam', {value:v})
            },

        },
        data: () => ({
            mytab: '',
            params:{},
        })
    }
</script>

<style scoped>

</style>
