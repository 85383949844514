<template>

    <div class="limiter">


        <div class="container-login100">

            <div class="wrap-login100 p-l-50 p-r-50 p-t-77 p-b-30">

                <div class="login100-form">
                    <div class="login100-form-title p-b-55">
                        <v-img class="d-inline-flex"
                               :src="logo"
                               max-width="86"
                               height="21"
                               @click="goHome"
                               style="cursor:pointer;"
                        ></v-img>
                        <span class="m-l-10">비밀번호 찾기</span>
                    </div>

                    <span class="login100-form-subtitle p-b-5">담당자 이메일로 인증</span>
                    <span class="login100-form-subscript p-b-35">가장 최근 등록된 담당자의 이메일 주소와 동일한 이메일 주소를 입력해야만 인증번호를 받을 수 있습니다.</span>

                    <v-form
                            style="width: 100%"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >

                        <v-text-field
                                label="아이디"
                                v-model="formData.id"
                                :rules="nameRules"
                                filled
                                required
                                @change="submitFlag=true"
                        ></v-text-field>

                        <v-text-field
                                label="담당자 이름"
                                v-model="formData.manager"
                                :rules="nameRules"
                                filled
                                required
                                @change="submitFlag=true"
                        ></v-text-field>

                        <v-text-field
                                label="담당자 이메일"
                                v-model="formData.email"
                                :rules="emailRules"
                                @keydown.enter="onAuth"
                                filled
                                required
                                @change="submitFlag=true"
                        ></v-text-field>
                    </v-form>


                    <v-btn depressed x-large dark color="#29d1cf" block
                           class="mt-2"
                           @click="onAuth"
                    >인증 메일 받기</v-btn>

                    <div class="w-full">
                        <div class="my-2">
                            <v-alert
                                    dense
                                    type="warning"
                                    color="grey"
                                    outlined
                                    style="font-size:0.8em"
                            >
                                <strong style="color:#666;font-size:14px">인증메일이 오지 않나요?</strong><br>
                                스팸 메일로 분류된 것은 아닌지 확인해 주세요.<br>
                                특히 Gmail 을 사용하신다면  스팸함을 확인 바랍니다.<br>
                                스팸 메일함에도 인증메일이 없다면, 다시 한 번 ‘인증 메일 받기'를 눌러주세요
                            </v-alert>
                            <!--
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <span style="color:rgba(0, 0, 0, 0.6);font-size:0.75rem;font-weight:500;letter-spacing:0.0892857143em;padding:5px" v-on="on">인증메일이 오지 않나요?</span>
                                </template>
                                <span>스팸메일로 분류된 것은 아닌지 확인해주세요.<br>스팸 메일함에도 메일이 없다면,<br>다시 한 번 ‘인증메일 발송'을 눌러주세요</span>
                            </v-tooltip>-->
                        </div>
                    </div>

                    <!--
                    <div class="text-center w-full p-t-20">
                        <v-icon style="font-size:medium">mdi-bullhorn</v-icon>
                        <span class="txt1 p-l-3" style="font-size:12px">
                        본 사이트는 크롬 브라우저에 최적화 되어있습니다.
                        </span>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>

    <!--
    <div id="app">

        <div id="login">
            <div id="description">
                <h1>Login</h1>
                <p>By logging in you agree to the ridiculously long terms that you didn't bother to read.</p>
            </div>
            <div id="form">
                <label for="email">Email</label>
                <input type="text" id="email" v-model="formData.email" placeholder="me@example.com" autocomplete="off">

                <label for="password">Password</label>&nbsp;
                <i class="fas" :class="[passwordIcon]" @click="hidePassword = !hidePassword"></i>
                <input type="password" id="password" v-model="formData.pass" placeholder="**********">

                <button type="button" @click="onLogin">Log in</button>
            </div>
        </div>


    </div>
    -->
</template>
<script>
    require('@/assets/css/main.css')
    require('@/assets/css/util.css')

    export default {
        created() {
        },
        mounted() {
            //console.log('aa')
            //console.log(this.$event)
        },
        data: () => ({
            logo: require('@/assets/logo.png'),
            checkbox:false,
            nameRules: [
                v => !!v || '담당자 이름을 입력해주세요',
                //v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],
            emailRules: [
                v => !!v || '담당자 이메일 주소를 입력해주세요',
                v => /.+@.+\..+/.test(v) || '이메일 형식이 올바르지 않습니다',
            ],
            valid:false,
            formData:{
                id:'',
                manager:'',
                email:'',
            },
            submitFlag:true,
        }),
        methods:{
            goHome:function(){
                this.$router.push({path:'/'})
            },
            onAuth:function () {
                if (!this.$refs.form.validate()) {
                    return;
                }
                if(!this.submitFlag){
                    return
                }
                var that = this
                this.submitFlag = false
                var url = "/api/user/forget/pw"
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.$event.$emit('alert','인증 메일이 발송되었습니다')
                        setTimeout(function () {
                            that.submitFlag = true
                        }, 3000)
                    }
                }).catch((err)=>{
                    this.submitFlag = true
                    var msg = '오류가 발생했습니다. 다시 시도해주세요.'
                    if(err.response.data.msg == 'NOTVALID'){
                        msg = '일치하는 회원 정보를 찾을 수 없습니다'
                    }
                    this.$event.$emit('alert',msg)
                })
            }
        }
    }
</script>