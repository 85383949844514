<template>
    <div>
        <div class="pt-3">
            <div class="d-flex">
                <div style="width:40px;margin:auto;">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">리포트 상세</div>
                <div style="width:110px;">
                    <v-btn block
                           depressed
                           @click="downloadCSV"
                    >
                        다운로드</v-btn>
                </div>
            </div>
        </div>

        <!--
        <v-alert
                text
                dense
                color="teal"
                border="left"
        >
            <v-row align="center" dense>
                <v-col class="shrink">
                    <v-icon color="teal">mdi-text-subject</v-icon>
                </v-col>
                <v-col class="grow">리포트 상세</v-col>
                <v-col class="shrink">
                    <v-menu offset-y
                            :position-x="-30"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-download</v-icon>

                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                    v-for="(item, index) in items" :key="index"
                                    @click="downloadCSV(item)"
                            >
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
        </v-alert>
        -->
        <v-row no-gutters class="mt-3">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">날짜</th>
                                    <th class="text-center bb bt br">사이트</th>
                                    <th class="text-center bb bt br">PV</th>
                                    <th class="text-center bb bt br">UV</th>
                                    <th class="text-center bb bt br">인당<br/>페이지뷰</th>
                                    <th class="text-center bb bt br">구매건수</th>
                                    <th class="text-center bb bt br">구매상품수</th>
                                    <th class="text-center bb bt br">전환당<br/>구매상품수</th>
                                    <th class="text-center bb bt br">구매율</th>
                                    <th class="text-center bb bt br">매출액<br><span style="font-size: 10px;">(간편결제 수치 미집계)</span></th>
                                    <th class="text-center bb bt">객단가</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in report_list" :key="item.company_code">
                                    <td class="text-center bb"><span v-html="$_ymd_s(item.ymd)"/></td>
                                    <td class="text-center bb">{{ item.company_name }}</td>
                                    <td class="text-center bb">{{ item.pv.price() }}</td>
                                    <td class="text-center bb">{{ item.uv.price() }}</td>
                                    <td class="text-center bb" v-html="perPrice2(item.pv,item.uv)"></td>
                                    <td class="text-center bb">{{ item.order_cnt.price() }}</td>
                                    <td class="text-center bb">{{ item.qty.price() }}</td>
                                    <td class="text-center bb" v-if="item.order_cnt == 0">0</td>
                                    <td class="text-center bb" v-else v-html="perPrice2(item.qty,item.order_cnt)"></td>
                                    <td class="text-center bb" v-html="percentage(item.order_cnt,item.uv)"></td>
                                    <td class="text-center bb">{{ item.sales.price() }}</td>
                                    <td class="text-center bb" v-if="item.order_cnt == 0">0</td>
                                    <td class="text-center bb" v-else v-html="perPrice(item.sales,item.order_cnt)"></td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-for="(item,i) in report_total" :key="i">
                                    <td colspan="2" class="text-center sum bbt bbb">합계</td>
                                    <td class="text-center sum bbt bbb">{{ item.pv.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.uv.price() }}</td>
                                    <td class="text-center sum bbt bbb" v-html="perPrice2(item.pv,item.uv)"></td>
                                    <td class="text-center sum bbt bbb">{{ item.order_cnt.price() }}</td>
                                    <td class="text-center sum bbt bbb">{{ item.qty.price() }}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.order_cnt == 0">0</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="perPrice2(item.qty,item.order_cnt)"></td>
                                    <td class="text-center sum bbt bbb" v-html="percentage(item.order_cnt,item.uv)"></td>
                                    <td class="text-center sum bbt bbb">{{ item.sales.price() }}</td>
                                    <td class="text-center sum bbt bbb" v-if="item.order_cnt == 0">0</td>
                                    <td class="text-center sum bbt bbb" v-else v-html="perPrice(item.sales,item.order_cnt)"></td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

        <div class="text-center pa-2">
            <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
        </div>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
            companyName : 'companyName',
        }),
        components: {

        },
        watch: {
            reportParam:function() {
                this.dataload(true)
            },
            page:function() {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()
        },
        methods:{
            dataload:function(pageReset) {

                if(!!!this.reportParam.start_date || !!!this.reportParam.end_date)
                    return

                if(!!pageReset){
                    this.page = 1
                }

                var that = this;
                var url = "/api/more/report/total"

                this.reportParam.page = this.page-1
                this.reportParam.size = this.itemsPerPage

                this.$http.post(url, this.reportParam).then(res => {
                    that.report_list = res.data.content
                    if(res.data.totalPages >= 1){
                        this.pageCount = res.data.totalPages
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })

                var url2 = "/api/more/report/total/total"
                this.$http.post(url2, this.reportParam).then(res => {
                    that.report_total = res.data
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            percentage:function (a, b) {
                var per = (a/b*100).toFixed(2)
                per = per.replace('.00','')
                return per + '%'
            },
            perPrice:function (a, b) {
                var per = Math.round(a/b)
                return per.price()
            },
            perPrice2:function (a, b) {
              var per = (a/b).toFixed(2)
              return per
            },
            downloadCSV: function () {
                var url = "/api/more/report/total/csv?"
                if (!!this.companyName) {
                    this.reportParam.company_name = this.companyName
                }
                var params = new URLSearchParams(this.reportParam).toString();
                location.href = url + params
            },
        },
        data: () => ({
            form2: {
                company_code : '',
            },
            report_list: [],
            report_total: {},
            page: 1,
            pageCount: 1,
            itemsPerPage: 50,
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>