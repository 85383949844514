<template>
    <div>
    </div>
</template>
<script>
    import { mapMutations, mapGetters } from 'vuex'
    export default {
        computed: mapGetters({
        }),
        components:{
        },
        props:{
        },
        watch:{
        },
        mounted() {

            this.payment_url = 'https://mpg.mocoplex.com'

            {
                const plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    this.payment_url + "/static/load.js"
                );
                plugin.async = true
                plugin.onload = this.onload
                document.head.appendChild(plugin)
            }

        },
        methods: {
            onload2:function() {
                //IMP.init('imp05709175')
            },
            on_cancel:function() {
                this.$event.$emit('alert', '결제 요청이 취소되었습니다.')
                this.$emit('failed_order')


                // 팝업 종료
                my_moco_pg.destroy()
            },
            on_failed:function() {
                this.$event.$emit('alert', '결제진행중 문제가 발생했습니다. 페이지를 새로고침 해주세요.')
                this.$emit('failed_order')


                // 팝업 종료
                my_moco_pg.destroy()
            },
            onload:function() {
                pg_cancel_callback = this.on_cancel
                pg_failed_callback = this.on_failed
                pg_succ_callback = this.on_succ

                /*
                const plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "https://cdn.iamport.kr/js/iamport.payment-1.1.5.js"
                );
                plugin.async = true
                plugin.onload = this.onload2
                document.head.appendChild(plugin)
                */
            },
            on_succ:function(imp_uid) {

                var url = '/api/payment/validate/v2?imp_uid='+imp_uid
                this.$http.get(url)
                    .then(data => {
                        // 주문 성공 페이지로 이동
                        this.$emit('succ_order')

                    }).catch(err=>{
                    this.$emit('failed_order')
                })


                // 팝업 종료
                my_moco_pg.destroy()
            },
            start:function (method, value) {

                value["pg"] = 'kcp'
                value["pay_method"] = method

                my_moco_pg.start(value)
            }
        },
        data: () => ({
            payment_url : null
        })

    }
</script>
