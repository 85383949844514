<template>

    <div style="position: relative;">

        <v-card-title class="subtitle mt-4">
            {{mode}}
        </v-card-title>
        <!--
        <div class="d-flex mt-4 mb-2">
            <v-switch
                    class="ma-0 mr-10"
                    :label="title"
                    color="teal"
                    inset
            ></v-switch>
        </div>
        -->

        <div v-if="!!mydata" class="pa-2">
            <v-row align="center" class="px-2">
                <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="center-input"
                                      v-model="mydata.start_ymd"
                                      readonly
                                      v-on="on"
                                      style="width:140px;"
                                      hint="계약시작일 이후"
                                      persistent-hint
                                      label="노출 시작일"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="mydata.start_ymd" no-title scrollable color="teal" @input="menu=false"></v-date-picker>
                </v-menu>
                <span class="px-2"> ~ </span>
                <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="center-input"
                                      v-model="mydata.end_ymd"
                                      readonly
                                      v-on="on"
                                      style="width:140px;"
                                      hint="미지정 가능"
                                      persistent-hint
                                      placeholder="미설정"
                                      clearable
                                      label="노출 종료일"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="mydata.end_ymd" no-title scrollable color="teal" @input="menu2=false"></v-date-picker>
                </v-menu>
            </v-row>
        </div>

        <div v-if="!!mydata" class="d-flex mt-6">
            <v-card
                    class="pa-3 pb-6 flex-grow-1"
                    style="overflow: hidden"
                    outlined
            >

                <v-subheader>노출 지면</v-subheader>

                <v-checkbox
                        class="pa-0"
                        label="메인 페이지"
                        hide-details
                        v-model="mydata.main"
                ></v-checkbox>

                <v-checkbox
                        class="pa-0"
                        label="카테고리 페이지"
                        hide-details
                        v-model="mydata.category"
                ></v-checkbox>

                <v-checkbox
                        class="pa-0"
                        label="상세 페이지"
                        hide-details
                        v-model="mydata.detail"
                ></v-checkbox>


            </v-card>

            <!--
            <v-card
                    class="pa-3 pb-6 ml-6 flex-grow-1"
                    style="overflow: hidden"
                    outlined
            >

                <v-subheader>메시지 타입</v-subheader>

                <v-checkbox
                        class="pa-0"
                        label="개인화 추천"
                        hide-details
                        v-model="mydata.pbyc"
                ></v-checkbox>

                <v-checkbox
                        class="pa-0"
                        label="구매 알림"
                        hide-details
                        v-model="mydata.latest"
                ></v-checkbox>

                <v-checkbox
                        class="pa-0"
                        label="인기 상품"
                        hide-details
                        v-model="mydata.best"
                ></v-checkbox>

                <v-checkbox
                        class="pa-0"
                        label="조회수 알림 (상세페이지 전용)"
                        hide-details
                        v-model="mydata.vcount"
                ></v-checkbox>

                <v-checkbox
                        class="pa-0"
                        label="구매수 알림 (상세페이지 전용)"
                        hide-details
                        v-model="mydata.bcount"
                ></v-checkbox>

            </v-card>
            -->
        </div>

        <div style="position: absolute;top:32px;right:15px;">
            <v-btn class="mr-2"
                   depressed
                   dark
                   color="grey"
                   @click="load"
            >
                취소
            </v-btn>
            <v-btn
                    depressed
                    dark
                    color="#EC407A"
                    @click="saveSettings"
            >
                저장
            </v-btn>
        </div>


    </div>

</template>
<script>
    export default {
        props:{
            mode:{
                type: String
            },
            company_code:{
                type: String
            },
            min_ymd:{
                type: String
            },
        },
        components: {
        },
        watch:{
            company_code: function () {
                this.load()
            }
        },
        mounted() {
            this.load()
        },
        data: () => ({
            menu:false,
            menu2:false,
            mydata:null,
        }),
        methods:{
            load: function(){
                var that = this
                var url = "/api/more/get/ticker/page_info?platform=" + this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile'){
                    url += '_web'
                }
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.mydata = res.data
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            saveSettings: function () {
                if(!!!this.mydata.start_ymd){
                    this.$event.$emit('alert', '노출 시작일을 확인해주세요')
                    return
                }

                if(this.mydata.start_ymd < this.min_ymd){
                    this.$event.$emit('alert', '노출 시작일을 확인해주세요')
                    return
                }

                if(!!this.mydata.end_ymd && (this.mydata.start_ymd > this.mydata.end_ymd)){
                    this.$event.$emit('alert', '노출 기간을 확인해주세요')
                    return
                }

                this.mydata.main = this.mydata.main?1:0;
                this.mydata.category = this.mydata.category?1:0;
                this.mydata.detail = this.mydata.detail?1:0;

                var page = this.mydata.main + this.mydata.category + this.mydata.detail
                if(page == 0){
                    this.$event.$emit('alert', '노출 지면을 한개 이상 설정해주세요')
                    return
                }

                var platform = this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile'){
                    platform += '_web'
                }
                this.mydata.platform = platform
                if(!!this.company_code){
                    this.mydata.company_code = this.company_code
                }

                var that = this
                this.$event.$emit('confirm', '노출 설정', '노출 설정을 변경하시겠습니까?', function () {
                    var url = "/api/more/update/ticker/page_info"
                    that.$http.post(url, that.mydata).then(res=>{
                        if(res.status == '200'){
                            that.load()
                            that.$event.$emit('alert','노출 설정이 변경되었습니다')
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert','오류가 발생했습니다')
                    })
                })

            }
        }
    }
</script>
<style scoped>
</style>
