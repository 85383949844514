<template>
    <div>
        <v-card class="mcard">

            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="대행 계정 현황"/>
                </v-card-title>
                <v-card-text class="stitle">
                    요약 리포트에서 행을 클릭하면 요약 리포트 하단에 상세 내역 리포트가 나옵니다.
                </v-card-text>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <v-select
                            v-model="form.agency"
                            :items="agency_items"
                            item-text="value"
                            item-value="code"
                            label="대행사"
                            return-code
                            outlined
                            hide-details
                            dense
                    ></v-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.agency" style="height: 500px">
            </div>

            <div class="pa-4" v-if="admin == 0 || !!form.agency">
                <v-row class="mt-3">
                    <v-col cols="3">
                        <v-select
                                label="조회년도"
                                :items="y_list"
                                v-model="form.yyyy"
                                dense
                                outlined
                                hide-details
                        ></v-select>
                    </v-col>
                </v-row>

                <div class="pt-3">
                    <div class="d-flex">
                        <div style="width:40px;margin:auto;">
                            <v-icon color="teal">mdi-text-subject</v-icon>
                        </div>
                        <div class="flex-grow-1" style="margin:auto;">리포트 요약</div>
                        <div style="width:110px;">
                            <v-btn block
                                   depressed
                                   @click="downloadSummaryCSV"
                            >
                                다운로드</v-btn>
                        </div>
                    </div>
                </div>

                <v-row no-gutters class="mt-3">
                    <v-layout column style="width:calc(100vw - 300px)">
                        <div class="d-flex" style="overflow: auto">

                            <div class="flex-grow-1">
                                <v-simple-table
                                        class="row-pointer"
                                        style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                                >
                                    <template v-slot:default>
                                        <thead style="background-color: #eee"
                                        >
                                        <tr>
                                            <th class="text-center bb bt br">기간</th>
                                            <th class="text-center bb bt br">PV</th>
                                            <th class="text-center bb bt br">비용</th>
                                            <th class="text-center bb bt br">계정 수</th>
                                            <th class="text-center bb bt br">상품 수</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-if="current != null && current.length == 0">
                                            <td class="text-center" colspan="5" style="color: #888">데이터가 없습니다.</td>
                                        </tr>
                                        <tr v-else v-for="(item,i) in current" :key="i" @click="clickList(item)">
                                            <td class="text-center bb">{{ item.ym }}</td>
                                            <td class="text-center bb">{{ item.pv.price() }}</td>
                                            <td class="text-center bb">{{ item.cost.price() }}</td>
                                            <td class="text-center bb">{{ item.account_cnt.price() }}</td>
                                            <td class="text-center bb">{{ item.prod_cnt.price() }}</td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="2" class="text-center sum bbt bbb">합계</td>
                                            <td class="text-center sum bbt bbb">
                                                <span>{{total_cost.price()}}</span>
                                            </td>
                                            <td colspan="2" class="text-center sum bbt bbb"></td>
                                        </tr>
                                        </tfoot>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </v-layout>
                </v-row>

                <div v-if="!!selected">
                    <div class="pt-9">
                        <div class="d-flex">
                            <div style="width:40px;margin:auto;">
                                <v-icon color="teal">mdi-text-subject</v-icon>
                            </div>
                            <div class="flex-grow-1" style="margin:auto;"><span class="mr-3">{{ selected.ym }}</span>상세 내역</div>
                            <div style="width:110px;">
                                <v-btn block
                                       depressed
                                       @click="downloadCSV"
                                >
                                    다운로드</v-btn>
                            </div>
                        </div>
                    </div>

                    <v-row no-gutters class="mt-3">
                        <v-layout column style="width:calc(100vw - 300px)">
                            <div class="d-flex" style="overflow: auto">

                                <div class="flex-grow-1">
                                    <v-simple-table
                                            style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                                    >
                                        <template v-slot:default>
                                            <thead style="background-color: #eee"
                                            >
                                            <tr>
                                                <th class="text-center bb bt br">사이트명</th>
                                                <th class="text-center bb bt br">URL</th>
                                                <th class="text-center bb bt br">상품</th>
                                                <th class="text-center bb bt br">PV</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item,i) in detail" :key="i">
                                                <td class="text-center bb">{{ item.company_name }}</td>
                                                <td class="text-center bb">{{ item.site_url }}</td>
                                                <td class="text-center bb">{{ item.prod }}</td>
                                                <td class="text-center bb">{{ item.pv.price() }}</td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colspan="3" class="text-center sum bbt bbb">합계</td>
                                                <td class="text-center sum bbt bbb">
                                                    <span>{{selected.pv.price()}}</span>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </div>
                        </v-layout>
                    </v-row>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import {mapGetters} from "vuex";
    export default {
        computed:
            mapGetters({
                agency: 'agency',
                admin: 'admin',
            })
        ,
        components: {
            MainTitle,
        },
        watch:{
            "form.agency":function () {
                this.load()
            },
            "form.yyyy":function () {
                this.load()
            },
            admin:function () {
                this.selectload()
                this.load()
            }
        },
        data: () => ({
            agency_items: [
                { value: '선택', code: '' },
            ],
            form: {
                agency : '',
                yyyy: '2023'
            },
            y_list: ["2023","2022","2021","2020"],
            current:null,
            total_cost: 0,
            detail:[],
            selected: null
        }),
        mounted() {
            this.selectload()
            this.load()
        },
        methods:{
            selectload:function() {
                if(this.admin == -1)
                    return

                var that = this
                var url = "/api/more/get/codes?type=agency"
                this.$http.get(url).then(res=>{
                    res.data.forEach(function(e, i){
                        that.agency_items.push({code: e.code, value: e.code_name})
                    })
                }).catch((err)=> {
                })
            },
            load:function () {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.agency)
                    return

                var that = this
                var url = "/api/more/get/agency/current?yyyy=" + this.form.yyyy
                if(!!this.form.agency){
                    url += "&agency=" + this.form.agency
                }
                this.$http.get(url).then(res=>{
                    this.current = res.data
                    this.total_cost = 0
                    res.data.forEach(function(e, i){
                        that.total_cost = that.total_cost + e.cost
                    })
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
                this.selected = null
            },
            clickList:function(item) {
                this.selected = item

                var url = "/api/more/get/agency/detail?ym=" + item.ym
                if(!!this.form.agency){
                    url += "&agency=" + this.form.agency
                }
                this.$http.get(url).then(res=>{
                    this.detail = res.data
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            downloadSummaryCSV:function () {
                var url = "/api/more/report/agency/summary_csv?yyyy=" + this.form.yyyy
                if(!!this.form.agency){
                    url += "&agency=" + this.form.agency
                }
                location.href = url
            },
            downloadCSV:function () {
                var url = "/api/more/report/agency/csv?ym=" + this.selected.ym
                if(!!this.form.agency){
                    url += "&agency=" + this.form.agency
                }
                location.href = url
            },
        }
    };
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 12px;
        color:  #000;
        font-weight: 600;
    }

    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }

</style>
