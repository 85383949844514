<template>
    <div>
        <div class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="소셜 리뷰 등록"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4 pb-3" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="review"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <div v-else class="pa-4">
              <review-list :company_code="form.company_code" option="list" :key="flag"/>
            </div>

        </div>

    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import { mapGetters } from 'vuex'
    import MySelect from "../../common/MySelect";
    import ReviewList from "@/components/page/manage/ReviewList";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        watch:{
            admin: function () {
                this.flag++
                this.load()
            },
            "form.company_code": function () {
                this.flag++
                this.load()
            },
        },
        components: {
            ReviewList,
            MainTitle,
            MySelect,
        },
        mounted() {
            this.load()
        },
        methods:{
            load: function() {
                this.tab = 0
            },
        },
        data: () => ({
            form: {
                company_code: ''
            },
            params:{},
            flag:0,
        })
    }
</script>
