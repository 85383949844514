<template>
        <div>
            <v-snackbar
                    :timeout="3000"
                    v-model="snackbar"
                    class="pb-8"
                    multi-line
            >
                {{ text }}
                <v-btn
                        color="pink"
                        text
                        @click="snackbar = false"
                >
                    Close
                </v-btn>
            </v-snackbar>
        </div>
</template>
<script>
    export default {
        data: () => ({
            snackbar: false,
            text: ''
        }),
        methods:{
            toast:function (v) {
                if(!!!v){
                    v = '오류가 발생했습니다.'
                }
                this.text = v
                this.snackbar = true

          }
        },
        mounted() {

            this.$event.$on('my-toast', this.toast)
        }
    }
</script>