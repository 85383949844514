<template>

    <div>

        <v-text-field
                :label="title"
                v-model="mdate"
                style="font-size: 0.9em"
                readonly
                outlined
                dense
                hide-details
                @click="selRangeDlg()"
        ></v-text-field>

        <v-bottom-sheet v-model="sel_sheet">
            <v-sheet class="text-center">

                <main-title title="기간을 선택해주세요" class="pt-4" style="font-size: 1.3em"/>

                <v-btn
                        class="mt-6"
                        text
                        color="red"
                        @click="sel_sheet = !sel_sheet"
                >닫기</v-btn>

                <v-btn
                        class="mt-6 mr-4"
                        text
                        color="teal"
                        @click="sel_date_ok()"
                >선택</v-btn>

                <div class="py-8">

                    <v-layout column align-center>
                        <vc-date-picker
                                is-inline
                                mode='range'
                                v-model='range'
                        />
                    </v-layout>
                </div>
            </v-sheet>
        </v-bottom-sheet>

    </div>

</template>
<script>
    import MainTitle from "./MainTitle";
    export default {
        props:{
            value:{
                type:String,
                default:''
            },
            title:{
                type:String,
                default:''
            },
        },
        components: {
            MainTitle,
        },
        mounted() {
            this.mdate = this.value
        },
        data: () => ({

            sel_sheet:false,
            tmp_date_func:null,

            range: {
                start: new Date(2018, 0, 16), // Jan 16th, 2018
                end: new Date(2018, 0, 19)    // Jan 19th, 2018
            },

            mdate:'',

            mydata:[
                {opt:'0',date:'2020.02.02 ~ 2020.03.03',show:false},
                {opt:'0',date:'2020.02.01 ~ 2020.03.03',show:false},
            ]
        }),

        methods:{

            sel_date_ok:function() {
                this.tmp_date_func()
            },

            selRangeDlg:function() {
                this.sel_sheet = true

                try {
                    var d = this.mdate.split('~')
                    var d1 = this.$moment(d[0].trim()).toDate()
                    var d2 = this.$moment(d[1].trim()).toDate()

                    this.range.start = d1
                    this.range.end = d2
                } catch (e) {
                    this.range["start"] = new Date()
                    this.range["end"] = new Date()
                }

                var v = this
                this.tmp_date_func = function () {
                    var d1 = this.$moment(v.range.start).format('YYYY.MM.DD').toString()
                    var d2 = this.$moment(v.range.end).format('YYYY.MM.DD').toString()

                    v.mdate = d1 + ' ~ ' + d2
                    //console.log(this.tmp_date)
                    v.sel_sheet = false

                    v.$emit('input', v.mdate)
                }

            }
        }
    }
</script>
<style scoped>
    .main-title {
        margin: 8px 8px;
        font-weight: 500;
        font-size:1em;
        color:#666;
    }
</style>