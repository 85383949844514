<template>

    <div>
        <div class="limiter">

            <div class="container-login100">

                <div class="wrap-join100 p-l-50 p-r-50 p-t-77 p-b-30">

                    <div class="login100-form">
                        <div class="login100-form-title p-b-55">
                            <v-img class="d-inline-flex"
                                   :src="logo"
                                   max-width="86"
                                   height="21"
                                   @click="goHome"
                                   style="cursor:pointer;"
                            ></v-img>
                            <span class="m-l-10">필수 정보 입력</span>
                        </div>

                        <div style="width:100%">
                            <v-form
                                    style="width: 100%"
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.manager"
                                                :rules="nameRules"
                                                label="담당자"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-text-field
                                                v-model="formData.phone"
                                                :rules="phoneRules"
                                                label="담당자 휴대폰 번호"
                                                outlined
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="col-8 pt-0">
                                        <v-text-field
                                                v-model="formData.email"
                                                :rules="emailRules"
                                                label="담당자 이메일"
                                                @keyup="enableBtn"
                                                outlined
                                                :readonly="auth"
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pl-0 pl-3">
                                        <v-btn depressed color="#566270" block
                                               @click="sendEmail"
                                               :dark="!disEmailBtn"
                                               :disabled="disEmailBtn"
                                               dense
                                        >인증메일 발송</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="col-8 pt-0">
                                        <v-text-field
                                                v-model="formData.code"
                                                label="인증번호"
                                                :disabled="need"
                                                outlined
                                                hide-details
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pl-3">
                                        <v-btn v-if="auth == false" depressed color="#29d1cf" block
                                               @click="onAuth"
                                               :disabled="need"
                                               outlined
                                               dense
                                        >인증번호 확인</v-btn>
                                        <v-btn v-else depressed dark
                                               color="#29d1cf" block
                                               dense
                                        ><v-icon dark v-if="auth == true">mdi-check-circle-outline</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                                <div class="w-full">
                                    <div class="my-2">
                                        <v-alert
                                                dense
                                                type="warning"
                                                color="grey"
                                                outlined
                                                style="font-size:0.8em"
                                        >
                                            <strong style="color:#666;font-size:14px">인증메일이 오지 않나요?</strong><br>
                                            스팸 메일로 분류된 것은 아닌지 확인해 주세요.<br>
                                            특히 Gmail 을 사용하신다면  스팸함을 확인 바랍니다.<br>
                                            스팸 메일함에도 인증메일이 없다면, 다시 한 번 ‘인증 메일 받기'를 눌러주세요
                                        </v-alert>
                                        <!--
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <span style="color:rgba(0, 0, 0, 0.6);font-size:0.75rem;font-weight:500;letter-spacing:0.0892857143em;padding:5px" v-on="on">인증메일이 오지 않나요?</span>
                                            </template>
                                            <span>스팸메일로 분류된 것은 아닌지 확인해주세요.<br>스팸 메일함에도 메일이 없다면,<br>다시 한 번 ‘인증메일 발송'을 눌러주세요</span>
                                        </v-tooltip>-->
                                    </div>
                                </div>
                            </v-form>

                            <v-btn v-if="!loading"
                                   depressed x-large dark color="#29d1cf" block
                                   class="mt-8"
                                   @click="onJoin"
                            >저장 후 시작하기</v-btn>
                            <v-btn v-else depressed x-large dark color="#29d1cf" block
                                   class="mt-8"
                            >
                                <v-progress-circular
                                        indeterminate
                                        color="white"
                                ></v-progress-circular>
                            </v-btn>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <my-confirm></my-confirm>
        </div>
    </div>
</template>
<script>
    import MyConfirm from "../common/MyConfirm";
    require('@/assets/css/main.css')
    require('@/assets/css/util.css')

    export default {
        components: {MyConfirm},
        created() {
        },
        mounted() {
            //console.log('aa')
            //console.log(this.$event)
        },
        comments: {
            MyConfirm
        },
        data: () => ({
            logo: require('@/assets/logo.png'),
            need: true,
            auth: false,
            idError:false,
            idErrorMsg:'',
            disEmailBtn:true,
            formData:{
                manager:'',
                email:'',
                phone:'',
            },
            /*
            rules: {
                required: v => !!v || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => ('The email and password you entered don\'t match'),
            },
            idRules: [
                v => !!v || '필수 정보입니다',
                v => (v.length >= 5 && v.length <= 20) || '5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다',
                v => /^[a-z0-9\-_]+$/.test(v) ||
                    '5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다',
            ],
            */

            nameRules: [
                v => !!v || '필수 정보입니다'
            ],

            phoneRules: [
                v => !!v || '필수 정보입니다',
                v => (v.replace(/-/gi,"").length >= 10 && v.replace(/-/gi,"").length <= 11) || '휴대폰 번호 형식이 올바르지 않습니다',
                v => /^[0-9\-]+$/.test(v) ||
                    '휴대폰 번호 형식이 올바르지 않습니다'
            ],

            emailRules: [
                v => !!v || '필수 정보입니다',
                v => /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v) ||
                    '이메일 형식이 올바르지 않습니다'
            ],

            valid:false,
            loading:false,
        }),
        methods:{
            goHome:function(){
                this.$router.push({path:'/'})
            },
            enableBtn:function(){
                var v = this.formData.email
                if(!!!v) {
                    this.disEmailBtn = true
                }else if(!/^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v)){
                    this.disEmailBtn = true
                }else
                    this.disEmailBtn = false
            },
            sendEmail:function(){
                if(this.auth){
                    this.$event.$emit('alert','이미 인증 되었습니다')
                    return
                }

                var url = "/api/user/send/code"
                //console.log(this.formData)
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.need = false
                        this.$event.$emit('alert','이메일이 발송되었습니다')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            onAuth:function(){
                var url = "/api/user/auth"
                //console.log(this.formData)
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.auth = true
                        this.$event.$emit('alert','인증 되었습니다')
                    }
                }).catch((err)=>{
                    var msg = err.response.data.msg
                    if(msg == 'NOTVALID'){
                        msg = '인증번호를 다시 확인해주세요'
                    }else{
                        msg = '오류가 발생했습니다'
                    }
                    this.$event.$emit('alert',msg)
                })
            },
            onJoin:function () {
                if (!this.$refs.form.validate()) {
                    window.scrollTo(0, 0)
                    return
                }

                if(this.idError) {
                    window.scrollTo(0, 0)
                    return
                }

                if(!this.auth){
                    this.$event.$emit('alert', '이메일 인증을 완료해주세요')
                    return
                }

                this.loading = true
                var url = "/api/user/agree"
                this.$http.post(url, this.formData).then(res=>{
                    if(res.data.msg == "OK"){
                        this.loading = false
                        this.$store.commit('agreeYn', {value:'Y'})
                        this.$router.push({path:'/'})
                    }
                }).catch((err)=>{
                    this.loading = false
                    this.$event.$emit('alert','오류가 발생했습니다.')
                })
            }
        }
    }
</script>