<template>
    <div>
        <div style="font-size: 0.8em;color: #666;">
            <div class="mb-1">* 이용 요금은 VAT를 포함하고 있습니다.</div>
            <div>* 결제 기한은 모든 계약이 동일하게 매월 1일 ~ 7일까지로 미납시 7일 후 운영이 자동 OFF 됩니다.</div>
        </div>

        <v-radio-group v-model="std"
                       row
                       hide-details
                       class="pt-5"
        >
            <v-radio label="납부 월" value="pay"></v-radio>
            <v-radio label="이용 월" value="use"></v-radio>
        </v-radio-group>
        <v-row class="mt-3">
            <v-col cols="3">
                <v-select
                        label="조회기간"
                        :items="yyyymm"
                        v-model="form.ym"
                        dense
                        outlined
                        hide-details
                ></v-select>
            </v-col>
            <v-col style="align-self: center">
                <div v-if="std == 'pay'" style="font-size: 0.85em;">* 해당 월에 납부 완료된 계약이 조회됩니다.</div>
                <div v-else style="font-size: 0.85em;">* 해당 월에 솔루션을 이용한 모든 유료 계약이 조회됩니다.</div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="4">
                <v-card elevation="0" v-ripple class="mdiv3"
                >
                    <div class="pa-3">
                        <div class="dtitle">{{pay_card.complete.price()}}</div>
                        <div class="dsub">납부 요금</div>
                    </div>
                    <v-icon class="dicon">mdi-currency-krw</v-icon>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card elevation="0" v-ripple class="mdiv3"
                >
                    <div class="pa-3">
                        <div v-if="std == 'use'" class="dtitle">{{pay_card.ing.price()}}</div>
                        <div v-else class="dtitle">-</div>
                        <div class="dsub">결제중 요금</div>
                    </div>
                    <v-icon class="dicon">mdi-currency-krw</v-icon>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card elevation="0" v-ripple class="mdivred"
                >
                    <div class="pa-3">
                        <div v-if="std == 'use'" class="dtitle">{{pay_card.delay.price()}}</div>
                        <div v-else class="dtitle">-</div>
                        <div class="dsub">미납 요금</div>
                    </div>
                    <v-icon class="dicon">mdi-currency-krw</v-icon>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-3">
            <!--
            <v-col cols="2">
                <v-select
                        label="대행사"
                        :items="usage_month"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                ></v-select>
            </v-col>
            -->
            <v-col cols="4">
                <my-select
                        type="all"
                        :value="form.company_code"
                        @input="(newCC) => {form.company_code = newCC}"
                ></my-select>
            </v-col>
            <v-col cols="2">
                <v-select
                        label="상품"
                        v-model="form.product_type"
                        :items="p_type"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="2">
                <v-select
                        label="납부상태"
                        v-model="form.pay_status"
                        :items="p_status"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                ></v-select>
            </v-col>
            <!--
            <v-col cols="2">
                <v-btn depressed>조회</v-btn>
            </v-col>
            -->
            <v-spacer/>
            <v-col cols="2">
                <v-btn block
                       depressed
                       @click="downloadCSV"
                >
                    다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-5">
            <v-layout column style="width:calc(100vw - 300px)">
                <div class="d-flex" style="overflow: auto">

                    <div class="flex-grow-1">
                        <v-simple-table
                                style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                        >
                            <template v-slot:default>
                                <thead style="background-color: #eee"
                                >
                                <tr>
                                    <th class="text-center bb bt br">대행사</th>
                                    <th class="text-center bb bt br">사이트명</th>
                                    <th class="text-center bb bt br">상품</th>
                                    <th class="text-center bb bt br">과금방식</th>
                                    <th class="text-center bb bt br">이용기간</th>
                                    <th class="text-center bb bt br">이용요금</th>
                                    <th class="text-center bb bt br">납부상태</th>
                                    <th class="text-center bb bt br">납부일시</th>
                                    <th class="text-center bb bt br">결제수단</th>
                                    <th class="text-center bb bt br">담당자 이메일</th>
                                    <th class="text-center bb bt">담당자 연락처</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!!!pay_list || pay_list.length == 0">
                                    <td class="text-center bb" colspan="11" style="color: #888">데이터가 없습니다.</td>
                                </tr>
                                <tr v-for="(item, i) in pay_list" :key="i">
                                    <td class="text-center bb">{{item.comment2}}</td>
                                    <td class="text-center bb">{{item.company_name}}</td>
                                    <td class="text-center bb" v-html="getProductType(item.product_type)"></td>
                                    <td class="text-center bb" v-html="getChargeType(item.charge_type)"></td>
                                    <td class="text-center bb">
                                        <span v-html="$_ymd(item.term_start)"></span><br>~ <span v-html="$_ymd(item.term_end)"></span>
                                    </td>
                                    <td class="text-center bb">{{ item.amount.price() }}</td>
                                    <td v-if="item.pay_status == 'C'" class="text-center bb">납부 완료</td>
                                    <td v-else-if="item.pay_status == 'I'" class="text-center bb">
                                        <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;color: blue;"
                                              @click="showChangeDialog(item)"
                                        >결제 진행중</span>
                                    </td>
                                    <td v-else-if="item.pay_status == 'N'" class="text-center bb">
                                        <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;color: red;"
                                              @click="showChangeDialog(item)"
                                        >미납</span>
                                    </td>
                                    <td v-else class="text-center bb">-</td>
                                    <td class="text-center bb" v-html="getDate(item.payed_at)"></td>
                                    <td v-if="item.pay_method == 'card'" class="text-center bb">
                                        카드결제
                                    </td>
                                    <td v-else-if="item.pay_method == 'vbank'" class="text-center bb">
                                        가상계좌
                                    </td>
                                    <td v-else class="text-center bb">{{item.pay_method}}</td>
                                    <td class="text-center bb">{{item.email}}</td>
                                    <td class="text-center bb">{{item.phone}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-if="!!pay_total && !!pay_total.amount">
                                    <td colspan="4" class="text-center sum bbt bbb">합계</td>
                                    <td class="text-center sum bbt bbb" v-html="pay_list.length"></td>
                                    <td class="text-center sum bbt bbb">{{ pay_total.amount.price() }}</td>
                                    <td colspan="5" class="text-center sum bbt bbb"></td>
                                </tr>
                                </tfoot>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
            </v-layout>
        </v-row>

        <v-dialog v-model="change_dlg" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">납부 상태 변경</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form2"
                            lazy-validation
                            class="mb-5"
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="3">
                                    <span>사이트명</span>
                                </v-col>
                                <v-col>
                                    <span class="bold">{{form2.company_name}}</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>상품</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getProductType(form2.product_type)"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>과금방식</span>
                                </v-col>
                                <v-col>
                                    <span class="bold" v-html="getChargeType(form2.charge_type)"></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>이용기간</span>
                                </v-col>
                                <v-col class="bold">
                                    <span v-html="$_ymd2(form2.term_start)"></span> ~ <span v-html="$_ymd2(form2.term_end)"></span>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col cols="3" style="vertical-align: middle;">
                                    <span>이용요금</span>
                                </v-col>
                                <v-col>
                                    <v-text-field style="max-width: 230px"
                                            v-model="form2.amount"
                                            :rules="required"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>납부상태</span>
                                </v-col>
                                <v-col>
                                    <span class="bold">현재 |</span> <span style="color:#000;" v-html="getPayStatus(form2.pay_status)"></span>
                                    <br>
                                    <span class="bold">변경 |</span> <span style="color:#000;">납부 완료</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>결제수단</span>
                                </v-col>
                                <v-col>
                                    <span class="bold">기타</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="change_dlg = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="changePayedStatus">변경하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import MySelect from "../../common/MySelect";
    export default {
        mounted() {
            this.load()
        },
        components:{
            MySelect
        },
        watch: {
            std:function() {
                this.dataload()
            },
            "form.ym":function() {
                this.dataload()
            },
            "form.company_code":function() {
                this.dataload()
            },
            "form.product_type":function() {
                this.dataload()
            },
            "form.pay_status":function() {
                this.dataload()
            },
        },
        data: () => ({
            std: 'pay',
            yyyymm: null,
            form: {
                ym: '',
                company_code: '',
                product_type: '',
                pay_status: '',
            },
            p_type:[
                {
                    id: "",
                    name: "전체"
                },
                {
                    id: "P",
                    name: "패널"
                },
                {
                    id: "T",
                    name: "티커"
                },
                {
                  id: "R",
                  name: "추천"
                },
                {
                  id: "S",
                  name: "서치"
                }
            ],
            p_status:[
                {
                    id: "",
                    name: "전체"
                },
                {
                    id: "I",
                    name: "결제 진행중"
                },
                {
                    id: "N",
                    name: "미납"
                },
                {
                    id: "C",
                    name: "납부완료"
                }
            ],
            pay_list:null,
            pay_total:null,
            pay_card:{
                complete: 0,
                ing: 0,
                delay: 0
            },
            change_dlg: false,
            form2: {},
            editable: false,
            required: [
                v => !!v || '필수 정보입니다'
            ],
        }),
        methods:{
            load: function () {
                var url = "/api/more/get/yyyymm"
                this.$http.get(url).then(res => {
                    this.yyyymm = res.data
                    this.form.ym = res.data[0]
                })
            },
            dataload: function() {
                var that = this
                if(this.std == 'pay'){
                    var url1 = "/api/more/get/payment_complete_card"
                    this.$http.post(url1, this.form).then(res=>{
                        this.pay_card = res.data
                    }).catch((err)=>{
                        this.$event.$emit('alert',"오류가 발생했습니다")
                    })
                    var url2 = "/api/more/get/payment_complete"
                    this.$http.post(url2, this.form).then(res=>{
                        this.pay_list = res.data
                        this.pay_total = {}
                        this.pay_list.forEach(function (e, i) {
                            if (i == 0) {
                                that.pay_total['amount'] = 0;
                            }
                            that.pay_total['amount'] += e['amount'];
                        })
                    }).catch((err)=>{
                        this.$event.$emit('alert',"오류가 발생했습니다")
                    })
                } else if(this.std == 'use') {
                    var url1 = "/api/more/get/payment_card"
                    this.$http.post(url1, this.form).then(res=>{
                        this.pay_card = res.data
                    }).catch((err)=>{
                        this.$event.$emit('alert',"오류가 발생했습니다")
                    })

                    var url2 = "/api/more/get/payment_status"
                    this.$http.post(url2, this.form).then(res=>{
                        this.pay_list = res.data
                        this.pay_total = {}
                        this.pay_list.forEach(function (e, i) {
                            if (i == 0) {
                                that.pay_total['amount'] = 0;
                            }
                            that.pay_total['amount'] += e['amount'];
                        })
                    }).catch((err)=>{
                        this.$event.$emit('alert',"오류가 발생했습니다")
                    })
                }
            },
            getProductType: function (type) {
                if(type == 'P') {
                    return '패널'
                }else if(type == 'T') {
                    return '티커'
                }else if(type == 'R') {
                  return '추천'
                }else if(type == 'S') {
                  return '서치'
                }else {
                    return '-'
                }
            },
            getChargeType: function (type) {
                if(type == 'C') {
                    return 'CPS'
                }else if(type == 'I') {
                    return '노출형'
                }else if(type == 'F') {
                    return '고정과금'
                }else if(type == 'P') {
                  return 'PV당 단가'
                }else {
                    return '-'
                }
            },
            getPayStatus: function (type) {
                if(type == 'C') {
                    return '납부 완료'
                }else if(type == 'I') {
                    return '결제 진행중'
                }else if(type == 'N') {
                    return '미납'
                }else {
                    return '-'
                }
            },
            getDate: function(time) {
                if(!!time)
                    return this.$moment(time).format('YYYY.MM.DD HH:mm:hh')
            },
            showChangeDialog: function(item) {
                this.form2 = Object.assign({}, item)
                this.change_dlg = true
            },
            changePayedStatus: function() {
                if (!this.$refs.form2.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/change/payed_status"
                this.$http.post(url, this.form2).then(res=>{
                    if(res.status == '200'){
                        that.dataload()
                        that.change_dlg = false
                        that.$event.$emit('alert','납부상태가 변경되었습니다')
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert','오류가 발생했습니다')
                })
            },
            downloadCSV: function () {
                var url = "/api/more/report/payment/csv?"
                var params = new URLSearchParams(this.form).toString();
                if (this.std == 'pay') {
                    params += '&type=1'
                    location.href = url + params
                }else if (this.std == 'use') {
                    params += '&type=2'
                    location.href = url + params
                }
            }
        }
    }
</script>
<style scoped>

    .mdivred {
        background-color: #d43835;
        position: relative;
        overflow: hidden;
    }

    .mdiv2 {
        background-color: #a38cc6;
        position: relative;
        overflow: hidden;
    }
    .mdiv {
        background-color: #6ab8f7;
        position: relative;
        overflow: hidden;
    }
    .mdiv3 {
        background-color: #4de5d5;
        position: relative;
        overflow: hidden;
    }
    .dtitle {
        font-family: "Roboto", "Helvetica Neue", "Helvetica";
        font-weight: 500;
        color:white;
        font-size:40px;
    }
    .dsub {
        font-size: 1em;
        color:white;
    }
    .dicon {
        font-size: 100px;
        opacity: 0.3;
        position: absolute;
        right:-40px;
        bottom:0;
        color:#fff;
    }

    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .bbb {
        border-bottom: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 11px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }

    .bold {font-weight: 600;}
</style>