<template>

    <div class="d-flex mt-4">

        <v-card
                v-if="!!mydata"
                class="pa-4 pb-6 mr-3"
                style="overflow: hidden;width:460px;position:relative;"
                outlined
        >
            <div style="position: absolute;top:12px;right:12px;">
                <v-btn class="mr-2"
                       depressed
                       dark
                       color="grey"
                       @click="load"
                >
                    취소
                </v-btn>
                <v-btn
                        depressed
                        dark
                        color="#EC407A"
                        @click="saveSettings"
                >
                    저장
                </v-btn>
            </div>

            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
            >
                <div class="pb-4 mt-4">
                    노출 타이밍 설정
                </div>

                <v-subheader class="pl-1" style="font-weight: 600;color:#333">1. 타이밍 설정</v-subheader>

                <div style="font-size: 0.8em" class="pl-3">
                    <table style="width: 100%">
                        <tr>
                            <td class="text-left">
                                - 메시지 노출시간 (Show)
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.show_time"
                                        style="width: 80px"
                                        suffix="초"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                - 다음 메시지까지 미노출 시간 (Hidden)
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.hide_time"
                                        style="width: 80px"
                                        suffix="초"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-left">
                                - 닫기 클릭 후 미노출 시간
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.noshow"
                                        style="width: 80px"
                                        suffix="시간"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </div>

                <v-subheader class="pl-1" style="font-weight: 600;color:#333">2. 행동 기준 설정</v-subheader>
                <div style="font-size: 0.8em" class="pl-3">
                    <table style="width: 100%">
                        <tr>
                            <td class="text-left">
                                - 스크롤 탐색 조건<br>(설정 이상 스크롤을 내리면 노출됩니다.)
                            </td>
                            <td class="pa-2" style="float: right">
                                <v-text-field
                                        v-model="mydata.scroll_deep"
                                        style="width: 80px"
                                        suffix="%"
                                        outlined
                                        dense
                                        hide-details
                                        :rules="required"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>
                </div>
            </v-form>

            <v-divider class="mt-3"></v-divider>

            <div class="pb-4 mt-4">
                디자인 설정
                <v-btn style="margin-left:20px !important"
                    color="teal"
                    @click="preview"
                    outlined
                >
                    미리보기
                </v-btn>
            </div>

            <!--
            <v-subheader>레이아웃 선택</v-subheader>

            <div style="font-size: 0.8em" class="pl-3">
                <v-select
                        :items="layout"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                ></v-select>
            </div>
            -->

            <v-subheader class="pl-1" style="font-weight: 600;color:#333">1. 컬러 설정</v-subheader>

            <div style="font-size: 0.8em" class="pl-3" :key="flag">
                <div class="pb-2">
                    - 타이틀 배경 및 윤곽선 컬러
                </div>
                <my-color v-model="mydata.header_color" />

                <div class="pb-2 pt-4">
                    - 타이틀 텍스트 컬러
                </div>
                <my-color v-model="mydata.header_text_color" />

                <div class="pb-2 pt-4">
                    - 강조 텍스트 컬러
                </div>
                <my-color v-model="mydata.point_text_color" />

            </div>

            <v-subheader class="pl-1 mt-4" style="font-weight: 600;color:#333">2. 노출 영역 선택</v-subheader>
            <div class="ticker_container">
              <div class="ticker_container_row">
                <div :class="mydata.position==3?'ticker_item_selected':'ticker_item'" @click="changePosition(3)"></div>
                <div :class="mydata.position==6?'ticker_item_selected':'ticker_item'" @click="changePosition(6)"></div>
                <div :class="mydata.position==4?'ticker_item_selected':'ticker_item'" @click="changePosition(4)"></div>
              </div>
              <div style="height: 2vw"></div>
              <div class="ticker_container_row">
                <div :class="mydata.position==5?'ticker_item_selected':'ticker_item'" @click="changePosition(5)"></div>
                <div :class="mydata.position==7?'ticker_item_selected':'ticker_item'" @click="changePosition(7)"></div>
              </div>
              <div style="height: 2vw"></div>
              <div class="ticker_container_row">
                <div :class="mydata.position==1?'ticker_item_selected':'ticker_item'" @click="changePosition(1)"></div>
                <div :class="mydata.position==8?'ticker_item_selected':'ticker_item'" @click="changePosition(8)"></div>
                <div :class="mydata.position==2?'ticker_item_selected':'ticker_item'" @click="changePosition(2)"></div>
              </div>
            </div>
            <v-subheader class="pl-1 mt-4" style="font-weight: 600;color:#333">3. 애니메이션 효과 선택</v-subheader>
            <div style="font-size: 0.8em" class="pl-3">
                <v-select
                        :items="animation"
                        v-model="mydata.animation"
                        item-text="value"
                        item-value="code"
                        return-code
                        dense
                        outlined
                        hide-details
                ></v-select>
            </div>
            <v-divider class="mt-5"></v-divider>

            <div class="pb-4 mt-4">
                배너형 티커 설정
            </div>

            <v-subheader class="pl-1" style="font-weight: 600;color:#333">1. 배너 이미지 등록</v-subheader>

            <div style="font-size: 0.8em" class="pl-3">
                <v-btn style="width:100%"
                       @click="show"
                       depressed
                       block
                >
                    배너 이미지 추가하기
                </v-btn>
            </div>

            <v-subheader class="pl-1 pt-3" style="font-weight: 600;color:#333">2. 등록된 이미지 관리</v-subheader>

            <div class="pl-3">
                <table class="mtable">
                    <thead>
                    <tr class="theader">
                        <th>배너 이름</th>
                        <th>관리</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!!banner_list && banner_list.length == 0">
                        <td class="text-center" colspan="62" style="color: #888">등록된 배너가 없습니다</td>
                    </tr>
                    <tr v-else v-for="(item,i) in banner_list" :key="i">
                        <td>{{item.comment}}</td>
                        <td>
                            <v-btn icon color="blue-grey lighten-1" @click="preview_banner(item)">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                            <v-btn icon color="teal" @click="update(item)">
                                <v-icon>mdi-file-document-edit-outline</v-icon>
                            </v-btn>
                            <v-btn icon color="gray" @click="deleteBanner(item)">
                                <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </v-card>

        <v-card v-if="!!comment_vcount"
                class="pa-4 pb-6 flex-grow-1 ml-3"
                style="overflow: hidden;"
                outlined
        >
            <div class="pb-4 mt-4">
                메시지 설정
            </div>


            <v-subheader>구매 알림 메시지</v-subheader>
            <v-row class="mx-1">
                <v-col cols="9" class="my-0 py-0">
                    <v-text-field
                            v-model="comment_latest.comment"
                            outlined
                            dense
                            counter="20"
                            :rules="!!comment_latest.comment?maxRules:[]"
                            placeholder="누가 이 상품을 샀어요!"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="my-0 py-0">
                    <v-btn class="pl-2 pr-2"
                           style="min-width: 45px !important;"
                           text
                           dark
                           color="grey"
                           @click="cancelMessage('latest')"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            class="pl-2 pr-2"
                            style="min-width: 45px !important;"
                            text
                            dark
                            color="#EC407A"
                            @click="saveMessage('latest')"
                    >
                        저장
                    </v-btn>
                </v-col>
            </v-row>

            <v-subheader>인기 상품 알림 메시지</v-subheader>
            <v-row class="mx-1">
                <v-col cols="9" class="my-0 py-0">
                    <v-text-field
                            v-model="comment_best.comment"
                            outlined
                            dense
                            counter="20"
                            :rules="!!comment_best.comment?maxRules:[]"
                            placeholder="지금 가장 인기있는 상품은?"
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="my-0 py-0">
                    <v-btn class="pl-2 pr-2"
                           style="min-width: 45px !important;"
                           text
                           dark
                           color="grey"
                           @click="cancelMessage('best')"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            class="pl-2 pr-2"
                            style="min-width: 45px !important;"
                            text
                            dark
                            color="#EC407A"
                            @click="saveMessage('best')"
                    >
                        저장
                    </v-btn>
                </v-col>
            </v-row>

            <v-subheader>개인화 추천 메시지</v-subheader>
            <v-row class="mx-1">
                <v-col cols="9" class="my-0 py-0">
                    <v-text-field
                            v-model="comment_pbyc.comment"
                            outlined
                            dense
                            counter="20"
                            :rules="!!comment_pbyc.comment?maxRules:[]"
                            placeholder="당신을 위해 준비했어요."
                    ></v-text-field>
                </v-col>
                <v-col cols="3" class="my-0 py-0">
                    <v-btn class="pl-2 pr-2"
                           style="min-width: 45px !important;"
                           text
                           dark
                           color="grey"
                           @click="cancelMessage('pbyc')"
                    >
                        취소
                    </v-btn>
                    <v-btn
                            class="pl-2 pr-2"
                            style="min-width: 45px !important;"
                            text
                            dark
                            color="#EC407A"
                            @click="saveMessage('pbyc')"
                    >
                        저장
                    </v-btn>
                </v-col>
            </v-row>


            <v-form
                    ref="form1"
                    v-model="valid1"
                    lazy-validation
            >
                <v-subheader>구매수 알림 메시지</v-subheader>
                <v-row class="mx-1">
                    <v-col cols="9" class="my-0 py-0">
                        <v-text-field
                                v-model="comment_bcount.title"
                                outlined
                                dense
                                counter="20"
                                :rules="!!comment_bcount.title?maxRules:[]"
                                placeholder="고객님을 위한 쇼핑 알림"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                        <v-btn class="pl-2 pr-2"
                               style="min-width: 45px !important;"
                               text
                               dark
                               color="grey"
                               @click="cancelMessage('bcount')"
                        >
                            취소
                        </v-btn>
                        <v-btn
                                class="pl-2 pr-2"
                                style="min-width: 45px !important;"
                                text
                                dark
                                color="#EC407A"
                                @click="saveMessage('bcount')"
                        >
                            저장
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-1">
                    <v-col cols="9" class="mt-0 pt-0">
                        <v-text-field
                                v-model="comment_bcount.comment"
                                prefix="최근/오늘"
                                outlined
                                dense
                                :rules="alertRules"
                                placeholder="{N}명이<br>이 상품을 구매했어요!"
                        ></v-text-field>

                    </v-col>
                </v-row>
            </v-form>

            <v-form
                    ref="form2"
                    v-model="valid2"
                    lazy-validation
            >
                <v-subheader class="mt-0">조회수 알림 메시지</v-subheader>
                <v-row class="mx-1">
                    <v-col cols="9" class="my-0 py-0">
                        <v-text-field
                                v-model="comment_vcount.title"
                                outlined
                                dense
                                counter="20"
                                :rules="!!comment_vcount.title?maxRules:[]"
                                placeholder="고객님을 위한 쇼핑 알림"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 py-0">
                        <v-btn class="pl-2 pr-2"
                               style="min-width: 45px !important;"
                               text
                               dark
                               color="grey"
                               @click="cancelMessage('vcount')"
                        >
                            취소
                        </v-btn>
                        <v-btn
                                class="pl-2 pr-2"
                                style="min-width: 45px !important;"
                                text
                                dark
                                color="#EC407A"
                                @click="saveMessage('vcount')"
                        >
                            저장
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mx-1">
                    <v-col cols="9" class="mt-0 pt-0">
                        <v-text-field
                                v-model="comment_vcount.comment"
                                prefix="최근/오늘"
                                outlined
                                dense
                                :rules="alertRules"
                                placeholder="{N}명이<br>이 상품을 봤어요!"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <div style="font-size: 0.8em;color:#999" class="mt-4">
                [TIP]<br>
                구매수 알림과 조회수 알림 메시지는 최소 숫자를 {10} 과 같이 설정하여 입력하셔야 합니다.<br>
                구매수 알림과 조회수 알림 메시지의 {N} 값은, 노출을 위한 최소 기준 값 입니다.<br>
                입력하신 N 값 이상부터 해당 메시지가 노출 됩니다.
            </div>

        </v-card>

        <v-dialog v-model="isshow" max-width="400px" style="background-color: white">
            <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                    티커 미리보기
                </v-card-title>
                <v-card-text>
                </v-card-text>

                <div class="pl-4" align="center" style="height:160px">
                  <Transition
                      :name="tanimation"
                      :enter-active-class="tanimation_enter"
                      leave-active-class="animate__animated animate__fadeOut"
                  >
                    <iframe v-if="isshow3" :src="preview_url" frameborder="0"
                            id="ifr_ticker"
                            v-on:load="iframe_loaded"
                            style="width:240px;height:160px"></iframe>
                  </transition>
                </div>

                <div class="pa-4 d-flex">
                    <v-btn class="mr-1 flex-grow-1"
                           depressed
                           dark
                           color="grey"
                           @click="closePreview"
                    >
                        닫기
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isshow2" persistent max-width="600px" style="background-color: white">
            <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                    배너 이미지 설정하기
                </v-card-title>
                <v-card-text>
                </v-card-text>

                <div class="pl-4 pr-4">

                    <v-form
                            ref="form3"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-row class="ma-0 pa-0">
                            <div class="text-right" style="width: 100%;font-size:12px;color:#f00;font-weight:600">
                                [이미지 사이즈] 가로: 240px, 세로: 130px
                            </div>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="mt-0 pt-0" style="align-self: center;color: #666;font-size: 0.9em" v-if="!!filename">
                                <span style="text-overflow: ellipsis;overflow:hidden;">
                                    {{filename}}
                                </span>
                            </v-col>
                            <v-col :cols="!!filename?8:12">
                                <v-btn class="mt-3"
                                       color="#566270"
                                       block dark depressed
                                       @click="attachFile"
                                >
                                    배너 이미지 파일 선택
                                </v-btn>
                                <input ref="file" id="file" type="file" accept="image/*" hidden
                                       @change="fileHandle">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pb-0">
                                <v-text-field class="img_view"
                                              label="배너 이름"
                                              v-model="banner_form.comment"
                                              outlined
                                              required
                                              dense
                                              counter="15"
                                              :rules="bannerRules"
                                >
                                    <template v-if="!!banner_form.image_url" v-slot:append-outer>
                                        <v-btn style="margin-top:-5px !important"
                                               color="teal"
                                               @click="showFile(banner_form.image_url)"
                                               text
                                        >이미지 보기</v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0">
                                <v-text-field
                                        label="배너 랜딩 URL"
                                        v-model="banner_form.page_url"
                                        outlined
                                        required
                                        dense
                                        :rules="required"
                                        hint="http:// 또는 https:// 를 포함해주세요"
                                        persistent-hint
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>

                <div class="pa-4 d-flex">
                    <v-btn class="mr-1 flex-grow-1"
                           depressed
                           dark
                           color="grey"
                           @click="isshow2=false"
                    >
                        취소
                    </v-btn>
                    <v-btn class="ml-1 flex-grow-1"
                           depressed
                           dark
                           color="#EC407A"
                           @click="addBanner"
                    >
                        저장
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

    </div>

</template>
<script>
    import MyColor from "./MyColor";
    export default {
        props:{
            company_code:{
                type: String
            },
        },
        components: {
            MyColor
        },
        watch:{
            company_code: function () {
                this.load()
                this.load_banner()
                this.load_message()
            }
        },
        mounted() {
            this.load()
            this.load_banner()
            this.load_message()
        },
        data: () => ({
            isshow:false,
            isshow3:false,
            tanimation: '',
            tanimation_enter: '',
            preview_url:'',
            valid:false,
            valid1:false,
            valid2:false,
            positions: [
                { value: '좌측하단(권장)', code: 1 },
                { value: '우측하단', code: 2 },
                { value: '좌측상단', code: 3 },
                { value: '우측상단', code: 4 },
            ],
            animation: [
              { value: '밝기변화', code: 'fade' },
              { value: '날아오기', code: 'bounce' },
              { value: '올라오기', code: 'fu' },
              { value: '확대/축소', code: 'zoom' },
              { value: '펄스', code: 'hb' },
              { value: '흔들기', code: 'swing' },
              { value: '뒤집기', code: 'flip' },
            ],
            mydata:null,
            flag:0,
            filename:null,
            file:null,
            fileurl : '',
            isshow2: false,
            banner_form:{
                comment: '',
                page_url: '',
                image_url: '',
                mode: ''
            },
            selected_item: {},
            banner_list: null,
            comments:null,
            comment_latest: null,
            comment_best: null,
            comment_pbyc: null,
            comment_bcount: null,
            comment_vcount: null,
            required: [
                v => !!v || '필수 입력값입니다',
            ],
            maxRules: [
                v => (v.length <= 20) || '20자 이하로 설정해주세요',
            ],
            alertRules: [
                v => !!v || '필수 입력값입니다',
                v => /{[0-9]+}/.test(v) || '문구에 반드시 최소 숫자를 설정해 주세요',
            ],
            bannerRules: [
                v => !!v || '필수 입력값입니다',
                v => (v.length <= 15) || '15자 이하로 설정해주세요',
            ],
        }),
        methods:{
            load: function () {
                var that = this
                var url = "/api/more/get/ticker/settings?platform=pc"
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.mydata = res.data
                        that.flag++
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_banner: function() {
                var that = this
                var url = "/api/more/get/ticker/banners?platform=pc"
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.banner_list = res.data
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_message: function () {
                var that = this
                var url = "/api/more/get/ticker/messages?platform=pc"
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.comments = res.data
                        that.comments.forEach(function (e, i) {
                            if(i == 0){
                                that.comment_latest = that.clone(e)
                            }else if(i == 1){
                                that.comment_best = that.clone(e)
                            }else if(i == 2){
                                that.comment_pbyc = that.clone(e)
                            }else if(i == 3){
                                that.comment_bcount = that.clone(e)
                            }else if(i == 4){
                                that.comment_vcount = that.clone(e)
                            }
                        })

                        if(!!this.$refs.form1){
                            this.$refs.form1.resetValidation()
                        }
                        if(!!this.$refs.form2){
                            this.$refs.form2.resetValidation()
                        }
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            changePosition: function(pos){
              this.mydata.position = pos
            },
            clone: function(obj){
                var output = {}
                for(var i in obj){
                    output[i] = obj[i]
                }
                return output
            },
            saveSettings: function () {
                if(!this.$refs.form.validate()){
                    return
                }

                var that = this
                var url = "/api/more/update/ticker/settings"
                this.mydata.platform = 'pc'
                if(!!this.company_code){
                    this.mydata.company_code = this.company_code
                }
                this.$http.post(url, this.mydata).then(res=>{
                    if(res.status == '200'){
                        that.$event.$emit('alert','티커 설정이 변경되었습니다')
                        that.load()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
            cancelMessage: function(type) {
                if(type == 'latest'){
                    this.comment_latest = this.clone(this.comments[0])
                }else if(type == 'best'){
                    this.comment_best = this.clone(this.comments[1])
                }else if(type == 'pbyc'){
                    this.comment_pbyc = this.clone(this.comments[2])
                }else if(type == 'bcount'){
                    this.comment_bcount = this.clone(this.comments[3])
                    this.$refs.form1.resetValidation()
                }else if(type == 'vcount'){
                    this.comment_vcount = this.clone(this.comments[4])
                    this.$refs.form2.resetValidation()
                }
            },
            saveMessage: function (type) {
                var params = {}
                if(type == 'latest'){
                    params = this.comment_latest
                }else if(type == 'best'){
                    params = this.comment_best
                }else if(type == 'pbyc'){
                    params = this.comment_pbyc
                }else if(type == 'bcount'){
                    if(!this.$refs.form1.validate()){
                        return
                    }
                    params = this.comment_bcount
                }else if(type == 'vcount'){
                    if(!this.$refs.form2.validate()){
                        return
                    }
                    params = this.comment_vcount
                }

                var that = this
                var url = "/api/more/update/ticker/message"
                this.$http.post(url, params).then(res=>{
                    if(res.status == '200'){
                        that.$event.$emit('alert','티커 메시지가 변경되었습니다')
                        that.load_message()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
            preview: function () {
                var src = "/html/ticker_example.html"

                this.preview_url = src
                this.tanimation = this.mydata.animation
                if(this.tanimation == 'fade') {
                  this.tanimation_enter = 'animate__animated animate__fadeIn'
                }else if(this.tanimation == 'bounce') {
                  this.tanimation_enter = 'animate__animated animate__bounceInUp'
                }else if(this.tanimation == 'zoom') {
                  this.tanimation_enter = 'animate__animated animate__zoomIn'
                }else if(this.tanimation == 'hb') {
                  this.tanimation_enter = 'animate__animated animate__heartBeat'
                }else if(this.tanimation == 'swing') {
                  this.tanimation_enter = 'animate__animated animate__swing'
                }else if(this.tanimation == 'flip') {
                  this.tanimation_enter = 'animate__animated animate__flipInX'
                }else {
                  this.tanimation_enter = 'animate__animated animate__fadeInUp'
                }
                this.isshow = true
                var that = this
                setTimeout(function() {
                  that.isshow3 = true
                }, 500)
            },
            preview_banner: function (item) {
                this.selected_item = item
                var src = "/html/ticker_banner_example.html"

                this.preview_url = src
                this.tanimation_enter = ''
                this.isshow = true
                this.isshow3 = true
            },
            show:function() {
                if(!!this.banner_list && this.banner_list.length >= 5) {
                    this.$event.$emit('alert','이미지는 최대 5개까지 등록 가능합니다.')
                    return
                }

                this.banner_form.comment = ''
                this.banner_form.page_url = ''
                this.banner_form.image_url = ''
                this.banner_form.platform = 'pc'

                this.banner_form.mode = 'insert'

                this.isshow2=true
                if(!!this.$refs.file){
                    this.$refs.file.value = '';
                }
                this.$nextTick(() => {
                    this.$refs.form3.resetValidation()
                })

            },
            update:function(item) {
                this.banner_form = Object.assign({}, item)

                this.banner_form.mode = 'update'

                this.isshow2=true
            },
            attachFile:function () {

                // upload
                document.getElementById("file").click()

            },
            fileHandle() {
                var f2 = this.$refs.file.files[0];
                if (!!!f2)
                    return;

                this.file = f2
                var v = this

                // 입력된 파일로 교체
                var reader = new FileReader();
                reader.addEventListener("load", function () {
                    //v.imgsrc = reader.result
                }, false);

                if (this.file) {
                    reader.readAsDataURL(this.file);
                }

                this.filename = document.getElementById("file").value
                var n = this.filename.lastIndexOf('/')
                if (n < 0) {
                    n = this.filename.lastIndexOf('\\')
                }
                if (n < 0)
                    return

                this.filename = this.filename.substring(n + 1, this.filename.length)
            },
            addBanner: function() {
                if (!this.$refs.form3.validate()) {
                    return
                }

                if(!!this.file) {
                    this.submitFile()
                } else {
                    this.addBanner2()
                }

            },
            addBanner2: function() {
                if(this.banner_form.mode == 'insert') {
                    if (!!!this.fileurl) {
                        this.$event.$emit('alert', "이미지를 첨부해주세요")
                        return
                    }
                }else{
                    if(!!!this.banner_form.image_url && !!!this.fileurl) {
                        this.$event.$emit('alert', "이미지를 첨부해주세요")
                        return
                    }
                }

                var that = this
                var url = ''
                if(this.banner_form.mode == 'insert'){
                    url = "/api/more/create/ticker_banner"
                }else if(this.banner_form.mode == 'update'){
                    url = "/api/more/update/ticker_banner"
                }
                if(!!this.company_code){
                    this.banner_form.company_code = this.company_code
                }
                if(!!this.fileurl){
                    this.banner_form.image_url = this.fileurl
                }

                var msg = ''
                if(this.banner_form.mode == 'insert'){
                    msg = '배너 이미지가 등록되었습니다'
                }else if(this.banner_form.mode == 'update'){
                    msg = '배너 이미지가 변경되었습니다'
                }
                this.$http.post(url, this.banner_form).then(res=>{
                    if(res.status == '200'){
                        that.file = null
                        that.filename = null
                        that.fileurl = ''
                        that.isshow2 = false
                        that.$event.$emit('alert',msg)
                        that.load_banner()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
            submitFile(){
                let formData = new FormData()
                formData.append('file', this.file)

                var v = this
                this.isLoading = true
                this.$http.post(
                    'https://img0-upload.adnext.co/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/vid',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(data){
                    var url = data.data
                    // 업로드한 파일 이미지
                    v.fileurl = url
                    v.addBanner2()
                })
                    .catch(function(err){
                        v.$event.$emit('alert', '파일 업로드에 실패하였습니다.')
                        v.isLoading = false
                    });
            },
            showFile:function (url) {
                window.open(
                    url,
                    '_blank'
                );
            },
            deleteBanner: function(item) {
                var del = Object.assign({}, item)
                var that = this
                var url = "/api/more/delete/ticker_banner"
                if(!!this.company_code){
                    del.company_code = this.company_code
                }

                this.$event.$emit('confirm', '배너 이미지 삭제', '선택하신 배너를 삭제하시겠습니까?', function () {
                    that.$http.post(url, del).then(res=>{
                        if(res.status == '200'){
                            that.$event.$emit('alert','배너 이미지가 삭제되었습니다')
                            that.load_banner()
                        }
                    }).catch((err)=>{
                        var msg = '오류가 발생했습니다'
                        that.$event.$emit('alert',msg)
                    })
                })

            },
            closePreview: function() {
              this.isshow3 = false
              var that = this
              setTimeout(function () {
                that.isshow = false
              }, 300)
            },
            iframe_loaded: function() {
              this.sendMessage()
            },
            sendMessage: function() {
              try {
                var iframeId = "ifr_ticker"
                var frameContentWindow = document.getElementById(iframeId).contentWindow
                if (frameContentWindow.postMessage) {
                  var data = {
                    'command': 'change_color',
                    'header_color': this.mydata.header_color,
                    'header_text_color': this.mydata.header_text_color,
                    'point_text_color': this.mydata.point_text_color,
                  }
                  frameContentWindow.postMessage(JSON.stringify(data), "*")

                  try {
                    var data2 = {
                      'command': 'change_image',
                      'image_url': this.selected_item.image_url
                    }
                    frameContentWindow.postMessage(JSON.stringify(data2), "*")
                  }catch (e){}
                }
              }catch (e) {
                alert(e)
              }
            },
        }
    }
</script>
<style scoped>
.ticker_container {
  width: 392px;
  height: 221px;
  display: flex;
  flex-direction: column;
  background: url('../../assets/images/browser.png') no-repeat center;
  background-size: contain;
  padding-top: 33px;
  padding-right: 6px;
}
.ticker_container_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ticker_item {
  background-color: #e3e3e3;
  width: 84px;
  height: 45px;
  cursor: pointer;
}
.ticker_item_selected {
  background-color: #29D1CF;
  width: 84px;
  height: 45px;
  cursor: pointer;
}
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
</style>