<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <div style="position: relative;">
                    <v-card-title class="pb-1">
                        <main-title title="스크립트 관리"/>
                    </v-card-title>
                    <v-card-text class="stitle">
                        MORE 솔루션을 이용하기 위해선 필수적으로 스크립트를 설치하셔야 합니다.
                    </v-card-text>
                </div>
                <div v-if="(admin == 0 || !!form2.company_code) && !!script_yn" style="position:absolute;top:30px;right:30px;">
                    <v-chip v-if="script_yn == 'N'"
                            class="ma-3"
                            outlined
                            style="font-size:0.8em;"
                    >
                        <v-icon left color="grey">mdi-lightbulb</v-icon>
                        스크립트 미설치
                    </v-chip>
                    <v-chip v-if="script_yn == 'Y'"
                            class="ma-3"
                            outlined
                            style="font-size:0.8em;"
                    >
                        <v-icon left color="orange">mdi-lightbulb-on</v-icon>
                        스크립트 설치 완료
                    </v-chip>
                    <v-chip v-if="script_yn == 'I'"
                            class="ma-3"
                            outlined
                            style="font-size:0.8em;"
                    >
                        <v-icon left>mdi-map-marker-question</v-icon>
                        스크립트 설치 확인중
                    </v-chip>
                </div>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="nocafe24"
                            option_default="선택"
                            :value="form2.company_code"
                            @input="(newCC) => {form2.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin== 1 && !!!form2.company_code" style="height: 500px">
            </div>

            <v-tabs v-if="admin == 0 || !!form2.company_code" fixed-tabs color="teal" style="width:100%"
                    v-model="e2"
            >
                <v-tab key="step1"
                >
                    스크립트 설치
                </v-tab>
                <v-tab key="step2"
                >
                    담당자 설치 확인
                </v-tab>
                <v-tab key="step3"
                >
                    설치 완료
                </v-tab>
            </v-tabs>

            <v-stepper v-if="admin == 0 || !!form2.company_code" v-model="e1" vertical class="ma-4">
                <v-stepper-step :complete="e1 > 1" step="1" color="teal lighten-1">
                    스크립트 설치
                </v-stepper-step>

                <v-stepper-content step="1">
                    <v-card outlined class="mb-12">
                        <v-card-title style="font-size:1.1em">
                            직접 설치 가이드
                        </v-card-title>
                        <v-card-subtitle>
                            MORE 솔루션 이용을 위한 스크립트 설치 가이드 입니다.
                        </v-card-subtitle>
                        <v-card-text>
                            <div>
                                <v-btn class="ma-2" depressed
                                       @click="download"
                                >
                                    MORE 통합 스크립트 설치 가이드<v-icon right>mdi-download</v-icon>
                                </v-btn>
                            </div>
                            <!--
                            <div>
                                <v-btn class="ma-2" depressed color="grey lighten-1">
                                    카페24 설치 가이드<v-icon right>mdi-download</v-icon>
                                </v-btn>
                                <v-btn class="ma-2" depressed color="grey lighten-1">
                                    고도몰 설치 가이드<v-icon right>mdi-download</v-icon>
                                </v-btn>
                                <v-btn class="ma-2" depressed color="grey lighten-1">
                                    메이크샵 설치 가이드<v-icon right>mdi-download</v-icon>
                                </v-btn>
                            </div>
                            -->
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-title style="font-size:1.1em">
                            스크립트 설치
                        </v-card-title>
                        <v-card-text>
                            <v-alert
                                    text
                                    dense
                                    color="teal"
                                    icon="mdi-code-tags"
                                    border="left"
                                    class="mb-0"
                            >
                                공통 스크립트
                            </v-alert>
                            <div class="mb-4" style="background-color: #f1f3f4;">
                                <v-btn depressed fab x-small
                                       color="#f1f3f4"
                                       style="float: right;margin-right:5px;margin-top:5px"
                                       @click="copy('common')"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <pre v-if="admin == 0" id="common" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
var _moreID = '{{companyCode}}';
function mcroPushEvent(n){"undefined"!=typeof globalCRO?globalCRO.sendEvent(n):("undefined"==typeof gCro&&(window.gCro=new Array),gCro.push(n))}
function _cro_initialize(){window.globalCRO=new MCro,globalCRO.jsInit(_moreID)}
&lt;/script&gt;
&lt;script async type="text/javascript" src="//cro.myshp.us/resources/common/js/more-common.js"&gt;&lt;/script&gt;</pre>
                                <pre v-else id="common" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
var _moreID = '{{form2.company_code}}';
function mcroPushEvent(n){"undefined"!=typeof globalCRO?globalCRO.sendEvent(n):("undefined"==typeof gCro&&(window.gCro=new Array),gCro.push(n))}
function _cro_initialize(){window.globalCRO=new MCro,globalCRO.jsInit(_moreID)}
&lt;/script&gt;
&lt;script async type="text/javascript" src="//cro.myshp.us/resources/common/js/more-common.js"&gt;&lt;/script&gt;</pre>
                            </div>
                            <v-alert
                                    text
                                    dense
                                    color="teal"
                                    icon="mdi-code-tags"
                                    border="left"
                                    class="mb-0"
                            >
                                상품 상세정보 수집 스크립트
                            </v-alert>
                            <div class="mb-4" style="background-color: #f1f3f4;">
                                <v-btn depressed fab x-small
                                       color="#f1f3f4"
                                       style="float: right;margin-right:5px;margin-top:5px"
                                       @click="copy('view')"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <pre id="view" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
try
{
    var evt_data = {};
    evt_data.evt = 'view';                                      // 이벤트코드 'view' 고정.
    evt_data.p_no = '<span style="color:#f00">12345</span>';     		                // url상에서 쓰이는 상품코드
    evt_data.p_name = '<span style="color:#f00">상품명</span>';  	                        // 상품명
    evt_data.price = '<span style="color:#f00">11000</span>';     		                // 상품판매가격(숫자만 포함한 문자열)
    evt_data.regular_price = '<span style="color:#f00">11000</span>';   	        // 상품정가(숫자만 포함한 문자열)
    evt_data.thumb = '<span style="color:#f00">http://이미지주소</span>';     	// 상품이미지 url
    evt_data.p_url = '<span style="color:#f00">http://상품상세주소</span>';          // 해당 상품페이지 url(트래킹 코드 등이 포함되지 않은 순수 url)
    evt_data.p_url_m = '<span style="color:#f00">http://m.상세주소</span>'; 	// 해당 상품페이지 모바일 url (optional)
    evt_data.cate1 = '<span style="color:#f00">하의</span>';     		    // 카테고리 대분류
    evt_data.cate2 = '<span style="color:#f00">스커트</span>';     		    // 카테고리 중분류. 존재하지 않으면 ''
    evt_data.cate3 = '';     			    // 카테고리 소분류. 존재하지 않으면 ''
    evt_data.soldout = '<span style="color:#f00">0</span>';     		    // 품절여부. (품절이 아닐경우 '0', 품절일 경우 '1')
    mcroPushEvent(evt_data);
}catch(e){}
&lt;/script&gt;</pre>
                            </div>
                            <v-alert
                                    text
                                    dense
                                    color="teal"
                                    icon="mdi-code-tags"
                                    border="left"
                                    class="mb-0"
                            >
                                구매완료 수집 스크립트
                            </v-alert>
                            <div class="mb-4" style="background-color: #f1f3f4;">
                                <v-btn depressed fab x-small
                                       color="#f1f3f4"
                                       style="float: right;margin-right:5px;margin-top:5px"
                                       @click="copy('buy')"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <pre id="buy" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
try
{
    //  상품 구매 건수만큼 반복 호출, 단 '사은품(=진열제외)' 상품의 경우 호출하지 않음.
    {
        var evt_data = {};
        evt_data.evt = 'buy';            		//  이벤트코드 'buy' 고정.
        evt_data.order_id = '<span style="color:#f00">주문번호</span>';     	//  주문번호
        evt_data.p_no = '<span style="color:#f00">12345</span>';     		//  url상에서 쓰이는 상품코드
        evt_data.p_name = '<span style="color:#f00">상품명</span>';  	        // 상품명
        evt_data.price = '<span style="color:#f00">11000</span>';     		// 실제결제금액(상품결제단가 * qty, 배송료포함X - 숫자만 포함한 문자열)
        evt_data.qty = '<span style="color:#f00">1</span>';     			        // 상품구매수량(숫자만 포함한 문자열)
        mcroPushEvent(evt_data);
    }
    //   상품 구매 건수만큼 반복 호출
}catch(e){}
&lt;/script&gt;</pre>
                            </div>
                            <v-alert
                                    text
                                    dense
                                    color="teal"
                                    icon="mdi-code-tags"
                                    border="left"
                                    class="mb-0"
                            >
                                패널,티커 노출 스크립트 - 메인 페이지
                            </v-alert>
                            <div class="mb-4" style="background-color: #f1f3f4;">
                                <v-btn depressed fab x-small
                                       color="#f1f3f4"
                                       style="float: right;margin-right:5px;margin-top:5px"
                                       @click="copy('showm')"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <pre id="showm" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
var meta = document.createElement('meta');
meta.setAttribute('name', 'more_page_type');
meta.setAttribute('content', 'main');
document.getElementsByTagName('head')[0].appendChild(meta);
&lt;/script&gt;</pre>
                            </div>
                            <v-alert
                                    text
                                    dense
                                    color="teal"
                                    icon="mdi-code-tags"
                                    border="left"
                                    class="mb-0"
                            >
                                패널,티커 노출 스크립트 - 카테고리 페이지
                            </v-alert>
                            <div class="mb-4" style="background-color: #f1f3f4;">
                                <v-btn depressed fab x-small
                                       color="#f1f3f4"
                                       style="float: right;margin-right:5px;margin-top:5px"
                                       @click="copy('showc')"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <pre id="showc" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
var meta = document.createElement('meta');
meta.setAttribute('name', 'more_page_type');
meta.setAttribute('content', 'category');
document.getElementsByTagName('head')[0].appendChild(meta);
&lt;/script&gt;</pre>
                            </div>
                            <v-alert
                                    text
                                    dense
                                    color="teal"
                                    icon="mdi-code-tags"
                                    border="left"
                                    class="mb-0"
                            >
                                패널,티커 노출 스크립트 - 상세 페이지
                            </v-alert>
                            <div style="background-color: #f1f3f4;">
                                <v-btn depressed fab x-small
                                       color="#f1f3f4"
                                       style="float: right;margin-right:5px;margin-top:5px"
                                       @click="copy('showd')"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                                <pre id="showd" class="pa-4" style="white-space: pre-wrap;font-family:Roboto, sans-serif">
&lt;script&gt;
var meta = document.createElement('meta');
meta.setAttribute('name', 'more_page_type');
meta.setAttribute('content', 'detail');
document.getElementsByTagName('head')[0].appendChild(meta);
var meta2 = document.createElement('meta');
meta2.setAttribute('name', 'more_detail_pid');
meta2.setAttribute('content', '<span style="color:#f00">상품코드</span>');       // 현재 페이지의 상품코드
document.getElementsByTagName('head')[0].appendChild(meta2);
&lt;/script&gt;</pre>
                            </div>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-title style="font-size:1.1em">
                            대행 설치
                        </v-card-title>
                        <v-card-subtitle>
                            직접 설치가 어려우신 경우, 스크립트 설치 대행을 요청하실 수 있습니다.
                            <br>
                            설치를 원하는 날짜와 호스팅사 정보를 보내주시면 설치 예정일자를 메일로 안내해드립니다.
                            <br>
                            설치는 약 30분 정도 소요가 되며, 설치 중에는 가급적 중복 접속을 피해주시기 바랍니다.
                        </v-card-subtitle>
                        <v-card-text>
                            <v-row align="center" >
                                <v-col>
                                    <p class="mb-0">현재 대행 설치가 가능한 호스팅사<br>
                                        <span style="font-weight: 600;font-size:13px">- 카페24</span><br>
                                        <span style="font-weight: 600;font-size:13px">- 고도몰</span><br>
                                        <span style="font-weight: 600;font-size:13px">- 메이크샵</span><br>
                                        <span style="font-weight: 600;font-size:13px">- 아임웹</span>
                                    </p>
                                </v-col>
                                <v-col md="3" align="center">
                                    <v-btn depressed
                                           color="#566270" dark
                                           @click="showDialog2"
                                    >
                                        설치 대행 요청
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn
                           color="teal lighten-1" dark
                           @click="nextStep(1)"
                    >
                        다음
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="e1 > 2" step="2" color="teal lighten-1">
                    담당자 설치 확인
                    <small class="pt-1">영업일 기준 7일 이내</small>
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card outlined class="mb-12">
                        <v-card-title style="font-size:1.1em">
                            최종 스크립트 설치 확인 요청
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center" >
                                <v-col>
                                    <p class="mb-0">스크립트 설치가 끝나셨다면, 담당자가 최종 확인하여 스크립트 설치 상태를 변경해 드립니다.<br>
                                        우측 버튼을 통해 확인 요청을 진행해주세요.</p>
                                </v-col>
                                <v-col md="3" align="center">
                                    <v-btn v-if="script_yn == 'N'" depressed
                                           color="#29d1cf" dark
                                           @click="request"
                                    >
                                        설치 확인 요청
                                    </v-btn>
                                    <v-btn v-else-if="script_yn == 'I'" depressed
                                           color="#29d1cf"
                                           disabled
                                    >
                                        설치 확인 중
                                    </v-btn>
                                    <v-btn v-else depressed
                                           color="#29d1cf"
                                           disabled
                                    >
                                        설치 완료
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn text @click="prevStep(2)">이전</v-btn>
                    <v-btn depressed class="ml-3"
                           color="teal lighten-1" dark
                           @click="nextStep(2)"
                    >
                        다음
                    </v-btn>
                </v-stepper-content>

                <v-stepper-step step="3" color="teal lighten-1">
                    설치 완료
                    <small class="pt-1">무료체험 신청</small>
                </v-stepper-step>
                <v-stepper-content step="3">
                    <v-card outlined class="mb-12">
                        <v-card-title style="font-size:1.1em">
                            무료체험 신청
                        </v-card-title>
                        <v-card-subtitle>
                            담당자가 스크립트 설치 확인까지 완료하면 아래 무료체험 신청 버튼이 활성화 됩니다.
                        </v-card-subtitle>
                    </v-card>
                    <v-btn text @click="prevStep(3)">이전</v-btn>
                    <v-btn class="ml-3"
                           color="#29d1cf"
                           @click="showApply"
                           style="font-weight: 600;"
                           :dark="script_yn=='Y'?true:false"
                           :disabled="script_yn=='Y'?false:true"
                    >
                        무료체험 시작하기
                    </v-btn>
                </v-stepper-content>
            </v-stepper>
        </v-card>

        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">무료체험 신청하기</span>
                </v-card-title>
                <v-card-subtitle v-if="!!script_ymd" class="mt-1">
                    <span>스크립트 설치 확인일 : </span><span>{{script_ymd}}</span>
                </v-card-subtitle>
                <v-card-text>
                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="form.service_type"
                                        :items="services"
                                        label="이용 상품"
                                        multiple
                                        chips
                                        :rules="required2"
                                    ></v-combobox>
                                  <!--
                                    <v-select
                                            :items="services"
                                            v-model="form.service_type"
                                            label="이용 상품"
                                            item-text="name"
                                            item-value="id"
                                            :rules="required"
                                    ></v-select>-->
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                            ref="menu"
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="form.f_start_ymd"
                                                    label="무료체험 시작 희망일자"
                                                    :rules="[ymdRules]"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="form.f_start_ymd" no-title scrollable color="teal" @input="$refs.menu.save(form.f_start_ymd)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <small>* 스크립트 설치 완료 이후 최소 5일간의 데이터 수집 기간이 필요합니다.<br>참고하시어 무료체험 시작 일자를 선택해주세요</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="dialog = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="apply">신청하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" persistent max-width="620px">
            <v-card>
                <v-card-title>
                    <span class="headline">설치 대행 신청하기</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                            ref="form3"
                            lazy-validation
                    >
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                            :items="hostings"
                                            v-model="form3.hosting"
                                            label="호스팅사"
                                            item-text="name"
                                            item-value="id"
                                            :rules="required"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="form3.url"
                                            label="어드민 URL"
                                            :rules="required"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4" v-if="form3.hosting == 'cafe24'">
                                    <v-text-field
                                            v-model="form3.shopid"
                                            label="(호스팅사) 상점ID"
                                    ></v-text-field>
                                </v-col>
                                <v-col :cols="form3.hosting == 'cafe24'?4:6">
                                    <v-text-field
                                            v-model="form3.id"
                                            label="(호스팅사) ID"
                                            :rules="required"
                                    ></v-text-field>
                                </v-col>
                                <v-col :cols="form3.hosting == 'cafe24'?4:6">
                                    <v-text-field
                                            v-model="form3.password"
                                            label="(호스팅사) PW"
                                            :rules="required"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu
                                            ref="menu2"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="form3.ymd"
                                                    label="설치 희망일자"
                                                    :rules="[ymdRules2]"
                                                    readonly
                                                    v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="form3.ymd"
                                                       no-title
                                                       scrollable
                                                       color="teal"
                                                       @input="$refs.menu2.save(form3.ymd)">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="gray darken-1" text @click="dialog2 = false">취소</v-btn>
                    <v-btn color="teal darken-1" text @click="scriptApply">신청하기</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import MainTitle from "../common/MainTitle";
    import MySelect from "../common/MySelect";

    import {mapGetters} from "vuex";
    export default {
        computed: {computedDateFormatted () {
                return this.formatDate(this.form.f_start_ymd)
            },...mapGetters({
                companyCode: 'companyCode',
                admin: 'admin',
            })
        },

        watch: {
            e1: function() {
                this.e2 = this.e1 - 1
            },
            e2: function() {
                this.e1 = this.e2 + 1
            },
            admin:function () {
                this.load()
            },
            "form2.company_code": function () {
                if(!!!this.form2.company_code){
                    this.script_yn = ''
                }
                this.load()
            },
        },
        mounted() {
            this.load()
        },
        components: {
            MainTitle,
            MySelect,
        },
        data: () => ({
            e1: 1,
            e2: 0,
            steps: 3,
            script_yn: '',
            script_ymd: '',
            min_ymd: '',
            dialog: false,
            menu: false,
            valid: false,
            required: [
                v => !!v || '필수 정보입니다'
            ],
            required2: [
              v => v.length>0 || '필수 정보입니다'
            ],
            form2: {
                company_code : '',
            },
            form: {
                service_type: [],
                f_start_ymd: '',
                f_end_ymd: '',
            },
            services: ["패널","티커","추천","서치","리뷰"],
            form3: {},
            min_ymd2: '',
            dialog2: false,
            menu2: false,
            hostings: [
                {
                    id: "cafe24",
                    name: "cafe24"
                },
                {
                    id: "godo",
                    name: "고도몰"
                },
                {
                    id: "makeshop",
                    name: "메이크샵"
                },
                {
                  id: "imweb",
                  name: "아임웹"
                },
                {
                  id: "etc",
                  name: "기타"
                }
            ],
        }),
        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${year}-${month}-${day}`
            },
            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            ymdRules:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.$moment(new Date()).format('YYYY-MM-DD') > v)
                    return "과거일자는 선택할 수 없습니다"
                if(this.min_ymd > v)
                    return "체험 시작일은 스크립트 완료 후 최소 5일 후부터 가능합니다"
                return true
            },
            ymdRules2:function(v) {
                if(!!!v)
                    return "필수 정보입니다"
                if(this.min_ymd2 > v)
                    return "설치 대행 신청은 최소 1일 후부터 가능합니다"
                var weekDayName = this.$moment(v,'YYYY-MM-DD').day()
                if(weekDayName == 0 || weekDayName == 6)
                    return "주말은 신청이 불가합니다"
                return true
            },
            load: function(){
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form2.company_code)
                    return

                var that = this
                var url = "/api/more/info"
                if(!!this.form2.company_code){
                    url += "?company_code=" + this.form2.company_code
                }
                this.$http.get(url).then(res=>{
                    // 스크립트 설치 여부 가져오기
                    // 스크립트 설치 여부가 'Y'이면 setp 4로..
                    that.script_yn = res.data.script_yn
                    that.script_ymd = !!res.data.script_ymd?that.$moment(res.data.script_ymd, 'YYYYMMDD').format('YYYY-MM-DD'):""
                    if(that.script_yn == 'Y' && !!res.data.script_ymd){
                        that.min_ymd = this.$moment(res.data.script_ymd, 'YYYYMMDD').add(5, 'days').format('YYYY-MM-DD')
                        var td = that.form.f_start_ymd = this.$moment(new Date()).format('YYYY-MM-DD')
                        var min = this.$moment(res.data.script_ymd, 'YYYYMMDD').add(5, 'days').format('YYYY-MM-DD')
                        if(td > min){
                            that.form.f_start_ymd = td
                        }else{
                            that.form.f_start_ymd = min
                        }
                    } else if(that.script_yn == 'Y') {
                        that.min_ymd = this.$moment(new Date()).format('YYYY-MM-DD')
                        that.form.f_start_ymd = this.$moment(new Date()).format('YYYY-MM-DD')
                    }

                    if(that.script_yn == 'Y'){
                        that.e2 = 2
                    }else if(that.script_yn == 'I'){
                        that.e2 = 1
                    }else{
                        that.e2 = 0
                    }
                }).catch((err)=>{
                    that.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            nextStep: function(n) {
                if (n !== this.steps) {
                    this.e1 = n + 1
                }
            },
            prevStep: function(n) {
                if (n !== 1) {
                    this.e1 = n - 1
                }
            },
            copy: function(objId){
                var srcObj = document.getElementById(objId);
                var range = document.createRange();
                range.selectNode(srcObj);
                window.getSelection().addRange(range);

                try {
                    document.execCommand('copy');
                    this.$event.$emit('alert',"스크립트가 복사 되었습니다")
                } catch(err) {
                }
                window.getSelection().removeAllRanges();

            },
            request : function(){
                var that = this
                this.$event.$emit('confirm', '설치 확인', '설치 확인을 요청하시겠습니까?<br />영업일 기준 7일 이내에 담당자가 확인 해드릴 예정입니다.', function () {
                    var url = "/api/more/apply/script"
                    if(!!that.form2.company_code){
                        url += "?company_code=" + that.form2.company_code
                    }
                    this.$http.get(url).then(res=>{
                        if(res.status == '200'){
                            that.script_yn = 'I'
                        }
                    }).catch((err)=>{
                        var msg = '오류가 발생했습니다'
                        if(err.response.data.msg == 'DUPLICATED') {
                            msg = '이미 설치 확인 요청중 입니다'
                            that.script_yn = 'I'
                        } else if(err.response.data.msg == 'ALREADY_COMPLETE'){
                            msg = '이미 설치 확인이 완료되었습니다'
                            that.load()
                        }
                        that.$event.$emit('alert',msg)
                    })
                })
            },
            showApply : function(){
                // 스크립트 미설치이면 신청 불가.
                this.dialog = true
            },
            apply : function(){
                if (!this.$refs.form.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/apply/service"
                if(!!this.form2.company_code){
                    this.form.company_code = this.form2.company_code
                }

                this.form.f_end_ymd = this.$moment(this.form.f_start_ymd, 'YYYY-MM-DD').add(13, 'days').format('YYYY-MM-DD')
                this.$http.post(url, this.form).then(res=>{
                    if(res.status == '200'){
                        that.dialog = false
                        that.$event.$emit('confirm', '무료 체험 신청완료', '무료체험 신청이 완료되었습니다.<br />소재 관리 페이지로 이동하시겠습니까?', function () {
                            var type = that.form.service_type
                            if(type.includes("패널")){
                              that.$router.push({path:'/manage/design/panel'})
                            }else if(type.includes("티커")){
                              that.$router.push({path:'/manage/design/ticker'})
                            }else if(type.includes("추천")){
                              that.$router.push({path:'/manage/design/recommend'})
                            }else if(type.includes("서치")){
                              that.$router.push({path:'/manage/design/search'})
                            }else {
                              that.$router.push({path:'/manage/review_items'})
                            }
                        })
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    if(err.response.data.msg == 'EXISTED') {
                        msg = '이미 무료체험을 이용한 상품이 있습니다'
                    }
                    that.$event.$emit('alert',msg)
                })
            },
            showDialog2 : function(){
                this.form3.ymd = this.$moment(new Date()).add(1,'days').format('YYYY-MM-DD')
                this.min_ymd2 = this.$moment(new Date()).add(1,'days').format('YYYY-MM-DD')
                this.dialog2 = true
            },
            scriptApply: function () {
                if (!this.$refs.form3.validate()) {
                    return
                }

                var that = this
                var url = "/api/more/apply/install_script"
                if(!!this.form2.company_code){
                    this.form3.company_code = this.form2.company_code
                }
                this.$http.post(url, this.form3).then(res=>{
                    if(res.status == '200'){
                        that.dialog2 = false
                        that.$event.$emit('alert','대행 신청이 완료되었습니다.<br>담당자가 확인하여 메일로 일정을 안내해드릴 예정입니다. ')
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    that.$event.$emit('alert',msg)
                })
            },
            download: function () {
                var file = '/file/MORE-Script-Guide_v2.0.pdf'
                window.open(file)
            }
        }
    };
</script>
<style scoped>
    .round-top { border-top-left-radius: 4px !important; border-top-right-radius: 4px !important; border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important;  }
</style>
