<template>
<div>

    <div>
        <v-alert
                text
                dense
                color="teal"
                icon="mdi-account"
                border="left"
        >
            정산 정보
        </v-alert>
        <v-form
                ref="form1"
                lazy-validation
                class="ma-3"
        >
            <v-text-field
                    label="법인(상호)명"
                    v-model="form.corp_name"
                    :rules="nameRules"
            ></v-text-field>
            <v-row>
                <v-col :cols="!!filename?4:8">
                    <v-text-field
                            label="사업자 등록번호"
                            v-model="form.license_num"
                            :rules="numRules"
                    >
                        <template v-if="!!form.license_file_url" v-slot:append-outer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on"
                                            color="teal"
                                            @click="showFile"
                                    >mdi-file-document-outline</v-icon>
                                </template>
                                사업자등록증 보기
                            </v-tooltip>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="4" style="align-self: center;color: #666;font-size: 0.9em" v-if="!!filename">
                    {{filename}}
                </v-col>
                <v-col cols="4">
                    <v-btn class="mt-3"
                           color="#566270"
                           block dark depressed
                           @click="attachFile"
                    >
                        사업자 등록증 첨부
                    </v-btn>
                </v-col>
            </v-row>
            <v-text-field
                    label="세금계산서 담당자"
                    v-model="form.tax_manager"
                    :rules="nameRules"
            ></v-text-field>
            <v-text-field
                    label="세금계산서 수신 이메일"
                    v-model="form.tax_email"
                    :rules="emailRules"
            ></v-text-field>
            <v-btn class="mt-3"
                   color="#29d1cf"
                   block dark depressed
                   @click="modify"
            >
                수정하기
            </v-btn>

            <input ref="file" id="file" type="file" accept="image/*" hidden
                   @change="fileHandle">

        </v-form>
    </div>
</div>
</template>
<script>

    export default {
        props: {
        },
        mounted() {
            this.load()
        },
        updated() {
        },
        created () {
        },
        destroyed () {
        },
        activated() {
        },
        watch:{
        },
        components: {

        },
        data: () => ({
            isLoading:false,
            corpInfo:null,
            filename:null,
            file:null,
            fileurl : '',
            form: {
                corp_name : '',
                license_num: '',
                tax_manager: '',
                tax_email: '',
                license_file_url: ''
            },
            nameRules: [
                v => !!v || '필수 정보입니다'
            ],

            urlRules: [
                v => !!v || '필수 정보입니다',
                v => /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(v) || 'url 형식이 올바르지 않습니다',
                v => v.indexOf('naver.com') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('band.us') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('tistory.com') < 0 || '서비스 이용이 불가능한 url 입니다',
                v => v.indexOf('instagram.com') < 0 || '서비스 이용이 불가능한 url 입니다'
            ],

            numRules: [
                v => !!v || '필수 정보입니다',
                v => v.replace(/-/gi,"").length == 10 || '사업자등록번호 형식이 올바르지 않습니다',
                v => /^[0-9\-]+$/.test(v) ||
                    '사업자등록번호 형식이 올바르지 않습니다'
            ],

            emailRules: [
                v => !!v || '필수 정보입니다',
                v => /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(v) ||
                    '이메일 형식이 올바르지 않습니다'
            ],
        }),
        methods: {
            load : function(){
                var that = this
                var url = "/api/user/corp/info"
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        var data = res.data
                        that.form.corp_name = data.corp_name
                        that.form.license_num = data.license_num
                        that.form.tax_manager = data.tax_manager
                        that.form.tax_email = data.tax_email
                        that.form.license_file_url = data.license_file_url
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            modify2:function() {
                if (!this.$refs.form1.validate()) {
                    return
                }

                if(!!!this.fileurl && !!!this.form.license_file_url){
                    this.$event.$emit('alert',"사업자등록증을 첨부해주세요")
                    return
                }

                var form2 = Object.assign({}, this.form)
                if(!!this.fileurl){
                    form2.license_file_url = this.fileurl
                }

                var that = this
                this.$event.$emit('confirm', '정산 정보 변경', '정보를 수정하시겠습니까?', function () {
                    var url = "/api/user/update/corpinfo"
                    this.$http.post(url, form2).then(res=>{
                        if(res.data.msg == "OK"){
                            that.fileurl = ''
                            that.filename = null
                            that.load()
                            that.$event.$emit('alert',"저장되었습니다")
                        }
                    }).catch((err)=>{
                        that.$event.$emit('alert',"오류가 발생했습니다")
                    })
                });

            },
            modify:function() {
                if(!!this.file) {
                    this.submitFile()
                } else {
                    this.modify2()
                }
            },
            attachFile:function () {

                // upload
                document.getElementById("file").click()

            },
            fileHandle() {
                var f2 = this.$refs.file.files[0];
                if(!!!f2)
                    return;

                this.file = f2
                var v = this

                // 입력된 파일로 교체
                var reader = new FileReader();
                reader.addEventListener("load", function () {
                    //v.imgsrc = reader.result
                }, false);

                if (this.file) {
                    reader.readAsDataURL(this.file);
                }

                this.filename = document.getElementById("file").value
                var n = this.filename.lastIndexOf('/')
                if(n<0) {
                    n = this.filename.lastIndexOf('\\')
                }
                if(n<0)
                    return

                this.filename = this.filename.substring(n+1, this.filename.length)

            },
            submitFile(){
                let formData = new FormData()
                formData.append('file', this.file)

                var v = this
                this.isLoading = true
                this.$http.post(
                    'https://img0-upload.adnext.co/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/vid',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(data){
                    var url = data.data
                    // 업로드한 파일 이미지
                    v.fileurl = url
                    v.modify2()
                })
                .catch(function(err){
                    v.$event.$emit('alert', '파일 업로드에 실패하였습니다.')
                    v.isLoading = false
                });
            },
            showFile:function () {
                window.open(
                    this.form.license_file_url,
                    '_blank'
                );
            }
        }
    }
</script>
<style scoped>
</style>