<template>
    <div>
        <div class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="추천 관리"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="recom"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    v-if="admin == 0 || !!form.company_code"
            >
                <v-tab key="opt1"
                >
                    Step1. 지면 관리
                </v-tab>
                <v-tab key="opt2"
                >
                    Step2. 추천소재 관리
                </v-tab>
                <v-tab key="opt3"
                >
                    Step3. 노출 관리
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless
                          v-if="admin == 0 || !!form.company_code"
            >
                <v-tab-item
                        class="pa-5"
                        key="opt1">

                    <div :key="flag">
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div v-if="!!min_ymd">
                                <manage-recommend mode="PC" class="mb-6" :company_code="form.company_code" :min_ymd="min_ymd"/>

                                <manage-recommend mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="opt2">
                    <div>
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="d-flex" >
                                <div class="flex-grow-1">

                                    <div class="pa-2">
                                        <v-card-title class="subtitle">
                                            공통 설정 (PC/Mobile)
                                        </v-card-title>

                                        <v-card
                                                outlined
                                                class="pa-3"
                                        >
                                            <div class="pa-2">
                                                <div class="pb-4 mt-4">
                                                  <b>디자인 설정</b>
                                                </div>

                                              <div style="border: 1px solid #e3e3e3">
                                                <div class="pl-2 mt-4">
                                                  <span style="font-size: 14px;color: #009688;font-weight: 600;letter-spacing: 0.0892857143em;">[디자인 미리보기]</span>
                                                </div>
                                                <iframe v-show="!!common" id="ifr_recommend" :src="preview_url" frameborder="0" scrolling="no"
                                                        :width="ifrWidth" :height="ifrHeight"
                                                        style="overflow:hidden;border: 1px solid #e3e3e3"></iframe>
                                              </div>

                                              <v-subheader class="pl-1" style="font-weight: 600;color:#333">1. 디자인 타입</v-subheader>
                                              <div style="font-size: 0.8em" class="pl-3">
                                                <v-row>
                                                  <v-col cols="4">
                                                    <v-select
                                                        :items="design_types"
                                                        label="디자인 선택"
                                                        v-model="common.design_type"
                                                        item-text="value"
                                                        item-value="code"
                                                        outlined
                                                        dense
                                                        hide-details
                                                        @change="designChanged"
                                                    ></v-select>
                                                  </v-col>
                                                </v-row>
                                              </div>

                                                <v-subheader class="pl-1" style="font-weight: 600;color:#333">2. 컬러 설정</v-subheader>
                                                <div style="font-size: 0.8em" class="pl-3">
                                                  <div class="pb-2">
                                                    - 추천 상품 타이틀 컬러
                                                  </div>
                                                  <my-color v-model="common.title_text_color" />

                                                  <div class="pb-2 pt-4">
                                                    - 가격 강조 컬러
                                                  </div>
                                                  <my-color v-model="common.point_text_color" />

                                                </div>

                                                <v-subheader class="pl-1" style="font-weight: 600;color:#333">3. 폰트 설정 & 스타일</v-subheader>
                                                <div style="font-size: 0.8em" class="pl-3">
                                                  <v-row>
                                                    <v-col>
                                                      <v-select
                                                          :items="fonts"
                                                          label="무료 폰트 선택"
                                                          v-model="selected_font"
                                                          item-text="value"
                                                          item-value="code"
                                                          outlined
                                                          dense
                                                          hide-details
                                                          @change="fontChanged"
                                                      ></v-select>
                                                    </v-col>
                                                    <v-col>
                                                      <v-text-field
                                                          v-model="common.font"
                                                          outlined
                                                          hide-details
                                                          dense
                                                          :disabled="selected_font==''?false:true"
                                                          label="스타일"
                                                          :rules="required"
                                                      />
                                                    </v-col>
                                                  </v-row>
                                                </div>
                                            </div>

                                            <v-row class="pa-4">
                                              <v-col>
                                                <v-btn
                                                    block
                                                    depressed
                                                    dark
                                                    color="grey"
                                                    @click="load_common"
                                                >
                                                  취소
                                                </v-btn>
                                              </v-col>
                                              <v-col>
                                                <v-btn
                                                    block
                                                    depressed
                                                    dark
                                                    color="#EC407A"
                                                    @click="updateRecomCommon"
                                                >
                                                  저장
                                                </v-btn>
                                              </v-col>
                                            </v-row>
                                        </v-card>
<!--
                                      <v-dialog v-model="isshow" max-width="800px" style="background-color: white">
                                        <v-card class="pa-4">
                                          <div class="pl-4" align="center">
                                            <iframe :src="preview_url" frameborder="0" style="width:100%;height:330px"></iframe>
                                          </div>

                                          <div class="pa-4 d-flex">
                                            <v-btn class="mr-1 flex-grow-1"
                                                   depressed
                                                   dark
                                                   color="grey"
                                                   @click="isshow=false"
                                            >
                                              닫기
                                            </v-btn>
                                          </div>
                                        </v-card>
                                      </v-dialog>
-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item
                        class="pa-5"
                        key="opt3">

                    <div :key="flag">
                        <div v-if="!!!common"></div>

                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div v-if="!!min_ymd">
                                <manage-recommend-status mode="PC" class="mb-6" :company_code="form.company_code" :min_ymd="min_ymd"/>

                                <manage-recommend-status mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
    import MyTab from "../../common/MyTab";
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import { mapMutations, mapGetters } from 'vuex'
    import MyColor from "../../common/MyColor";
    import ManageRecommendStatus from "@/components/common/ManageRecommendStatus";
    import ManageRecommend from "@/components/common/ManageRecommend";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        watch:{
            admin: function () {
                this.loadAll()
            },
            "form.company_code": function () {
                this.loadAll()
            },
            mytab2: function () {
                //this.sendMessage()
            },
            mytab:function () {
                this.flag++
            }
        },
        components: {
          MainTitle,
          MySelect,
          ManageRecommend,
          MyTab,
          MyColor,
          ManageRecommendStatus
        },
        created() {
            this.$event.$on('color_changed',this.colorChanged)
        },
        mounted() {
            this.loadAll()
        },
        beforeDestroy() {
            this.$event.$off('color_changed',this.colorChanged)
        },
        methods:{
            flag:0,
            loadAll: function() {
                this.tab = 0
                this.load_current()
                this.load_fonts();
                this.load_common()
            },
            load_current: function() {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                var that = this
                var url = "/api/more/get/current_period?type=recom"
                if(!!this.form.company_code){
                    url += "&company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.term_start = res.data.term_start
                        that.term_end = res.data.term_end
                        var today = this.$moment(new Date()).format('YYYYMMDD')
                        that.min_ymd = this.$moment(that.term_start,'YYYYMMDD').format('YYYY-MM-DD')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_fonts: function() {
              var that = this
              var url = "/api/more/get/fonts"
              this.$http.get(url).then(res=>{
                res.data.forEach(function(e, i){
                  that.fonts.push({code: e.code, value: e.code_name})
                })
              }).catch((err)=> {
              })
            },
            load_common: function(){
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                this.common = null

                var that = this
                var url = "/api/more/get/recom_common"
                if(!!this.form.company_code){
                    url += "?company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.common = res.data
                        that.selected_font = that.common.font
                        if (that.fonts.filter(e => e.code === that.common.font).length <= 0) {
                          that.selected_font = ''
                        }
                        that.preview()
                    }
                }).catch((err)=>{
                    console.log(err)
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            updateRecomCommon: function(){
                if(!!!this.common.font) {
                  this.$event.$emit('alert', '스타일을 입력해주세요')
                  return
                }

                var url = "/api/more/update/recom_common"
                if (!!this.form.company_code) {
                    this.common.company_code = this.form.company_code
                }

                var that = this
                this.$event.$emit('confirm', '추천 공통 설정', '변경사항을 저장하시겠습니까?', function () {
                    that.$http.post(url, that.common).then(res => {
                        if (res.status == '200') {
                            that.load_common()
                            that.$event.$emit('alert', '추천 공통 설정이 변경되었습니다')
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                })
            },
            clone: function(obj){
              var output = {}
              for(var i in obj){
                output[i] = obj[i]
              }
              return output
            },
            preview: function () {
              var dt = ''
              if(this.common.design_type == 'V') {
                dt = 'v_'
                this.ifrWidth = '200px';
                this.ifrHeight = '460px';
              } else {
                this.ifrWidth = '100%';
                this.ifrHeight = '370px';
              }
              var src = "https://pi.myshp.us/more?&key=&type=pbyp&url=%23&iw=1&ih=1&ratid=more_demo_id&channel=detail&myid=_chj85tlri&title=%ED%95%A8%EA%BB%98%20%EB%A7%8E%EC%9D%B4%20%EB%B3%B8%20%EC%83%81%ED%92%88"
              src += "&style=p_" + dt + this.common.font.toLowerCase()
              src += "&clr=" + encodeURIComponent(this.common.point_text_color)
              src += "&titclr=" + encodeURIComponent(this.common.title_text_color)

              this.preview_url = src
            },
            colorChanged: function(){
              this.preview()
            },
            fontChanged: function() {
              this.common.font = this.selected_font
              this.preview()
            },
            designChanged: function() {
              this.preview()
            }
        },
        data: () => ({
            min_ymd: '',
            form: {
                company_code: ''
            },
            common: null,
            selected_font: '',
            mytab:null,
            mytab2:null,
            show_pc:false,
            params:{},

            tab:0,
            term_start: '',
            term_end: '',
            show1:false,
            show2:false,
            fonts: [],
            design_types: [
              {code: 'H', value: '가로형'},
              {code: 'V', value: '세로형 (모바일은 적용 불가)'}
            ],
            isshow:false,
            preview_url:'',
            ifrWidth: '100%',
            ifrHeight: '370px',
            required: [
              v => !!v || '필수 정보입니다'
            ],
        })
    }
</script>

<style scoped>
.mtable > tbody > tr > td {
    padding: 20px;
}
.tab-item-wrapper {
    /* vuetify sets the v-tabs__container height to 48px */
    height: 100%;
}
</style>
