<template>
    <div>
        <div class="colorbox"
             @click="panel = true"
             :style="'text-align:center;display: flex;flex-direction: column;justify-content: center;margin:auto;font-size:0.8em;color:' + textColor + ';background-color:'+val">
            {{val}}
        </div>
        <div v-if="panel">

            <v-layout column align-center>
                <sketch
                        class="mt-4"
                        v-model="val2" />
            </v-layout>

            <div
                    style="justify-content: center;"
                    class="d-flex mt-3 mb-10">
                <v-btn
                        class="mr-2"
                        depressed
                        @click="panel=false"
                >
                    취소
                </v-btn>
                <v-btn
                        color="teal lighten-2"
                        depressed
                        dark
                        @click="updateValue"
                >
                    선택
                </v-btn>
            </div>
        </div>

    </div>
</template>
<script>

    import { Sketch } from 'vue-color'

    export default {
        props: {
            value : {
                type:String,
                default:'#ffffff'
            }
        },
        mounted() {
            this.val = this.value
            this.val2 = this.value
        },
        updated() {
        },
        created () {
        },
        destroyed () {
        },
        activated() {
        },
        watch:{
            val: function () {
                var luma = this.getColorLuma(this.val)
                if(luma < 128) {
                    this.textColor = '#fff'
                }else {
                    this.textColor = '#000'
                }
            }
        },
        components: {
            Sketch
        },
        data: () => ({
            val:'',
            val2:'',
            panel:false,
            textColor: '#fff'
        }),
        methods: {
            updateValue:function () {
                this.panel = false

                this.val = this.val2.hex
                this.$emit('input', this.val)
                this.$event.$emit('color_changed')
            },
            getColorLuma:function(color) {
                var color = "" + color, isHEX = color.indexOf("#") == 0, isRGB = color.indexOf("rgb") == 0;
                if (isHEX) {
                    const hasFullSpec = color.length == 7;
                    var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
                    if (m) var r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16), g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16), b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
                }
                if (isRGB) {
                    var m = color.match(/(\d+){3}/g);
                    if (m) var r = m[0], g = m[1], b = m[2];
                }
                if (typeof r != "undefined") return ((r*299)+(g*587)+(b*114))/1000;
            }
        }
    }
</script>
<style scoped>
    .colorbox {
        border-radius: 5px;
        height: 35px;
        cursor: pointer;
        border: #ccc 0.5px solid;
    }
</style>