<template>
    <div>
        <v-row class="pt-3 pb-6">
          <v-col cols="12" sm="5">
            <v-text-field
                v-model="search_item"
                placeholder="태그를 입력하세요"
                hide-details
                dense
                clearable
                @keydown.enter="search"
                append-icon="mdi-magnify"
                @click:append="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <div style="font-size: 0.83em;color: #666;">
            <div class="mb-1">
                * 인스타그램, 네이버 블로그, 틱톡, 유튜브, 카카오스토리 리뷰를 등록할 수 있습니다.
            </div>
        </div>
        <v-row class="mx-4 pb-3">
            <v-layout justify-end>
              <v-flex shrink>
                <v-btn block depressed color="teal lighten-2" dark @click="add">
                  <v-icon left class="mr-3">
                    mdi-plus
                  </v-icon>
                  등록하기
                </v-btn>
              </v-flex>
            </v-layout>
        </v-row>

        <v-card flat class="pb-3">
            <v-card-text>
                <v-row class="mt-2 px-4 pb-3">

                    <table class="mtable">
                        <thead>
                        <tr class="theader">
                            <th v-if="option == 'popup'" class="text-center" style="border-bottom:1px solid #666">선택</th>
                            <th class="text-left" style="border-bottom:1px solid #666">소셜 리뷰 <span v-html="getTotal()" style="margin-left:6px;"></span></th>
                            <th class="text-center" style="border-bottom:1px solid #666">태그</th>
                            <th class="text-center" style="border-bottom:1px solid #666">기능</th>
                        </tr>

                        </thead>
                        <tbody>
                            <tr v-if="itemList.length == 0">
                                <td class="text-center" colspan="3" style="color: #888">등록된 내역이 없습니다.</td>
                            </tr>
                            <tr v-else v-for="obj in itemList" :key="obj.item">
                                <td v-if="option == 'popup'" class="text-center">
                                  <v-checkbox v-model="obj.selected" @change="selected(obj)"></v-checkbox>
                                </td>
                                <td class="text-left">
                                    <v-row class="pa-3">
                                        <v-avatar
                                                size="100"
                                                tile
                                        >
                                            <v-img v-if="obj.image_url != null" :src="obj.image_url"></v-img>
                                        </v-avatar>
                                        <v-col class="align-self-center">
                                            <span v-if="obj.post_id == null || obj.post_id == ''" style="color: #888">소셜 정보 없음</span>
                                            <div v-else>
                                              <div style="display: flex;margin-bottom: 10px">
                                                <img :src="require('@/assets/images/' + obj.sns_type + '.png')" style="width:22px">
                                                <span style="padding-left: 8px">{{ obj.nickname }}</span>
                                              </div>
                                              <div style="margin-bottom: 15px"><span><strong>{{ obj.content_summary }}</strong></span></div>
                                              <div><span style="font-size: 12px">{{ obj.content }}</span></div>
                                              <div v-if="obj.detail_url != null"><a :href="obj.detail_url" target="_blank">{{ obj.detail_url }}</a></div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </td>
                                <td class="text-center" style="width: 140px">{{ obj.hashtag }}</td>
                                <td class="text-center">
                                    <v-btn class="ma-2" depressed color="blue-grey darken-3" dark small
                                           @click="edit(obj)"
                                    >
                                        <v-icon style="font-size: 1.4em" left>mdi-pencil-outline</v-icon>
                                        수정
                                    </v-btn>
                                    <v-btn class="ma-2" depressed color="blue-grey darken-3" dark small
                                           @click="del(obj)"
                                    >
                                        <v-icon style="font-size: 1.4em" left>mdi-trash-can-outline</v-icon>
                                        삭제
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </v-row>

                <v-row class="px-4 pb-3">
                    <div class="text-center pa-2" style="width:100%">
                        <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                    </div>
                </v-row>

            </v-card-text>
        </v-card>

        <v-row class="pb-3">
            <v-dialog v-model="dialog" scrollable max-width="700px" persistent>
                <v-card>
                    <v-card-title style="color: #666">
                        <v-icon class="mr-3">mdi-check</v-icon>
                        {{ dialogm1.title }}
                    </v-card-title>
                    <v-card-text style="max-height: 500px; font-color: #666" class="pa-7">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="formData.sns_url"
                                        label="SNS URL"
                                        :rules="[rules.required]"
                                        :placeholder="dialogm1.message"
                                        :hint="dialogm1.message"
                                        persistent-hint
                                        :readonly="crawled"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-btn class="mt-3"
                                         color="#566270"
                                         block dark depressed
                                         :disabled="crawled"
                                         @click="crawl_review"
                                  >
                                    가져오기
                                  </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="crawled">
                              <v-col>
                                <v-text-field
                                    v-model="formData.sns_type"
                                    label="SNS 타입"
                                    hide-details
                                    disabled
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="crawled">
                              <v-col>
                                <v-text-field
                                    v-model="formData.nickname"
                                    label="작성자"
                                    hide-details
                                    disabled
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="crawled" class="pb-3">
                              <v-col cols="4">
                                <v-avatar
                                    size="170"
                                    tile
                                >
                                  <v-img :src="formData.image_url"></v-img>
                                </v-avatar>
                              </v-col>
                              <v-col>
                                <div>
                                  <v-textarea
                                      outlined
                                      v-model="formData.content"
                                      label="본문내용"
                                      hide-details
                                      disabled
                                  ></v-textarea>
                                  <div style="margin-bottom: 15px"></div>
                                  <v-text-field
                                      v-model="formData.content_summary"
                                      label="제목"
                                      counter="80"
                                      :rules="[rules.content_summary]"
                                      hint="실제 리뷰영역에 노출되는 내용이므로 강조하고 싶은 부분을 넣어주세요"
                                      persistent-hint
                                      autofocus
                                  ></v-text-field>
                                  <div style="margin-bottom: 15px"></div>
                                  <v-textarea
                                      outlined
                                      v-model="formData.hashtag"
                                      rows="2"
                                      label="검색 태그"
                                      hint="등록한 리뷰를 쉽게 검색할 수 있게 키워드를 입력해주세요. 쉼표를 넣어 여러개 입력도 가능합니다. (ex. 스커트,민트컬러)"
                                      persistent-hint
                                  ></v-textarea>
                                  <div style="margin-bottom: 15px"></div>
                                  <v-text-field
                                      v-model="formData.detail_url"
                                      label="상세페이지 URL"
                                      hint="리뷰 클릭 후 해당 상품 페이지로 이동시키고 싶으시면, 상세페이지 URL을 입력해주세요."
                                      persistent-hint
                                  ></v-text-field>
                                </div>
                              </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="teal darken-1" text @click="closeDialog">닫기</v-btn>
                        <v-btn v-if="crawled" color="teal darken-1" text @click="modify">수정하기</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

      <v-overlay style="z-index: 1000"
          :opacity=".8"
          :value="overlay"
      >
        <div style="display:flex;flex-direction: column;">
          <v-progress-circular indeterminate size="64" style="align-self: center"></v-progress-circular>
          <div style="padding-bottom: 10px"></div>
          <span style="align-self: center">리뷰를 가져오는 중입니다.<br>잠시만 기다려주세요.</span>
        </div>
      </v-overlay>

    </div>
</template>
<script>
    export default {
        watch:{
            "page":function () {
                this.load()
            }
        },
        props:{
            company_code:{
                type:String,
                default:''
            },
            option:{
                type:String,
                default:''
            }
        },
        mounted() {
            // formData.myshp, formData.type 에 값 셋팅
            if(!!this.company_code){
                this.formData.company_code = this.company_code
            }
            this.page = 1
            this.pageCount = 1
            this.itemsPerPage = 50
            this.load()
        },
        methods:{
            search: function() {
                this.page = 1;
                this.load();
            },
            load : function(){
                var that = this
                var sitem = this.search_item==null?'':this.search_item
                var url = "/api/more/review_list?search_item=" + sitem
                url += "&page="+(this.page-1)
                url += "&size=" + this.itemsPerPage
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }

                this.$http.get(url).then(res=>{
                    this.chk_all = false
                    this.itemList = res.data.content
                    if(res.data.content.length == 0) {
                      this.pageCount = 1
                      this.totalCount = 0
                    }
                    res.data.content.forEach(function(e, i){
                        //e.regdate = moment(e.regdate).format('YYYY-MM-DD HH:mm:ss')
                        var url2 = "/api/more/review/get_catalog?item=" + e.post_id
                        if(!!that.company_code){
                            url2 += "&company_code=" + that.company_code
                        }
                        that.$http.get(url2).then(res2=>{
                            e.nickname = res2.data.nickname
                            e.image_url = 'https://cdnpxy0.mocoplex.com/crop/250x250/' + res2.data.image_url
                            e.content = res2.data.content
                            e.content_summary = res2.data.content_summary
                            e.detail_url = res2.data.detail_url
                            that.$set(that.itemList, i, e)
                        }).catch((err2)=>{

                        })
                    })
                    if(res.data.totalPages >= 1){
                        this.pageCount = res.data.totalPages
                        this.totalCount = res.data.totalElements
                    }
                }).catch((err)=>{
                    this.chk_all = false
                    var msg = err.response.data.msg
                    this.$event.$emit('my-toast',msg)
                })
            },
            crawl_review: function () {
                if (!this.$refs.form.validate()) {
                  return;
                }

                var that = this
                var url = "/api/more/add/review_post"
                if (!!this.company_code) {
                  url += "?company_code=" + this.company_code
                }
                this.overlay = true
                this.$http.post(url, this.formData).then(res => {
                  if (res.status == '200') {
                    that.overlay = false
                    that.crawled = true
                    that.$event.$emit('alert', "등록되었습니다")

                    that.formData.post_id = res.data.post_id
                    that.formData.sns_type = res.data.sns_type
                    that.formData.nickname = res.data.nickname
                    that.formData.content = res.data.content
                    that.formData.image_url = res.data.image_url
                    if(res.data.content_summary){
                      that.formData.content_summary = res.data.content_summary
                    }

                    that.load()
                  }
                }).catch((err) => {
                  that.overlay = false
                  var msg = '오류가 발생했습니다'
                  if(err.response.data.msg == 'NOT_SUPPORT'){
                    msg = '지원하지 않는 URL 입니다'
                  } else if(err.response.data.msg == 'EXISTED'){
                    msg = '이미 등록된 리뷰 입니다'
                  }
                  this.$event.$emit('alert',msg)
                })
            },
            add : function(){
                var title = ''
                var msg = ''
                title = '소셜 리뷰 등록하기'
                msg = '소셜 리뷰 피드 URL을 입력하고 가져오기 버튼을 눌러주세요 (인스타그램, 네이버 블로그, 틱톡, 유튜브, 카카오스토리)'
                this.dialogm1.title = title
                this.dialogm1.message = msg
                this.dialog = true
            },
            del : function(obj){
                var param = {};
                param.post_id = obj.post_id

                var that = this
                this.$event.$emit('confirm', '삭제', '선택한 항목을 삭제하시겠습니까?', function () {
                    var url = "/api/more/delete/review_post"
                    if(!!that.company_code){
                      url += '?company_code=' + that.company_code
                    }
                    this.$http.post(url,param).then(res=>{
                        if(res.status == '200'){
                            that.$event.$emit('my-toast','삭제되었습니다.')
                            that.remove(obj.item)
                        }
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        that.$event.$emit('my-toast',msg)
                    })
                })
            },
            remove:function(item) {

                for (var i = 0; i < this.itemList.length; i++) {
                    if (this.itemList[i].item === item) {
                        this.itemList.splice(i, 1);
                        this.totalCount--
                        break
                    }
                }
            },
            closeDialog: function () {
              if(this.crawled && !this.formData.content_summary){
                this.$event.$emit('alert', '먼저 리뷰 영역에 노출할 제목을 저장해주세요.')
                return
              }
              this.dialog = false
              this.crawled = false
              this.$refs.form.reset()
            },
            edit: function (obj) {
              this.formData.sns_url = obj.sns_url
              this.formData.post_id = obj.post_id
              this.formData.sns_type = obj.sns_type
              this.formData.nickname = obj.nickname
              this.formData.content = obj.content
              this.formData.image_url = obj.image_url
              if(obj.content_summary){
                this.formData.content_summary = obj.content_summary
              }
              this.formData.detail_url = obj.detail_url
              this.formData.hashtag = obj.hashtag

              this.dialogm1.title = '소셜 리뷰 수정하기'
              //this.dialogm1.message = msg
              this.crawled = true
              this.dialog = true
            },
            modify : function(){
                if (!this.$refs.form.validate()) {
                    return;
                }

                var that = this
                var url = "/api/more/update/review_post"
                if(!!this.company_code){
                  url += '?company_code=' + this.company_code
                }
                this.$http.post(url, this.formData).then(res => {
                    if (res.status == '200') {
                        that.$event.$emit('alert', "저장되었습니다")
                        that.closeDialog()
                        that.load()
                    }
                }).catch((err) => {
                    that.$event.$emit('alert', '오류가 발생했습니다')
                })
            },
            getTotal :function () {
                return "(Total : " + this.totalCount + ")"
            },
            selected: function (obj) {
              this.$emit("reviewSelected", obj);
            }
        },
        data: () => ({
            chk_all:false,
            valid:false,
            overlay:false,
            search_item: '',
            formData:{
                company_code:'',
                sns_url:'',
            },
            page: 1,
            pageCount: 1,
            totalCount: 0,
            itemList: [],
            dialog: false,
            dialogm1:{
                title:'',
                message:''
            },
            rules: {
                required: value => !!value || '필수값입니다',
                content_summary: value => !!value || '실제 리뷰영역에 노출되는 내용이므로 강조하고 싶은 부분을 넣어주세요'
            },
            crawled: false,
        }),
    }
</script>
<style scoped>
    .bbb {
        border-bottom: 1px solid #666;
    }
    .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg{
      background-color: #e3e3e3 !important;
      color: #8c8c8c !important;
    }
</style>
