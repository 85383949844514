import '@babel/polyfill'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'

import {store} from "./store"
var eventBus = new Vue()
Vue.prototype.$event = eventBus
Vue.prototype.$store = store


import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false


import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

const VueCookie = require('vue-cookie');
// 플러그인 사용
Vue.use(VueCookie);

Vue.use(require('vue-moment'));

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
})



// global
Vue.prototype.$global = {
  getParameter : function (param) {
    var returnValue;
    var url = location.href;
    var parameters = (url.slice(url.indexOf('?') + 1, url.length)).split('&');
    for (var i = 0; i < parameters.length; i++) {
      var varName = parameters[i].split('=')[0];
      if (varName.toString().toUpperCase() == param.toString().toUpperCase()) {
        returnValue = parameters[i].split('=')[1];
        return decodeURIComponent(returnValue);
      }
    }
  }
}


String.prototype.price = function(){
  var n = (this + '');

  var reg = /(^[+-]?\d+)(\d{3})/;
  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

  return n
};


Number.prototype.price = function(){
  if(this==0) return 0;

  var n = (this + '');
  return n.price()
};

Number.prototype.floatNumber = function(){
  return this.toFixed(2)
};

String.prototype.dateFormat = function(){
  if(this.length > 8) {
    return this;
  }
  return this.substring(0,4) + '-' + this.substring(4,6) + '-' + this.substring(6,8);
};




// global
// 숫자 타입에서 쓸 수 있도록 format() 함수 추가
Number.prototype.price = function (w) {
  if (this == 0) return 0;

  var reg = /(^[+-]?\d+)(\d{3})/;
  var n = (this + '');

  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

  if (w != null)
    return n + " <span class='won'>" + w + "</span>";
  else
    return n
};

Vue.prototype.$ymdhms = function (date) {
  if(!!date) {
//        return moment('YYYY.MM.DD HH:mm:ss')
    var v = this.$moment(date).format('YYYY.MM.DD HH:mm:ss').toString()
    return '<span style="font-size: 12px">'+v+'</span>'
  }
  return v
  //{{ item.regdate | moment('YYYY.MM.DD HH:mm:ss')}}
}
Vue.prototype.$ymd = function (date) {
  if(!!date) {
//        return moment('YYYY.MM.DD HH:mm:ss')
    var v = this.$moment(date).format('YYYY.MM.DD').toString()
    return '<span style="font-size: 10px">'+v+'</span>'
  }
  return v
  //{{ item.regdate | moment('YYYY.MM.DD HH:mm:ss')}}
}
Vue.prototype.$_ymd = function (strDate) {
  if(!!strDate) {
//        return moment('YYYY.MM.DD HH:mm:ss')
    var v = strDate.substring(0,4) + '.' + strDate.substring(4,6) + '.' + strDate.substring(6,8)
    return '<span style="font-size: 12px">'+v+'</span>'
  }
  return v
  //{{ item.regdate | moment('YYYY.MM.DD HH:mm:ss')}}
}

Vue.prototype.$_ymd_s = function (strDate) {
  if(!!strDate) {
//        return moment('YYYY.MM.DD HH:mm:ss')
    strDate = strDate.replace(/-/gi,'')
    var v = strDate.substring(0,4) + '/' + strDate.substring(4,6) + '/' + strDate.substring(6,8)
    return '<span style="font-size: 12px">'+v+'</span>'
  }
  return v
  //{{ item.regdate | moment('YYYY.MM.DD HH:mm:ss')}}
}

Vue.prototype.$_ymd2 = function (strDate) {
  if(!!strDate) {
//        return moment('YYYY.MM.DD HH:mm:ss')
    var v = strDate.substring(0,4) + '.' + strDate.substring(4,6) + '.' + strDate.substring(6,8)
  }
  return v
  //{{ item.regdate | moment('YYYY.MM.DD HH:mm:ss')}}
}

Vue.prototype.$ymd = function () {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('');
}

new Vue({
  store,
  vuetify,
  router,

  render: h => h(App)
}).$mount('#app')