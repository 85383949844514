<template>
    <div>

        <v-progress-linear
                v-if="myprogress"
                indeterminate
                color="yellow darken-2"
        ></v-progress-linear>

        <v-snackbar
                v-model="snackbar"
                :timeout="3000"
                class="pb-8"
                multi-line
        >
            <span v-html="msg"></span>
            <v-btn
                    color="pink"
                    text
                    @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>


        <v-dialog
                v-model="mprompt"
                max-width="290"
        >
            <v-card>
                <v-card-title class="headline">{{mtitle}}</v-card-title>

                <v-card-text>
                    <div>
                        {{mcontent}}
                    </div>
                    <div class="mt-6">
                        <v-text-field v-model="minput"
                                      color="teal"
                                      :label="mlabel"
                                      outlined
                                      ref="inp"
                                      v-on:keyup.enter="promptok()"
                                      hide-details
                        />
                    </div>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="green darken-1"
                            text
                            @click="mprompt = false"
                    >
                        취소
                    </v-btn>

                    <v-btn
                            color="green darken-1"
                            text
                            @click="promptok"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog
                v-model="mconfirm" persistent
                max-width="350"
                @keyup.enter="confirmok()"
        >
            <v-card>
                <v-card-title class="headline">{{mtitle}}</v-card-title>

                <v-card-text v-html="mcontent"></v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="green darken-1"
                            text
                            @click="confirmcancel()"
                    >
                        아니오
                    </v-btn>

                    <v-btn
                            color="green darken-1"
                            text
                            @click="confirmok()"
                    >
                        예
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="mnoti" persistent
            max-width="360"
            @keyup.enter="confirmok()"
        >
          <v-card>
            <v-card-title class="headline">{{mtitle}}</v-card-title>

            <v-card-text v-html="mcontent"></v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  color="green darken-1"
                  text
                  @click="notiok()"
              >
                확인
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


    </div>
</template>

<script>
    export default {
        data: () => ({
            minput:'',
            mlabel:'',

            mprompt: false,

            mconfirm: false,
            mnoti: false,

            myprogress: false,
            snackbar: false,
            msg: '',

            mtitle:'',
            mcontent:'',
            mcallback: null,
            mcallback2: null,
        }),
        created() {
            if(process.client) {
                document.addEventListener('keydown', this.onKeyPressEvt)
            }
        },
        destroyed() {
            if(process.client) {
                document.removeEventListener('keydown', this.onKeyPressEvt)
            }
        },
        mounted() {
            this.$event.$on('loading', this.loading)
            this.$event.$on('alert', this.alert)

            this.$event.$on('confirm', this.confirm)
            this.$event.$on('noti', this.noti)
            this.$event.$on('prompt', this.prompt)
        },
        methods: {
            onKeyPressEvt:function(evt) {
                if (evt.keyCode === 13) {
                    if(this.mconfirm) {
                        this.confirmok()
                    }
                }
            },
            promptok:function() {
                this.mprompt = false
                if(this.mcallback != null)
                    this.mcallback(this.minput)

                this.minput = ''
            },
            prompt:function(t,m, lb, callback) {
                this.mcallback = null

                this.mlabel = lb

                this.mtitle = t
                this.mcontent = m
                this.mprompt = true

                if(process.client) {
                    var v = this
                    this.$nextTick(() => {

                        setTimeout(function () {
                            try {
                                v.$refs.inp.focus()
                            } catch (e) {
                            }
                        },500)
                    })
                }

                this.mcallback = callback
            },
            confirmcancel:function() {
                this.mconfirm = false
                if(this.mcallback2 != null)
                    this.mcallback2()
            },
            confirmok:function() {
                this.mconfirm = false
                if(this.mcallback != null)
                    this.mcallback()
            },
            notiok:function() {
              this.mnoti = false
              if(this.mcallback != null)
                this.mcallback()
            },
            confirm:function(t,m, callback, callback2) {
                this.mcallback = null
                this.mcallback2 = null

                this.mtitle = t
                this.mcontent = m
                this.mconfirm = true

                this.mcallback = callback
                if(!!callback2) {
                    this.mcallback2 = callback2
                }
            },
            noti:function(t,m, callback) {
              this.mcallback = null

              this.mtitle = t
              this.mcontent = m
              this.mnoti = true

              this.mcallback = callback
              if(!!callback2) {
                this.mcallback2 = callback2
              }
            },
            loading: function (b) {
                this.myprogress = b
            },
            alert: function (msg) {
                this.msg = msg
                this.snackbar = true

            }

        }
    }
</script>