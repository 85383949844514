<template>
    <div class="d-flex" v-if="!!common">
      <div style="width: 543px;min-width: 543px">
            <div class="py-2 d-flex" style="height:100%;flex-direction: column;">
                <v-card-title class="subtitle">
                    디자인 미리보기
                </v-card-title>

                <v-card
                        style="overflow: hidden;"
                        class="flex-grow-1"
                        outlined
                >
                    <v-tabs
                            class="d-flex" style="flex-direction:column;height: 100%;"
                            fixed-tabs
                            background-color="teal lighten-2"
                            dark
                            v-model="mytab2"
                    >
                        <v-tab key="pc" style="font-size: 18px">
                            <span v-if="mytab2==0"><b>PC</b></span>
                            <span v-else>PC</span>
                        </v-tab>
                        <v-tab key="mobile" style="font-size: 18px">
                          <span v-if="mytab2==0">Mobile</span>
                          <span v-else><b>Mobile</b></span>
                        </v-tab>

                        <v-tabs-items
                                v-model="mytab2"
                                class="tab-item-wrapper"
                                touchless
                        >
                            <v-tab-item
                                    style="height: 947px;position: relative"
                                    key="pc"
                            >
                                <v-img style="width: 100%;height: 100%" :src="pc_back" />
                                <iframe v-show="!!common" id="pc_panel" src="/html/panel_promo_example.html?v=2" frameborder="0"
                                        v-on:load="iframe_loaded"
                                        style="position:absolute;top:98px;left:0;width: 543px;height:852px;"></iframe>
                                <div v-if="show_l" id="sen_l" style="width:150px;height:300px;background-color: rgba(255,0,0,0.4);position:absolute;top:0;right:0;pointer-events: none;"></div>
                                <div v-if="show_m" id="sen_m" style="width:300px;height:300px;background-color: rgba(255,0,0,0.4);position:absolute;top:0;right:0;pointer-events: none;"></div>
                                <div v-if="show_h" id="sen_h" style="width:100%;height:300px;background-color: rgba(255,0,0,0.4);position:absolute;top:0;right:0;pointer-events: none;"></div>
                            </v-tab-item>
                            <v-tab-item
                                    style="height: 782px;position: relative"
                                    key="mobile"
                            >
                                <v-img style="width: 100%;height: 100%" :src="mo_back" />
                                <iframe v-show="!!common" id="mobile_panel" src="/html/panel_m_promo_example.html" frameborder="0"
                                        v-on:load="iframe_loaded"
                                        style="position:absolute;top:101px;left:29px;width: 483px;height:681px;"></iframe>
                            </v-tab-item>
                        </v-tabs-items>

                    </v-tabs>
                </v-card>
            </div>
        </div>
      <div class="flex-grow-1">
        <div class="pa-2">
          <v-card-title class="subtitle">
            공통 설정 (PC/Mobile)
          </v-card-title>

          <v-card
              outlined
              class="pa-3"
          >
            <div class="pa-2">
              <div>패널 컬러</div>
              <div class="pl-5">
                <v-row>
                  <v-col cols="5">
                    <v-subheader style="height: 35px">버튼 컬러</v-subheader>
                  </v-col>
                  <v-col>
                    <my-color v-model="common.button_color" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="pt-0">
                    <v-subheader style="height: 35px">버튼 마우스오버 컬러</v-subheader>
                  </v-col>
                  <v-col class="pt-0">
                    <my-color v-model="common.button_hover_color" />
                  </v-col>
                </v-row>

                <v-row class="pt-4">
                  <v-col cols="5">
                    <v-subheader style="height: 35px">버튼 텍스트 컬러</v-subheader>
                  </v-col>
                  <v-col>
                    <my-color v-model="common.button_text_color" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5" class="pt-0">
                    <v-subheader style="height: 35px">버튼 마우스오버 텍스트 컬러</v-subheader>
                  </v-col>
                  <v-col class="pt-0">
                    <my-color v-model="common.button_hover_text_color" />
                  </v-col>
                </v-row>

                <v-row class="pt-4" v-if="false">
                  <v-col cols="5">
                    <v-subheader style="height: 35px">강조 텍스트 컬러</v-subheader>
                  </v-col>
                  <v-col>
                    <my-color v-model="common.point_text_color" />
                  </v-col>
                </v-row>

              </div>
            </div>
          </v-card>

        </div>


        <div v-if="mytab2 == 0" class="pa-2">
          <div>
            <v-card-title class="subtitle">
              노출 설정 (PC)
            </v-card-title>

            <v-card
                outlined
                class="pa-3"
            >
              <div class="d-flex pa-2">
                <div class="pt-1">
                  마우스 커서 감지
                  <v-tooltip v-model="show1" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey lighten-1">mdi-help-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>닫기 버튼의 감지 영역을 조절합니다.<br>(‘상’ 일 수록 노출빈도가 높아집니다.)</span>
                  </v-tooltip>
                </div>
              </div>

              <div class="mt-3 pl-2 pr-2 pb-2">
                <v-select
                    :items="s_status"
                    label="감지영역"
                    v-model="common.m_sensitivity"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    hide-details
                    @change="changeSensitivity"
                ></v-select>
              </div>

              <div class="d-flex mt-6 pl-2 pr-2 pb-2">
                <div class="pt-1">
                  웹 닫기 감지
                  <v-tooltip v-model="show2" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey lighten-1">mdi-help-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>탭 ‘닫기’버튼 클릭 시 패널이 노출되는<br>기능을 ON/OFF 할 수 있습니다.</span>
                  </v-tooltip>
                </div>
                <div class="flex-grow-1">
                  <v-switch
                      v-model="common.exit_alert"
                      style="float:right"
                      class="ma-0"
                      color="teal"
                      hide-details
                      inset
                  ></v-switch>
                </div>
              </div>
            </v-card>
          </div>
        </div>

        <v-row class="pa-4">
          <v-col>
            <v-btn
                block
                depressed
                dark
                color="grey"
                @click="load_common"
            >
              취소
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                block
                depressed
                dark
                color="#EC407A"
                @click="updatePanelCommon"
            >
              저장
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-dialog v-model="isshow" persistent max-width="800px" style="background-color: white">
        <v-card class="pa-4">
          <v-toolbar
              flat
              color="#fff"
          >
            <v-toolbar-title style="color: #666;">프로모션 정보</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="isshow = false"
            >
              <v-icon color="#666">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
          </v-card-text>

          <div class="pl-4 pr-4">

            <v-form
                ref="form1"
                v-model="valid"
                lazy-validation
            >
              <v-row class="ma-0 pa-0">
                <div v-if="mytab2 == 0" class="text-right" style="width: 100%;font-size:12px;color:#f00;font-weight:600">
                  [이미지 사이즈] 가로: 420px, 세로: 850px
                </div>
                <div v-else class="text-right" style="width: 100%;font-size:12px;color:#f00;font-weight:600">
                  [이미지 사이즈] 가로: 640px, 세로: 590px
                </div>
              </v-row>
              <v-row class="mt-0 pt-0">
                <v-col cols="4" style="align-self: center;color: #666;font-size: 0.9em" v-if="!!filename">
                                <span style="text-overflow: ellipsis;overflow:hidden;">
                                    {{filename}}
                                </span>
                </v-col>
                <v-col :cols="!!filename?8:12">
                  <v-btn class="mt-3"
                         color="#566270"
                         block dark depressed
                         @click="attachFile"
                  >
                    프로모션 이미지 파일 선택
                  </v-btn>
                  <input ref="file" id="file" type="file" accept="image/*" hidden
                         @change="fileHandle">
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-text-field class="img_view"
                                label="프로모션 이름"
                                v-model="banner_form.pro_name"
                                outlined
                                required
                                dense
                                :rules="required"
                  >
                    <template v-if="!!banner_form.pro_image_url" v-slot:append-outer>
                      <v-btn style="margin-top:-6px !important"
                             color="teal"
                             @click="showFile(banner_form.pro_image_url)"
                             text
                      >이미지 보기</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                      label="프로모션 랜딩 URL"
                      v-model="banner_form.pro_page_url"
                      outlined
                      required
                      dense
                      :rules="required"
                      hint="http:// 또는 https:// 를 포함해주세요"
                      persistent-hint
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </div>


          <div class="pa-4 d-flex">
            <v-btn class="mr-1 flex-grow-1"
                   depressed
                   dark
                   color="grey"
                   @click="isshow=false"
            >
              취소
            </v-btn>
            <v-btn class="ml-1 flex-grow-1"
                   depressed
                   dark
                   color="#EC407A"
                   @click="addBanner"
            >
              저장
            </v-btn>
          </div>

          <reserved-promotion :company_code="company_code" :platform="mytab2==0?'pc':'mobile_web'" :device="mytab2==0?'PC':'Mobile'" @updateReservedBanner="refreshBanner" />

        </v-card>
      </v-dialog>

    </div>
</template>

<script>
    import { mapMutations, mapGetters } from 'vuex'
    import MyColor from "../../common/MyColor";
    import ReservedPromotion from "@/components/common/ReservedPromotion";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        props:{
          company_code:{
            type: String
          },
        },
        watch:{
            admin: function () {
                this.loadAll()
            },
            "company_code": function () {
                this.loadAll()
            },
            mytab2: function () {
              this.load_common()
            },
        },
        components: {
            MyColor,
            ReservedPromotion,
        },
        created() {
            this.$event.$on('color_changed',this.colorChanged)
        },
        mounted() {
            window.addEventListener('message', this.receiveMessage, false);
            this.loadAll()
        },
        beforeDestroy() {
            window.removeEventListener('message', this.receiveMessage);
            this.$event.$off('color_changed',this.colorChanged)
        },
        methods:{
            receiveMessage(event) {
              try{
                var data = JSON.parse(event.data)
                if(data.command == 'edit_banner') {
                  this.editBanner()
                }
              } catch (e){
                //console.log(e)
              }
            },
            loadAll: function() {
                this.mytab2 = 0
                this.load_common()
            },
            loadAll2: function() {
              this.load_common()
            },
            load_common: function(){
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.company_code)
                    return

                this.common = null
                try{
                    var iframeId = this.mytab2==0?"pc_panel":"mobile_panel"
                    var iframe = document.getElementById(iframeId)
                    iframe.contentDocument.location.reload(true)
                }catch(e){
                }

                var that = this
                var url = "/api/more/get/panel_common"
                if(!!this.company_code){
                    url += "?company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.common = res.data
                        //var frameContentWindow = document.getElementById('pc_panel').contentWindow
                        //console.log(frameContentWindow)
                        that.load_banner()
                    }
                }).catch((err)=>{
                    console.log(err)
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            load_banner:function() {
              var that = this
              var platform = this.mytab2==0?"pc":"mobile_web"
              var url = "/api/more/get/panel_default_promotion?platform=" + platform
              if(!!this.company_code){
                url += "&company_code=" + this.company_code
              }
              this.$http.get(url).then(res=>{
                if(res.data.length > 0) {
                  that.default_banner = res.data[0]
                } else {
                  that.default_banner = null
                }
                this.load_banner2()
              }).catch((err)=>{
                console.log(err)
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })
            },
            load_banner2:function() {
              var that = this
              var platform = this.mytab2==0?"pc":"mobile_web"
              var url = "/api/more/get/panel_current_promotion?platform=" + platform
              if(!!this.company_code){
                url += "&company_code=" + this.company_code
              }
              this.$http.get(url).then(res=>{
                if(res.data.length > 0) {
                  that.banner = res.data[0]
                  that.bannerChanged()
                } else {
                  that.banner = null
                }
              }).catch((err)=>{
                console.log(err)
                that.$event.$emit('alert',"데이터 조회에 실패했습니다")
              })
            },
            updatePanelCommon: function(){
                var url = "/api/more/update/panel_common"
                if (!!this.company_code) {
                    this.common.company_code = this.company_code
                }
                if(this.common.exit_alert){
                    this.common.exit_alert = 1
                }else{
                    this.common.exit_alert = 0
                }

                var that = this
                this.$event.$emit('confirm', '패널 공통 설정', '변경사항을 저장하시겠습니까?', function () {
                    that.$http.post(url, that.common).then(res => {
                        if (res.status == '200') {
                            that.load_common()
                            that.$event.$emit('alert', '패널 공통 설정이 변경되었습니다')
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                })
            },
            iframe_loaded: function() {
                this.sendMessage()
            },
            colorChanged: function(){
                var iframeId = this.mytab2==0?"pc_panel":"mobile_panel"
                var frameContentWindow = document.getElementById(iframeId).contentWindow
                if(frameContentWindow.postMessage) {
                    var data = {
                        'command': 'change_color',
                        'btn': this.common.button_color,
                        'btn_hv': this.common.button_hover_color,
                        'btn_txt': this.common.button_text_color,
                        'btn_hv_txt': this.common.button_hover_text_color,
                        'point': this.common.point_text_color,
                    }
                    frameContentWindow.postMessage(JSON.stringify(data), "*")
                }
            },
            bannerChanged: function(){
              var iframeId = this.mytab2 == 0 ? "pc_panel" : "mobile_panel"
              var frameContentWindow = document.getElementById(iframeId).contentWindow
              if(frameContentWindow.postMessage) {
                var data = {
                  'command': 'change_banner',
                  'pro_name': this.banner.pro_name,
                  'pro_page_url': this.banner.pro_page_url,
                  'pro_image_url': this.banner.pro_image_url,
                }
                console.log(data)
                frameContentWindow.postMessage(JSON.stringify(data), "*")
              }
            },
            sendMessage: function() {
                try {
                  var iframeId = this.mytab2 == 0 ? "pc_panel" : "mobile_panel"
                  var frameContentWindow = document.getElementById(iframeId).contentWindow
                  if (frameContentWindow.postMessage) {
                    var data = {
                      'command': 'change_color',
                      'btn': this.common.button_color,
                      'btn_hv': this.common.button_hover_color,
                      'btn_txt': this.common.button_text_color,
                      'btn_hv_txt': this.common.button_hover_text_color,
                      'point': this.common.point_text_color,
                    }
                    frameContentWindow.postMessage(JSON.stringify(data), "*")
                  }

                  if (frameContentWindow.postMessage) {
                    try {
                      var data = {
                        'command': 'change_banner',
                        'pro_name': this.banner.pro_name,
                        'pro_page_url': this.banner.pro_page_url,
                        'pro_image_url': this.banner.pro_image_url,
                      }
                      frameContentWindow.postMessage(JSON.stringify(data), "*")
                    }catch (e){}
                  }

                  if(frameContentWindow.postMessage) {
                      var data = {
                          'command': 'show_panel',
                      }
                      frameContentWindow.postMessage(JSON.stringify(data), "*")
                  }
                }catch (e) {

                }
            },
            dataload:function() {
                //var v = Object.assign({}, this.form)
                //this.$store.commit('reportParam', {value:v})
            },
            editBanner: function() {
              if(this.default_banner == null) {
                this.show()
              } else {
                this.update()
              }
            },
            show:function() {
              this.banner_form.pro_name = ''
              this.banner_form.pro_image_url = ''
              this.banner_form.pro_page_url = ''

              this.banner_form.mode = 'insert'

              this.isshow=true
              this.$refs.form1.resetValidation()
            },
            update:function() {
              this.banner_form = Object.assign({}, this.default_banner)

              this.banner_form.mode = 'update'

              this.isshow=true
            },
            showFile:function (url) {
              window.open(
                  url,
                  '_blank'
              );
            },
            attachFile:function () {

              // upload
              document.getElementById("file").click()

            },
            fileHandle() {
              var f2 = this.$refs.file.files[0];
              if (!!!f2)
                return;

              this.file = f2
              var v = this

              // 입력된 파일로 교체
              var reader = new FileReader();
              reader.addEventListener("load", function () {
                //v.imgsrc = reader.result
              }, false);

              if (this.file) {
                reader.readAsDataURL(this.file);
              }

              this.filename = document.getElementById("file").value
              var n = this.filename.lastIndexOf('/')
              if (n < 0) {
                n = this.filename.lastIndexOf('\\')
              }
              if (n < 0)
                return

              this.filename = this.filename.substring(n + 1, this.filename.length)
            },
            addBanner: function() {
              if (!this.$refs.form1.validate()) {
                return
              }

              if(!!this.file) {
                this.submitFile()
              } else {
                this.addBanner2()
              }

            },
            addBanner2: function() {
              if(this.banner_form.mode == 'insert') {
                if (this.banner_form.b_type == 'I' && !!!this.fileurl) {
                  this.$event.$emit('alert', "이미지를 첨부해주세요")
                  return
                }
              }else{
                if (this.banner_form.b_type == 'I'){
                  if(!!!this.banner_form.pro_image_url && !!!this.fileurl) {
                    this.$event.$emit('alert', "이미지를 첨부해주세요")
                    return
                  }
                }
              }

              var that = this
              var url = ''
              if(this.banner_form.mode == 'insert'){
                url = "/api/more/create/panel_promotion"
              }else if(this.banner_form.mode == 'update'){
                url = "/api/more/update/panel_promotion"
              }
              this.banner_form.platform = this.mytab2==0?"pc":"mobile_web"
              if(!!this.company_code){
                this.banner_form.company_code = this.company_code
              }
              if(!!this.fileurl){
                this.banner_form.pro_image_url = this.fileurl
              }

              var msg = ''
              if(this.banner_form.mode == 'insert'){
                msg = '프로모션이 등록되었습니다'
              }else if(this.banner_form.mode == 'update'){
                msg = '프로모션이 변경되었습니다'
              }
              this.$http.post(url, this.banner_form).then(res=>{
                if(res.status == '200'){
                  that.file = null
                  that.filename = null
                  that.fileurl = ''
                  that.isshow = false
                  that.$event.$emit('alert',msg)
                  that.load_common()
                }
              }).catch((err)=>{
                var msg = '오류가 발생했습니다'
                var errmsg = err.response.data.msg
                if(errmsg == 'DUPLICATED'){
                  msg = '노출기간에 중복된 프로모션이 있습니다'
                }
                that.$event.$emit('alert',msg)
              })
            },
            refreshBanner() {
              this.loadAll2()
            },
            submitFile(){
              let formData = new FormData()
              formData.append('file', this.file)

              var v = this
              this.isLoading = true
              this.$http.post(
                  'https://img0-upload.adnext.co/upload/xmore/image',
                  //'http://localhost:8181/upload/xmore/image',
                  //'http://localhost:8181/upload/xmore/vid',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
              ).then(function(data){
                var url = data.data
                // 업로드한 파일 이미지
                v.fileurl = url
                v.addBanner2()
              })
                  .catch(function(err){
                    v.$event.$emit('alert', '파일 업로드에 실패하였습니다.')
                    v.isLoading = false
                  });
            },
            changeSensitivity: function (){
              this.show_l = false;
              this.show_m = false;
              this.show_h = false;
              if(this.timeoutL != null) {
                clearTimeout(this.timeoutL)
              }
              if(this.timeoutM != null) {
                clearTimeout(this.timeoutM)
              }
              if(this.timeoutH != null) {
                clearTimeout(this.timeoutH)
              }
              var sen = this.common.m_sensitivity
              if(sen == 'h') {
                this.show_h = true
                this.timeoutH = setTimeout(() => {
                  this.show_h = false
                }, 8000);
              }else if(sen == 'm') {
                this.show_m = true
                this.timeoutM = setTimeout(() => {
                  this.show_m = false
                }, 8000);
              }else if(sen == 'l') {
                this.show_l = true
                this.timeoutL = setTimeout(() => {
                  this.show_l = false
                }, 8000);
              }
            }
        },
        data: () => ({
            common: null,
            show_l: false,
            show_m: false,
            show_h: false,
            timeoutL: null,
            timeoutM: null,
            timeoutH: null,
            pc_back: require('@/assets/images/pc_back.png'),
            mo_back: require('@/assets/images/mo_back.png'),
            ifr_pc_url: "/html/panel_promo_example.html",
            s_status:[
                {
                    id: "h",
                    name: "상"
                },
                {
                    id: "m",
                    name: "중"
                },
                {
                    id: "l",
                    name: "하"
                },
            ],

            mytab2:null,
            show_pc:false,
            params:{},

            tab:0,
            show1:false,
            show2:false,
            banner: null,
            default_banner: null,
            banner_form:{
              pro_name: '',
              pro_image_url: '',
              pro_page_url: '',
              mode: ''
            },
            valid: false,
            filename:null,
            file:null,
            fileurl : '',
            isshow:false,
            required: [
              v => !!v || '필수 정보입니다'
            ],
        })
    }
</script>

<style scoped>
.mtable > tbody > tr > td {
    padding: 20px;
}
.tab-item-wrapper {
    /* vuetify sets the v-tabs__container height to 48px */
    height: 100%;
}
.rounded_card {
  /* Add shadows to create the "card" effect */
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.w100_h100 {
  width: 8vw;
  height: 8vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.edge {
  border : 2px solid #333333;
}
.txt_btn {
  background: url('../../../assets/images/t-solid.svg') no-repeat center;
  background-size: contain;
  height:80%;
  width: 80%;
  margin-top: 10px;
}
.img_btn {
  background: url('../../../assets/images/image-regular.svg') no-repeat center;
  background-size: contain;
  height:80%;
  width: 80%;
  margin-top: 10px;
}
.video_btn {
  background: url('../../../assets/images/youtube.svg') no-repeat center;
  background-size: contain;
  height:80%;
  width: 80%;
  margin-top: 10px;
}
.box_desc {
  padding-top: 5px;
  padding-bottom: 10px;
  color: #4d3750;
  font-size: 1.8vh;
}

.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
