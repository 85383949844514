<template>
    <div>
        <v-row v-if="option == 'product' || option == 'keyword'" class="pt-3 pb-6">
          <v-col cols="12" sm="5">
            <v-text-field
                v-model="search_item"
                :placeholder="ph_search"
                hide-details
                dense
                clearable
                @keydown.enter="search"
                append-icon="mdi-magnify"
                @click:append="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <div style="font-size: 0.83em;color: #666;">
            <div v-if="option == 'product'" class="mb-1">
                * 해당 기능은 <span style="text-decoration: underline;text-underline-position: under;">개인화추천 로직을 사용하는 상품(개인화추천패널, 개인화추천티커, 추천/서치)에만 적용</span>이 됩니다.
            </div>
            <div v-if="option == 'keyword'" class="mb-1">
              * 해당 기능은 <span style="text-decoration: underline;text-underline-position: under;">서치 상품에만 적용</span>이 됩니다.
            </div>
            <div v-if="option == 'product_first'" class="mb-1">
                * 해당 기능은 <span style="text-decoration: underline;text-underline-position: under;">개인화추천 로직을 사용하는 상품(개인화추천패널, 개인화추천티커, 추천/서치)에만 적용</span>이 됩니다.<br>
                * 저장 후 실시간 반영되지 않으며, <span style="text-decoration: underline;text-underline-position: under;">매 시 10~15분 사이에 반영</span>됩니다. (예: 9시20분에 최종 수정시 10시10분 이후에 반영)<br>
                * <span style="text-decoration: underline;text-underline-position: under;">최대 5개까지 등록</span> 가능합니다.
            </div>
            <div v-if="option == 'keyword_first'" class="mb-1">
                * 해당 기능은 <span style="text-decoration: underline;text-underline-position: under;">서치 상품에만 적용</span>이 됩니다.<br>
                * 저장 후 실시간 반영되지 않으며, <span style="text-decoration: underline;text-underline-position: under;">매 시 10~15분 사이에 반영</span>됩니다. (예: 9시20분에 최종 수정시 10시10분 이후에 반영)<br>
                * <span style="text-decoration: underline;text-underline-position: under;">최대 5개까지 등록</span> 가능합니다.
            </div>
        </div>
        <v-row class="mx-4 pb-3">
          <!--
            <v-layout v-if="option == 'product' || option == 'keyword'" justify-end>
                <v-flex  shrink class="mr-3">
                    <v-btn block depressed color="teal lighten-2" dark @click="add">
                        <v-icon left class="mr-3">
                            mdi-plus
                        </v-icon>
                        대량 등록/삭제
                    </v-btn>
                </v-flex>
                <v-flex shrink>
                  <v-btn block depressed color="teal lighten-2" dark @click="add">
                    <v-icon left class="mr-3">
                      mdi-plus
                    </v-icon>
                    개별 등록
                  </v-btn>
                </v-flex>
            </v-layout>
            -->
            <v-layout justify-end>
                <div v-if="option == 'product'" class="mr-2">
                  <v-btn block
                         depressed
                         @click="downloadCSV"
                  >
                    다운로드</v-btn>
                </div>
                <div>
                  <v-btn block depressed color="teal lighten-2" dark @click="add">
                    <v-icon left class="mr-3">
                      mdi-plus
                    </v-icon>
                    등록하기
                  </v-btn>
                </div>
            </v-layout>
        </v-row>

        <v-card flat class="pb-3">
            <v-card-text>
                <v-row class="mt-2 px-4 pb-3">

                    <table class="mtable">
                        <thead>
                        <tr v-if="option == 'product'" class="theader">
                            <th class="text-left" style="border-bottom:1px solid #666">제외 상품 <span v-html="getTotal()" style="margin-left:6px;"></span></th>
                            <th class="text-center" style="border-bottom:1px solid #666">등록일시</th>
                            <th class="text-center" style="border-bottom:1px solid #666">삭제</th>
                        </tr>
                        <tr v-else-if="option == 'keyword'" class="theader">
                            <th class="text-left" style="border-bottom:1px solid #666">제외 키워드</th>
                            <th class="text-center" style="border-bottom:1px solid #666">등록일시</th>
                            <th class="text-center" style="border-bottom:1px solid #666">삭제</th>
                        </tr>
                        <tr v-else-if="option == 'product_first'" class="theader">
                            <th class="text-left" style="border-bottom:1px solid #666">우선 노출 상품 <span v-html="getTotal()" style="margin-left:6px;"></span></th>
                            <th class="text-center" style="border-bottom:1px solid #666">최종 수정일시</th>
                            <th class="text-center" style="border-bottom:1px solid #666">삭제</th>
                        </tr>
                        <tr v-else-if="option == 'keyword_first'" class="theader">
                            <th class="text-left" style="border-bottom:1px solid #666">우선 노출 키워드 <span v-html="getTotal()" style="margin-left:6px;"></span></th>
                            <th class="text-center" style="border-bottom:1px solid #666">최종 수정일시</th>
                            <th class="text-center" style="border-bottom:1px solid #666">삭제</th>
                        </tr>

                        </thead>
                        <tbody v-if="option == 'product'">
                            <tr v-if="itemList.length == 0">
                                <td class="text-center" colspan="3" style="color: #888">등록된 내역이 없습니다.</td>
                            </tr>
                            <tr v-else v-for="obj in itemList" :key="obj.item">
                                <td class="text-left">
                                    <v-row class="pa-3">
                                        <v-avatar
                                                size="100"
                                                tile
                                        >
                                            <v-img v-if="obj.thumb != null" :src="obj.thumb"></v-img>
                                        </v-avatar>
                                        <v-col class="align-self-center">
                                            <strong>{{ obj.item }}</strong>
                                            <br>
                                            <span v-if="obj.p_name == null || obj.p_name == ''" style="color: #888">상품정보 없음</span>
                                            <span v-else>{{ obj.p_name }}</span>
                                        </v-col>
                                    </v-row>
                                </td>
                                <td class="text-center">{{ obj.regdate }}</td>
                                <td class="text-center">
                                    <v-btn class="ma-2" depressed color="blue-grey darken-3" dark small
                                           @click="del(obj)"
                                    >
                                        <v-icon style="font-size: 1.4em" left>mdi-trash-can-outline</v-icon>
                                        삭제
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="option == 'product_first'">
                        <tr v-if="itemList.length == 0">
                            <td class="text-center" colspan="3" style="color: #888">등록된 내역이 없습니다.</td>
                        </tr>
                        <tr v-else v-for="obj in itemList" :key="obj.item">
                            <td class="text-left">
                                <v-row class="pa-3">
                                    <v-avatar
                                            size="100"
                                            tile
                                    >
                                        <v-img v-if="obj.thumb != null" :src="obj.thumb"></v-img>
                                    </v-avatar>
                                    <v-col class="align-self-center">
                                        <strong>{{ obj.item }}</strong>
                                        <br>
                                        <span v-if="obj.p_name == null || obj.p_name == ''" style="color: #888">상품정보 없음</span>
                                        <span v-else>{{ obj.p_name }}</span>
                                    </v-col>
                                </v-row>
                            </td>
                            <td class="text-center">{{ obj.regdate }}</td>
                            <td class="text-center">
                                <v-btn class="ma-2" depressed color="blue-grey darken-3" dark small
                                       @click="del(obj)"
                                >
                                    <v-icon style="font-size: 1.4em" left>mdi-trash-can-outline</v-icon>
                                    삭제
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-else-if="option == 'keyword' || 'keyword_first'">
                            <tr v-if="itemList.length == 0">
                                <td class="text-center" colspan="3" style="color: #888">등록된 내역이 없습니다.</td>
                            </tr>
                            <tr v-else v-for="obj in itemList" :key="obj.item">
                                <td class="text-left"><strong>{{ obj.item }}</strong></td>
                                <td class="text-center">{{ obj.regdate }}</td>
                                <td class="text-center">
                                    <v-btn class="ma-2" depressed color="blue-grey darken-3" dark small
                                           @click="del(obj)"
                                    >
                                        <v-icon style="font-size: 1.4em" left>mdi-trash-can-outline</v-icon>
                                        삭제
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </v-row>

                <v-row v-if="option == 'product' || option == 'keyword'" class="px-4 pb-3">
                    <div class="text-center pa-2" style="width:100%">
                        <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
                    </div>
                </v-row>

            </v-card-text>
        </v-card>

        <v-row class="pb-3">
            <v-dialog v-model="dialog" scrollable max-width="700px">
                <v-card>
                    <v-card-title style="color: #666">
                        <v-icon class="mr-3">mdi-check</v-icon>
                        {{ dialogm1.title }}
                    </v-card-title>
                    <v-card-text style="max-height: 500px; font-color: #666" class="pa-7">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row class="pb-3">
                                <v-col>
                                    <v-textarea
                                            :rules="[rules.required]"
                                            outlined
                                            v-model="formData.items"
                                            :placeholder="dialogm1.message"
                                            hide-details
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="teal darken-1" text @click="dialog = false">닫기</v-btn>
                        <v-btn color="teal darken-1" text @click="register">등록</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        watch:{
            "page":function () {
                this.load()
            }
        },
        props:{
            company_code:{
                type:String,
                default:''
            },
            option:{
                type:String,
                default:''
            }
        },
        mounted() {
            // formData.myshp, formData.type 에 값 셋팅
            this.formData.type = this.option.charAt(0)
            if(!!this.company_code){
                this.formData.company_code = this.company_code
            }
            this.page = 1
            this.pageCount = 1
            this.itemsPerPage = 50
            if(this.option.indexOf('keyword') >= 0){
              this.ph_search = "검색할 키워드를 입력하세요"
            }else {
              this.ph_search = "검색할 상품코드를 입력하세요"
            }
            this.load()
        },
        methods:{
            search: function() {
                this.page = 1;
                this.load();
            },
            load : function(){
                var that = this
                var sitem = this.search_item==null?'':this.search_item
                if(this.option == 'product' || this.option == 'keyword') {
                    var url = "/api/more/manage_list?type=" + this.formData.type + "&search_item=" + sitem
                    url += "&page="+(this.page-1)
                    url += "&size=" + this.itemsPerPage
                    if(!!this.company_code){
                        url += "&company_code=" + this.company_code
                    }

                    this.$http.get(url).then(res=>{
                        this.itemList = res.data.content
                        res.data.content.forEach(function(e, i){
                            e.regdate = moment(e.regdate).format('YYYY-MM-DD HH:mm:ss')
                            if(that.formData.type == 'p'){
                                var url2 = "/api/more/manage/get_catalog?item=" + e.item
                                if(!!that.company_code){
                                    url2 += "&company_code=" + that.company_code
                                }
                                that.$http.get(url2).then(res2=>{
                                    e.p_name = res2.data.p_name
                                    e.thumb = (!!res2.data.thumb)?"https://cdnpxy0.mocoplex.com/" + res2.data.thumb:res2.data.thumb
                                    that.$set(that.itemList, i, e)
                                }).catch((err2)=>{

                                })
                            }
                        })
                        if(res.data.totalPages >= 1){
                            this.pageCount = res.data.totalPages
                            this.totalCount = res.data.totalElements
                        }
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        this.$event.$emit('my-toast',msg)
                    })
                }else if(this.option == 'product_first' || this.option == 'keyword_first') {
                    var url = "/api/more/manage_first_list?type=" + this.formData.type
                    if(!!this.company_code){
                        url += "&company_code=" + this.company_code
                    }

                    this.$http.get(url).then(res=>{
                        this.itemList = res.data
                        this.totalCount = res.data.length
                        res.data.forEach(function(e, i){
                            e.regdate = moment(e.regdate).format('YYYY-MM-DD HH:mm:ss')
                            if(that.formData.type == 'p'){
                                var url2 = "/api/more/manage/get_catalog?item=" + e.item
                                if(!!that.company_code){
                                    url2 += "&company_code=" + that.company_code
                                }
                                that.$http.get(url2).then(res2=>{
                                    e.p_name = res2.data.p_name
                                    e.thumb = (!!res2.data.thumb)?"https://cdnpxy0.mocoplex.com/" + res2.data.thumb:res2.data.thumb
                                    that.$set(that.itemList, i, e)
                                }).catch((err2)=>{

                                })
                            }
                        })
                    }).catch((err)=>{
                        var msg = err.response.data.msg
                        this.$event.$emit('my-toast',msg)
                    })
                }
            },
            downloadCSV: function () {
              var that = this
              this.$event.$emit('confirm', '데이터 다운로드', '대량의 데이터는 시간이 오래걸릴수 있습니다.\n계속하시겠습니까?', function () {
                var url = "/api/more/report/manage_list/csv?"
                var params = 'type=' + that.formData.type
                if(!!that.company_code){
                  params += "&company_code=" + that.company_code
                }
                location.href = url + params
              });
            },
            add : function(){
                var title = ''
                var msg = ''
                if(this.option == 'product'){
                    title = '추천 제외 상품 코드 등록'
                    msg = '추천결과에서 제외할 상품코드를 입력해주세요.\n복수개 입력시 공백없이 콤마(,)로 구분하여 입력합니다.'
                }else if(this.option == 'keyword'){
                    title = '추천 제외 키워드 등록'
                    msg = '추천결과에서 제외할 키워드를 입력해주세요.\n복수개 입력시 공백없이 콤마(,)로 구분하여 입력합니다.'
                }else if(this.option == 'product_first'){
                    title = '우선 노출 상품 코드 등록'
                    msg = '추천결과에서 우선 노출할 상품코드를 입력해주세요.\n복수개 입력시 공백없이 콤마(,)로 구분하여 입력합니다.'
                }else if(this.option == 'keyword_first'){
                    title = '우선 노출 키워드 등록'
                    msg = '추천결과에서 우선 노출할 키워드를 입력해주세요.\n복수개 입력시 공백없이 콤마(,)로 구분하여 입력합니다.'
                }
                this.dialogm1.title = title
                this.dialogm1.message = msg
                this.dialog = true
            },
            del : function(obj){
                if(this.option == 'product' || this.option == 'keyword') {
                    var param = {};
                    param.item = obj.item
                    param.type = this.formData.type
                    if(!!this.company_code){
                        param.company_code = this.company_code
                    }
                    var that = this
                    this.$event.$emit('confirm', '삭제', '선택한 항목을 삭제하시겠습니까?', function () {
                        var url = "/api/more/manage_delete"
                        this.$http.post(url,param).then(res=>{
                            if(res.status == '200'){
                                that.$event.$emit('my-toast','삭제되었습니다.')
                                that.remove(obj.item)
                            }
                        }).catch((err)=>{
                            var msg = err.response.data.msg
                            that.$event.$emit('my-toast',msg)
                        })
                    })
                } else if(this.option == 'product_first' || this.option == 'keyword_first') {
                    var param = {};
                    param.type = this.formData.type
                    if(!!this.company_code){
                        param.company_code = this.company_code
                    }
                    var uitems = ''
                    this.itemList.forEach(function(e,i){
                        var item = e.item
                        if(item == obj.item)
                            return

                        if(i !== 0){
                            uitems += ','
                        }
                        uitems += item
                    })
                    param.uitems = uitems

                    var that = this
                    this.$event.$emit('confirm', '삭제', '선택한 항목을 삭제하시겠습니까?', function () {
                        var url = "/api/more/manage_first_additems"
                        this.$http.post(url, param).then(res => {
                            if (res.status == '200') {
                                that.$event.$emit('my-toast','삭제되었습니다.')
                                that.remove(obj.item)
                            }
                        }).catch((err) => {
                            that.$event.$emit('alert', '오류가 발생했습니다')
                        })
                    })
                }
            },
            remove:function(item) {

                for (var i = 0; i < this.itemList.length; i++) {
                    if (this.itemList[i].item === item) {
                        this.itemList.splice(i, 1);
                        break
                    }
                }
            },
            register : function(){
                if (!this.$refs.form.validate()) {
                    return;
                }

                var that = this
                if(this.option == 'product' || this.option == 'keyword') {
                    var url = "/api/more/manage_additems"
                    if (!!this.company_code) {
                        this.formData.company_code = this.company_code
                    }
                    this.$http.post(url, this.formData).then(res => {
                        if (res.status == '200') {
                            that.dialog = false
                            that.$event.$emit('alert', "등록되었습니다")
                            that.$refs.form.reset()
                            this.load()
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                } else if(this.option == 'product_first' || this.option == 'keyword_first') {
                    if(that.formData.items.lastIndexOf(",") === that.formData.items.length - 1) {
                        that.formData.items = that.formData.items.substr(0, that.formData.items.length - 1)
                    }

                    var itemList = that.formData.items.split(',')
                    if(itemList.length + that.totalCount > 5) {
                        that.$event.$emit('alert', '5개 까지만 등록할 수 있습니다')
                        return
                    }

                    var uitems = ''
                    that.itemList.forEach(function(e,i){
                        var item = e.item
                        if(item.length == 0)
                            return

                        if(i !== 0){
                            uitems += ','
                        }
                        uitems += item
                    })
                    itemList.forEach(function(e){
                        var item = e
                        if(that.formData.type == 'k'){
                            item = item.toLowerCase();
                        }
                        item = item.replace(/(\s*)/g, '')
                        if(item.length == 0)
                            return

                        if(uitems.length > 0) {
                            uitems += ','
                        }
                        uitems += item
                    })
                    that.formData.uitems = uitems

                    var url = "/api/more/manage_first_additems"
                    if (!!this.company_code) {
                        this.formData.company_code = this.company_code
                    }
                    this.$http.post(url, this.formData).then(res => {
                        if (res.status == '200') {
                            that.dialog = false
                            that.$event.$emit('alert', "등록되었습니다")
                            that.$refs.form.reset()
                            this.load()
                        }
                    }).catch((err) => {
                        that.$event.$emit('alert', '오류가 발생했습니다')
                    })
                }
            },
            getTotal :function () {
                return "(Total : " + this.totalCount + ")"
            }
        },
        data: () => ({
            valid:false,
            ph_search: '',
            search_item: '',
            formData:{
                company_code:'',
                type:'',
                items:'',
                uitems:'',
            },
            page: 1,
            pageCount: 1,
            totalCount: 0,
            itemList: [],
            dialog: false,
            dialogm1:{
                title:'',
                message:''
            },
            rules: {
                required: value => !!value || '필수값입니다'
            },
        }),
    }
</script>
<style scoped>
    .bbb {
        border-bottom: 1px solid #666;
    }
</style>
