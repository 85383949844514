<template>
  <v-dialog v-model="isshow" persistent max-width="800px" style="background-color: white">
    <v-card class="pa-4">
      <v-card-title class="pa-3" style="color: #666">
        베스트 추천 상품 정보
        <v-tooltip v-if="view_type == 'thumb'" v-model="show1" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-5"
                   fab dark x-small
                   color="#29D1CF"
                   @click="view_type = 'list'"
            >
              <v-icon dark>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </template>
          <span>목록보기</span>
        </v-tooltip>
        <v-tooltip v-else v-model="show2" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="mx-5"
                   fab dark x-small
                   color="#29D1CF"
                   @click="view_type = 'thumb'"
            >
              <v-icon dark>mdi-view-dashboard</v-icon>
            </v-btn>
          </template>
          <span>썸네일보기</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <div v-if="view_type == 'thumb'">
          <div style="display: flex;justify-content: space-between;">
            <div class="best_container pt-3">
              <div class="best_container_row">
                <div v-if="best_list.length==0" class="best_item">
                  <v-btn v-if="best_list.length==0" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[0].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(0)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
                <div v-if="best_list.length<=1" class="best_item">
                  <v-btn v-if="best_list.length==1" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[1].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(1)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="best_container_row mt-2">
                <div v-if="best_list.length<=2" class="best_item">
                  <v-btn v-if="best_list.length==2" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[2].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(2)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
                <div v-if="best_list.length<=3" class="best_item">
                  <v-btn v-if="best_list.length==3" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[3].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(3)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="best_container_row mt-2">
                <div v-if="best_list.length<=4" class="best_item">
                  <v-btn v-if="best_list.length==4" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[4].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(4)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
                <div v-if="best_list.length<=5" class="best_item">
                  <v-btn v-if="best_list.length==5" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[5].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(5)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="best_container_row mt-2">
                <div v-if="best_list.length<=6" class="best_item">
                  <v-btn v-if="best_list.length==6" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[6].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(6)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
                <div v-if="best_list.length<=7" class="best_item">
                  <v-btn v-if="best_list.length==7" class="mx-2"
                         fab dark x-small
                         color="#29D1CF"
                         @click="editable=true"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div v-else class="best_item"
                     :style="'background: url('+best_list[7].thumb+') no-repeat center center/contain;'">
                  <v-btn class="mx-2"
                         fab dark x-small
                         color="red"
                         @click="remove(7)"
                  >
                    <v-icon dark>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div v-if="editable" style="flex:1" class="ma-2">
              <v-card class="pa-4">
                <v-card-title class="pa-3" style="color: #666">
                  추천 상품 등록
                </v-card-title>
                <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  >
                  <v-row class="pt-2">
                    <v-col class="py-0">
                      <v-text-field
                          label="상품코드"
                          v-model="product.recommend_product"
                          outlined
                          required
                          dense
                          :rules="required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                          label="PC 상품 URL"
                          v-model="product.p_url"
                          outlined
                          required
                          dense
                          :rules="required"
                          hint="http:// 또는 https:// 를 포함해주세요"
                          persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                          label="Mobile 상품 URL"
                          v-model="product.p_url_m"
                          outlined
                          required
                          dense
                          :rules="required"
                          hint="http:// 또는 https:// 를 포함해주세요"
                          persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-card-text>
                <div class="pa-4 d-flex">
                  <v-btn class="mr-1 flex-grow-1"
                         depressed
                         dark
                         color="grey"
                         @click="editCancel"
                  >
                    취소
                  </v-btn>
                  <v-btn class="ml-1 flex-grow-1"
                         depressed
                         dark
                         color="#EC407A"
                         @click="editOk"
                  >
                    등록
                  </v-btn>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="width:730px">
            <div class="d-flex" style="overflow: auto">
              <v-simple-table class="mtable" style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0">
                <thead>
                <tr class="theader">
                  <th>순위</th>
                  <th>상품코드</th>
                  <th>상품명</th>
                  <th>PC 페이지 URL</th>
                  <th>Mobile 페이지 URL</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!!best_list && best_list.length == 0">
                  <td class="text-center" colspan="5" style="color: #888">등록된 베스트 상품이 없습니다</td>
                </tr>
                <tr v-else v-for="(item) in best_list" :key="item.item">
                  <td>{{item.rank}}</td>
                  <td>{{item.recommend_product}}</td>
                  <td>{{item.p_name}}</td>
                  <td><a :href="item.p_url" target="_blank">{{item.p_url}}</a></td>
                  <td><a :href="item.p_url_m" target="_blank">{{item.p_url_m}}</a></td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </div>
      <div class="pa-4 d-flex">
        <v-btn class="mr-1 flex-grow-1"
               depressed
               dark
               color="grey"
               @click="close"
        >
          취소
        </v-btn>
        <v-btn class="ml-1 flex-grow-1"
               depressed
               dark
               color="#EC407A"
               @click="save"
        >
          저장
        </v-btn>
      </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BestUploadDlg",
  computed: mapGetters({
    admin: 'admin',
    companyName: 'companyName',
  }),
  props:{
    company_code:{
      type: String
    },
    isshow:{
      type: Boolean
    }
  },
  data: () => ({
    show1: false,
    show2: false,
    view_type: 'thumb',
    valid: false,
    required: [
      v => !!v || '필수 정보입니다'
    ],
    best_list:[],
    product: {
      recommend_product: null,
      p_name: null,
      p_url: null,
      p_url_m: null,
    },
    editable: false,
  }),
  mounted() {
    this.load()
  },
  methods: {
    load:function() {
      var that = this
      var url = "/api/more/get/best_products"
      if(!!this.company_code){
        url += "?company_code=" + this.company_code
      }
      this.$http.get(url).then(res=>{
        this.best_list = res.data
        res.data.forEach(function(e, i){
          var url2 = "/api/more/manage/get_catalog?item=" + e.recommend_product
          if(!!that.company_code){
            url2 += "&company_code=" + that.company_code
          }
          that.$http.get(url2).then(res2=>{
            if(!!!res2.data.p_name){
              e.p_name = '추천DB에 상품이 수집되지 않았습니다'
            }else{
              e.p_name = res2.data.p_name
              e.thumb = res2.data.thumb
            }
            that.$set(that.best_list, i, e)

          }).catch((err2)=>{

          })
        })
      }).catch((err)=>{
        this.$event.$emit('alert',"데이터 조회에 실패했습니다")
      })
    },
    editCancel: function() {
      try {
        this.$refs.form.resetValidation()
      }catch (e){}
      this.product = {}
      this.editable = false
    },
    remove: function(idx) {
      this.best_list.splice(idx, 1)
    },
    editOk: function () {
      if (!this.$refs.form.validate()) {
        return
      }

      var that = this
      var url = "/api/more/manage/get_catalog?item=" + this.product.recommend_product
      if(!!this.company_code){
        url += "&company_code=" + this.company_code
      }
      this.$http.get(url).then(res=>{
        if(!!!res.data.p_name){
          that.$event.$emit('alert',"추천DB에 상품이 수집되지 않았습니다")
          return

        }else{
          that.product.p_name = res.data.p_name
          that.product.thumb = res.data.thumb
        }
        var obj = Object.assign({}, that.product)
        that.best_list.push(obj)
        //that.$set(that.best_list, that.best_list.length-1,that.product)
        that.product = {}
        that.editable = false
      }).catch((err)=>{
        console.log(err)
      })
    },
    close: function() {
      this.editCancel()
      this.isshow = false
      this.$emit("closeDlg");
    },
    save: function (){
      if(this.best_list.length < 4 || (this.best_list.length%2) == 1) {
        this.$event.$emit('alert',"4개 이상 짝수의 상품을 등록해주세요")
        return
      }

      var url = "/api/more/upload/panel_best"
      if (!!this.company_code) {
        url += "?company_code=" + this.company_code
      }

      var that = this
      this.$event.$emit('confirm', '베스트 추천 상품', '등록한 상품으로 패널 추천상품을 변경하시겠습니까?', function () {
        that.$http.post(url, that.best_list).then(res => {
          if (res.status == '200') {
            that.close()
            that.$event.$emit('alert', '패널 베스트 추천상품이 변경되었습니다')
          }
        }).catch((err) => {
          that.$event.$emit('alert', '오류가 발생했습니다')
        })
      })
    }
  }
}
</script>

<style scoped>
.best_container {
  width: 14vw;
  display: flex;
  flex-direction: column;
}
.best_container_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.best_item {
  background-color: #e3e3e3;
  width: 6vw;
  height: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>