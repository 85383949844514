<template>
    <div>
        <!--
        <v-card v-if="option == 'clk' || option == 'sales' || option == 'search'" outlined width="100%" class="pa-5">
            <my-chart ref="chart"/>
        </v-card>
        -->
        <div v-if="option == 'all'">

            <v-row class="mb-5">
                <v-col cols="4">
                    <my-select
                            :type="option"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div class="pb-3">
              <div class="d-flex">
                <div style="width:40px;margin:auto;">
                  <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">전체 계정 상세</div>
                <div v-if="!form.company_code" style="width:110px;">
                  <v-btn block
                         depressed
                         @click="downloadCSV()"
                  >
                    다운로드</v-btn>
                </div>
              </div>
            </div>

            <v-row no-gutters>
                <v-layout column style="width:calc(100vw - 300px)">
                    <div class="d-flex" style="overflow: auto">

                        <div class="flex-grow-1">
                            <v-simple-table
                                    style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                            >
                                <template v-slot:default>
                                    <thead style="background-color: #eee"
                                    >
                                    <tr>
                                        <th rowspan="2" class="text-center bb bt br">사이트명</th>
                                        <th rowspan="2" class="text-center bb bt br">URL</th>
                                        <th rowspan="2" class="text-center bb bt br">more ID</th>
                                        <th rowspan="2" class="text-center bb bt br">업종</th>
                                        <th rowspan="2" class="text-center bb bt br">계정 생성일</th>
                                        <th rowspan="2" class="text-center bb bt br">스크립트<br>확인여부</th>
                                        <th colspan="4" class="text-center bb bt">상품별 계약상태</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center bb br">패널</th>
                                        <th class="text-center bb br">티커</th>
                                        <th class="text-center bb br">추천</th>
                                        <th class="text-center bb">서치</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in all_list" :key="item.company_code">
                                        <td class="text-center bb">
                                            <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                                  @click="goDetail(item)"
                                            >{{ item.company_name }}</span>
                                        </td>
                                        <td class="text-center bb" style="max-width:500px;"><a :href="item.site_url" target="_blank" style="display:block;max-width:500px;overflow:hidden;word-break:normal;white-space:nowrap;text-overflow:ellipsis;">{{ item.site_url }}</a></td>
                                        <td class="text-center bb">{{ item.company_code }}</td>
                                        <td class="text-center bb">{{ item.category }}</td>
                                        <td class="text-center bb">{{ item.register_date }}</td>
                                        <td class="text-center bb">
                                            <span v-if="item.script_yn == 'Y'">확인 완료</span>
                                            <span v-else-if="item.script_yn == 'N'"
                                                  style="text-decoration: underline;color: #00f;cursor:pointer;text-underline-position: under;"
                                                  @click="showDialog(item)"
                                            >설치 전</span>
                                            <span v-else-if="item.script_yn == 'I'"
                                                  style="text-decoration: underline;color: #f00;cursor:pointer;text-underline-position: under;"
                                                  @click="showDialog(item)"
                                            >확인 요청</span>
                                        </td>
                                        <td class="text-center bb" v-html="getCstatus('panel',item)"></td>
                                        <td class="text-center bb" v-html="getCstatus('ticker',item)"></td>
                                        <td class="text-center bb" v-html="getCstatus('recom',item)"></td>
                                        <td class="text-center bb" v-html="getCstatus('sch',item)"></td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </v-layout>
            </v-row>

            <div class="text-center pa-2">
                <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
            </div>

        </div>

        <div v-if="option == 'panel'">

            <v-row class="mb-5">
                <v-col cols="4">
                    <my-select
                            :type="option"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
                <v-col cols="2">
                    <v-select
                            :items="s_cstatus"
                            v-model="form.cstatus"
                            label="계약상태"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            hide-details
                    ></v-select>
                </v-col>
            </v-row>

            <div class="pb-3">
              <div class="d-flex">
                <div style="width:40px;margin:auto;">
                  <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">패널 계정 상세</div>
              </div>
            </div>

            <v-row no-gutters>
                <v-layout column style="width:calc(100vw - 300px)">
                    <div class="d-flex" style="overflow: auto">

                        <div class="flex-grow-1">
                            <v-simple-table
                                    style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                            >
                                <template v-slot:default>
                                    <thead style="background-color: #eee"
                                    >
                                    <tr>
                                        <th class="text-center bb bt br">상품</th>
                                        <th class="text-center bb bt br">more ID</th>
                                        <th class="text-center bb bt br">사이트명</th>
                                        <th colspan="2" class="text-center bb bt br">사이트 URL</th>
                                        <th class="text-center bb bt br">업종</th>
                                        <th class="text-center bb bt br">무료기간</th>
                                        <th class="text-center bb bt br">유료 누적일수</th>
                                        <th class="text-center bb bt br">계약상태</th>
                                        <th class="text-center bb bt">영업채널</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="panel_list.length == 0">
                                            <td class="text-center bb" colspan="10" style="color: #888">데이터가 없습니다.</td>
                                        </tr>
                                        <template v-else  v-for="(item, i) in panel_list">

                                            <tr v-for="(option, j) in option_list">
                                                <td v-if="j == 0" rowspan="2" class="text-center bb">패널</td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.company_code }}</td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb">
                                                    <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                                          @click="goDetail(item)"
                                                    >{{ item.company_name }}</span>
                                                </td>
                                                <td class="text-center bb">{{option}}</td>
                                                <td v-if="j== 0" class="text-center bb"><a :href="item.site_url" target="_blank">{{ item.site_url }}</a></td>
                                                <td v-else class="text-center bb"><a :href="item.site_m_url" target="_blank">{{ item.site_m_url }}</a></td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.category }}</td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getFree('panel',item)"></td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getPaidDays('panel',item)"></td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getCstatus('panel',item)"></td>
                                                <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.sales_ch }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </v-layout>
            </v-row>

            <div class="text-center pa-2">
                <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
            </div>

        </div>

        <div v-if="option == 'ticker'">

            <v-row class="mb-5">
                <v-col cols="4">
                    <my-select
                            :type="option"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>

                <v-col cols="2">
                    <v-select
                            :items="s_cstatus"
                            v-model="form.cstatus"
                            label="계약상태"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            hide-details
                    ></v-select>
                </v-col>
            </v-row>

            <div class="pb-3">
              <div class="d-flex">
                <div style="width:40px;margin:auto;">
                  <v-icon color="teal">mdi-text-subject</v-icon>
                </div>
                <div class="flex-grow-1" style="margin:auto;">티커 계정 상세</div>
              </div>
            </div>

            <v-row no-gutters>
                <v-layout column style="width:calc(100vw - 300px)">
                    <div class="d-flex" style="overflow: auto">

                        <div class="flex-grow-1">
                            <v-simple-table
                                    style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                            >
                                <template v-slot:default>
                                    <thead style="background-color: #eee"
                                    >
                                    <tr>
                                        <th class="text-center bb bt br">상품</th>
                                        <th class="text-center bb bt br">more ID</th>
                                        <th class="text-center bb bt br">사이트명</th>
                                        <th colspan="2" class="text-center bb bt br">사이트 URL</th>
                                        <th class="text-center bb bt br">업종</th>
                                        <th class="text-center bb bt br">무료기간</th>
                                        <th class="text-center bb bt br">유료 누적일수</th>
                                        <th class="text-center bb bt br">계약상태</th>
                                        <th class="text-center bb bt">영업채널</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="ticker_list.length == 0">
                                        <td class="text-center bb" colspan="10" style="color: #888">데이터가 없습니다.</td>
                                    </tr>
                                    <template v-for="(item, i) in ticker_list">
                                        <tr v-for="(option, j) in option_list">
                                            <td v-if="j == 0" rowspan="2" class="text-center bb">티커</td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.company_code }}</td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb">
                                                    <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                                          @click="goDetail(item)"
                                                    >{{ item.company_name }}</span>
                                            </td>
                                            <td class="text-center bb">{{option}}</td>
                                            <td v-if="j== 0" class="text-center bb"><a :href="item.site_url" target="_blank">{{ item.site_url }}</a></td>
                                            <td v-else class="text-center bb"><a :href="item.site_m_url" target="_blank">{{ item.site_m_url }}</a></td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.category }}</td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getFree('ticker',item)"></td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getPaidDays('ticker',item)"></td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getCstatus('ticker',item)"></td>
                                            <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.sales_ch }}</td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </v-layout>
            </v-row>

            <div class="text-center pa-2">
                <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
            </div>

        </div>

        <div v-if="option == 'recom'">

          <v-row class="mb-5">
            <v-col cols="4">
              <my-select
                  :type="option"
                  :value="form.company_code"
                  @input="(newCC) => {form.company_code = newCC}"
              ></my-select>
            </v-col>

            <v-col cols="2">
              <v-select
                  :items="s_cstatus"
                  v-model="form.cstatus"
                  label="계약상태"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
              ></v-select>
            </v-col>
          </v-row>

          <div class="pb-3">
            <div class="d-flex">
              <div style="width:40px;margin:auto;">
                <v-icon color="teal">mdi-text-subject</v-icon>
              </div>
              <div class="flex-grow-1" style="margin:auto;">추천 계정 상세</div>
            </div>
          </div>

          <v-row no-gutters>
            <v-layout column style="width:calc(100vw - 300px)">
              <div class="d-flex" style="overflow: auto">

                <div class="flex-grow-1">
                  <v-simple-table
                      style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                  >
                    <template v-slot:default>
                      <thead style="background-color: #eee"
                      >
                      <tr>
                        <th class="text-center bb bt br">상품</th>
                        <th class="text-center bb bt br">more ID</th>
                        <th class="text-center bb bt br">사이트명</th>
                        <th colspan="2" class="text-center bb bt br">사이트 URL</th>
                        <th class="text-center bb bt br">업종</th>
                        <th class="text-center bb bt br">무료기간</th>
                        <th class="text-center bb bt br">유료 누적일수</th>
                        <th class="text-center bb bt br">계약상태</th>
                        <th class="text-center bb bt">영업채널</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="recom_list.length == 0">
                        <td class="text-center bb" colspan="10" style="color: #888">데이터가 없습니다.</td>
                      </tr>
                      <template v-for="(item, i) in recom_list">
                        <tr v-for="(option, j) in option_list">
                          <td v-if="j == 0" rowspan="2" class="text-center bb">추천 </td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.company_code }}</td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">
                                                      <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                                            @click="goDetail(item)"
                                                      >{{ item.company_name }}</span>
                          </td>
                          <td class="text-center bb">{{option}}</td>
                          <td v-if="j== 0" class="text-center bb"><a :href="item.site_url" target="_blank">{{ item.site_url }}</a></td>
                          <td v-else class="text-center bb"><a :href="item.site_m_url" target="_blank">{{ item.site_m_url }}</a></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.category }}</td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getFree('recom',item)"></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getPaidDays('recom',item)"></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getCstatus('recom',item)"></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.sales_ch }}</td>
                        </tr>
                      </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-layout>
          </v-row>

          <div class="text-center pa-2">
            <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
          </div>

        </div>

        <div v-if="option == 'sch'">

          <v-row class="mb-5">
            <v-col cols="4">
              <my-select
                  :type="option"
                  :value="form.company_code"
                  @input="(newCC) => {form.company_code = newCC}"
              ></my-select>
            </v-col>

            <v-col cols="2">
              <v-select
                  :items="s_cstatus"
                  v-model="form.cstatus"
                  label="계약상태"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details
              ></v-select>
            </v-col>
          </v-row>

          <div class="pb-3">
            <div class="d-flex">
              <div style="width:40px;margin:auto;">
                <v-icon color="teal">mdi-text-subject</v-icon>
              </div>
              <div class="flex-grow-1" style="margin:auto;">서치 계정 상세</div>
            </div>
          </div>

          <v-row no-gutters>
            <v-layout column style="width:calc(100vw - 300px)">
              <div class="d-flex" style="overflow: auto">

                <div class="flex-grow-1">
                  <v-simple-table
                      style="display: block;overflow-x: scroll;white-space: nowrap;border-radius: 0"
                  >
                    <template v-slot:default>
                      <thead style="background-color: #eee"
                      >
                      <tr>
                        <th class="text-center bb bt br">상품</th>
                        <th class="text-center bb bt br">more ID</th>
                        <th class="text-center bb bt br">사이트명</th>
                        <th colspan="2" class="text-center bb bt br">사이트 URL</th>
                        <th class="text-center bb bt br">업종</th>
                        <th class="text-center bb bt br">무료기간</th>
                        <th class="text-center bb bt br">유료 누적일수</th>
                        <th class="text-center bb bt br">계약상태</th>
                        <th class="text-center bb bt">영업채널</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="sch_list.length == 0">
                        <td class="text-center bb" colspan="10" style="color: #888">데이터가 없습니다.</td>
                      </tr>
                      <template v-for="(item, i) in sch_list">
                        <tr v-for="(option, j) in option_list">
                          <td v-if="j == 0" rowspan="2" class="text-center bb">서치</td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.company_code }}</td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">
                                                        <span style="text-decoration: underline;cursor:pointer;text-underline-position: under;"
                                                              @click="goDetail(item)"
                                                        >{{ item.company_name }}</span>
                          </td>
                          <td class="text-center bb">{{option}}</td>
                          <td v-if="j== 0" class="text-center bb"><a :href="item.site_url" target="_blank">{{ item.site_url }}</a></td>
                          <td v-else class="text-center bb"><a :href="item.site_m_url" target="_blank">{{ item.site_m_url }}</a></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.category }}</td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getFree('sch',item)"></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getPaidDays('sch',item)"></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb" v-html="getCstatus('sch',item)"></td>
                          <td v-if="j == 0" rowspan="2" class="text-center bb">{{ item.sales_ch }}</td>
                        </tr>
                      </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </v-layout>
          </v-row>

          <div class="text-center pa-2">
            <v-pagination color="grey" circle v-model="page" :length="pageCount" total-visible="10"></v-pagination>
          </div>

        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import MySelect from "../../common/MySelect";
    export default {
        computed: mapGetters({
            reportParam: 'getReportParam',
        }),
        props:{
            option:{
                type:String,
                default:''
            }
        },
        components: {
            //MyChart
            MySelect,
        },
        watch: {
            reportParam:function() {
                this.dataload()
            },
            page:function () {
                this.dataload()
            },
            "form.company_code": function () {
                this.dataload()
            },
            "form.cstatus": function () {
                this.dataload()
            }
        },
        destroyed() {
        },
        mounted() {
            this.dataload()

            //this.$global.getParameter()
            //var v = this.$cookie.get('sid')
            //이렇게 쓸수있는건
            //$cookie = 이런식으로 저기 컴포넌트에서 전역으로 해주니까
            //console.log(v)
        },
        methods:{
            dataload:function() {
                if(this.form.company_code == null)
                  return

                if (this.option == 'all') {
                    var url = "/api/more/get/all_account?company_code=" + this.form.company_code
                    url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                    this.$http.get(url).then(res => {
                        this.all_list = res.data.content

                        var paging = res.data.pageable
                        this.page = paging["pageNumber"] + 1
                        this.pageCount = res.data.totalPages
                    })
                    .catch((err)=>{
                        this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'panel') {
                    var url = "/api/more/get/panel_account?company_code=" + this.form.company_code + "&cstatus=" + this.form.cstatus
                    url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                    this.$http.get(url).then(res => {
                        this.panel_list = res.data.content

                        var paging = res.data.pageable
                        this.page = paging["pageNumber"] + 1
                        this.pageCount = res.data.totalPages
                    })
                    .catch((err)=>{
                        this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'ticker') {
                    var url = "/api/more/get/ticker_account?company_code=" + this.form.company_code + "&cstatus=" + this.form.cstatus
                    url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                    this.$http.get(url).then(res => {
                        this.ticker_list = res.data.content

                        var paging = res.data.pageable
                        this.page = paging["pageNumber"] + 1
                        this.pageCount = res.data.totalPages
                    })
                    .catch((err)=>{
                        this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                    })
                } else if(this.option == 'recom') {
                  var url = "/api/more/get/recom_account?company_code=" + this.form.company_code + "&cstatus=" + this.form.cstatus
                  url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                  this.$http.get(url).then(res => {
                    this.recom_list = res.data.content

                    var paging = res.data.pageable
                    this.page = paging["pageNumber"] + 1
                    this.pageCount = res.data.totalPages
                  })
                  .catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                } else if(this.option == 'sch') {
                  var url = "/api/more/get/sch_account?company_code=" + this.form.company_code + "&cstatus=" + this.form.cstatus
                  url += '&page='+(this.page-1)+'&size='+this.itemsPerPage
                  this.$http.get(url).then(res => {
                    this.sch_list = res.data.content

                    var paging = res.data.pageable
                    this.page = paging["pageNumber"] + 1
                    this.pageCount = res.data.totalPages
                  })
                  .catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                  })
                }
            },
            goDetail:function(item) {
                this.$router.push({path:'/manage/account/detail',query:{company_code: item.company_code}})
            },
            showDialog: function(item) {
                var that = this
                this.$event.$emit('confirm', '스크립트 확인', '['+item.company_name + ']의 스크립트 정상 설치를 확인하셨습니까?', function () {
                    var url = "/api/more/complete/script?company_code=" + item.company_code
                    this.$http.get(url).then(res=>{
                        if(res.status == '200'){
                            item.script_yn = 'Y'
                        }
                    }).catch((err)=>{
                        var msg = '오류가 발생했습니다'
                        if(err.response.data.msg == 'ALREADY_COMPLETE'){
                            msg = '이미 설치 확인이 완료되었습니다'
                            that.dataload()
                        }
                        that.$event.$emit('alert',msg)
                    })
                })
            },
            isEmptyObject:function(param) {
                return Object.keys(param).length === 0 && param.constructor === Object;
            },
            getFree: function(type, item) {
                var period = ''
                if(type == 'panel') {
                    period = this.$_ymd(item.panel_f_start_ymd)
                    period += '<br>~ '
                    period += this.$_ymd(item.panel_f_end_ymd)
                }else if(type == 'ticker') {
                    period = this.$_ymd(item.ticker_f_start_ymd)
                    period += '<br>~ '
                    period += this.$_ymd(item.ticker_f_end_ymd)
                }else if(type == 'recom') {
                  period = this.$_ymd(item.recom_f_start_ymd)
                  period += '<br>~ '
                  period += this.$_ymd(item.recom_f_end_ymd)
                }else if(type == 'sch') {
                  period = this.$_ymd(item.sch_f_start_ymd)
                  period += '<br>~ '
                  period += this.$_ymd(item.sch_f_end_ymd)
                }

                return period
            },
            getPaidDays: function(type, item) {
                var std = this.$moment(new Date()).format("YYYYMMDD")
                var days = item.past_days
                if(type == 'panel') {
                    if(!!item.panel_start_ymd && item.panel_start_ymd <= std){
                        if(!!item.panel_last_ymd && item.panel_last_ymd < std) {
                            std = item.panel_last_ymd
                        }

                        var start = this.$moment(item.panel_start_ymd, 'YYYYMMDD')
                        var diff = this.$moment(std, 'YYYYMMDD').diff(start,'days') + 1
                        days += diff
                    }
                }else if(type == 'ticker') {
                    if(!!item.ticker_start_ymd && item.ticker_start_ymd <= std){
                        if(!!item.ticker_last_ymd && item.ticker_last_ymd < std) {
                            std = item.ticker_last_ymd
                        }

                        var start = this.$moment(item.ticker_start_ymd, 'YYYYMMDD')
                        var diff = this.$moment(std, 'YYYYMMDD').diff(start,'days') + 1
                        days += diff
                    }
                }else if(type == 'recom') {
                  if(!!item.recom_start_ymd && item.recom_start_ymd <= std){
                    if(!!item.recom_last_ymd && item.recom_last_ymd < std) {
                      std = item.recom_last_ymd
                    }

                    var start = this.$moment(item.recom_start_ymd, 'YYYYMMDD')
                    var diff = this.$moment(std, 'YYYYMMDD').diff(start,'days') + 1
                    days += diff
                  }
                }else if(type == 'sch') {
                  if(!!item.sch_start_ymd && item.sch_start_ymd <= std){
                    if(!!item.sch_last_ymd && item.sch_last_ymd < std) {
                      std = item.sch_last_ymd
                    }

                    var start = this.$moment(item.sch_start_ymd, 'YYYYMMDD')
                    var diff = this.$moment(std, 'YYYYMMDD').diff(start,'days') + 1
                    days += diff
                  }
                }

                return days + '일'
            },
            getCstatus: function (type, item) {
                var cstatus = ''
                if(type == 'panel'){
                    cstatus = item.panel_cstatus
                }else if(type == 'ticker') {
                    cstatus = item.ticker_cstatus
                }else if(type == 'recom') {
                  cstatus = item.recom_cstatus
                }else if(type == 'sch') {
                  cstatus = item.sch_cstatus
                }

                if(cstatus == 'N') {
                    return '신규'
                }else if(cstatus == 'F') {
                    return '무료'
                }else if(cstatus == 'P') {
                    return '유료'
                }else if(cstatus == 'W') {
                    return '대기'
                }else if(cstatus == 'E') {
                    return '종료'
                }else if(cstatus == 'A') {
                    return '유료 대기'
                }else {
                    return '-'
                }
            },
            downloadCSV: function () {
              var url = "/api/more/report/account/csv"
              location.href = url
            },
        },
        data: () => ({
            page: 1,
            pageCount: 1,
            itemsPerPage: 30,

            form: {
                company_code : '',
                cstatus : '',
            },
            option_list:["P","M"],
            all_list: null,
            panel_list: null,
            ticker_list: null,
            recom_list: null,
            sch_list: null,
            s_cstatus:[
                {
                    id: "",
                    name: "전체"
                },
                {
                    id: "W",
                    name: "대기"
                },
                {
                    id: "F",
                    name: "무료"
                },
                {
                    id: "P",
                    name: "유료"
                },
                {
                    id: "E",
                    name: "종료"
                }
            ],
        }),
    }
</script>
<style scoped>
    .bb {
        border-bottom: 1px solid #ccc;
    }
    .br {
        border-right: 1px solid #ccc;
    }
    .bl {
        border-left: 1px solid #ccc;
    }
    .bt {
        border-top: 1px solid #ccc;
    }
    .bbt {
        border-top: 1px solid #666;
    }
    .num {
        font-size: 11px;
        color:  #666;
    }
    .sum {
        font-size: 11px;
        color:  #000;
        font-weight: 600;
    }

    .v-data-table
    /deep/
    tbody
    /deep/
    tr:hover:not(.v-data-table__expanded__content) {
        background: transparent !important;
    }
</style>
