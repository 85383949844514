<template>

    <div>

        <div v-if="!!mydata" class="d-flex mt-4 mb-2">
            <v-switch
                    v-model="onCount"
                    class="ma-0 mr-10"
                    :label="mode"
                    color="teal"
                    :disabled="!!mydata.start_ymd?false:true"
                    inset
                    @change="changeTStatusAll"
            ></v-switch>
        </div>

        <div v-if="!!mydata" class="pa-2">
            <v-row align="center" class="px-2">
                <v-col cols="3">노출기간</v-col>
                <v-col>
                    <span>{{mydata.start_ymd}}</span>
                    <span class="px-2"> ~ </span>
                    <span v-if="!!mydata.end_ymd">{{mydata.end_ymd}}</span>
                    <span v-else>미설정</span>
                </v-col>
            </v-row>
        </div>

        <div v-if="!!mydata" class="d-flex mt-4">
            <v-card
                    class="pa-3 pb-6 flex-grow-1"
                    style="overflow: hidden"
                    outlined
            >

                <v-subheader class="mb-4">메시지 타입</v-subheader>

                <v-switch
                        class="ma-0 mr-10"
                        label="개인화 추천"
                        color="teal"
                        inset
                        v-model="mydata.pbyc"
                        @change="changePbyc"
                        :disabled="!!mydata.start_ymd?false:true"
                        :hide-details="!!mydata.pbyc_onoff_date?true:false"
                ></v-switch>
                <div v-if="!!mydata.pbyc_onoff_date" class="mt-1 mb-3" v-html="ymdhms(mydata.pbyc_onoff_date)"></div>

                <v-switch
                        class="ma-0 mr-10"
                        label="구매 알림"
                        color="teal"
                        inset
                        v-model="mydata.latest"
                        @change="changeLatest"
                        :disabled="!!mydata.start_ymd?false:true"
                        :hide-details="!!mydata.latest_onoff_date?true:false"
                ></v-switch>
              <div v-if="!!mydata.latest_onoff_date" class="mt-1 mb-3" v-html="ymdhms(mydata.latest_onoff_date)"></div>

                <v-switch
                        class="ma-0 mr-10"
                        label="인기상품"
                        color="teal"
                        inset
                        v-model="mydata.best"
                        @change="changeBest"
                        :disabled="!!mydata.start_ymd?false:true"
                        :hide-details="!!mydata.best_onoff_date?true:false"
                ></v-switch>
              <div v-if="!!mydata.best_onoff_date" class="mt-1 mb-3" v-html="ymdhms(mydata.best_onoff_date)"></div>

                <v-switch
                        class="ma-0 mr-10"
                        label="조회수 알림 (상세페이지 전용)"
                        color="teal"
                        inset
                        v-model="mydata.vcount"
                        @change="changeVcount"
                        :disabled="!!mydata.start_ymd?false:true"
                        :hide-details="!!mydata.vcount_onoff_date?true:false"
                ></v-switch>
              <div v-if="!!mydata.vcount_onoff_date" class="mt-1 mb-3" v-html="ymdhms(mydata.vcount_onoff_date)"></div>

                <v-switch
                        class="ma-0 mr-10"
                        label="구매수 알림 (상세페이지 전용)"
                        color="teal"
                        inset
                        v-model="mydata.bcount"
                        @change="changeBcount"
                        :disabled="!!mydata.start_ymd?false:true"
                        :hide-details="!!mydata.bcount_onoff_date?true:false"
                ></v-switch>
              <div v-if="!!mydata.bcount_onoff_date" class="mt-1 mb-3" v-html="ymdhms(mydata.bcount_onoff_date)"></div>

                <v-switch v-if="mode == 'PC'"
                        class="ma-0 mr-10"
                        label="배너형"
                        color="teal"
                        inset
                        v-model="mydata.banner"
                        @change="changeBanner"
                        :disabled="!!mydata.start_ymd?false:true"
                        :hide-details="!!mydata.banner_onoff_date?true:false"
                ></v-switch>
              <div v-if="!!mydata.banner_onoff_date" class="mt-1 mb-3" v-html="ymdhms(mydata.banner_onoff_date)"></div>

            </v-card>


        </div>
    </div>

</template>
<script>
    export default {
        props:{
            mode:{
                type: String
            },
            company_code:{
                type: String
            },
            min_ymd:{
                type: String
            },
        },
        components: {
        },
        watch:{
            company_code: function () {
                this.load()
            }
        },
        mounted() {
            this.load()
        },
        data: () => ({
            onCount:0,
            mydata:null,
        }),
        methods:{
            load: function(){
                var that = this
                var url = "/api/more/get/ticker/page_info?platform=" + this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile'){
                    url += '_web'
                }
                if(!!this.company_code){
                    url += "&company_code=" + this.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.onCount = res.data.pbyc + res.data.best + res.data.latest + res.data.vcount + res.data.bcount + res.data.banner
                        that.mydata = res.data
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
            getParams: function(panel_type) {
                var params = {}
                params.panel_type = panel_type
                var platform = this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile') {
                    platform += '_web'
                }
                params.platform = platform
                if(!!this.company_code){
                    params.company_code = this.company_code
                }

                return params
            },
            changePbyc: function(){
                var params = this.getParams('pbyc')
                params.status = this.mydata.pbyc?1:0
                this.changeTStatus(params)
            },
            changeBest: function(){
                var params = this.getParams('best')
                params.status = this.mydata.best?1:0
                this.changeTStatus(params)
            },
            changeLatest: function(){
                var params = this.getParams('latest')
                params.status = this.mydata.latest?1:0
                this.changeTStatus(params)
            },
            changeVcount: function(){
                var params = this.getParams('vcount')
                params.status = this.mydata.vcount?1:0
                this.changeTStatus(params)
            },
            changeBcount: function(){
                var params = this.getParams('bcount')
                params.status = this.mydata.bcount?1:0
                this.changeTStatus(params)
            },
            changeBanner: function(){
                var params = this.getParams('banner')
                params.status = this.mydata.banner?1:0
                this.changeTStatus(params)
            },
            changeTStatus: function(item) {
                var that = this
                this.$event.$emit('confirm', '티커 노출 상태', '티커 노출 상태를 변경하시겠습니까?', function () {
                    var url = "/api/more/update/ticker/status"
                    that.$http.post(url, item).then(res=>{
                        if(res.status == '200'){
                            that.load()
                            that.$event.$emit('alert','노출 상태가 변경되었습니다')
                        }
                    }).catch((err)=>{
                        that.load()
                        var msg = '오류가 발생했습니다'
                        var errmsg = err.response.data.msg
                        if(errmsg == 'NOCREATIVE'){
                            msg = '상품연계 메시지의 문구 설정을 확인해주세요'
                        }else if(errmsg == 'NOPAID'){
                            msg = '티커 상품의 미납내역이 있어 ON 하실 수 없습니다.<br>결제 완료 후 진행해주세요'
                        }else if(errmsg == 'STATUS_END'){
                            msg = '계약이 종료된 상품입니다'
                        }
                        that.$event.$emit('alert',msg)
                    })
                }, function() {
                    if(item.panel_type == 'pbyc'){
                        that.mydata.pbyc = !item.status
                    }else if(item.panel_type == 'best'){
                        that.mydata.best = !item.status
                    }else if(item.panel_type == 'latest'){
                        that.mydata.latest = !item.status
                    }else if(item.panel_type == 'bcount'){
                        that.mydata.bcount = !item.status
                    }else if(item.panel_type == 'vcount'){
                        that.mydata.vcount = !item.status
                    }else if(item.panel_type == 'banner'){
                        that.mydata.banner = !item.status
                    }
                })
            },
            changeTStatusAll: function() {
                var that = this
                var platform = this.mode.toLowerCase()
                if(this.mode.toLowerCase() == 'mobile'){
                    platform += '_web'
                }
                this.mydata.platform = platform
                if(!!this.company_code){
                    this.mydata.company_code = this.company_code
                }

                if(this.onCount){
                    this.$event.$emit('confirm', '티커 노출 상태', '모든 티커 메시지의 노출 상태를 변경하시겠습니까?', function () {
                        var url = "/api/more/update/ticker/all_status_on"
                        that.$http.post(url, that.mydata).then(res=>{
                            if(res.status == '200'){
                                that.load()
                                that.$event.$emit('alert','노출 상태가 변경되었습니다')
                            }
                        }).catch((err)=>{
                            that.load()
                            var msg = '오류가 발생했습니다'
                            var errmsg = err.response.data.msg
                            if(errmsg == 'NOCREATIVE'){
                                msg = '상품연계 메시지의 문구 설정을 확인해주세요'
                            }else if(errmsg == 'NOPAID'){
                                msg = '티커 상품의 미납내역이 있어 ON 하실 수 없습니다.<br>결제 완료 후 진행해주세요'
                            }else if(errmsg == 'STATUS_END'){
                                msg = '계약이 종료된 상품입니다'
                            }
                            that.$event.$emit('alert',msg)
                        })
                    }, function() {
                        that.onCount = !that.onCount
                    })
                }else{
                    this.$event.$emit('confirm', '티커 노출 상태', '모든 티커 메시지의 노출 상태를 변경하시겠습니까?', function () {
                        var url = "/api/more/update/ticker/all_status_off"
                        that.$http.post(url, that.mydata).then(res=>{
                            if(res.status == '200'){
                                that.load()
                                that.$event.$emit('alert','노출 상태가 변경되었습니다')
                            }
                        }).catch((err)=>{
                            that.load()
                            var msg = '오류가 발생했습니다'
                            that.$event.$emit('alert',msg)
                        })
                    }, function() {
                        that.onCount = !that.onCount
                    })
                }

            },
            ymdhms:function (date) {
              var strDate = this.$moment(date).format('YYYY.MM.DD HH:mm:ss')
              return '<span style="font-size: 12.5px">최종 변경일시 : '+strDate+'</span>'
            },
        }
    }
</script>
<style scoped>
</style>