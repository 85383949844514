<template>
    <div>
        <div class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="티커 관리"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4 pb-3" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="ticker"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    v-if="admin == 0 || !!form.company_code"
            >
                <v-tab key="opt1"
                >
                    Step1. 지면 관리
                </v-tab>
                <v-tab key="opt2"
                >
                    Step2. 티커소재 관리
                </v-tab>
                <v-tab key="opt3"
                >
                    Step3. 노출 관리
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless
                          v-if="admin == 0 || !!form.company_code"
            >
                <v-tab-item
                        class="pa-5"
                        key="opt1">

                    <div :key="flag">
                        <div v-if="!!!min_ymd"></div>
                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div class="d-flex">
                                <my-ticker-setting class="pr-8 flex-grow-1" mode="PC" :company_code="form.company_code" :min_ymd="min_ymd"/>
                                <div style="border-left: #efefef 0.5px solid"></div>
                                <my-ticker-setting class="pl-8 flex-grow-1" mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item
                        class="pa-5"
                        key="opt2">

                    <!--
                    <div class="d-flex">
                        <div class="flex-grow-1" style=";font-size: 0.7em;align-self: center">
                            계약 기간: YYYY.MM.DD ~ YYYY.MM.DD
                        </div>
                        <v-btn
                                depressed
                                dark
                                color="#EC407A"
                        >
                            <v-icon class="mr-1">mdi-content-save</v-icon>
                            설정 저장하기
                        </v-btn>
                    </div>
                    -->
                    <div>
                        <div v-if="!!!min_ymd"></div>
                        <div v-else>
                            <my-tab v-model="tab" :list="tab_list"/>

                            <div v-if="tab==0">

                                <!--
                                <div class="d-flex">
                                    <div class="flex-grow-1 text-right mr-6" style="align-self: center;font-size: 0.7em">
                                        최근 업데이트 : YYYY.MM.DD ~ YYYY.MM.DD
                                    </div>
                                </div>
                                -->
                                <setting-ticker-pc :company_code="form.company_code" />

                            </div>
                            <div v-else-if="tab==1">

                                <!--
                                <div class="d-flex">
                                    <div class="flex-grow-1 text-right mr-6" style="align-self: center;font-size: 0.7em">
                                        최근 업데이트 : YYYY.MM.DD ~ YYYY.MM.DD
                                    </div>
                                </div>
                                -->

                                <setting-ticker-mob :company_code="form.company_code" />

                            </div>

                        </div>
                    </div>
                </v-tab-item>

                <v-tab-item
                        class="pa-5"
                        key="opt3">

                    <div :key="flag">
                        <div v-if="!!!min_ymd"></div>
                        <div v-else>
                            <div class="text-right pb-4 pr-4" style="font-size: 1em">
                                계약 기간 : <span v-html="$_ymd2(term_start)"></span>
                                <span> ~ </span>
                                <span v-if="!!term_end" v-html="$_ymd2(term_end)"/>
                                <span v-else>미정일</span>
                            </div>

                            <div class="d-flex">
                                <my-ticker-status class="pr-8 flex-grow-1" mode="PC" :company_code="form.company_code" :min_ymd="min_ymd"/>
                                <div style="border-left: #efefef 0.5px solid"></div>
                                <my-ticker-status class="pl-8 flex-grow-1" mode="Mobile" :company_code="form.company_code" :min_ymd="min_ymd"/>
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>


        </div>

    </div>
</template>

<script>
    import MyTab from "../../common/MyTab";
    import MainTitle from "../../common/MainTitle";
    import { mapMutations, mapGetters } from 'vuex'
    import MySelect from "../../common/MySelect";
    import MyTickerSetting from "../../common/MyTickerSetting";
    import MyTickerSetting_PC from "../../common/MyTickerSetting_nw_PC";
    import MyTickerSetting_MOB from "../../common/MyTickerSetting_nw_MOB";
    import MyTickerStatus from "../../common/MyTickerStatus";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        watch:{
            admin: function () {
                this.loadAll()
            },
            "form.company_code": function () {
                this.loadAll()
            },
            mytab:function () {
                this.flag++
            }
        },
        components: {
            MainTitle,
            MyTab,
            MySelect,
            MyTickerSetting,
            MyTickerStatus,
            'setting-ticker-pc':MyTickerSetting_PC,
            'setting-ticker-mob':MyTickerSetting_MOB
        },
        mounted() {
            this.loadAll()
        },
        methods:{
            flag:0,
            loadAll: function() {
                this.tab = 0
                this.load_current()
            },
            load_current: function() {
                if(this.admin == -1)
                    return

                if(this.admin == 1 && !!!this.form.company_code)
                    return

                var that = this
                var url = "/api/more/get/current_period?type=ticker"
                if(!!this.form.company_code){
                    url += "&company_code=" + this.form.company_code
                }
                this.$http.get(url).then(res=>{
                    if(!!res.data) {
                        that.term_start = res.data.term_start
                        that.term_end = res.data.term_end
                        var today = this.$moment(new Date()).format('YYYYMMDD')
                        that.min_ymd = this.$moment(that.term_start,'YYYYMMDD').format('YYYY-MM-DD')
                    }
                }).catch((err)=>{
                    this.$event.$emit('alert',"데이터 조회에 실패했습니다")
                })
            },
        },
        data: () => ({
            min_ymd: '',
            form: {
                company_code: ''
            },
            mytab:null,
            show_pc:false,
            params:{},

            tab:0,
            tab_list: [
                '<span class="mr-1" style="font-size: 1.3em"></span>\n' +
                'PC',
                '<span class="mr-1" style="font-size: 1.3em"></span>\n' +
                'Mobile'
            ],
            term_start: '',
            term_end: '',
        })
    }
</script>

<style scoped>
    .mtable > tbody > tr > td {
        padding: 20px;
    }
</style>
