<template>
    <div>
        <div class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="추천 상품 관리"/>
                </v-card-title>
            </div>

            <v-row class="pl-4 pt-4 pb-3" v-if="admin == 1">
                <v-col cols="4">
                    <my-select
                            type="all2"
                            option_default="선택"
                            :value="form.company_code"
                            @input="(newCC) => {form.company_code = newCC}"
                    ></my-select>
                </v-col>
            </v-row>

            <div v-if="admin==1 && !!!form.company_code" style="height: 500px">
            </div>

            <v-tabs color="teal"
                    v-model="mytab"
                    v-if="admin == 0 || !!form.company_code"
            >
                <v-tab key="product"
                >
                    노출 제외 상품
                </v-tab>
                <v-tab key="keyword"
                >
                  노출 제외 키워드
                </v-tab>
                <v-tab key="product_first"
                >
                    우선 노출 상품
                </v-tab>
                <v-tab key="keyword_first"
                >
                    우선 노출 키워드
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="mytab"
                          touchless
                          v-if="admin == 0 || !!form.company_code"
            >
                <v-tab-item
                        class="pa-5"
                        key="product">
                    <mdetail :company_code="form.company_code" option="product" ref="product" :key="flag"/>
                </v-tab-item>
                <v-tab-item
                    class="pa-5"
                    key="keyword">
                  <mdetail :company_code="form.company_code" option="keyword" ref="keyword" :key="flag"/>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="product_first">
                    <mdetail :company_code="form.company_code" option="product_first" ref="product_first" :key="flag"/>
                </v-tab-item>
                <v-tab-item
                        class="pa-5"
                        key="keyword_first">
                    <mdetail :company_code="form.company_code" option="keyword_first" ref="keyword_first" :key="flag"/>
                </v-tab-item>
            </v-tabs-items>


        </div>

    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import { mapMutations, mapGetters } from 'vuex'
    import MySelect from "../../common/MySelect";
    import Mdetail from "./Mdetail";

    export default {
        computed: mapGetters({
            admin: 'admin',
            companyName: 'companyName',
        }),
        watch:{
            mytab:function () {
                this.flag++
            },
            admin: function () {
                this.flag++
                this.load()
            },
            "form.company_code": function () {
                this.flag++
                this.load()
            },
        },
        components: {
            Mdetail,
            MainTitle,
            MySelect,
        },
        mounted() {
            this.load()
        },
        methods:{
            load: function() {
                this.tab = 0
            },
        },
        data: () => ({
            form: {
                company_code: ''
            },
            mytab:null,
            params:{},
            tab:0,
            flag:0,
        })
    }
</script>
