<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title title="사이트 분석"/>
                </v-card-title>
            </div>

            <div class="pt-4 pl-6">
                <v-row v-if="admin == 1">
                    <v-col cols="4">
                        <my-select
                                type="available"
                                option_default="전체"
                                :value="form2.company_code"
                                @input="(newCC) => {form2.company_code = newCC}"
                        ></my-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <my-date-range @mychange="calendarChanged" :start="computedStartDate" :end="computedEndDate"></my-date-range>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col style="max-width:250px;">
                        <v-select
                                style="max-width:250px;"
                                :items="dList"
                                v-model="form2.platform"
                                label="디바이스"
                                item-text="name"
                                item-value="id"
                                outlined
                                dense
                        ></v-select>
                    </v-col>
                </v-row>
            </div>

          <div class="pa-5">
            <total-detail />
          </div>
        </v-card>
    </div>
</template>

<script>
    import MainTitle from "../../common/MainTitle";
    import MySelect from "../../common/MySelect";
    import TotalDetail from "./TotalDetail";
    import MyDateRange from "../../common/MyDateRange";

    import {mapGetters} from "vuex";
    export default {
        computed: {
            computedStartDate() {
                var v = this.$moment(new Date()).format('YYYYMM01')
                return this.$moment(v, 'YYYYMMDD').toDate()
            },computedEndDate() {
                return new Date()
            },...mapGetters({
                companyCode: 'companyCode',
                admin: 'admin',
            })
        },
        watch: {
            admin:function () {
                this.dataload()
            },
            "form2.company_code":function () {
                this.dataload()
            },
            "form2.platform":function () {
                this.dataload()
            },
        },
        mounted() {
            this.dataload()
        },
        components: {
            MainTitle,
            MySelect,
            MyDateRange,
            TotalDetail
        },
        data: () => ({
            form2: {
                company_code : '',
                admin : -1,
                start_date:'',
                end_date:'',
                platform: '',
            },
            dList:[{id:"", name:"전체"},{id:"pc", name:"pc"},{id:"mobile_web", name:"mobile 전체"},{id:"mobile_aos", name:"mobile aos"},{id:"mobile_ios", name:"mobile ios"}],
        }),
        methods: {
            calendarChanged:function (v) {
                this.form2.start_date = v.start
                this.form2.end_date = v.end
                this.dataload()
            },
            dataload: function() {
                if(this.admin == -1)
                    return

                this.form2.admin = this.admin
                var v = Object.assign({}, this.form2)
                this.$store.commit('reportParam', {value:v})
            }
        }
    };
</script>