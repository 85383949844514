<template>
    <div>
        <v-card class="mcard">
            <div class="panel-title">
                <v-card-title class="pb-1">
                    <main-title :title="page_title"/>
                </v-card-title>
            </div>
            <div class="pa-4">
                <v-row class="mx-2">
                    <v-col cols="2">
                        <div style="display: table;height:100%;">
                            <div style="display: table-cell;vertical-align: middle;">제목</div>
                        </div>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="form.title"
                                outlined
                                dense
                                hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mx-2">
                    <v-col cols="2">
                        <div style="display: table;height:100%;">
                            <div style="display: table-cell;vertical-align: middle;">파일 첨부</div>
                        </div>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="filename"
                                clearable
                                outlined
                                dense
                                hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-btn
                                color="#566270"
                                block dark depressed
                                @click="attachFile"
                        >
                            파일찾기
                        </v-btn>
                        <input ref="file" id="attach_file" type="file" hidden
                               @change="fileHandle">
                    </v-col>
                </v-row>
                <v-row v-if="!!form.file_url" class="mx-2">
                    <v-col cols="2" class="mt-0 pt-0"></v-col>
                    <v-col class="mt-0 pt-0 pl-1">
                        <v-chip
                                class="ma-2"
                                color="teal"
                                label
                                dark
                                @click="downloadResource(form.file_url)"
                        >
                            <v-icon left>mdi-paperclip</v-icon>
                            첨부파일 다운로드
                        </v-chip>
                    </v-col>
                </v-row>
                <editor v-if="show" :initialValue="editorText" class="mt-3"
                        ref="toastuiEditor"
                        initialEditType="wysiwyg"
                        height="350px"
                />
                <v-row class="mt-3">
                    <v-spacer/>
                    <v-col cols="2">
                        <v-btn block
                               depressed
                               @click="backToList"
                        >
                            목록
                        </v-btn>
                    </v-col>
                    <v-col cols="2">
                        <v-btn v-if="page_type == 'new'" block
                               dark
                               color="#29d1cf"
                               style="font-weight: 600;"
                               depressed
                               @click="modify"
                        >
                            등록
                        </v-btn>
                        <v-btn v-else-if="page_type == 'edit'" block
                               dark
                               color="#29d1cf"
                               style="font-weight: 600;"
                               depressed
                               @click="modify"
                        >
                            저장
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>
<script>
    import MainTitle from "./MainTitle";
    import 'codemirror/lib/codemirror.css';
    import '@toast-ui/editor/dist/toastui-editor.css';

    import { Editor } from '@toast-ui/vue-editor';

    export default {
        components: {
            MainTitle,
            editor: Editor
        },
        computed: {
            page_title:function() {
                if(!!this.$route.query.id){
                    return "공지 수정하기"
                }
                return "공지 작성하기"
            },
            page_type:function() {
                if(!!this.$route.query.id){
                    return "edit"
                }
                return "new"
            }
        },
        watch: {
            filename: function () {
                if(!!!this.filename){
                    this.file = null
                    this.fileurl = ''
                }
            }
        },
        mounted() {
            if(!!this.$route.query.id){
                this.nid = this.$route.query.id
                this.dataload()
            }else {
                this.show = true
            }
        },
        data: () => ({
            show: false,
            nid: '',
            form: {
                title: '',
                content: '',
                file_url: '',
            },
            editorText: '',
            filename:'',
            file:null,
            fileurl : '',
        }),
        methods: {
            scroll() {
                this.$refs.toastuiEditor.invoke('scrollTop', 10);
            },
            moveTop() {
                this.$refs.toastuiEditor.invoke('moveCursorToStart');
            },
            getHtml() {
                let html = this.$refs.toastuiEditor.invoke('getHtml');
                return html
            },
            backToList() {
                this.$router.push({path:"/manage/notice"})
            },
            dataload: function () {
                var url = "/api/more/get/notice/detail?nid="+this.nid
                this.$http.get(url).then(res=>{
                    this.form.title = res.data.title
                    this.form.file_url = res.data.file_url
                    this.editorText = res.data.content
                    this.show = true
                }).catch((err)=> {
                    this.$event.$emit('alert', '오류가 발생했습니다')
                })
            },
            createNotice: function () {
                if(!!!this.form.title){
                    this.$event.$emit('alert',"제목을 입력해주세요")
                    return
                }

                this.form.content = this.getHtml()
                if(!!!this.form.content){
                    this.$event.$emit('alert',"내용을 입력해주세요")
                    return
                }
                if(!!this.fileurl){
                    this.form.file_url = this.fileurl
                }else{
                    this.form.file_url = ''
                }

                var url = "/api/more/create/notice"
                this.$http.post(url, this.form).then(res=>{
                    if(res.status == '200'){
                        this.$event.$emit('alert',"공지가 등록되었습니다")
                        this.backToList()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    this.$event.$emit('alert',msg)
                })
            },
            attachFile:function () {

                // upload
                document.getElementById("attach_file").click()

            },
            fileHandle() {
                var f2 = this.$refs.file.files[0];
                if (!!!f2)
                    return;

                this.file = f2
                var v = this

                // 입력된 파일로 교체
                var reader = new FileReader();
                reader.addEventListener("load", function () {
                    //v.imgsrc = reader.result
                }, false);

                if (this.file) {
                    reader.readAsDataURL(this.file);
                }

                this.filename = document.getElementById("attach_file").value
                var n = this.filename.lastIndexOf('/')
                if (n < 0) {
                    n = this.filename.lastIndexOf('\\')
                }
                if (n < 0)
                    return

                this.filename = this.filename.substring(n + 1, this.filename.length)
            },
            submitFile(){
                let formData = new FormData()
                formData.append('file', this.file)

                var v = this
                this.isLoading = true
                this.$http.post(
                    'https://img0-upload.adnext.co/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/image',
                    //'http://localhost:8181/upload/xmore/vid',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(data){
                    var url = data.data
                    // 업로드한 파일 이미지
                    v.fileurl = url
                    if(v.page_type == 'new'){
                        v.createNotice()
                    }else if(v.page_type == 'edit'){
                        v.updateNotice()
                    }
                    v.modify2()
                }).catch(function(err){
                    v.$event.$emit('alert', '파일 업로드에 실패하였습니다.')
                    v.isLoading = false
                });
            },
            modify: function() {
                if(!!this.file) {
                    this.submitFile()
                } else {
                    if(this.page_type == 'new'){
                        this.createNotice()
                    }else if(this.page_type == 'edit'){
                        this.updateNotice()
                    }
                }
            },
            updateNotice: function () {
                if(!!!this.form.title){
                    this.$event.$emit('alert',"제목을 입력해주세요")
                    return
                }

                this.form.content = this.getHtml()
                if(!!!this.form.content){
                    this.$event.$emit('alert',"내용을 입력해주세요")
                    return
                }
                this.form.nid = this.nid
                if(!!this.fileurl){
                    this.form.file_url = this.fileurl
                }else{
                    this.form.file_url = ''
                }

                var url = "/api/more/update/notice"
                this.$http.post(url, this.form).then(res=>{
                    if(res.status == '200'){
                        this.$event.$emit('alert',"공지가 수정되었습니다")
                        this.backToList()
                    }
                }).catch((err)=>{
                    var msg = '오류가 발생했습니다'
                    this.$event.$emit('alert',msg)
                })
            },
            forceDownload: function(blob, filename) {
                var a = document.createElement('a');
                a.download = filename
                a.href = blob
                document.body.appendChild(a)
                a.click()
                a.remove()
            },
            downloadResource: function(url, filename) {
                var that = this
                if (!filename) filename = url.split('\\').pop().split('/').pop();
                fetch(url, {
                    headers: new Headers({
                        'Origin': location.origin
                    }),
                    mode: 'cors'
                })
                    .then(response => response.blob())
                    .then(blob => {
                        let blobUrl = window.URL.createObjectURL(blob);
                        that.forceDownload(blobUrl, filename);
                    })
                    .catch(e => console.error(e));
            }
        },
    };
</script>